import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export class TableOptions {
  limit: number = 10;
  total: number = 0;
  searchAmount: number = 0;
  offset: number = 0;
  prop: string = 'Name'; //Sort
  dir: SortDirection = 'asc'; //Sort
  search: any = '';
  filters: any = {};
  constructor(_limit: number = 10, _prop: string = 'Name') {
    this.limit = _limit;
    this.prop = _prop;
  }
}
export class TableMessages {
  totalMessage: string = marker('TOTAL_TABLE_FOOTER');
  emptyMessage: string = marker('NO_RECORDS_FOUND');
  errorMessage: string = marker('SOMETHING_WENT_WRONG');
}
type SortDirection = 'asc' | 'desc';
