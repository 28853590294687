import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { UserType } from 'src/app/user-roles';

/* --------------------------------------------------------------------------
    Menuitems for the sidemenu

        title(optional): The heading of a page group,
        roles(optional): all roles that can access the page group
        pages(required): The pages of the group. Must contain at least one page
        [
            {
                title(required): Name of the page,
                url(required): Url of the page
                icon(optional): material icon name for icon in front of title
                roles(optional)<Array>: all roles that can access the page
                exactMatch(optional) Boolean: to show active link only when exact match
            }
        ]
*/
export interface MenuItem {
  title?: string;
  roles?: UserType | UserType[];
  pages: SubMenuItem[];
  component?: string;
}
interface SubMenuItem {
  title: string;
  url: string;
  icon?: string;
  roles?: UserType | UserType[];
  exactMatch?: boolean;
  component?: string;
}
export const menuItems: MenuItem[] = [
  {
    pages: [
      {
        title: marker('DASHBOARD_MENU'),
        url: '/',
        exactMatch: true,
        icon: 'home'
      }
    ]
  },
  {
    title: 'MANAGE_MENU',
    pages: [
      {
        title: marker('LOCATIONS_MENU'),
        url: '/stations',
        icon: 'place'
      },
      {
        title: marker('POINTS_MENU'),
        url: '/points',
        icon: 'power'
      },
      {
        title: marker('GROUPS_MENU'),
        url: '/groups',
        icon: 'groups'
      },
      {
        title: marker('USERS_MENU'),
        url: '/users',
        roles: UserType.CanEditUsers,
        icon: 'person_search'
      },
      {
        title: marker('COMPANIES_MENU'),
        url: '/companies',
        roles: [UserType.SuperUser, UserType.Admin, UserType.Operator],
        icon: 'business'
      },
      {
        title: marker('INVOICES_MENU'),
        url: '/invoices',
        roles: UserType.CanEditUsers,
        icon: 'receipt'
      },
      {
        title: marker('PACKAGE_TEMPLATES_MENU'),
        url: '/package-templates',
        roles: [UserType.SuperUser, UserType.Operator],
        icon: 'backpack'
      },
      {
        title: marker('PRODUCT_TEMPLATES_MENU'),
        url: '/product-templates',
        roles: [UserType.Admin, UserType.Operator],
        icon: 'inventory'
      },
      {
        title: marker('BULK_OPERATIONS_MENU'),
        url: '/bulk-operations',
        roles: [UserType.OwnsStations, UserType.Operator, UserType.Installer],
        icon: 'tune'
      },
      {
        title: marker('STICKERS_MENU'),
        url: '/stickers',
        roles: [UserType.Admin],
        icon: 'qr_code_2'
      }
    ]
  },
  {
    title: marker('REPORTS_MENU'),
    component: 'Reports',
    pages: [
      {
        title: marker('LOCATION_REPORTS_MENU'),
        url: '/reports/location-reports',
        icon: 'receipt_long'
      },
      {
        title: marker('POINT_USAGE_REPORT_MENU'),
        url: '/reports/point-usage',
        icon: 'description',
        component: 'BusinessReports'
      },
      {
        title: marker('USER_REPORT_MENU'),
        url: '/reports/users',
        icon: 'contact_page',
        component: 'UserReports'
      },
      {
        title: marker('Insights'),
        url: '/insights',
        roles: [UserType.SuperUser],
        icon: 'insights'
      }
    ]
  },
  {
    title: 'ADD_MENU',
    roles: [UserType.BoxManufacturer, UserType.Operator, UserType.Installer],
    pages: [
      {
        title: marker('LOCATION_MENU'),
        url: '/add/station', //'/select-plan',,
        icon: 'add_location_alt'
      },
      {
        title: marker('POINTS_MENU'),
        url: '/add/box',
        icon: 'power'
      }
    ]
  },
  {
    title: marker('BILLING_MENU'),
    roles: [UserType.OwnsCompany, UserType.OwnsStations],
    pages: [
      {
        title: 'SETTLEMENTS_MENU',
        url: '/billing/settlements',
        icon: 'currency_exchange'
      },
      {
        title: marker('PAYMENTS_MENU'),
        url: '/billing/payments',
        icon: 'paid'
      },
      {
        title: 'INVOICES_MENU',
        url: '/billing/invoices',
        icon: 'receipt_long'
      },
      {
        title: marker('FINANCIALS_MENU'),
        url: '/billing/financials',
        icon: 'table_chart'
      },
      {
        title: marker('BILLING_INFORMATION_MENU'),
        url: '/billing/settings',
        icon: 'account_balance'
      }
      // Disable for now
      // {
      //     title: 'Operator',
      //     url: '/billing/operator',
      //     icon: 'headset_mic'
      // }
    ]
  }
];
