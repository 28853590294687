import { Injectable } from '@angular/core';
import { TableOptions } from '../../classes/table-options';
import { AppliedFilters } from 'src/app/components/filters/filter-types';

@Injectable()
export class TableService {
  constructor() {}

  setOptions(options, name) {
    let tblOptions: any = this.getOptions();

    if (!tblOptions[name]) tblOptions[name] = new TableOptions();

    tblOptions[name] = Object.assign(tblOptions[name], options);

    localStorage.setItem('tblOptions', JSON.stringify(tblOptions));
  }
  /**
   * Gets the options that are stored locally.
   * Returns default options if nothing is stored
   * @param name Name of options to return
   * @param tableOptions Default options to use instead of TableOptions()
   */
  getOptions(name?, tableOptions?: TableOptions) {
    let tblOptions: any = localStorage.getItem('tblOptions');
    if (tblOptions) {
      tblOptions = JSON.parse(tblOptions);
      if (name && !tblOptions[name])
        tblOptions[name] = tableOptions || new TableOptions();
    } else {
      tblOptions = {};
      if (name) tblOptions[name] = tableOptions || new TableOptions();
    }

    if (name) {
      return tblOptions[name];
    } else {
      return tblOptions;
    }
  }


  setFilters(name: string, appliedFilter: AppliedFilters[]) {
    const filters = localStorage.getItem('filters');
    if (filters) {
      const updatedFilters = JSON.parse(filters);
      if (!updatedFilters[name]) updatedFilters[name] = [];
      updatedFilters[name] = appliedFilter;
      localStorage.setItem('filters', JSON.stringify(updatedFilters));
    } else {
      localStorage.setItem('filters', JSON.stringify({ [name]: appliedFilter }));
    }
  }

  getFilters(name: string) {
    const allFilters = localStorage.getItem('filters');
    if (allFilters) {
      const filters: any = JSON.parse(allFilters);
      if (filters[name])
        return filters[name] || [];
      else
        return [];
    } else {
      return [];
    }
  }
}
