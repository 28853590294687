import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { RoamingPlatFormActions, RoamingPlatformService } from '.';

@Injectable({ providedIn: 'root' })
export class RoamingPlatformEffects {
  loadWidgetHelp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoamingPlatFormActions.LoadRoamingPlatforms),
      mergeMap((action) =>
        this.roamingPlatformService.getRoamingPlatforms(0, 1000).pipe(
          map((data) => {
            return RoamingPlatFormActions.LoadRoamingPlatformsLoaded({ data: data.List });
          })
        )
      )
    )
  );
  constructor(
    private actions$: Actions,
    private roamingPlatformService: RoamingPlatformService
  ) { }
}
