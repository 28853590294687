import { CommonModule, ViewportScroller } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormControlElementInjectorDirective } from './directives/form-control-element-injector.directive';
import { CurrencySymbolPipe } from './pipes/currency-symbol.pipe';
import { ParseIntPipe } from './pipes/parse-int.pipe';
import { SmoothViewportScroller } from './services/smooth-viewport-scroller.service';

const DIRECTIVES = [FormControlElementInjectorDirective];
const PIPES = [CurrencySymbolPipe, ParseIntPipe];

@NgModule({
  imports: [CommonModule],
  declarations: [...DIRECTIVES, ...PIPES],
  exports: [...DIRECTIVES, ...PIPES],
  providers: [
    {
      provide: Window,
      useValue: window
    },
    {
      provide: ViewportScroller,
      useClass: SmoothViewportScroller
    }
  ]
})
export class SharedModule {}
