import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../../services';
import { map } from 'rxjs/operators';
import {
  SpotPriceItem,
  SpotPriceItemWithFee
} from 'src/app/components/spot-price/types';

@Injectable({
  providedIn: 'root'
})
export class SpotPriceService {
  constructor(private _http: HttpInterceptorService) {}

  getSpotPrice(stationID: number, startTime: number, endTime: number) {
    return this._http
      .get(`BiddingZones/PricesStation/${stationID}/${startTime}/${endTime}`)
      .pipe(
        map((res: SpotPriceItem[]) => {
          return res.map((priceItem) => {
            const hour = new Date(priceItem.Start).getHours();
            const price = priceItem.Price_PerKWH;
            const currency = priceItem.Currency;
            return {
              hour: `${hour < 10 ? `0${hour}` : hour}:00`,
              price: {
                spotPrice: price,
                currency: currency,
                fee: 0,
                charginPrice: price
              }
            } as SpotPriceItemWithFee;
          });
        })
      );
  }
}
