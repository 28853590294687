import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
/** This service can be used to refresh a specific component's data base on action from other module */
@Injectable()

export class RefreshService {

  refreshedInvoiceComment$ = new BehaviorSubject(1);
  constructor() { }

  refreshInvoiceComments() {
    this.refreshedInvoiceComment$.next(this.refreshedInvoiceComment$.getValue() + 1);
  }

}

