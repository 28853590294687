import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'sc-remove-owner',
  templateUrl: './remove-owner.component.html',
  styleUrls: ['./remove-owner.component.scss']
})
export class RemoveOwnerComponent {

  deactivateCharger = new FormControl(true);
  constructor(
    public dialogRef: MatDialogRef<RemoveOwnerComponent>
  ) { }

  ngOnInit() { }

  removeOwner() {
    this.dialogRef.close({
      deactiveCharger: this.deactivateCharger.value
    });
  }
}
