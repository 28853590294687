// @TODO: Create seperate file
import { createFeatureSelector, createSelector, select } from '@ngrx/store';

import * as fromOperator from './operator.reducer';

import { Operator } from '../../operator';

// Feature selector
export const selectOperatorState =
  createFeatureSelector<fromOperator.OperatorState>('operator');

export const selectAllOperators = createSelector(
  selectOperatorState,
  fromOperator.selectEntities
);

export const selectSelectedOperator = createSelector(
  selectOperatorState,
  (state) => {
    return state.entities[state.selectedOperatorID];
  }
);

export const selectIsLoading = createSelector(
  selectOperatorState,
  (state) => state.isLoading
);
