import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { Email } from 'src/app/classes/Email';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(private _http: HttpInterceptorService) {}

  sendEmail(email: Email): Promise<any> {
    return new Promise((resolve) => {
      this._http.post('email/SendMassEmails', email).subscribe((res) => {
        resolve(res);
      });
    });
  }
}
