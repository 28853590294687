import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  constructor(private _http: HttpInterceptorService) {}

  getCountries() {
    return this._http.get('Countries/GetAll').pipe(map((res) => res.datas));
  }
}
