// @TODO: Create seperate file
import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromCompanies from './companies.reducer';
import { Company, emptyCompany } from '../../companies/companies.model';

// Exports
export { CompaniesFacade } from './companies.facade';
export { CompaniesEffects } from './companies.effects';

// Feature selector
export const selectCompaniesState =
  createFeatureSelector<fromCompanies.CompaniesState>('companies');

export const selectProjectIds = createSelector(
  selectCompaniesState,
  fromCompanies.selectCompaniesIds
);

export const selectCompaniesEntities = createSelector(
  selectCompaniesState,
  fromCompanies.selectCompaniesEntities
);

export const selectAllCompanies = createSelector(
  selectCompaniesState,
  fromCompanies.selectAllCompanies
);

export const selectSelectedCompany = createSelector(
  selectCompaniesEntities,
  fromCompanies.getSelectedCompanyId,
  (companies, selectedCompanyId): Company => {
    return selectedCompanyId ? companies[selectedCompanyId] : emptyCompany;
  }
);

export const selectIsSingleLoading = createSelector(
  selectCompaniesState,
  fromCompanies.getIsSingleLoading
);

// @TODO: See if utilities can be created to automate this
export const selectSelectedCompanyPaymentMethods = createSelector(
  selectCompaniesState,
  fromCompanies.getSelectedCompanyId,
  (state, id) => {
    return state.paymentMethods[id];
  }
);

export const selectSelectedCompanyPaymentMethodsIsLoading = createSelector(
  selectCompaniesState,
  fromCompanies.getSelectedCompanyId,
  (state, id) => {
    return state.paymentMethodsLoading[id];
  }
);

export const selectShouldShowAddPaymentModal = createSelector(
  selectCompaniesState,
  fromCompanies.getShouldShowAddPaymentModal
);
