import { Injectable } from '@angular/core';
import { Defaults } from '../../defaults';
//import { AuthHttp } from 'angular2-jwt';
import { TokenService } from '../auth/token/token.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable, throwError } from 'rxjs';
import { map, flatMap, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class HttpInterceptorService {
  constructor(
    private http: HttpClient,
    private defaults: Defaults,
    //private authHttp: AuthHttp,
    private router: Router,
    private tokenService: TokenService,
    private snackBar: MatSnackBar
  ) {}

  addAuth(options: any = {}) {
    if (!options.headers) options.headers = {};

    options.headers.append(
      'Authorization',
      'Bearer ' + localStorage.getItem('token')
    );

    return options;
  }
  preventCache(options: any = {}) {
    if (!options.headers) options.headers = {};

    options.headers.append('Cache-Control', 'no-cache');
    options.headers.append('Pragma', 'no-cache');

    return options;
  }

  getCustomUrl(base: string, url: string, options: any = {}): Observable<any> {
    if (!options.observe) options.observe = 'response';

    return this.tokenService.check().pipe(
      flatMap((authenticated) => {
        //authenticated = false;
        if (authenticated) {
          //options = this.addAuth(options)
          //options = this.preventCache(options);
          return this.http.get(base + url, options).pipe(
            map((res) => {
              return this.extractData(res, url, 'GET');
            }),
            catchError(this.handleError)
          );
        } else {
          this.router.navigate(['auth', 'logout']);
          return throwError(
            new APIError({ errorName: 'Unable to re-authenticate' })
          );
          //throw Observable.throw('Unable to re-authenticate');
        }
      })
    );
  }

  postCustomUrl(
    base: string,
    url: string,
    body: any,
    options: any = {}
  ): Observable<any> {
    if (!options.observe) options.observe = 'response';
    return this.tokenService.check().pipe(
      flatMap((authenticated) => {
        if (authenticated) {
          //options = this.addAuth(options)
          //options = this.preventCache(options);
          return this.http.post(base + url, body, options).pipe(
            map((res) => {
              return this.extractData(res, url, 'POST');
            }),
            catchError(this.handleError)
          );
        } else {
          this.router.navigate(['auth', 'logout']);
          return throwError(
            new APIError({ errorName: 'Unable to re-authenticate' })
          );
          //throw Observable.throw('Unable to re-authenticate');
        }
      })
    );
  }

  getUnsecure(url: string, options: any = {}): Observable<any> {
    if (!options.observe) options.observe = 'response';
    //To be used on api's that requires no authentication. Request will be denied if api is secure
    return this.http.get(this.defaults.base + url, options).pipe(
      map((res) => {
        return this.extractData(res, url, 'GET_UNSECURE');
      }),
      catchError((err) => {
        return this.handleError(new APIError(err, url, 'GET_UNSECURE'));
      })
    );
  }
  get<T = any>(url: string, options: any = {}): Observable<T> {
    if (!options.observe) options.observe = 'response';
    return this.tokenService.check().pipe(
      flatMap((authenticated) => {
        if (authenticated) {
          return this.http.get(this.defaults.base + url, options).pipe(
            map((res) => {
              return this.extractData(res, url, 'GET');
            }),
            catchError((err) => {
              return this.handleError(new APIError(err, url, 'GET'));
            })
          );
        } else {
          //Prompt login.
          //this.authService.promptAuth();
          //This only happens if user does not have a refresh-token, and that can only be received after login/registration
          return this.handleError(
            new APIError({ errorName: 'Unable to re-authenticate' }, url, 'GET')
          );
        }
      })
    );
  }
  getNonJson(url: string, options: any = {}): Observable<any> {
    if (!options.observe) options.observe = 'body';
    return this.tokenService.check().pipe(
      flatMap((authenticated) => {
        //authenticated = false;
        if (authenticated) {
          return this.http
            .get(this.defaults.base + url, options)
            .pipe(catchError(this.handleError));
        } else {
          return this.handleError(
            new APIError({ errorName: 'Unable to re-authenticate' })
          );
          //throw Observable.throw('Unable to re-authenticate');
        }
      })
    );
  }
  postNonJson(url: string, body: any, options: any = {}): Observable<any> {
    if (!options.observe) options.observe = 'body';
    return this.tokenService.check().pipe(
      flatMap((authenticated) => {
        //authenticated = false;
        if (authenticated) {
          return this.http
            .post(this.defaults.base + url, body, options)
            .pipe(catchError(this.handleError));
        } else {
          return this.handleError(
            new APIError({ errorName: 'Unable to re-authenticate' })
          );
          //throw Observable.throw('Unable to re-authenticate');
        }
      })
    );
  }
  postUnsecure(url: string, body: any, options: any = {}): Observable<any> {
    if (!options.observe) options.observe = 'response';
    return this.http.post(this.defaults.base + url, body, options).pipe(
      map((res) => {
        return this.extractData(res, url, 'POST_UNSECURE');
      }),
      catchError((err) => {
        return this.handleError(new APIError(err, url, 'POST_UNSECURE'));
      })
    );
  }
  post(url: string, body: any, options: any = {}): Observable<any> {
    if (!options.observe) options.observe = 'response';
    return this.tokenService.check().pipe(
      flatMap((authenticated) => {
        if (authenticated) {
          return this.http.post(this.defaults.base + url, body, options).pipe(
            map((res) => {
              return this.extractData(res, url, 'POST');
            }),
            catchError((err) => {
              return this.handleError(new APIError(err, url, 'POST'));
            })
          );
        } else {
          //Prompt login.
          //this.authService.promptAuth();
          //This only happens if user does not have a refresh-token, and that can only be received after login/registration
          return this.handleError(
            new APIError(
              { errorName: 'Unable to re-authenticate' },
              url,
              'POST'
            )
          );
        }
      })
    );
  }
  //Put is always secure
  put<T = any>(url: string, body: any, options: any = {}): Observable<T> {
    if (!options.observe) options.observe = 'response';
    return this.tokenService.check().pipe(
      flatMap((authenticated) => {
        if (authenticated) {
          return this.http.put(this.defaults.base + url, body, options).pipe(
            map((res) => {
              return this.extractData(res, url, 'PUT');
            }),
            catchError((err) => {
              return this.handleError(new APIError(err, url, 'PUT'));
            })
          );
        } else {
          //Prompt login.
          //this.authService.promptAuth();
          //This only happens if user does not have a refresh-token, and that can only be received after login/registration
          return this.handleError(
            new APIError({ errorName: 'Unable to re-authenticate' }, url, 'PUT')
          );
        }
      })
    );
  }
  //Patch is always secure
  patch<T = any>(url: string, body: any, options: any = {}): Observable<T> {
    if (!options.observe) options.observe = 'response';
    return this.tokenService.check().pipe(
      flatMap((authenticated) => {
        if (authenticated) {
          return this.http.patch(this.defaults.base + url, body, options).pipe(
            map((res) => {
              return this.extractData(res, url, 'PATCH');
            }),
            catchError((err) => {
              return this.handleError(new APIError(err, url, 'PATCH'));
            })
          );
        } else {
          //Prompt login.
          //this.authService.promptAuth();
          //This only happens if user does not have a refresh-token, and that can only be received after login/registration
          return this.handleError(
            new APIError(
              { errorName: 'Unable to re-authenticate' },
              url,
              'PATCH'
            )
          );
        }
      })
    );
  }
  //Delete is always secure
  delete(url: string, options: any = {}): Observable<any> {
    if (!options.observe) options.observe = 'response';
    return this.tokenService.check().pipe(
      flatMap((authenticated) => {
        if (authenticated) {
          return this.http.delete(this.defaults.base + url, options).pipe(
            map((res) => {
              return this.extractData(res, url, 'DELETE');
            }),
            catchError((err) => {
              return this.handleError(new APIError(err, url, 'DELETE'));
            })
          );
        } else {
          //Prompt login.
          //this.authService.promptAuth();
          //This only happens if user does not have a refresh-token, and that can only be received after login/registration
          return this.handleError(
            new APIError(
              { errorName: 'Unable to re-authenticate' },
              url,
              'DELETE'
            )
          );
        }
      })
    );
  }
  private extractData(res, url, type: apiType) {
    if (res.status === 200) {
      if (res.body && res.body.Result) {
        res = res.body.Result;
      }
      if (res.body && !res.body.Result) {
        res = res.body;
      }

      if (res.errorName) {
        throw new APIError(res, url, type);
      } else {
        //All good
        return res;
      }
    } else {
      throw new APIError(res, url, type);
    }
  }
  handleError(err: APIError): Observable<any> {
    return throwError(new APIError(err));
  }

  externalPost(url: string, body: any, options: any = {}): Observable<any> {
    return this.http.post(url, body, options).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        return this.handleError(new APIError(err, url, 'POST'));
      })
    );
  }
}
class APIError {
  success?: boolean = false;
  errorName?: string = 'Unknown error';
  error?: number;
  datas?: any;
  api?: string;
  type?: apiType;
  constructor(err?: any, _api?: string, _type?: apiType) {
    this.api = (_type ? _type + ' ' : ' ') + _api;
    this.type = _type;

    if (err) {
      if (err.error && err.error.Message) {
        err.errorName = err.error.Message;
      } else if (err.error && err.error.datas && err.error.datas.Message) {
        err.errorName = err.error.datas.Message;
      } else if (!err.errorName) {
        err.errorName = err.statusText;
      }
      Object.assign(this, err);
    }
  }
}
type apiType =
  | 'GET'
  | 'POST'
  | 'PUT'
  | 'PATCH'
  | 'DELETE'
  | 'GET_UNSECURE'
  | 'POST_UNSECURE';
