import { Station } from '..';

export type Dto = {};
export const ALL_STATION_ID = Infinity;

export interface Revenue {
  TotalIncVAT: number;
  TotalWithoutVAT: number;
}

export interface CostItem extends Revenue {
  Name: string;
  Stations: FinancialStation[];
  Type: number;
}

export interface Cost extends Revenue {
  Items: CostItem[];
  OrderStatus: OrderStatus;
}

type Order = {
  CompanyPaidOutId: number;
  CompanyPaidOutName: string;
  Date: string;
  DatePaidOut: string | null;
  FK_CompanyId: number | null;
  FK_CustomerId: number;
  InvoiceDateIssued: string | null;
  InvoiceDatePaid: string | null;
  InvoicePaymentResult: boolean;
  InvoicedToName: string | null;
  PriceIncVAT: number;
  PriceWithoutVAT: number;
  StationId: number;
  StationName: string;
  TimeFinished: string;
  TimeStarted: string;
  Type: number;
  TypeName: String;
};

export interface Balance extends Revenue {
  Orders: Order[];
}

export interface OrderStatus {
  TotalInvoicedAndNotPaidIncVAT: number;
  TotalInvoicedAndNotPaidWithoutVAT: number;
  TotalInvoicedAndPaidIncVAT: number;
  TotalInvoicedAndPaidWithoutVAT: number;
  TotalNotInvoicedIncVAT: number;
  TotalNotInvoicedWithoutVAT: number;
  TotalPaidOutIncVAT: number;
  TotalPaidOutWithoutVAT: number;
  TotalPaidOutByMonthIncVAT: any[];
  TotalPaidAndNotPaidOutWithoutVat: number;
}

export interface FinancialStation {
  Name: string;
  StationId: number;
  StationTotalIncVAT: number;
  StationTotalWithoutVat: number;
}

export interface RevenueItem extends Revenue {
  Type: number;
  TotalType?: number;
  Name: string;
  Stations: FinancialStation[];
}

export interface RevenueWithItems extends Revenue {
  Items: RevenueItem[];
}

export type Metrics = {
  Items: RevenueItem[];
};

export type MonthlyData = {
  Key: {
    Year: number;
    Month: number;
  };
  KeyDateTime: string;
  Revenues: {
    RecurringRevenues: RevenueWithItems;
    OrderStatus: OrderStatus;
    NonRecurringRevenues: RevenueWithItems;
    TotalIncVAT: number;
    TotalWithoutVAT: number;
  };
  Costs: Cost;
  Balance: Balance;
  Metrics: Metrics;
};

export interface DateKey {
  Year: number;
  Month: number;
  Day: number;
}

export interface YearMonthDateKey {
  Year: string;
  Month: string;
}

export interface Payout extends Revenue {
  PaidOutIncVAT: number;
  PaidOutWithoutVAT: number;
  Key: DateKey;
}

export interface StationAutoCompleteItem {
  Name: string;
  StationId: number;
}
