import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  imports: [MatDatepickerModule],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'nb' }],
  exports: [MatDatepickerModule]
})
export class DateModule {}
