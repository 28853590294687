import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { Currency } from 'src/app/lib';

@Pipe({
  name: 'scCompactNumber'
})
export class CompactNumberPipe implements PipeTransform {
  transform(value: number, currency: string): string {
    const options: any = {
      notation: 'compact',
      style: 'currency',
      currency: currency
    };
    return new Intl.NumberFormat('no-NB', options).format(value);
  }
}
