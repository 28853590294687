<h2 mat-dialog-title>{{ title || 'Choose a location' }}</h2>
<div mat-dialog-content class="dialog-content">
  <sc-station-search
    (itemSelected)="selectStation($event)"
    (clear)="selectStation($event)"
    [enter]="false"></sc-station-search>
</div>
<div mat-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    (click)="selectAndClose()"
    [disabled]="!selectedStationID">
    {{ 'OK' | translate }}
  </button>
  <button mat-raised-button (click)="close()" type="button">
    {{ 'CANCEL' | translate }}
  </button>
</div>
