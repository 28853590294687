import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ComponentCriteriaTypes,
  ConfigVariable,
  Ocpp201Components,
  Ocpp201Variables
} from 'src/app/lib/commands';

@Component({
  selector: 'sc-ocpp-set-monitoring',
  templateUrl: './set-monitoring.component.html',
  styleUrls: ['./set-monitoring.component.scss']
})
export class SetMonitoringComponent implements OnInit {
  configForm: FormGroup;
  MonitoringData: FormArray;
  componentsList = Ocpp201Components;
  variablesList = Ocpp201Variables;
  // ComponentCriteriaList = ComponentCriteriaTypes;

  constructor(public dialogRef: MatDialogRef<SetMonitoringComponent>) {}

  ngOnInit() {
    this.configForm = new FormGroup({
      MonitoringData: new FormArray([this.createConfigFormItem()])
    });
    this.MonitoringData = this.configForm.get('MonitoringData') as FormArray;
  }

  createConfigFormItem() {
    const requiredValidators = [Validators.required];
    const controllerValidators = requiredValidators;
    return new FormGroup({
      id: new FormControl(null),
      transaction: new FormControl(false),
      value: new FormControl(null, [Validators.required]),
      type: new FormControl(null, [Validators.required]),
      severity: new FormControl(null, [Validators.required]),
      Controller: new FormControl('', controllerValidators),
      Key: new FormControl('', [Validators.required]),
      Instance: new FormControl(''),
      EVSEID: new FormControl('')
    });
  }

  filterComponents(event) {
    const searchText = (event.target.value || '').toLowerCase();
    if (searchText) {
      this.componentsList = [...Ocpp201Components]
        .slice()
        .filter((item) => item.toLowerCase().includes(searchText));
    } else {
      this.componentsList = [...Ocpp201Components].slice();
    }
  }

  filteVariable(event) {
    const searchText = (event.target.value || '').toLowerCase();
    if (searchText) {
      this.variablesList = [...Ocpp201Variables]
        .slice()
        .filter((item) => item.toLowerCase().includes(searchText));
    } else {
      this.variablesList = [...Ocpp201Variables].slice();
    }
  }

  addDataItem() {
    this.MonitoringData.push(this.createConfigFormItem());
  }
  deleteDataItem(index: number) {
    this.MonitoringData.removeAt(index);
  }

  handleOnSubmit(event) {
    event.preventDefault();
    if (this.configForm.valid) {
      this.dialogRef.close(this.configForm.value);
    }
  }

  close() {
    this.dialogRef.close();
  }
}
