import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { FinancialsActions, FinancialsState } from '.';
import { FinancialsService } from '../../financials';

@Injectable({ providedIn: 'root' })
export class FinancialsEffects {
  loadAllOperators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FinancialsActions.LoadCompanyFinancials),
      switchMap((action) =>
        this.financialsService
          .getFinancialsForCompany(
            action.PK_CompanyID,
            action.startTime,
            action.endTime
          )
          .pipe(
            map((data) => {
              //Making 12 months always.
              // data.Currencies.slice().map((currency) => {
              //   if (currency.MonthlyData.length < 12) {
              //     const value = currency.MonthlyData.length;
              //     for (let i = 0; i < 12 - value; i++) {
              //       const first = currency.MonthlyData[0].Key;
              //       const newMonth = first.Month - 1;
              //       if (newMonth > 0) {
              //         currency.MonthlyData.unshift({
              //           Key: { Month: first.Month - 1, Year: first.Year },
              //           KeyDateTime: `${first.Year}-${first.Month - 1}-01`
              //         });
              //       } else {
              //         currency.MonthlyData.unshift({
              //           Key: { Month: 12, Year: first.Year - 1 },
              //           KeyDateTime: `${first.Year - 1}-12-01`
              //         });
              //       }
              //     }
              //   }
              // });
              return FinancialsActions.CompanyFinancialsLoaded({ data });
            }),
            catchError((apiError) => {
              return of(
                FinancialsActions.CompanyFinancialsLoadFailed({
                  error: apiError
                })
              );
            })
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<FinancialsState>,
    private financialsService: FinancialsService
  ) {}
}
