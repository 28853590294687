import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sc-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss']
})
export class LocationSelectorComponent {

  title = '';
  selectedStationID = null;
  constructor(public dialogRef: MatDialogRef<LocationSelectorComponent>) { }


  selectStation(station) {
    this.selectedStationID = station?.ID || null;
  }

  selectAndClose() {
    this.dialogRef.close({ stationID: this.selectedStationID });
  }

  close() {
    this.dialogRef.close();
  }

}
