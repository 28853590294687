import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appCard'
})
export class AppCardPipe implements PipeTransform {
  transform(card: any, args?: any): any {
    if (!card) return;
    let first = card.substring(0, 2);
    let last = card.slice(-6);

    if (first === 'AA' && last === 'ABCDEF') {
      return 'App';
    } else {
      return card;
    }
  }
}
