import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  FormControl,
  Validators,
  FormArray,
  FormGroup,
  FormBuilder
} from '@angular/forms';
import { Input } from 'src/app/classes/DialogInput';

@Component({
  selector: 'sc-multiple-input',
  templateUrl: './multiple-input.component.html',
  styleUrls: ['./multiple-input.component.scss']
})
export class MultipleInputComponent implements OnInit {
  public inputs: Input[] = []; //Req
  title: string;
  action: string;
  content: string;
  inputsFormGroup: FormGroup;
  //inputCtrl = new FormControl('', [Validators.required]);

  get inputForms(): FormArray {
    return this.inputsFormGroup.get('inputs') as FormArray;
  }
  constructor(
    public dialogRef: MatDialogRef<MultipleInputComponent>,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    let controls = [];
    for (let input of this.inputs) {
      controls.push(
        new FormControl(
          input.preset,
          input.required ? [Validators.required] : null
        )
      );
    }
    this.inputsFormGroup = this.formBuilder.group({
      inputs: this.formBuilder.array(controls)
    });
  }
  uppercaseFirst(s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
  submitForm(ev) {
    ev.preventDefault();
    if (this.inputsFormGroup.invalid) return;

    this.dialogRef.close(this.inputForms.value);
  }
}
