<h2 mat-dialog-title>{{ 'UPDATED_TERMS_CONDITIONS' | translate }}</h2>
<div mat-dialog-content>
  <div>
    <a [href]="termsURL" class="icon-link" target="_blank">
      {{ 'TERMS_AND_CONDITIONS' | translate }}
      <mat-icon>open_in_new</mat-icon>
    </a>
  </div>
  <div class="margin-vertical">
    <mat-checkbox [(ngModel)]="termsAccepted">
      {{ 'TERMS_AND_CONDITIONS_CONSENT' | translate }}
    </mat-checkbox>
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!termsAccepted"
    type="submit"
    (click)="closeTerms()">
    {{ 'OK' | translate }}
  </button>
</div>
