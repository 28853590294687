import { on, createReducer } from '@ngrx/store';
import { OperatorPackage } from '../../operator-package/operator-package.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as OperatorPackageActions from './operator-package.actions';

import { AppState } from '..';

export interface OperatorPackageState extends EntityState<OperatorPackage> {
  isFetched: boolean;
  isLoading: boolean;
  selectedOperatorPackageID: string;
  isModalOpen: boolean;
  selectedCountryID: string;
}

// Entity adapter
export const adapter: EntityAdapter<OperatorPackage> =
  createEntityAdapter<OperatorPackage>({
    selectId: (_) => _.PK_OperatorPackageID.toString()
  });

export const initialState: OperatorPackageState = adapter.getInitialState({
  isFetched: false, // Defines if the companies have been fetched
  isLoading: false, // Defines if it is currently loading
  selectedOperatorPackageID: '',
  selectedCountryID: '1',
  isModalOpen: false
});

export const operatorPackageReducer = createReducer(
  initialState,

  on(OperatorPackageActions.OpenModal, (state) => ({
    ...state,
    isModalOpen: true
  })),

  on(OperatorPackageActions.CloseModal, (state) => ({
    ...state,
    isModalOpen: false
  })),

  on(OperatorPackageActions.LoadAllOperatorPackage, (state) => ({
    ...state,
    isLoading: true
  })),

  on(
    OperatorPackageActions.AllOperatorPackageLoaded,
    (state, { operatorPackages }) => {
      return {
        ...adapter.addMany(operatorPackages, state),
        isLoading: false,
        isFetched: true
      };
    }
  ),

  on(
    OperatorPackageActions.SelectOperatorPackageID,
    (state, { PK_OperatorPackageID }) => ({
      ...state,
      selectedOperatorPackageID: String(PK_OperatorPackageID)
    })
  ),

  on(
    OperatorPackageActions.CreateOperatorPackageSuccess,
    (state, { operatorPackage }) => ({
      ...adapter.addOne(operatorPackage, state),
      isLoading: false,
      isFetched: true,
      isModalOpen: false
    })
  ),
  on(
    OperatorPackageActions.UpdateOperatorPackageSuccess,
    (state, { operatorPackage }) => ({
      ...adapter.updateOne(
        { id: operatorPackage.PK_OperatorPackageID, changes: operatorPackage },
        state
      ),
      isLoading: false,
      isFetched: true,
      isModalOpen: false
    })
  ),

  on(
    OperatorPackageActions.SetSelectedCountryID,
    (state, { PK_CountryID }) => ({
      ...state,
      selectedCountryID: String(PK_CountryID)
    })
  )
);

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
