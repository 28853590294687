import { createAction, props } from '@ngrx/store';
import { User } from '../../user/user.model';

export const LoadCustomer = createAction(
  '[Customer] Load customer',
  props<{ customerID: number }>()
);

export const CustomerLoaded = createAction(
  '[Customer] Customer loaded',
  props<{ customerID: number; user: User }>()
);
