import { Country } from '../countries';

interface Pricing {
  MonthlySubscription_Advanced_AC: number;
  MonthlySubscription_Advanced_DC: number;
  MonthlySubscription_Basic_AC: number;
  MonthlySubscription_Basic_DC: number;
  MonthlySubscription_Core_AC: number;
  MonthlySubscription_Core_DC: number;
  MonthlySubscription_Premium_AC: number;
  MonthlySubscription_Premium_DC: number;
  PricePerKWH: number;
  ShareCharging: number;
  ShareServices: number;
}

export interface DegressivePricing extends Pricing {
  FK_OperatorPackagesID: number;
  nbPoints: number;
}

type PackageType = 'Pro';
export type OperatorPackageType = 'Start' | 'Grow' | 'Pro';

export class OperatorPackage {
  PK_OperatorPackageID?: number;
  FK_CountryID?: number;
  Currency?: string;
  Name?: string;
  typePackage?: OperatorPackageType;
  nbPointsMinimum?: number;
  onlyForAdmin?: boolean = false;
  ShareCharging?: number;
  ShareServices?: number;
  monthlyLicense?: number;
  module_advanced_statistics?: boolean = true;
  module_advanced_pricing?: boolean = true;
  module_advanced_user_management?: boolean = true;
  module_advanced_user_analytics?: boolean = true;
  module_company_vehicle?: boolean = true;
  module_load_management?: boolean = true;
  MonthlySubscription_Core_AC?: number;
  MonthlySubscription_Core_DC?: number;
  MonthlySubscription_Basic_AC?: number;
  MonthlySubscription_Basic_DC?: number;
  MonthlySubscription_Advanced_AC?: number;
  MonthlySubscription_Advanced_DC?: number;
  MonthlySubscription_Premium_AC?: number;
  MonthlySubscription_Premium_DC?: number;
  PricePerKWH?: number;
  DegressivePricing?: DegressivePricing[] = [];
  Country: Country;
}

export interface ClientOperatorPackage extends OperatorPackage {
  PK_OperatorPackageClientID?: number;
  FK_OperatorID?: number;
  FK_PackageOperatorID?: number;
  DegressivePricing?: ClientDegressivePricing[];
}
export interface ClientDegressivePricing extends DegressivePricing {
  PK_OperatorPackageClientsDegressivePricingID?: number;
  FK_OperatorPackageClientsID?: number;
}
