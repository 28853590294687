import { Action, createAction, props } from '@ngrx/store';
import { User } from '../../user/user.model';
import { UserPreferences } from '../../user/user-preferences.model';

export const LoadUser = createAction('[User] Load user');

export const UserLoaded = createAction(
  '[User] User loaded',
  props<{ user: User; userPreferences?: UserPreferences }>()
);

export const LogoutUser = createAction('[User] User Logout');

export const UserUpdated = createAction(
  '[User] User Updated',
  props<{ user: User }>()
);

export const PreferencesUpdated = createAction(
  '[User] User Preferences Updated',
  props<{ userPreferences: UserPreferences }>()
);
