import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from '../../user/user.model';
import * as UserActions from './user.actions';
import { UserState } from './user.reducer';
import { selectUser, selectUserPreferences } from './user.selectors';
import { UserPreferences } from '../../user/user-preferences.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UserFacade {
  constructor(
    private store: Store<UserState>,
    private translate: TranslateService
  ) {}

  user$ = this.store.select(selectUser);
  userPreferences$ = this.store.select(selectUserPreferences);

  loadUser(user: User, userPreferences?: UserPreferences) {
    if (userPreferences) this.translate.use(userPreferences?.language || 'en');
    this.store.dispatch(UserActions.UserLoaded({ user, userPreferences }));
  }

  logOutUser() {
    this.store.dispatch(UserActions.LogoutUser());
  }

  getUserFromLocal(): User {
    let storedUser: string = localStorage.getItem('user');
    let storedPreferences: string = localStorage.getItem('userPreferences');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      const preferences = storedPreferences
        ? JSON.parse(storedPreferences)
        : null;
      this.loadUser(parsedUser, preferences);
      return parsedUser;
    } else {
      return null;
    }
  }

  userUpdated(user: User) {
    this.store.dispatch(UserActions.UserUpdated({ user }));
  }

  preferencesUpdated(preferences: UserPreferences) {
    this.store.dispatch(
      UserActions.PreferencesUpdated({ userPreferences: preferences })
    );
  }
}
