import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../../http-interceptor/http-interceptor.service';
import { StyleService } from '../../style/style.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GlobalFunctions } from '../../global-functions/global-functions.service';
import { TokenService } from '../token/token.service';
import { ClearService } from '../../clear/clear.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { UserFacade } from 'src/app/lib/state/user/user.facade';
import { UserService } from 'src/app/lib';
import { TranslateService } from '@ngx-translate/core';
const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthAsService {
  constructor(
    private _http: HttpInterceptorService,
    private styleService: StyleService,
    private userService: UserService,
    private gf: GlobalFunctions,
    private tokenService: TokenService,
    private clearService: ClearService,
    private router: Router,
    private snackBar: MatSnackBar,
    private location: Location,
    public userFacade: UserFacade,
    private translate: TranslateService
  ) {}

  authAs(userID, email) {
    let appID = this.gf.guid4();

    this._http
      .post('Users/AuthenticateAs', {
        Email: email,
        FK_UserID: userID,
        appID: appID,
        isDashboard: true
      })
      .subscribe(
        (res) => {
          this.storeLoggedInUser();

          //Setting style
          this.styleService.init(res.DashboardPreferences);
          //let token = this.jwtHelper.decodeToken(res.accessToken)
          let token = helper.decodeToken(res.accessToken);

          this.userService.storeUser(
            res.customer,
            res.accessToken,
            res.rToken,
            appID,
            res.user.FK_UserTypeID,
            res.Roles,
            res.CompaniesUsersHasAccessTo,
            res.OperatorsUserHasAccessTo,
            res.InstallersUserHasAccessTo,
            res.userPreferences
          );

          this.router.navigate(['stations']);

          window.location.reload();
          //Refresh?
        },
        (err) => {
          this.snackBar.open(
            this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            }),
            this.translate.instant('CLOSE')
          );
        }
      );
  }
  logout() {
    let loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
    localStorage.removeItem('loggedInUser');

    this.userService.clearUser(false);

    this.styleService.clearStyle();
    this.tokenService.clearToken();
    this.clearService.clearAll();

    this.userService.storeUser(
      loggedInUser.user,
      loggedInUser.token,
      loggedInUser.rToken,
      loggedInUser.appID,
      loggedInUser.type,
      null,
      loggedInUser.userCompanies || [],
      loggedInUser.userOperators || [],
      loggedInUser.userInstallers || [],
      loggedInUser.userPreferences
    );
    localStorage.setItem('ac', loggedInUser.ac);
    location.reload();
  }
  private storeLoggedInUser() {
    let loggedInUser = {
      token: localStorage.getItem('token'),
      rToken: localStorage.getItem('rToken'),
      ac: localStorage.getItem('ac'),
      type: localStorage.getItem('type'),
      user: JSON.parse(localStorage.getItem('user')),
      appID: localStorage.getItem('appID'),
      userCompanies: localStorage.getItem('userCompanies'),
      userOperators: localStorage.getItem('userOperators'),
      userInstallers: localStorage.getItem('userInstallers'),
      userPreferences: JSON.parse(localStorage.getItem('userPreferences'))
    };

    localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
  }
}
