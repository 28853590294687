import { state } from '@angular/animations';
import { createReducer, on } from '@ngrx/store';

import {
  Currency,
  FinancialStation,
  LoadingStatus,
  MonthlyData,
  ALL_STATION_ID
} from '../..';

import * as FinancialsActions from './financials.actions';

interface CompanyStation {
  StationId: number | null;
  Name: string;
}
export interface FinancialsState {
  status: LoadingStatus;
  companyFinancials: any;
  companyStations: CompanyStation[];
  selectedCurrencyID: number;
}

export const initialState: any = {
  status: LoadingStatus,
  companyFinancials: null,
  companyStations: [],
  selectedCurrencyID: null
};

export const financialsReducer = createReducer(
  initialState,
  on(FinancialsActions.CompanyFinancialsLoaded, (state, { data }) => {
    return {
      ...state,
      status: LoadingStatus.Loaded,
      companyFinancials: data,
      companyStations: [],
      selectedCurrencyID: data.CompanyDefaultCurrency
    };
  }),

  on(FinancialsActions.CompanyFinancialsLoadFailed, (state, { error }) => {
    return {
      ...state,
      status: LoadingStatus.Error,
      companyFinancials: error
    };
  }),

  on(FinancialsActions.SelectedCurrencyChanged, (state, { currencyID }) => {
    const currencyData = state.companyFinancials.Currencies.filter(
      (curr) => curr.Currency === currencyID
    )[0];
    const currencyStations = getUniqueStation(currencyData);
    return {
      ...state,
      selectedCurrencyID: currencyID,
      companyStations: currencyStations
    };
  }),
  on(FinancialsActions.RestsetFinancialState, (state) => {
    return initialState;
  })
);

const getUniqueStation = (currency: any) => {
  return [
    ...new Map(
      currency?.MonthlyData.map((monthData) =>
        Object.keys(monthData)
          .map(
            (key) =>
              monthData[key]?.Items?.map((item) =>
                item.Stations.map((station) => ({
                  StationId: station.StationId,
                  Name: station.Name || 'NA'
                }))
              ) || []
          )
          .reduce((acc, curr) => [...acc, ...curr], [])
      )
        .reduce((acc, curr) => [...acc, ...curr], [])
        .reduce((acc, curr) => [...acc, ...curr], [])
        .map((item) => [item.StationId, item])
    ).values()
  ];
};
