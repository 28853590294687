import { Action, createAction, props } from '@ngrx/store';

import { BrainTreeDTO } from 'src/app/dtos';

import { Operator } from '../../operator';

export const SelectOperator = createAction(
  '[Operator] Select Operator',
  props<{ PK_OperatorID: number }>()
);

export const LoadAllOperators = createAction('[Operator] Load all Operators');

export const AllOperatorsLoaded = createAction(
  '[Operator] All Operator loaded',
  props<{ operators: Operator[] }>()
);

export const LoadOperator = createAction(
  '[Operator] Load Operator',
  props<{ PK_OperatorID: number }>()
);

export const OperatorLoaded = createAction(
  '[Operator] Operator Loaded',
  props<{ operator: Operator }>()
);
