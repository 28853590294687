import { Action, createAction, props } from '@ngrx/store';

import {
  AddPaymentMethodDTO,
  BrainTreeDTO,
  CustomerPaymentMethod,
  DeletePaymentMethodDTO
} from 'src/app/dtos';

import { Company, PaymentMethod } from '../../companies/companies.model';

export enum CompaniesActionTypes {
  LoadCompanies = '[Companies] Load Data',
  CompaniesLoaded = '[Companies] Data loaded'
}

export const SelectCompanyId = createAction(
  '[Companies] Select',
  props<{ PK_CompanyID: number }>()
);

export const LoadSingleCompany = createAction(
  '[Companies] Load Single',
  props<{ PK_CompanyID: number }>()
);

export const SingleCompanyLoaded = createAction(
  '[Companies] Single company loaded',
  props<{ company: Company }>()
);
export const LoadCompanies = createAction(
  '[Companies] Load Data',
  props<{ refresh: boolean }>()
);

export const CompaniesLoaded = createAction(
  CompaniesActionTypes.CompaniesLoaded,
  props<{ companies: Company[] }>()
);

// Payment methods
export const LoadPaymentMethods = createAction(
  '[Company] Load payment method',
  props<{ PK_CompanyID: number }>()
);

export const PaymentMethodsLoaded = createAction(
  '[Company] Payment methods loaded',
  props<{ paymentMethods: any[]; companyId: number }>()
);

export const AddPaymentMethod = createAction(
  '[Company] Add payment method added',
  props<{ payload: AddPaymentMethodDTO }>()
);

export const UpdatePaymentMethod = createAction(
  '[Company] Update payment method',
  props<{ payload: CustomerPaymentMethod }>()
);

export const DeletePaymentMethod = createAction(
  '[Company] Delete payment method',
  props<DeletePaymentMethodDTO>()
);

export const PaymentMethodAdded = createAction(
  '[Company] Payment method added',
  props<AddPaymentMethodDTO>()
);

export const ToggleIsDefaultPaymentMethod = createAction(
  '[Company] Toggle IsDefault payment method'
);

export const SetDefaultPaymentMethod = createAction(
  '[Company] Set default payment method',
  props<DeletePaymentMethodDTO>()
);

export const RemoveDefaultPaymentMethod = createAction(
  '[Company] Remove default payment method',
  props<CustomerPaymentMethod>()
);

// Braintree
//
export interface CreateBraintreeCustomerProps {
  braintreeObj: BrainTreeDTO;
  customer: any;
  currency: string;
  companyID: number;
}
export const CreateBraintreeCustomer = createAction(
  '[Company] Create Braintree customer',
  props<CreateBraintreeCustomerProps>()
);

export const BraintreeCustomerCreated = createAction(
  '[Company] Brainree customer created'
);

export const ShowAddPaymentMethodModal = createAction(
  '[Company] Show add payment method modal'
);
export const HideAddPaymentMethodModal = createAction(
  '[Company] Hide add payment method modal'
);

export const SetSearchQuery = createAction(
  '[Company] Set Search query',
  props<{ query: string }>()
);
