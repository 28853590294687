<div *ngIf="!loading">
  <sc-socket-search
    [selectedItem]="selectedUser"
    (itemSelected)="selectUser($event)"
    (clear)="clear.emit(null)"
    [displayFn]="displayFn"
    [socketURL]="socketURL"
    (search)="handleSearch($event)"
    placeholder="{{ 'SEARCH_FOR_A_USER' | translate }}"
    errorMessage="{{ 'PLEASE_SELECT_A_USER' | translate }}">
    <ng-template let-state>
      <div class="flex">
        <span class="search-option">
          <span
            *ngIf="state.item.Name"
            [innerHTML]="
              state.item.Name | highlight : state.searchInputCtrl.value
            "></span>
          <span
            *ngIf="state.item.PhoneNumber"
            [ngClass]="{ address: state.item.Name }"
            [innerHTML]="
              state.item.PhoneNumber | highlight : state.searchInputCtrl.value
            "></span>
          <span
            *ngIf="state.item.Email"
            [ngClass]="{ address: state.item.Name || state.item.PhoneNumber }"
            [innerHTML]="
              state.item.Email | highlight : state.searchInputCtrl.value
            "></span>
        </span>

        <span class="operator-logo" *canSee="userType.Admin">
          <div class="flex-center">
            <img
              [matTooltip]="state.item.OperatorName"
              [src]="
                state.item.OperatorLogoURL || 'assets/img/current_c.svg'
              " />
          </div>
        </span>
      </div>
    </ng-template>
  </sc-socket-search>
</div>
