import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* Directives */
import { CanNotSeeDirective } from './can-not-see.directive';
import { CanSeeDirective } from './can-see.directive';
import { HideSmDirective } from './hide-sm.directive';
import { CanNotSeeExactDirective } from './can-not-see-exact.directive';
import { TrimFormInputDirective } from './trim-input.directive';
import { TrimInputDirective } from './trim.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    CanSeeDirective,
    CanNotSeeDirective,
    HideSmDirective,
    CanNotSeeExactDirective,
    TrimFormInputDirective,
    TrimInputDirective
  ],
  exports: [
    CanSeeDirective,
    CanNotSeeDirective,
    HideSmDirective,
    CanNotSeeExactDirective,
    TrimFormInputDirective,
    TrimInputDirective
  ],
  providers: []
})
export class DirectivesModule {}
