export class Report {
  NbChargingSessions: number = 0;
  NbChargingSessionsFree: number = 0;
  ShareBase: number = 0;
  ShareBaseExVAT: number = 0;
  ShareStationOwner: number = 0;
  ShareStationOwnerExVAT: number = 0;
  TotalCostKWh: number = 0;
  TotalGatewayFees: number = 0;
  TotalKWHDelivered: number = 0;
  TotalKWHDeliveredForFree: number = 0;
  TotalPrice: number = 0;
  TotalPriceExcludingVAT: number = 0;
  TotalStartupCost: number = 0;
  Name: string = '';

  constructor() {}
}
export class ReportsByMethod {
  Card: Report = new Report();
  SMS: Report = new Report();
  PayPal: Report = new Report();
  Invoice: Report = new Report();
  Free: Report = new Report();
  Unknown: Report = new Report();
}
