import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  WebsocketService,
  CustomWS
} from '../../../services/websocket/websocket.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import * as Rx from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { StationService } from '../../../services/station/station.service';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { FormControl, Validators } from '@angular/forms';
import { FloatLabelType as FloatLabelType } from '@angular/material/form-field';
import { UserService } from 'src/app/lib';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sc-station-search',
  templateUrl: './station-search.component.html',
  styleUrls: ['./station-search.component.scss']
})
export class StationSearchComponent implements OnInit, OnChanges {
  @ViewChild('autoComplete', { read: MatAutocompleteTrigger })
  auto: MatAutocompleteTrigger;

  @Input('enter') enter: boolean = true;
  @Input('stationID') stationID: any;

  @Input('styleClass') styleClass: string = '';
  @Input('floatLabel') floatLabel: FloatLabelType = 'auto';
  @Input('required') required: boolean = false;
  @Output() search: EventEmitter<any> = new EventEmitter();
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();
  @Output() clear: EventEmitter<any> = new EventEmitter();
  @Input('searchInput') searchInput: string = '';
  @Input('accessComponent') accessComponent: string;
  @Input('customPlaceHolder') customPlaceHolder: string = '';
  @Input() width: string = null;
  @Input() multiple: boolean = false;
  @Input() clearSelected: boolean = false;
  @Input() selectedMultipleID: number[] = [];
  @Input() showLabel = true;
  @Input('errorText') errorText: string = this.translate.instant(
    'Please select a location'
  );
  multipleSelected: any[] = [];
  searchInputCtrl: FormControl = new FormControl();

  searchedStations: any = [];
  searchInited: boolean = false;

  selected: any;
  stationsWS: CustomWS;
  socketRetries: number = 0;

  constructor(
    private ws: WebsocketService,
    private userService: UserService,
    private route: ActivatedRoute,
    private stationService: StationService,
    private permissionService: PermissionsService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    //if(this.route.params)
    if (this.required) this.searchInputCtrl.setValidators(Validators.required);
    this.searchInputCtrl.patchValue(this.searchInput);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (
      !changes?.clearSelected?.previousValue &&
      changes?.clearSelected?.currentValue
    ) {
      this.multipleSelected = [];
    }
    if (this.stationID) {
      this.getStation(this.stationID);
    }
    if (this.selectedMultipleID?.length) {
      this.fetchMultipleSelected();
    }
  }

  fetchMultipleSelected() {
    this.stationService.getMultipleStations(this.selectedMultipleID).subscribe({
      next: (stations) => {
        this.multipleSelected = [];
        stations.forEach((station) => {
          this.multipleSelected.push({
            ID: station.StationID,
            Name: station.Name
          });
        });
        this.itemSelected.emit(this.multipleSelected);
      },
      error: (err) => {}
    });
  }

  checkAccess(component, stations) {
    if (!component) return;

    stations.map((station) => {
      let access = this.permissionService.checkAccess(component, station.ID);
      station.canSee = access.canSee;
      return station;
    });
  }
  getStation(id) {
    this.stationService.getSingle(id, true).then((station) => {
      station.ID = station.PK_ChargingStationID;
      this.selected = station;
      //this.searchInput = station.Name;
      this.searchInputCtrl.patchValue(station.Name);
      this.itemSelected.emit(station);
    });
  }
  initSearch() {
    if (!this.searchInited) {
      this.stationsWS.webSocket.next(localStorage.getItem('token'));
    }
  }

  initSocket() {
    if (this.stationsWS) return;
    this.userService.getUser().subscribe((user) => {
      this.stationsWS = this.ws.init(
        'ChargingStations/StationsByNameDashboard/' + user.PK_CustomerID
      ); //Init stations ws
      this.stationsWS.webSocket.subscribe({
        next: (res) => {
          if (res instanceof Array) {
            this.searchedStations = res;

            this.checkAccess(this.accessComponent, res);
          }
        },
        error: (err) => {
          if (this.socketRetries < 3) {
            this.socketRetries++;
            this.initSocket();
          } else {
            this.socketRetries = 0;
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
          }
        }
      });
      this.stationsWS.onOpen.then((open) => {
        this.searchInited = true;

        //Send empty to show a list
        this.onSearch(null);
      });
    });
  }
  onSearch(e) {
    if (['ArrowDown', 'ArrowUp'].includes(e?.key)) return;
    if (e?.key === 'Enter' && this.enter) {
      this.auto.closePanel();

      this.search.emit(this.searchInputCtrl.value);
    } else {
      if (
        !this.searchInputCtrl.value ||
        typeof this.searchInputCtrl.value === 'string'
      ) {
        let search = {
          Text: this.searchInputCtrl.value
            ? this.searchInputCtrl.value.trim()
            : '',
          Nb: 4
        };
        if (this.searchInited) this.stationsWS?.webSocket.next(search);
      }
    }
  }
  selectStation(e) {
    if (typeof e.option.value === 'string') {
      this.onSearch({ key: 'Enter' });
    } else {
      let station = e.option.value;

      if (this.multiple) {
        this.searchInputCtrl.patchValue('');
        this.multipleSelected.push(station);
        this.itemSelected.emit(this.multipleSelected);
      } else {
        //Reset the input only if enter is true. This is when you search for users in a users page
        //If we search for user other places, we want the user to remain in the input
        if (this.enter) this.searchInputCtrl.patchValue(''); // reset()? /
        this.selected = station;

        this.itemSelected.emit(station);
      }
    }
  }
  clearSearch() {
    //this.searchInput = "";
    this.searchInputCtrl.patchValue(''); //Reset?
    this.auto.closePanel();
    this.clear.emit(null);
  }
  displayFn(station: any): string {
    //if(station) station.PK_ChargingStationID = station.PK_ChargingStationID || station.ID;
    return station && typeof station == 'object' ? station.Name : station;
  }
  clearSocket() {
    if (this.stationsWS) {
      this.searchInited = false;
      this.stationsWS.webSocket.complete();
      this.stationsWS = null;
    }
  }
  ngOnDestroy() {
    if (this.search) {
      this.search = null;
      this.clearSocket();
    }
  }

  removeFromSelected = (ID: number) => {
    this.multipleSelected = this.multipleSelected.filter(
      (station) => station.ID !== ID
    );
    this.itemSelected.emit(this.multipleSelected);
  };
}
