import { Inject, Injectable } from '@angular/core';

import { STORAGE } from './providers';
import { StorageDriver } from './types';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(@Inject(STORAGE) private readonly storage: StorageDriver) {}

  clear(): Promise<void> {
    return this.storage.clear();
  }

  get<T = string>(key: string): Promise<T> {
    return this.storage.get(key);
  }

  remove(key: string): Promise<void> {
    return this.storage.remove(key);
  }

  set(key: string, value: unknown): Promise<void> {
    return this.storage.set(key, value);
  }
}
