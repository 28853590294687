export interface User {
  Address?: string;
  City?: string;
  CompanyNumberInvoicing: null;
  CountryCode?: string;
  CreatedOn?: string;
  CustomerNote?: string;
  Email: string;
  EmailInvoicing?: string;
  FK_CountryID: number;
  FK_CustomerTypeID: number;
  FK_OperatedByID?: number;
  FK_PhoneCountryIndicator?: string;
  FK_UserID: number;
  FK_UserTypeID: number;
  FirstName: string;
  FirstNameInvoicing?: string;
  Identificator: string;
  Keys: string;
  LastName: string;
  LastNameInvoicing?: string;
  MiddleName?: string;
  MiddleNameInvoicing?: string;
  OperatorLogoURL?: string;
  OperatorMarkerURL?: string;
  OperatorName?: string;
  PK_CustomerID: number;
  PhoneNumber: string;
  RoamingPlatformID?: number;
  PostCode: string;
  UserKeys: string;
}

export const emptyUser: User = {
  Address: '',
  City: '',
  CompanyNumberInvoicing: null,
  Email: '',
  FK_CountryID: 0,
  FK_CustomerTypeID: 0,
  FK_UserID: 0,
  FK_UserTypeID: 0,
  FirstName: '',
  Identificator: '',
  Keys: '',
  LastName: '',
  PK_CustomerID: 0,
  PhoneNumber: '',
  PostCode: '',
  UserKeys: ''
};
