import { Injectable } from '@angular/core';
import { HttpInterceptorService } from 'src/app/services/http-interceptor/http-interceptor.service';
import { map } from 'rxjs';
import { BoxManufacturer, BoxModel } from 'src/app/classes';
import {
  AvailableWidgetOptions,
  CreateDashboardResponse,
  InsightsRequest,
  InsightsResponse,
  WidgetDashboard,
  WidgetDashboardDB
} from './types';
import { ConnectorType } from 'src/app/lib/stations/stations.model';
import { Operator } from 'src/app/lib/operator';
import { Installer } from 'src/app/lib/installer/installer.model';

@Injectable({
  providedIn: 'root'
})
export class InsightsService {
  constructor(private _http: HttpInterceptorService) {}

  getChargingBoxManufacturers() {
    return this._http
      .get('ChargingBoxManufacturers')
      .pipe(map((res) => (res?.datas || []) as BoxManufacturer[]));
  }

  getChargingBoxModels() {
    return this._http.get<BoxModel[]>(
      'ChargingBoxModels/GetChargingBoxModelsICanAdd'
    );
  }

  getConnectorTypes() {
    return this._http.get<ConnectorType[]>('CPConnectorTypes');
  }

  getOperatorsICanSee() {
    return this._http
      .get('Operators/OperatorsICanSee')
      .pipe(map((res) => (res?.datas || []) as Operator[]));
  }

  getInstallersICanSee() {
    return this._http
      .get('Installers/InstallersICanSee')
      .pipe(map((res) => (res?.datas || []) as Installer[]));
  }

  getAvailableOptions() {
    return this._http.get<AvailableWidgetOptions>('Insights/Help');
  }

  getDashboards() {
    return this._http
      .get('Insights/Dashboards')
      .pipe(map((res) => (res?.datas || []) as WidgetDashboardDB[]));
  }

  createDashboard(payload: WidgetDashboard) {
    return this._http
      .post('Insights/Dashboards', payload)
      .pipe(map((res) => res as CreateDashboardResponse));
  }

  deleteDashboard(dashboardID: number) {
    return this._http.delete(`Insights/Dashboards/${dashboardID}`);
  }

  updateDashboard(dashboard: WidgetDashboardDB) {
    return this._http
      .put(`Insights/Dashboards`, dashboard)
      .pipe(map((res) => res as CreateDashboardResponse));
  }

  updateDashboardName(dashboardID: number, dashboardName: string) {
    return this._http.patch(`Insights/Dashboards/${dashboardID}`, {
      name: dashboardName
    });
  }

  cloneDashboard(dashboardID: number) {
    return this._http
      .post(`Insights/Dashboards/Clone/${dashboardID}`, {})
      .pipe(map((res) => res as CreateDashboardResponse));
  }

  fetchWidgetData(payload: InsightsRequest) {
    return this._http
      .post('Insights', payload)
      .pipe(map((res) => (res?.datas || []) as InsightsResponse));
  }
}
