import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { UserService } from 'src/app/lib';
@Pipe({
  name: 'cDate'
})
export class CDatePipe implements PipeTransform {
  dateFormat = 'en-GB';
  constructor(
    private translate: TranslateService,
    private userService: UserService
  ) {
    this.userService.getUserPreferences().subscribe({
      next: (res) => {
        this.dateFormat = res?.DateFormatCulture || this.dateFormat;
      },
      error: (err) => {}
    });
  }
  transform(date: any, format?: any, utc?: boolean): any {
    if (!date) return null;
    moment.locale(this.translate.currentLang);
    if (utc) {
      return moment.utc(date).locale(this.dateFormat).format(format);
    } else {
      return moment(date).locale(this.dateFormat).format(format);
    }
  }
}
