import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FloatLabelType as FloatLabelType } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/lib';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { RolesService } from 'src/app/services/roles/roles.service';
import { UserType } from 'src/app/user-roles';
import { StationService } from '../../../services/station/station.service';
import {
  CustomWS,
  WebsocketService
} from '../../../services/websocket/websocket.service';
import { TranslateService } from '@ngx-translate/core';
import { PointService } from 'src/app/services/point/point.service';

@Component({
  selector: 'sc-super-search',
  templateUrl: './super-search.component.html',
  styleUrls: ['./super-search.component.scss']
})
export class SuperSearchComponent implements OnInit, OnChanges {
  @ViewChild('autoComplete', { read: MatAutocompleteTrigger })
  auto: MatAutocompleteTrigger;

  @Input('enter') enter: boolean = true;
  @Input('stationID') stationID: any;
  @Input('pointID') pointID: any;

  @Input('styleClass') styleClass: string = '';
  @Input('floatLabel') floatLabel: FloatLabelType = 'auto';
  @Output() search: EventEmitter<any> = new EventEmitter();
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();
  @Output() clear: EventEmitter<any> = new EventEmitter();
  @Output() typing: EventEmitter<any> = new EventEmitter();
  @Input('searchInput') searchInput: string = '';
  @Input('accessComponent') accessComponent: string;
  @Input('sendLocations') sendLocations: boolean = true;
  @Input('sendPoints') sendPoints: boolean = true;
  @Input('required') required: boolean = false;
  @Input('placeholder') placeholder: string = this.translate.instant(
    'Search for a location or point'
  );
  @Input('errorText') errorText: string = this.translate.instant(
    'Please select a location or point'
  );
  searchedStations: any = [];
  searchInited: boolean = false;

  selected: any;
  stationsWS: CustomWS;
  socketRetries: number = 0;
  userType = UserType;

  constructor(
    private ws: WebsocketService,
    private userService: UserService,
    private route: ActivatedRoute,
    private stationService: StationService,
    private permissionService: PermissionsService,
    private snackBar: MatSnackBar,
    private rolesService: RolesService,
    private translate: TranslateService,
    private pointService: PointService
  ) {}

  ngOnInit() {
    if (!this.sendLocations) {
      this.errorText = this.translate.instant('Please select a point');
      this.placeholder = this.translate.instant('Search for a point');
    }
    if (!this.sendPoints) {
      this.errorText = this.translate.instant('Please select a location');
      this.placeholder = this.translate.instant('Search for a location');
    }
  }

  ngOnChanges() {
    if (this.stationID) {
      this.getStation(this.stationID);
    }
    if (this.pointID) {
      this.getPoint(this.pointID);
    }
  }
  checkAccess(component, stations) {
    if (!component) return;

    stations.map((station) => {
      let access = this.permissionService.checkAccess(component, station.ID);
      station.canSee = access.canSee;
      return station;
    });
  }
  getStation(id) {
    this.stationService.getSingle(id, true).then((station) => {
      station.ID = station.PK_ChargingStationID;
      this.selected = station;
      this.searchInput = station.Name;
      this.itemSelected.emit(station);
    });
  }
  getPoint(id) {
    this.pointService.getPoint(id, true).then((point) => {
      this.selected = point;
      this.searchInput = point.Name;
      this.itemSelected.emit(point);
    });
  }
  initSocket() {
    if (this.stationsWS) return;
    this.userService.getUser().subscribe((user) => {
      this.stationsWS = this.ws.init(
        'Dashboard/Supersearch/' + user.PK_CustomerID
      ); //Init stations ws
      this.stationsWS.webSocket.subscribe({
        next: (res) => {
          if (res instanceof Array) {
            this.searchedStations = res;
            if (this.sendLocations) {
              this.checkAccess(this.accessComponent, res);
            }
          }
        },
        error: (err) => {
          if (this.socketRetries < 3) {
            this.socketRetries++;
            this.initSocket();
          } else {
            this.socketRetries = 0;
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
          }
        }
      });
      this.stationsWS.onOpen.then((open) => {
        this.searchInited = true;

        //Send empty to show a list
        this.onSearch(null);
      });
    });
  }
  onSearch(e) {
    if (['ArrowDown', 'ArrowUp'].includes(e?.key)) return;
    if (e?.key === 'Enter' && this.enter) {
      this.auto.closePanel();

      this.search.emit(this.searchInput);
    } else {
      if (typeof this.searchInput === 'string') {
        this.typing.emit(this.searchInput);
        let search = {
          Text: this.searchInput ? this.searchInput.trim() : '',
          Nb: 4,
          SendLocations: this.sendLocations,
          SendPoints: this.sendPoints
        };
        if (this.searchInited && this.stationsWS)
          this.stationsWS.webSocket.next(search);
      }
    }
  }
  createAddress(components) {
    let address = '';
    if (components.Address) address += components.Address;

    if (components.PostCode) address += ', ' + components.PostCode;
    if (components.StateOrProvince) address += ' ' + components.StateOrProvince;
    if (components.CountryName) address += ', ' + components.CountryName;

    return address;
  }
  selectItem(e) {
    if (typeof e.option.value === 'string') {
      this.onSearch({ key: 'Enter' });
    } else {
      let item = e.option.value;
      if (this.enter) this.searchInput = '';
      this.selected = item;
      this.itemSelected.emit(item);
    }
  }
  clearSearch() {
    this.searchInput = '';
    this.auto.closePanel();
    this.clear.emit(null);
  }
  displayFn(station: any): string {
    //if(station) station.PK_ChargingStationID = station.PK_ChargingStationID || station.ID;
    return station && typeof station == 'object' ? station.Name : station;
  }
  clearSocket() {
    if (this.stationsWS) {
      this.searchInited = false;

      this.stationsWS.webSocket.complete();
      this.stationsWS = null;
    }
  }
  ngOnDestroy() {
    if (this.search) {
      this.search = null;
      this.clearSocket();
    }
  }
}
