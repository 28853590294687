import { createReducer, on } from '@ngrx/store';
import { Station } from '../../stations/stations.model';
import * as StationActions from './station.actions';

export interface StationState {
  isFetched: boolean;
  isLoading: boolean;
  selectedStation: Station;
}

export const initialState: StationState = {
  isFetched: false,
  isLoading: false,
  selectedStation: null
};

export const stationReducer = createReducer(
  initialState,
  on(StationActions.LoadSelectedStation, (state, { station }) => {
    return {
      ...state,
      selectedStation: station
    };
  })
);
