import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { InsightsActions } from '.';
import { InsightsService } from 'src/app/lib/state/insights/insights.service';

@Injectable({ providedIn: 'root' })
export class InsightsEffects {
  loadWidgetHelp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsightsActions.LoadInsightsWidgetHelp),
      mergeMap((action) =>
        this.insightsService.getAvailableOptions().pipe(
          map((data) => {
            return InsightsActions.InsightsWidgetHelpLoaded({ data });
          }),
          catchError((apiError) => {
            return of(
              InsightsActions.ErrorInsightsWidgetHelp({
                error: apiError
              })
            );
          })
        )
      )
    )
  );
  constructor(
    private actions$: Actions,
    private insightsService: InsightsService
  ) {}
}
