import { version } from './version';
import { Injectable } from '@angular/core';
export const baseID: number = 2;
const baseURL = 'api.smartcharge.io'; //'https://meshcrafts-demo-api-app-service.azurewebsites.net';
const debugURL = 'smartchargeapipd.azurewebsites.net';
const stagingURL = 'meshcrafts-demo-api-app-service-stagging.azurewebsites.net';
const sandBoxURL = 'current-api-sandbox.azurewebsites.net';
const qaURL = 'meshcrafts-demo-api-app-service-qa.azurewebsites.net';
const localURL = 'localhost:43827';

export const snackbarConfig = {
  duration: 2000
};

const boxDocumentationFolder = 'BoxDocumentations';

@Injectable()
export class Defaults {
  http: string = 'https';
  secure: string = 's';
  version: string = version;
  boxDocumentationFolder: string = boxDocumentationFolder;

  private getEnv: any = () => {
    let env = localStorage.getItem('environment');
    const hostname = window.location.hostname;

    if (hostname.includes('staging')) {
      env = 'staging';
      localStorage.setItem('environment', env);
    }
    if (hostname.includes('sandbox') || hostname.includes('greeable-bush-05b6fad03.4')) {
      env = 'sandbox';
      localStorage.setItem('environment', env);
    }
    if (hostname.includes('qa')) {
      env = 'qa';
      localStorage.setItem('environment', env);
    }

    let url = baseURL;

    if (env === 'staging') {
      url = stagingURL;
    } else if (env === 'sandbox') {
      url = sandBoxURL;
    }  else if (env === 'qa') {
      url = qaURL;
    } else if (env === 'debug') {
      url = debugURL;
    } else if (env === 'local') {
      url = localURL;
      this.http = 'http';
      this.secure = '';
    } else {
      url = baseURL;
    }
    return url;
  };

  production: boolean =
    localStorage.getItem('environment') === 'staging' ||
    localStorage.getItem('environment') === 'debug' ||
    localStorage.getItem('environment') === 'qa' ||
    localStorage.getItem('environment') === 'sandbox'
      ? false
      : true;
  base: string = 'http' + this.secure + '://' + this.getEnv() + '/v2/'; //(this.production) ? 'https://meshcrafts-demo-api-app-service.azurewebsites.net/api/' : 'https://meshcrafts-demo-api-app-service-stagging.azurewebsites.net/api/';
  socket: string = 'ws' + this.secure + '://' + this.getEnv() + '/v2/';
  imgBase: string = 'http' + this.secure + '://' + this.getEnv(); //(this.production) ? 'https://meshcrafts-demo-api-app-service.azurewebsites.net' : 'https://meshcrafts-demo-api-app-service-stagging.azurewebsites.net';

  colors: Colors = {
    primary: '#2b4c3c',
    success: '#4e7944',
    secondary: '#8DBA00',
    danger: '#ef4453',
    warn: '#fdcc17',
    light: '#e0e0e0',
    dark: '#424242',
    purple: 'purple',
    gray: 'gray',
    blue: '#4CB1DE'
  };

  shade(color, percent) {
    if (color.length > 7) return this.shadeRGBColor(color, percent);
    else return this.shadeColor(color, percent);
  }
  private shadeRGBColor(color, percent) {
    var f = color.split(','),
      t = percent < 0 ? 0 : 255,
      p = percent < 0 ? percent * -1 : percent,
      R = parseInt(f[0].slice(4)),
      G = parseInt(f[1]),
      B = parseInt(f[2]);
    return (
      'rgb(' +
      (Math.round((t - R) * p) + R) +
      ',' +
      (Math.round((t - G) * p) + G) +
      ',' +
      (Math.round((t - B) * p) + B) +
      ')'
    );
  }
  private shadeColor(color, percent) {
    var f = parseInt(color.slice(1), 16),
      t = percent < 0 ? 0 : 255,
      p = percent < 0 ? percent * -1 : percent,
      R = f >> 16,
      G = (f >> 8) & 0x00ff,
      B = f & 0x0000ff;
    return (
      '#' +
      (
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
        .toString(16)
        .slice(1)
    );
  }
  lightenColor(color, percent) {
    var num = parseInt(color.replace('#', ''), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = ((num >> 8) & 0x00ff) + amt,
      G = (num & 0x0000ff) + amt;
    return (
      '#' +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
        (G < 255 ? (G < 1 ? 0 : G) : 255)
      )
        .toString(16)
        .slice(1)
    );
  }
}
interface Colors {
  primary: string;
  secondary: string;
  danger: string;
  warn: string;
  light: string;
  dark: string;
  gray: string;
  purple: string;
  success: string;
  blue: string;
}
