import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';



import { RoamingPlatformState } from './roaming-platform.reducer';
import { selectRoamingLoadingState, selectRoamingPlatform } from './roaming-platform.selectors';
import { RoamingPlatFormActions } from '.';

@Injectable({
  providedIn: 'root'
})
export class RoamingPlatformFacade {
  constructor(private store: Store<RoamingPlatformState>) {}
  roamingPlatforms$ = this.store.select(selectRoamingPlatform);
  roamingPlatformStatus$ = this.store.select(selectRoamingLoadingState);

  loadRoamingPlatforms() {
    this.store.dispatch(RoamingPlatFormActions.LoadRoamingPlatforms());
  }
}
