import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptorService } from 'src/app/services/http-interceptor/http-interceptor.service';
import { Company } from './companies.model';
import { Observable } from 'rxjs';

import { BrainTreeDTO } from 'src/app/dtos';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  constructor(private _http: HttpInterceptorService) {}

  getAll(): Observable<Company[]> {
    return this._http
      .get<{ success: boolean; datas: Company[] }>('Companies')
      .pipe(map((res) => res.datas));
  }

  getSingle(PK_CompanyID: number): Observable<Company> {
    return this._http.get<Company>(`Companies/${PK_CompanyID}`);
  }

  getPaymentMethods(companyID: number | string) {
    return this._http.get(`PaymentMethods/ForCompany/${companyID}/false`);
  }

  createBraintreeCustomer({
    braintreeObj,
    customer,
    currency,
    companyID
  }: {
    // @todo: move this dto file somewheremore neutral
    braintreeObj: BrainTreeDTO;
    customer: any; // @note: Is this user?
    currency: string;
    companyID: number;
  }) {
    // @TODO: Maybe make this into it's own helper
    const parsedName = braintreeObj.details.cardholderName
      .split(/(\s).+\s/)
      .join('');

    const splitName = parsedName.split(' ');
    const FirstName = splitName[0];
    const LastName = splitName[0];

    const newCustomer = {
      FirstName,
      LastName,
      CustomerID: customer.PK_CustomerID,
      Email: customer.Email, //Remove this when the prod-api is updated to accept CustomerID instead
      NonceFromClient: braintreeObj.nonce,
      Identifyer: braintreeObj.details.lastTwo || braintreeObj.details.email,
      PaymentType: braintreeObj.details.cardType || 'PayPal',
      Currency: currency,
      FK_CompanyID: companyID
    };

    return this._http.post(
      'PaymentMethods/AddPaymentMethodFromBraintree',
      newCustomer
    );
  }
}
