<form
  [formGroup]="inputsFormGroup"
  (submit)="submitForm($event)"
  class="multiple-input-form">
  <h2 mat-dialog-title>{{ title }}</h2>
  <div mat-dialog-content class="dialog-content">
    <p *ngIf="content">{{ content }}</p>

    <div formArrayName="inputs">
      <div *ngFor="let input of inputForms.controls; index as i">
        <mat-form-field
          [ngStyle]="{ width: inputs[i].width || 0 }"
          *ngIf="
            inputs[i].type !== 'textarea' &&
            inputs[i].type !== 'date' &&
            inputs[i].type !== 'select'
          ">
          <mat-label>{{ inputs[i].placeholder }}</mat-label>
          <input
            matInput
            [type]="inputs[i].type"
            [required]="inputs[i].required"
            name="i"
            [formControlName]="i" />
          <span *ngIf="inputs[i].suffix" matTextSuffix>
            {{ inputs[i].suffix }}
          </span>
          <span *ngIf="inputs[i].prefix" matPrefix>
            {{ inputs[i].prefix }}
          </span>
          <mat-hint *ngIf="inputs[i].hint">
            {{ inputs[i].hint }}
          </mat-hint>
          <mat-error *ngIf="input.hasError('required')">
            {{ 'REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          [ngStyle]="{ width: inputs[i].width || 0 }"
          *ngIf="inputs[i].type === 'textarea'">
          <mat-label>{{ inputs[i].placeholder }}</mat-label>
          <textarea
            matInput
            rows="8"
            [required]="inputs[i].required"
            name="i"
            [formControlName]="i"></textarea>
          <mat-hint *ngIf="inputs[i].hint">
            {{ inputs[i].hint }}
          </mat-hint>
          <mat-error *ngIf="input.hasError('required')">
            {{ 'REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          [ngStyle]="{ width: inputs[i].width || 0 }"
          *ngIf="inputs[i].type === 'date'">
          <input
            matInput
            [matDatepicker]="datePicker"
            (click)="datePicker.open()"
            [placeholder]="inputs[i].placeholder"
            [required]="inputs[i].required"
            name="i"
            [formControlName]="i" />
          <button
            matSuffix
            mat-icon-button
            *ngIf="input.value"
            (click)="input.reset()">
            <mat-icon>close</mat-icon>
          </button>
          <!-- <button matTextSuffix mat-icon-button matTooltip="{{ 'RESET' | translate }}" *ngIf="input.value" (click)="input.reset()"> -->
          <!--     <mat-icon>close</mat-icon> -->
          <!-- </button> -->
          <mat-hint *ngIf="inputs[i].hint">
            {{ inputs[i].hint }}
          </mat-hint>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field
          [ngStyle]="{ width: inputs[i].width || 0 }"
          *ngIf="inputs[i].type === 'select'">
          <mat-label>{{ inputs[i].placeholder }}</mat-label>
          <mat-select
            [formControlName]="i"
            [required]="inputs[i].required"
            [multiple]="inputs[i].multiSelect">
            <mat-option *ngFor="let item of inputs[i].options" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="input.hasError('required')">
            {{ 'REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit">
      {{ action || ('OK' | translate) }}
    </button>
    <button mat-raised-button (click)="dialogRef.close()" type="button">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</form>
