import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Injectable()
export class GlobalFunctions {
  public loading: boolean;
  static getDateAsUTC(y?: number) {
    /* Getting current date as UTC
            y = (optional)(int) Years ahead in time.
        */
    y = y ? y : 0; //if y then y else 0
    let date;
    date = new Date();

    //All stuff below is to make it like a nice UTC date
    //Like so: 2016-06-23T13:52:07.193
    date =
      date.getUTCFullYear() +
      y +
      '-' +
      ('00' + (date.getUTCMonth() + 1)).slice(-2) +
      '-' +
      ('00' + date.getUTCDate()).slice(-2) +
      'T' +
      ('00' + date.getUTCHours()).slice(-2) +
      ':' +
      ('00' + date.getUTCMinutes()).slice(-2) +
      ':' +
      ('00' + date.getUTCSeconds()).slice(-2) +
      '.' +
      date.getUTCMilliseconds();
    return date;
  }
  static guid4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  constructor(private route: ActivatedRoute) {}

  getDateAsUTC(date?) {
    return moment.utc(date).format('YYYY-MM-DDTHH:mm[Z]');
  }
  guid4(maxLength?) {
    if (maxLength && maxLength < 36) {
      return this.randomString(maxLength);
    } else {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    }
  }
  randomString(length) {
    let chars = '0123456789-ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  /**
   * Converts a date to timestamp. Backend will use the UTC time. So Tue Oct 01 2019 00:00:00 turns into Monday 30. September 2019 22:00:00
   */
  toTS(date, hour?, min?, second?) {
    let timestamp;
    if (date) {
      timestamp = new Date(date);

      if (hour && second) {
        timestamp.setHours(hour, min, second);
      }

      timestamp = timestamp.getTime();
      return timestamp;
    } else {
      return '';
    }
  }
  sort(a: string, b: string) {
    let sort;
    a = a.toLowerCase();
    b = b.toLowerCase();

    let aWithoutNumber = a.replace(/\d+$/, '');
    let bWithoutNumber = b.replace(/\d+$/, '');
    let aMatch = a.match(/(\d+)/g);
    let bMatch = b.match(/(\d+)/g);

    if (aWithoutNumber === bWithoutNumber && aMatch && bMatch) {
      //Name is same, sort by number. Also sorts Box2 before Box10.
      sort = Number(a.match(/(\d+)/g)[0]) - Number(b.match(/(\d+)/g)[0]);
    } else {
      if (a < b) sort = -1;
      if (a > b) sort = 1;
    }

    return sort;
  }
  notFound() {
    this.route.url.subscribe((res) => {});
  }
}
