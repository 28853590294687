import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../../services';

import { APIResponse } from '../api/api.model';
import {
  OperatorPackage,
  ClientOperatorPackage
} from './operator-package.model';
import { mapData, mapSuccess } from '../rxjs';

@Injectable({
  providedIn: 'root'
})
export class OperatorPackageService {
  constructor(private _http: HttpInterceptorService) {}

  getPackageStation(stationID: number) {
    return this._http.get(`PackageClients/GetByStation/${stationID}/false`);
  }

  getOperatorPackages() {
    return this._http.get('OperatorPackagesController').pipe(mapData());
  }

  getOperatorPackagesByCountry(countryID: number) {
    return this._http
      .get(`OperatorPackagesController/PackagesICanSelect/${countryID}`)
      .pipe(mapData());
  }

  getOperatorPackage(packageID: number) {
    return this._http.get(`OperatorPackagesController/${packageID}`);
  }

  createOperatorPackage(operatorPackage: OperatorPackage) {
    return this._http
      .post('OperatorPackagesController', operatorPackage)
      .pipe(mapData());
  }

  updateOperatorPackage(operatorPackage: OperatorPackage) {
    return this._http
      .put('OperatorPackagesController', operatorPackage)
      .pipe(mapSuccess());
  }

  updateOperatorClientPackage(clientPackage: ClientOperatorPackage) {
    return this._http
      .put('Operators/Package', clientPackage)
      .pipe(mapSuccess());
  }
}
