import * as Sentry from '@sentry/angular';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

const whitelabels = [
  'fjordkraft',
  'elywhere',
  'electricfreeway',
  'chargebnb',
  'glup',
  'rexel',
  'wennstrom'
] as const;

type Whitelabel = (typeof whitelabels)[number];

type Environment = Whitelabel | 'prod' | 'qa' | 'staging' | 'localhost' | 'sandbox';

const whitelabelsDomains = whitelabels.map(
  (whitelabel) => `${whitelabel}.smartcharge.io`
);

export function getEnvironment(): Environment {
  let result: Environment = 'localhost';

  const rawDomain = window.location.hostname;
  const [domain] = rawDomain.split('.');

  if (domain === 'portal') {
    result = 'prod';
  } else if (domain === 'staging') {
    result = 'staging';
  } else if (domain === 'qa') {
    result = 'qa';
  } else if (domain === 'sandbox' || 'agreeable-bush-05b6fad03.4') {
    result = 'sandbox';
  }else if (whitelabels.includes(domain as Whitelabel)) {
    result = domain as Whitelabel;
  }

  return result;
}

export function enableSentry() {
  Sentry.init({
    environment: getEnvironment(),
    dsn: 'https://b1582ac89d9446ce9890724700497338@o4504163250995200.ingest.sentry.io/4504163251912704',
    integrations: [
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: [
          'localhost',
          'https://portal.smartcharge.io',
          'https://qa.smartcharge.io',
          'https://staging.smartcharge.io',
          'https://agreeable-bush-05b6fad03.4.azurestaticapps.net',
          ...whitelabelsDomains
        ],
        routingInstrumentation: Sentry.instrumentAngularRouting
      })
    ],
    tracesSampleRate: 1.0
  });
}
