import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/modules/shared.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { DirectivesModule } from '../../directives/directives.module';
import { AppChangelogModule } from '../app-changelog/app-changelog.module';
import { StationSearchModule } from '../stations/station-search/station-search.module';
import { MainSidenavComponent } from './main-sidenav.component';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    RouterModule,
    DirectivesModule,
    AppChangelogModule,
    StationSearchModule,
    SharedModule,
    PipesModule
  ],
  declarations: [MainSidenavComponent]
})
export class MainSidenavModule {}
