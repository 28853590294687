export enum OperatorRoles {
  None = 0,
  /**Can see the station and action logs (lowest access) */
  See = 1 << 0,
  /**Change the settings of a station */
  EditStationSettings = (1 << 1) | See,
  /**can change the users of an operator */
  CanEditOperator = (1 << 2) | See,
  /**Can consult the analytics of stations */
  Analytics = (1 << 3) | See,
  /**Can change the rules of a station */
  EditRules = (1 << 4) | See,
  /**Access to order histories */
  OrderHistories = (1 << 5) | See,
  /**Access to technical logs */
  TechnicalLogs = (1 << 6) | See,
  /**Change the load balancing settings of stations */
  EditStationLimitations = (1 << 7) | See,
  /**Change the settings of a box */
  BoxSettings = (1 << 8) | See,
  /**Remote commands on boxes */
  CommandsOnBoxes = (1 << 9) | See,
  /**access to bi reports */
  BusinessReports = (1 << 10) | See,
  /**Other reports than financial reports */
  StatisticsReports = (1 << 11) | See,
  Other12 = (1 << 12) | See,
  Other13 = (1 << 13) | See,
  Other14 = (1 << 14) | See,
  Other15 = (1 << 15) | See,
  Other16 = (1 << 16) | See,
  Other17 = (1 << 17) | See,
  Other18 = (1 << 18) | See,
  Other19 = (1 << 19) | See,
  /**Electrician access, includes technical logs, box settings and remote commands */
  Electrician = (1 << 25) | See | TechnicalLogs | BoxSettings | CommandsOnBoxes,
  /**All but owner, can be used to give full access to someone else than the owner of the operator */
  All = (1 << 29) |
    See |
    EditStationSettings |
    CanEditOperator |
    Analytics |
    EditRules |
    OrderHistories |
    TechnicalLogs |
    EditStationLimitations |
    BoxSettings |
    CommandsOnBoxes |
    BusinessReports |
    StatisticsReports |
    Other12 |
    Other13 |
    Other14 |
    Other15 |
    Other16 |
    Other17 |
    Other18 |
    Other19 |
    Electrician,
  /**Access to All + owner state */
  Owner = All | (1 << 30)
}

export const OperatorAccess = [
  { value: 'See', text: 'See', enum: OperatorRoles.See },
  {
    value: 'EditStationSettings',
    text: 'Edit station',
    enum: OperatorRoles.EditStationSettings
  },
  {
    value: 'CanEditOperator',
    text: 'Edit operator',
    enum: OperatorRoles.CanEditOperator
  },
  { value: 'Analytics', text: 'Analytics', enum: OperatorRoles.Analytics },
  { value: 'EditRules', text: 'Rules', enum: OperatorRoles.EditRules },
  {
    value: 'OrderHistories',
    text: 'Orders',
    enum: OperatorRoles.OrderHistories
  },
  {
    value: 'TechnicalLogs',
    text: 'Technical logs',
    enum: OperatorRoles.TechnicalLogs
  },
  {
    value: 'EditStationLimitations',
    text: 'Charging limitations',
    enum: OperatorRoles.EditStationLimitations
  },
  {
    value: 'BoxSettings',
    text: 'Box settings',
    enum: OperatorRoles.BoxSettings
  },
  {
    value: 'CommandsOnBoxes',
    text: 'Commands',
    enum: OperatorRoles.CommandsOnBoxes
  },
  {
    value: 'BusinessReports',
    text: 'BI reports',
    enum: OperatorRoles.BusinessReports
  },
  {
    value: 'StatisticsReports',
    text: 'Statistics',
    enum: OperatorRoles.StatisticsReports
  },
  /*{value: 'See', text: 'See', enum: 4097},
    {value: 'See', text: 'See', enum: 8193},
    {value: 'See', text: 'See', enum: 16385},
    {value: 'See', text: 'See', enum: 32769},
    {value: 'See', text: 'See', enum: 65537},
    {value: 'See', text: 'See', enum: 131073},
    {value: 'See', text: 'See', enum: 262145},
    {value: 'See', text: 'See', enum: 524289},*/
  {
    value: 'Electrician',
    text: 'Electrician',
    enum: OperatorRoles.Electrician
  },
  { value: 'All', text: 'Full access', enum: OperatorRoles.All },
  { value: 'Owner', text: 'Owner', enum: OperatorRoles.Owner }
];
