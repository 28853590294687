import { createAction, props } from '@ngrx/store';
import { AvailableWidgetOptions } from 'src/app/lib/state/insights/types';

export const LoadInsightsWidgetHelp = createAction(
  '[insights] Load widget help'
);

export const InsightsWidgetHelpLoaded = createAction(
  '[insights] widget help loaded',
  props<{ data: AvailableWidgetOptions }>()
);

export const ErrorInsightsWidgetHelp = createAction(
  '[insights] error loading widget help',
  props<{ error: any }>()
);
