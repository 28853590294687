import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pointsOnline'
})
export class PointsOnlinePipe implements PipeTransform {
  transform(points: any, args?: any): any {
    if (!points || !points.length) return null;

    //Should return the number of offline active points
    //If all is offline, returns -1
    let offlinePoints = 0;
    let inactivePoints = 0;
    points.forEach((point) => {
      if (!point.IsActive) inactivePoints++;

      if (point.State === 'Offline' && point.IsActive) {
        //Should not count inactive points, because they are not visible
        offlinePoints++;
      }
    });
    if (offlinePoints === points.length) {
      offlinePoints = -1;
    }
    if (inactivePoints === points.length) offlinePoints = -2;
    return offlinePoints;
  }
}
