enum InvoiceType {
  END_CUSTOMER = 'EndCustomer'
}

export enum InvoicePaymentResult {
  NOT_PAIED = 'NotPaid',
  PAID = 'Paid',
  CREDENTIALS_MISSING = 'CredentialsMissing',
  CARD_REFUSED = 'CardRefused',
  UNKNOWN_REASON = 'UnknownReason',
  PAID_FREE_TRANSACTION = 'PaidFreeTransaction',
  NO_PAYMENT_METHOD = 'NoPaymentMethod',
  CREDITED = 'Credited'
}

export enum OrderLineItemTypeEnum {
  CHARGING_SESSION = 'ChargingSession',
  INSTALLATION_COST = 'InstallationCost',
  MONTHLY_SUBSCRIPTION_PER_POINT = 'MonthlySubscriptionPerPoint',
  PAYBACK_CHARGING = 'PaybackCharging',
  REFUND = 'Refund',
  MONTHLY_FIXED_KWH_FEE = 'MonthlyFixedKWHFee',
  REFUND_NOT_INCLUED = 'RefundNotIncluded',
  SIM_CARD_SUBSCRIPTION = 'SimCardSubscription',
  SIM_CARD_SUBSCRIPTION_PAID_OUT = 'SimCardSubscriptionPaidOut',
  SERVICE_FEE = 'Servicefee',
  SERVICE_FEE_PAID_OUT = 'ServicefeePaidOut',
  CUSTOM_OWNER_FEE = 'CustomOwnerFee',
  CUSTOM_OWNER_FEE_PAID_OUT = 'CustomOwnerFeePaidOut',
  OPERATOR_LICENSE_FEE = 'OperatorLicenseFee',
  OPERATOR_MONTHLY_SUBSCRIPTION_POINT = 'OperatorMonthlySubscriptionPoint',
  BASE_CUT_CHARGING_TO_OPERATOR = 'BaseCutChargingToOperator',
  BASE_CUT_SERVICES_SOLD_BY_STATION_OWNER = 'BaseCutServicesSoldByStationOwner',
  OPERATOR_CUT_SERVICES_SOLD_BY_STATION_OWNER = 'OperatorCutServicesSoldByStationOwner',
  OPERATOR_CUT_STARTUP_COST = 'OperatorCutStartupCost',
  OPERATOR_CUT_NON_RECURRING_SERVICES_SOLD_BY_STATION_OWNER = 'OperatorCutNonRecurringServicesSoldByStationOwner',
  BASE_CUT_SERVICES_NON_RECURRING_TO_OPERATOR = 'BaseCutServicesNonRecurringToOperator',
  OPERATOR_MINIMUM_POINTS_SUBSCRIPTION = 'OperatorMinimumPointsSubscription',
  OPERATOR_CUT_CHARGING = 'OperatorCutCharging',
  BASE_CUT_NON_RECCURING_SERVICES_SOLD_BY_STATION_OWNER = 'BaseCutNonRecurringServicesSoldByStationOwner',
  GROUP_SUBSCRIPTION = 'GroupSubscription',
  MANUAL_SERVICES = 'ManualServices',
  MANUAL_SERVICES_NONRECURRING = 'ManualServicesNonRecurring',
  MANUAL_CHARGING = 'ManualCharging'
}

// Can use this for type casting
export const orderLineItemTypeCut = [
  OrderLineItemTypeEnum.OPERATOR_CUT_STARTUP_COST,
  OrderLineItemTypeEnum.CUSTOM_OWNER_FEE,
  OrderLineItemTypeEnum.BASE_CUT_CHARGING_TO_OPERATOR,
  OrderLineItemTypeEnum.BASE_CUT_SERVICES_SOLD_BY_STATION_OWNER,
  OrderLineItemTypeEnum.OPERATOR_CUT_SERVICES_SOLD_BY_STATION_OWNER,
  OrderLineItemTypeEnum.OPERATOR_CUT_NON_RECURRING_SERVICES_SOLD_BY_STATION_OWNER,
  OrderLineItemTypeEnum.BASE_CUT_SERVICES_NON_RECURRING_TO_OPERATOR
];

export const orderLineItemTypePoint = [];

export interface Invoice {
  $id: string;
  Currency: string; // Perhaps define enum for this
  CustomerName: string;
  DateDue: string;
  DateIssued: string;
  DatePaid: string;
  DialCode: string;
  Email: string;
  ExternalInvoiceId?: number;
  ExternalInvoieNumber?: number;
  FK_CompanyID?: number;
  FK_CustomerID: number;
  FK_SettlementID?: number;
  InvoiceType: InvoiceType; // Enum?
  Invoice_No: string;
  IsTripletexInvoice: boolean;
  ItemsGrouped: StationItem[];
  ItemsGroupedByStation: StationItem[];
  NonChargingItems: StationItem[];
  PK_InvoiceID: number;
  PaymentResult: InvoicePaymentResult; // Enum
  PhoneNumber?: string;
  StartupCost: number;
  StartupCostVAT: number;
  TotalKWH: number;
  TotalPrice: number;
  TotalPriceChargingTime: number;
  TotalPriceExcludingVAT: number;
  TotalPriceKWh: number;
  TotalPriceNotCharging: number;
  TotalPriceOccupied: number;
  TotalRefund: number;
  TotalTimeCharging: number;
  TotalTimeOccupied: number;
  TotalVAT: number;
  TransactionFee: number;
  TransactionFeeVAT: number;
  isExpired: boolean;
  isPaid: boolean;
  sentCount: number;
}

export interface MonthlySubscriptionPerPoint {
  ChargingPointName: string;
  IsDC: boolean;
  PK_ChargingPointID: number;
  PackageType: string;
}

export type OperatorMonthlySubscriptionPoint = MonthlySubscriptionPerPoint &
  OrderLinePointType;

interface OrderLineItemBaseType {
  Month: number;
  Year: number;
}

export interface OrderLineCutType extends OrderLineItemBaseType {
  ShareValue: number; // In percent
}

export interface OrderLineManualType extends OrderLineItemBaseType {
  Description: string;
}

export interface OrderLinePointType extends OrderLineItemBaseType {
  NbPoints: number;
}

export interface OrderLineMonthlyFixedKWHFee extends OrderLineItemBaseType {
  PricePerKWH: number;
  Currency: number;
  TotalKWH: number;
}

export interface OrderLineWithProductName extends OrderLineItemBaseType {
  ProductName: string;
}

export type InvoiceOrderLineItemTypes =
  | OrderLineCutType
  | OrderLinePointType
  | OrderLineMonthlyFixedKWHFee
  | null;

export interface StationItem {
  $id: string;
  StationID: number;
  Items: InvoiceOrderLine<InvoiceOrderLineItemTypes>[];
  PeriodStart: string | null;
  PeriodEnd: string | null;
}

export interface InvoiceOrderLine<OtherInfoType> {
  $id: string;
  ChargingUnitsConsumed: number;
  Description?: string;
  FK_CPActiveServiceID?: number;
  FK_ChargingStationID: number;
  FK_MM_PackageClientID: number;
  OtherInfos: OtherInfoType;
  PK_OrderLineItem: number;
  PeriodStart: string | null;
  PeriodEnd: string | null;
  PricePerUnit: number;
  PricePerUnits: number;
  PriceVAT: number;
  StartupCost: number;
  StartupCostVAT: number;
  StationName: string | null;
  TimeStarted: string | null;
  TimeFinished: string | null;
  TotalGatewayFee: number;
  TotalGatewayFeeVAT: number;
  TotalPrice: number;
  TotalPriceCharging: number;
  TotalPriceChargingTime: number;
  TotalPriceExcludingVAT: number;
  TotalPriceOccupied: number;
  TransactionFee: number;
  TransactionFeeVAT: number;
  UTC: string;
  Units: number;
  hideDate: boolean;
  OrderLineItemType: OrderLineItemTypeEnum;
}
