import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isCharging'
})
export class IsChargingPipe implements PipeTransform {
  transform(state: string): any {
    if (
      ['Discharging', 'Charging', 'Occupied', 'Suspended'].indexOf(state) === -1
    ) {
      return false;
    } else {
      return true;
    }
  }
}
