import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { Package } from 'src/app/components/package-template/package.model';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  constructor(private _http: HttpInterceptorService) {}

  getPackages(
    isSuperUser: boolean = false,
    countryID?: number,
    operatorID?: string | number
  ) {
    return this._http.get(
      isSuperUser
        ? 'Packages'
        : `Packages/PackagesOperatorCanAdd/${countryID}/${operatorID}`
    );
  }

  getInstallerPackages(installerCompanyID: number) {
    return this._http.get(`Packages/PackagesCompanyCanAdd/${installerCompanyID}`)
  }

  createPackage(pack: Package) {
    return this._http.post('Packages', pack);
  }

  updatePackage(pack: Package) {
    return this._http.put('Packages', pack);
  }

  updateClientPackage(pack: Package) {
    return this._http.put('PackageClients', pack);
  }

  deletePackage(packId: number) {
    return this._http.delete(`Packages/${packId}`);
  }

  getTimeZones() {
    return this._http.get('UserPreferences/GetTimezones');
  }
}
