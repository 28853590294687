import { ChargingProfile, ChargingProfileCriterion } from "src/app/classes/ChargingProfile"
import { ChargingProfilePurposeType, ComponentCriteriaType, ConfigVariable, GetCertificateType, HashAlgorithmType, IdTokenType, InstallCertificateType, LogType, OCPPInterfaceType, ReportBaseType, TriggerMessageType } from "./types"

export interface GenericCommandRequest {
  evseID?: number
  ChargingBoxID?: number
  connectorID_OCPP20?: number
}

export interface GetExpectedConfigurationOfBoxesRequest {
  Boxes?: number[]
}

export interface AddBatchTaskRequest {
  Boxes?: number[]
  Type?: CommandRequestType
  Parameters?: any
  ExecutionDate?: string
  FK_ChargingStationID?: number
}

export interface ChangeAvailabilityRequest extends GenericCommandRequest {
  IsOperative?: boolean
}

export interface GetConfigVariablesRequest extends GenericCommandRequest {
  Configurations: ConfigVariable[]
}

export interface ChangeConfigurationRequest extends GenericCommandRequest {
  Configurations: ConfigVariable[]
}

export interface ParametersStartSessionRequest {
  FK_ChargingPointID?: number
  FK_CustomerID?: number
  SpeedWanted?: SpeedWantedEnum
  MaxAmpsWanted?: number
  FK_UserVehicle?: number
  FK_CustomerPaymentMethodID?: number
  Origin?: StartSessionOriginEnum
  AuthorizationReference?: string
  ResponseURL?: string
}

export interface PostLocalList {
  listVersion?: number
  updateType?: LocalListUpdateTypeEnum
  localAuthorizationList?: AuthorizationData[]
}

export interface SendLocalListRequest extends GenericCommandRequest {
  datas?: PostLocalList
}



export interface SetDisplayMessageRequest extends GenericCommandRequest {
  id?: number
  priority: DisplayMessagePriorityEnum
  state?: DisplayMessageStateEnum
  startDateTime?: string
  endDateTime?: string
  transactionId?: string
  message: DisplayMessageContentType
  DisplayComponentName?: string
  DisplayInstanceName?: string
  DisplayEVSEID?: number
  DisplayConnectorID?: number
  DeviceID?: string
}

export interface GetAllDisplayedMessagesRequest extends GenericCommandRequest {
  priority: DisplayMessagePriorityEnum
  state?: DisplayMessageStateEnum
  ids?: number[]
  DeviceID?: string
}

export interface ClearDisplayMessageRequest extends GenericCommandRequest {
  Id: number
  DeviceID?: string
}

export interface SetMonitoringBaseRequest extends GenericCommandRequest {
  MonitoringBase?: MonitoringBaseEnum
}

export interface SetMonitoringLevelRequest extends GenericCommandRequest {
  Severity?: number
}

export interface ClearVariableMonitoringRequest extends GenericCommandRequest {
  id?: number[]
}

export interface SetVariableMonitoringRequest {
  setMonitoringData?: SetMonitoringData[]
}

export interface CustomerInformationRequest extends GenericCommandRequest {
  Parameters?: CustomerInformationParameters
}


export interface GetMonitoringReportRequest extends GenericCommandRequest {
  monitoringCriteria?: MonitoringCriterionEnum[]
  componentVariable?: SetConfigRequest[]
}

export interface GetReportRequest extends GenericCommandRequest {
  componentCriteria?: ComponentCriteriaType[]
  componentVariable?: SetConfigRequest[]
}

export interface SetNetworkProfileRequest extends GenericCommandRequest {
  configurationSlot?: number
  ocppNetworkConfiguration?: OcppNetworkParams
  vpnConfiguraiton?: NetworkVPNDetails
  apnConfiguration?: NetworkAPNDetails
}

export interface GetBaseReportRequest extends GenericCommandRequest {
  RequestID?: number
  Type?: ReportBaseType
}

export interface ClearChargingProfilesRequest extends GenericCommandRequest {
  chargingProfileId?: number
  chargingProfileCriteria?: {
    evseId?: number;
    chargingProfilePurpose?: ChargingProfilePurposeType,
    stackLevel?: number
  }
}

export interface GetChargingProfilesRequest extends GenericCommandRequest {
  chargingProfile?: ChargingProfileCriterion
  DeviceID?: string
}

export interface SendChargingProfile extends GenericCommandRequest {
  chargingProfile?: ChargingProfile
  ChargingProfileID?: number
}

export interface RemoteStartWithProfileRequest extends GenericCommandRequest {
  idTag: string
  chargingProfile?: ChargingProfile
  ChargingProfileID?: number
}

export interface ReserveNowRequest extends GenericCommandRequest {
  idTag?: string
  parentIdTag?: string
  expiryDate?: string
}


export interface CertificateSignedRequest {
  certificateChain?: string
  certificateType?: CertificateSigningUseEnum
}

export interface GetLogRequest extends GenericCommandRequest {
  Parameters?: LogParameters
}

export interface SetConfigRequest extends GenericCommandRequest {
  Key?: string
  Value?: string
  AttributeType?: string
  ComponentName?: string
  EVSEID?: number
}

export interface GetDiagnosticsRequest {
  PK_ChargingBoxID: number
  location?: string
  startTime?: string
  stopTime?: string
  retries?: number
  retryInterval?: number
}

export interface GetTransactionStatusRequest extends GenericCommandRequest {
  ChargingSessionID?: number,
  TransactionOcppID: string
}

export interface RemoteStartTransactionRequest extends GenericCommandRequest {
  idTag: string
}

export interface TriggerMessageRequest extends GenericCommandRequest {
  TypeMessage: TriggerMessageType
}

export interface GetCompositeScheduleRequest extends GenericCommandRequest {
  duration?: number
  chargingRateUnit: ChargingRateUnitEnum
  DeviceID?: string
}

export interface ChangePasswordRequest extends GenericCommandRequest {
  NewPassword: string
  DeviceID?: string
}

export interface UpdateFirmwareRequest {
  URLFirmware?: string
  PK_BoxUpdateID?: number
  FK_BoxModelID?: number
  FirmwareVersion?: string
  MinFirmwareVersionRequired?: string
  IsLatestFirmware?: boolean
  UseManufacturersCertificate?: boolean
  SigningCertificate?: string
  MustRebootWhenDone?: boolean
  Signature?: string
  installDate?: string
  retrieveDate?: string
}

export interface InstallCertificateRequest extends GenericCommandRequest {
  certificateType?: InstallCertificateType
  certificate?: string
  DeviceID?: string
}

export interface GetInstalledCertificatesRequest extends GenericCommandRequest {
  certificateTypes?: GetCertificateType[]
  DeviceID?: string
}

export interface DeleteCertificateRequest extends GenericCommandRequest {
  HashParameters?: CertificateHashParameters
  DeviceID?: string
}

export interface DataTransferRequest extends GenericCommandRequest {
  vendorId?: string
  messageId?: string
  data?: string
  DeviceID?: string
}

/*** Responses */
export interface GenericCommandResponse {
  PK_CommandRequestID: number
  type: CommandRequestType
  parameters: string
  time: string
  requestSent: boolean
  FK_InitiatedByCustomerID: number
  CustomerName: string
  CreatedOn: string
  FK_ChargingStationID: number
  Boxes: Box[]
}

export interface QueryResult {
  Result: any
}

export interface GenericBoxUpdate {
  PK_UpdateID?: number
  FK_ChargingBoxModelID?: number
  FirmwareVersion?: string
  VersionMinRequired?: string
  Link?: string
  Username?: string
  Password?: string
  PreviousUpdatesNeeded?: boolean
  FK_CertificateID?: number,
  ReleaseDate?: string
}

export interface DisplayMessageContentType {
  format: DisplayMessageFormatEnum,
  language?: string
  content: string
}



/*** Models used in commands model **/
export interface Box {
  ChargePoints: ChargePoint[]
  FK_ChargePointID: number
  PK_CommandRequestBoxID: number
  FK_CommandRequestID: number
  FK_ChargingBoxID: number
  requestID: string
  timeRequest: string
  timeAnswer: string
  answer: any
  parameters: any
  HasFailed: boolean
}

export interface BoxConfiguration {
  key: string
  readonly: boolean
  value: string
}

export interface ChargePoint {
  Name?: string
  PK_ChargePointID?: number
  FK_ChargingStationID?: number
}

export enum SpeedWantedEnum {
  Cheapest = 0,
  Custom = 1,
  Fastest = 2
}

export enum StartSessionOriginEnum {
  Unknown = 0,
  Dashboard = 1,
  App = 2,
  QR = 3,
  RFID = 4,
  SMS = 5,
  PLUG_AND_CHARGE = 6,
  ROAMING_LOCAL_START = 7,
  ROAMING_REMOTE_START = 8
}

export enum LocalListUpdateTypeEnum {
  Full = 0,
  Differential = 1
}

export enum CommandRequestType {
  Reset = 0,
  GetConfiguration = 1,
  UnlockConnector = 2,
  ClearCache = 3,
  SetConfiguration = 4,
  Activate = 5,
  Deactivate = 6,
  TurnOnMaintenance = 7,
  TurnOffMaintenance = 8,
  StartTransaction = 9,
  StopTransaction = 10,
  UpdateFirmware = 11,
  SetDefaultConfig = 12,
  ClearChargingProfile = 13,
  GetDiagnostics = 14,
  SetDefaultAuthentication = 15,
  ChangeAvailability = 16,
  GetCompositeSchedule = 17,
  SetDefaultPermanentCableLocking = 18,
  SendLocalList = 19,
  SetConfigOnBoot = 20,
  GetDisplayedMessages = 21,
  GetTransactionStatus = 22,
  TriggerMessage = 23,
  SetChargingProfile = 24,
  GetLocalListVersion = 25,
  CancelReservation = 26,
  ReserveNow = 27,
  DataTransfer = 28,
  ClearDisplayMessage = 29,
  SetDisplayMessage = 30,
  SetMonitoringBase = 31,
  SetMonitoringLevel = 32,
  SetVariableMonitoring = 33,
  SetNetworkProfile = 34,
  CertificateSigned = 35,
  DeleteCertificate = 36,
  CostUpdated = 37,
  CustomerInformation = 38,
  GetChargingProfiles = 39,
  GetInstalledCertificateIds = 40,
  GetLog = 41,
  GetMonitoringReport = 42,
  SetAuthentication = 43,
  GetBaseReport = 44,
  GetReport = 45,
  ClearVariableMonitoring = 46
}

export enum DisplayMessagePriorityEnum {
  AlwaysFront = 0,
  InFront = 1,
  NormalCycle = 2
}

export enum DisplayMessageStateEnum {
  Charging = 0,
  Faulted = 1,
  Idle = 2,
  Unavailable = 3,
}

export enum DisplayMessageFormatEnum {
  ASCII = 0,
  HTML = 1,
  URI = 2,
  UTF8 = 3,
}

export enum MonitoringBaseEnum {
  All = 0,
  FactoryDefault = 1,
  HardWiredOnly = 2
}

export enum OcppVersionEnum {
  SMARTPOINT_1 = "SMARTPOINT_1",
  VERSION_12 = "VERSION_12",
  VERSION_15 = "VERSION_15",
  VERSION_15_SOAP_KEBA = "VERSION_15_SOAP_KEBA",
  VERSION_16_SOAP = "VERSION_16_SOAP",
  VERSION_16_JSON = "VERSION_16_JSON",
  VERSION_201_JSON = "VERSION_201_JSON"
}

export enum MonitoringCriterionEnum {
  ThresholdMonitoring = 0,
  DeltaMonitoring = 1,
  PeriodicMonitoring = 2
}

export enum IdTokenEnum {
  Central = 0,
  eMAID = 1,
  ISO14443 = 2,
  KeyCode = 3,
  Local = 4,
  NoAuthorization = 5,
  ISO15693 = 6,
  MacAddress = 7
}

export enum HashAlgorithmEnum {
  SHA256 = 0,
  SHA384 = 1,
  SHA512 = 2
}




export enum ChargingLimitSourceEnum {
  EMS = 0,
  Other = 1,
  SO = 2,
  CSO = 3,
}

export enum ChargingProfileKindEnum {
  Absolute = 0,
  Recurring = 1,
  Relative = 2
}

export enum RecurrencyKindEnum {
  Daily = 0,
  Weekly = 1
}

export enum ChargingRateUnitEnum {
  W = 0,
  A = 1
}

export enum CostKindEnum {
  CarbonDioxideEmission = 0,
  RelativePricePercentage = 1,
  RenewableGenerationPercentage = 2,
}



export enum CertificateSigningUseEnum {
  ChargingStationCertificate = 0,
  V2GCertificate = 1
}

export enum MonitorEnum {
  UpperThreshold = 0,
  LowerThreshold = 1,
  Delta = 2,
  Periodic = 3,
  PeriodicClockAligned = 4
}

export enum OCPPVersionEnum {
  OCPP12 = 0,
  OCPP15 = 1,
  OCPP16 = 2,
  OCPP20 = 3
}

export enum VPNTypeEnum {
  IKEv2 = 0,
  IPSec = 1,
  L2TP = 2,
  PPTP = 3
}

export enum APNAuthenticationEnum {
  CHAP = 0,
  NONE = 1,
  PAP = 2,
  AUTO = 3
}

export enum OCPPTransportEnum {
  JSON = 0,
  SOAP = 1
}



export enum ResetType {
  SOFT = 0,
  HARD = 1
}

export interface AuthorizationData {
  idTag?: string
  idTagInfo?: IdTagInfo
}

export interface IdTagInfo {
  expiryDate?: number
  parentIdTag?: string
  AuthorizeStatus?: string
}

export interface SetMonitoringData {
  id?: number
  transaction?: boolean
  value?: number
  type?: MonitorEnum
  severity?: number
  component?: ComponentType
  variable?: Variable
}

export interface ComponentType {
  name?: string | number
  instance?: string
  evse?: Evse
}

export interface Evse {
  id?: number
  connectorId?: number
}

export interface Variable {
  name?: string
  instance?: string
}

export interface CustomerInformationParameters {
  requestId?: number
  report?: boolean
  clear?: boolean
  customerIdentifier?: string
  idToken?: IdToken
  customerCertificate?: CustomerCertificate
}

export interface IdToken {
  idToken?: string
  type?: IdTokenType
  additionalInfo?: AdditionalInfo[]
}

export interface AdditionalInfo {
  additionalIdToken?: string
  type?: string
}

export interface CustomerCertificate {
  hashAlgorithm?: HashAlgorithmEnum
  issuerNameHash?: string
  issuerKeyHash?: string
  serialNumber?: string
}

export interface ChargingSchedule {
  id?: number
  startSchedule?: string
  duration?: number
  chargingRateUnit?: ChargingRateUnitEnum
  minChargingRate?: number
  chargingSchedulePeriod?: ChargingSchedulePeriod[]
  salesTariff?: SalesTariff
}

export interface ChargingSchedulePeriod {
  startPeriod?: number
  limit?: number
  numberPhases?: number
  phaseToUse?: number
}

export interface SalesTariff {
  id?: number
  salesTariffDescription?: string
  numEPriceLevels?: number
  salesTariffEntry?: SalesTariffEntry[]
}

export interface SalesTariffEntry {
  ePriceLevel?: number
  relativeTimeInterval?: RelativeTimeInterval
  consumptionCost?: ConsumptionCost[]
}

export interface RelativeTimeInterval {
  start?: number
  duration?: number
}

export interface ConsumptionCost {
  startValue?: number
  cost?: Cost[]
}

export interface Cost {
  costKind?: CostKindEnum
  amount?: number
  amountMultiplier?: number
}

export interface LogParameters {
  remoteLocation?: string
  oldestTimestamp?: string
  latestTimestamp?: string
  logType?: LogType
  retries?: number
  retryInterval?: number
}

export interface GenericBoxModelDefaultConfig {
  PK_BoxModelDefaultConfig?: number
  FK_BoxModelID?: number
  OCPPVersion?: OcppVersionEnum
  Key?: string
  Value?: string
  ComponentName?: string
  AuthenticationEnabled?: boolean
  LoadBalancingEnabled?: boolean
  PermanentCableLockingEnabled?: boolean
  InternalConnectorId?: number
  OnlyUsedForSetup?: boolean
}

export interface OcppNetworkParams {
  ocppVersion?: OCPPVersionEnum
  ocppTransport?: OCPPTransportEnum
  ocppCsmsUrl?: string
  messageTimeout?: number
  securityProfile?: number
  ocppInterface?: OCPPInterfaceType
}

export interface NetworkVPNDetails {
  server?: string
  user?: string
  group?: string
  password?: string
  key?: string
  type?: VPNTypeEnum
}

export interface NetworkAPNDetails {
  apn?: string
  apnUserName?: string
  apnPassword?: string
  simPin?: number
  preferredNetwork?: string
  useOnlyPreferredNetwork?: boolean
  apnAuthentication?: APNAuthenticationEnum
}

export interface CertificateHashParameters {
  hashAlgorithm: HashAlgorithmType,
  issuerNameHash: string
  issuerKeyHash: string
  serialNumber: string
}

