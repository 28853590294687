import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { CountriesState, selectAllCountries, selectIsLoading } from '.';

import * as CountriesActions from './countries.actions';
import { Country } from '../../countries';
import { AppState } from '..';
import {
  selectActiveCountries,
  selectAvailablePaymentMethods,
  selectSelectedCountry
} from './countries.selectors';

@Injectable({
  providedIn: 'root'
})
export class CountriesFacade {
  allCountries$ = this.store
    // @TODO: Could be written nicer
    .pipe(
      tap(({ countries }) => {
        if (!countries.isLoading && !countries.isFetched) {
          this.getAll();
        }
      }),
      select(selectAllCountries)
    );

  activeCountries$ = this.store.pipe(select(selectActiveCountries));
  selectedCountry$ = this.store.pipe(select(selectSelectedCountry));
  avaiablePaymentMethods$ = this.store.pipe(
    select(selectAvailablePaymentMethods)
  );

  constructor(private store: Store<AppState>) {}

  getAll() {
    this.store.subscribe(({ countries }) => {
      if (!countries.isLoading && !countries.isFetched) {
        this.store.dispatch(CountriesActions.LoadAllCountries());
      }
    });
  }

  selectCountryAndLoadPaymentMethods(CountryID: number) {
    this.selectCountry(String(CountryID));
    this.loadAvailablePaymentMethods(String(CountryID));
  }

  selectCountry(PK_CountryID: string) {
    this.store.dispatch(CountriesActions.SelectCountryID({ PK_CountryID }));
  }

  loadAvailablePaymentMethods(PK_CountryID: string) {
    this.store.dispatch(
      CountriesActions.LoadAvailablePaymentMethodsForCountry({ PK_CountryID })
    );
  }
}
