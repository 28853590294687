import { Injectable } from '@angular/core';
import { AppConfig } from '@smartcharge/shared/core/config';
import { AppTokens, WhitelabelClass, WhitelabelInterface } from './types'
import { WhitelabelService } from './whitelabel.service';

@Injectable({
  providedIn: 'root'
})
export class WhitelabelInstance {
  whitelabel: WhitelabelInterface;
  whitelabelID: string = null;
  isWhiteLabel: boolean = false;

  constructor(private WhitelabelService: WhitelabelService) {
    this.whitelabel = this.getWhitelabelByDomain();
    this.whitelabelID = this.whitelabel.whitelabelID;

    // Utility property
    this.isWhiteLabel = this.whitelabelID !== null;
  }

  init(): Promise<void> {
    //This function will run before everything else in the app
    return new Promise((resolve) => {
      this.loadWhitelabel();
      resolve();
    });
  }

  getConfig(): AppConfig {
    return {
      appToken: this.whitelabelID,
      availableLangs: this.whitelabel.availableLangs,
      defaultLang: this.whitelabel.defaultLang,
      isWhitelabel: this.isWhiteLabel,
      showTitle: false,
      title: this.whitelabel.title
    };
  }



  isFellTech(): boolean {
    return this.whitelabel.whitelabelID === AppTokens.abracharge
  }

  private getWhitelabelByDomain(): WhitelabelInterface {
    let domain: any = window.location.hostname;
    //Removes all before and including " :// "
    if (domain.includes('://')) {
      domain = /:\/\/([^\/]+)/.exec(domain)[1];
    }

    let domains: any[] = domain.split('.');
    let subdomain = '';

    if (['www', 'app', 'portal'].includes(domains[0].toLowerCase())) {
      //Removes www, app and portal
      domains.shift();
    }

    subdomain = domains[0].toLowerCase();
    const operators = new WhitelabelClass();

    if (subdomain === 'felltech') {
      subdomain = 'abracharge';
    }

    return subdomain in operators
      ? operators[subdomain]
      : operators.smartcharge;
  }

  private loadWhitelabel(): void {
    if (this.whitelabelID) {
      this.WhitelabelService.getWhitelabel(this.whitelabelID).subscribe({
        next: (res) => {
          this.whitelabel.whiteLabelDetails = {
            AppName: res.AppName,
            FK_OperatorID: res.FK_OperatorID
          };
        },
        error: (err) => { }
      });
    }
  }
}





