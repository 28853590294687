export enum Currency {
  EUR = 0,
  NOK = 1,
  USD = 2,
  CAD = 3,
  GBP = 4, // United kingdom pound
  CNY = 5, // China Yuan Renminbi
  DKK = 6, // Denmark Krone
  SEK = 7, // Sweden Krona
  CHF = 8, // Switzerland Franc
  JPY = 9, // Japan Yen,
  ISK = 10 // Icelandic krona
}
