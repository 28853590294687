import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { HttpInterceptorService } from '../../../app/services/http-interceptor/http-interceptor.service';

import { AddPaymentMethodDTO, CustomerPaymentMethod } from '../../../app/dtos';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {
  constructor(private _http: HttpInterceptorService) {}

  addPaymentMethod(options: AddPaymentMethodDTO) {
    return this._http
      .post('PaymentMethods/AddPaymentMethod', options)
      .pipe(map((res) => res.datas));
  }

  updatePaymentMethod(options: CustomerPaymentMethod) {
    return this._http
      .put('PaymentMethods', options)
      .pipe(map((res) => res.datas));
  }

  deletePaymentMethod(customerPaymentMethodID: number) {
    return this._http
      .delete(`PaymentMethods/${customerPaymentMethodID}`)
      .pipe(map((res) => res.datas));
  }

  getPaymentMethods(PK_CountryID: string) {
    return this._http.get(
      `PaymentMethods/PossiblePaymentMethods/${PK_CountryID}/All/true`
    )
  }

  setDefaultPaymnetMethod(customerPaymentMethodID: number) {
    return this._http.put(
      `PaymentMethods/SetDefault/${customerPaymentMethodID}`,
      {}
    );
  }

  removeDefaultPaymnetMethod(paymentMethod: CustomerPaymentMethod) {
    return this._http.put(`PaymentMethods/`, paymentMethod);
  }
}
