import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { BoxManufacturer } from 'src/app/classes';

@Injectable({
  providedIn: 'root'
})
export class ManufacturerService {
  constructor(private _http: HttpInterceptorService) {}

  createManufacturer(
    manufacturer: BoxManufacturer
  ): Observable<BoxManufacturer> {
    return this._http.post('/ChargingBoxManufacturers', manufacturer);
  }

  getAll(): Observable<BoxManufacturer[]> {
    return this._http
      .get('/ChargingBoxManufacturers')
      .pipe(map((res) => res.datas));
  }

  getSingle(id: number): Observable<BoxManufacturer> {
    return this._http.get(`/ChargingBoxManufacturers/${id}`);
  }

  removeManufacturer(manufacturer: BoxManufacturer): Observable<any> {
    return this._http.delete(
      `/ChargingBoxManufacturers/${manufacturer.PK_ChargerManufacturerID}`
    );
  }

  updateManufacturer(
    manufacturer: BoxManufacturer
  ): Observable<BoxManufacturer> {
    return this._http.put('/ChargingBoxManufacturers', manufacturer);
  }
}
