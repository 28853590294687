<form (submit)="handleOnSubmit($event)" [formGroup]="form">
  <h2 mat-dialog-title>Clear monitoring</h2>

  <div formArrayName="inputs">
    <div
      class="mb-4 flex"
      *ngFor="let item of inputs.controls; let i = index"
      [formGroupName]="i">
      <mat-form-field floatLabel="auto" class="ml-4">
        <mat-label>{{ 'ID' | translate }}</mat-label>
        <input matInput formControlName="ID" type="number" />
      </mat-form-field>

      <button mat-icon-button class="ml-4" (click)="deleteInput(i)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>

  <div class="mb-4">
    <button mat-raised-button (click)="addInput()">
      <mat-icon>add</mat-icon>
      {{ 'ADD' | translate }}
    </button>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit">
      {{ 'CLEAR' | translate }}
    </button>
    <button mat-raised-button (click)="close()" type="button">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</form>
