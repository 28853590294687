import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CDatePipe } from 'src/app/pipes/c-date/c-date.pipe';

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  constructor(private cDatePipe: CDatePipe) {}
  makeObjectArray(
    arrayOfObjects: any[],
    date?,
    dateInc?: number,
    removeNegative: boolean = true,
    removePostDate: boolean = false
  ): Promise<any> {
    return new Promise((resolve) => {
      let objectArray = { labels: [] };
      let count = 0;
      let tempDate = date;
      for (let obj of arrayOfObjects) {
        count++;
        if (removePostDate && new Date(obj.Day) > new Date()) continue;

        for (let key in obj) {
          //Create the arrays
          if (!objectArray[key]) objectArray[key] = [];
          if (obj[key] < 0 && removeNegative) obj[key] = 0;
          objectArray[key].push(obj[key]);
        }

        if (date && dateInc) {
          //Use date as label

          if (objectArray.labels.length) {
            //Only increase if not first
            tempDate = moment(tempDate).add(dateInc, 'm').toDate();
          }
          objectArray.labels.push(moment(tempDate));
        } else {
          //Just push the number
          objectArray.labels.push(count);
        }
      }
      // Done anyway
      resolve(objectArray);
    });
  }
  makeObjectArrayWithDate(
    arrayOfObjects: any[],
    dateKey?: string,
    date?,
    dateInc?: number,
    removeNegative: boolean = true
  ): Promise<any> {
    return new Promise((resolve) => {
      let objectArray = { labels: [], test: [] };
      if (!arrayOfObjects.length) {
        resolve(objectArray);
      }
      let count = 0;
      let tempDate = date;
      for (let obj of arrayOfObjects) {
        count++;

        for (let key in obj) {
          //Create the arrays
          if (!objectArray[key]) objectArray[key] = [];
          if (obj[key] < 0 && removeNegative) obj[key] = 0;

          objectArray[key].push(obj[key]);
        }

        if (date && dateInc) {
          //Use date as label

          if (objectArray.labels.length) {
            //Only increase if not first
            tempDate = moment(tempDate).add(dateInc, 'm').toDate();
          }
          objectArray.labels.push(moment(tempDate));
        } else if (dateKey) {
          objectArray.labels.push(moment(obj[dateKey]));
        } else {
          //Just push the number
          objectArray.labels.push(count);
        }

        if (count >= arrayOfObjects.length) {
          //Done
          resolve(objectArray);
        }
      }
    });
  }
}
