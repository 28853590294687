import { Action, createAction, props } from '@ngrx/store';

import { Country } from '../../countries';
import { AvailablePaymentMethod } from '../../../../app/lib';

export const SelectCountryID = createAction(
  '[Countries] Select Country ID',
  props<{ PK_CountryID: string }>()
);

export const LoadAllCountries = createAction('[Countries] Load all countries');

export const AllCountriesLoaded = createAction(
  '[Countries] All countries loaded',
  props<{ countries: Country[] }>()
);

export const LoadAvailablePaymentMethodsForCountry = createAction(
  '[Countries] Load available payment methods for country',
  props<{ PK_CountryID: string }>()
);

export const LoadedAvailablePaymentMethodsForCountry = createAction(
  '[Countries] Loaded available payment methods for country',
  props<{ PK_CountryID: string; paymentMethods: AvailablePaymentMethod[] }>()
);
