/* Service Loader */
/* Put all services in this file. They will be automatically loaded into app.module */
/* NB! If the service uses a module to be loaded, do not put it here. */

//Importing services
import { HttpInterceptorService } from 'src/app/services';

import { UrlTitleService } from './url-title/url-title.service';
import { GlobalFunctions } from './global-functions/global-functions.service';

import { StationService } from './station/station.service';
import { StationTabsService } from './station-tabs/station-tabs.service';
import { PointService } from './point/point.service';
import { GroupService } from './group/group.service';

import { StyleService } from './style/style.service';
import { UsersService } from './users/users.service';
import { OrdersService } from './orders/orders.service';
import { TableService } from './table/table.service';
import { CompanyService } from './company/company.service';
import { WebsocketService } from './websocket/websocket.service';
import { ClearService } from './clear/clear.service';
import { RouterService } from './router/router.service';
import { PermissionsService } from './permissions/permissions.service';
import { ExportService } from './export/export.service';
import { WallSettingService } from './wall-setting/wall-setting.service';
import { ConfigFileService } from './config-file/config-file.service';
import { CommandRequestsService } from './command-requests/command-requests.service';
import { RolesService } from './roles/roles.service';
import { EmailService } from './email/email.service';
import { ChartService } from './chart/chart.service';
import { ReportService } from './report/report.service';
import { PackagesService } from './packages/packages.service';
import { OperatorService } from './operator/operator.service';
import { TermsService } from './terms/terms.service';
import { WhitelabelService } from './whitelabel/whitelabel.service';
import { ProductsService } from './products/products.service';
import { SharedService } from './shared/shared.service';
import { QRCodeService } from './qrcode/qrcode.service';
import { FileDownloadService } from './filedownload/filedownload.service';
import { SpotPriceService } from './spot-price/spot-price.service';
import { InstallerService } from './installer/installer.service';
import { AbraAuthService } from './abraAuth/abra-auth.service';
import { CommandsService } from '../lib/commands';
import { WhitelabelInstance } from './whitelabel/whitelabel-instance.service';
import { RefreshService } from './refresh/refresh.service';

//All services below will be provided in app.module
export const Services = [
  HttpInterceptorService,
  UrlTitleService,
  GlobalFunctions,
  StationService,
  StationTabsService,
  PointService,
  GroupService,
  StyleService,
  UsersService,
  OrdersService,
  TableService,
  CompanyService,
  WebsocketService,
  ClearService,
  RouterService,
  PermissionsService,
  ExportService,
  WallSettingService,
  ConfigFileService,
  CommandRequestsService,
  RolesService,
  EmailService,
  ChartService,
  ReportService,
  PackagesService,
  OperatorService,
  TermsService,
  WhitelabelService,
  WhitelabelInstance,
  ProductsService,
  SharedService,
  QRCodeService,
  FileDownloadService,
  SpotPriceService,
  InstallerService,
  AbraAuthService,
  CommandsService,
  RefreshService
];
