import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { TableOptions } from '../../classes/table-options';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { snackbarConfig } from '../../defaults';
import { DialogService } from '../dialog/dialog.service';
import { Station, UserService } from '../../lib';
import { PackageStation } from './station.models';
import { Observable, map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ChargingStationSettings } from 'src/app/lib/stations/stations.model';
@Injectable()
export class StationService {
  stations: any = [];
  selectedStation: any = null;
  selectedPoint: any = null;
  chargingPoints: any = [];
  totalStations: number = 0;

  constructor(
    private _http: HttpInterceptorService,
    private userService: UserService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private dialogService: DialogService,
    private translate: TranslateService
  ) {}

  get(
    refresh?: boolean,
    options: TableOptions = new TableOptions()
  ): Promise<any> {
    return new Promise((resolve) => {
      if (this.stations.length && !refresh) {
        resolve(this.stations);
      } else {
        this.userService.getUser().subscribe((user) => {
          // ChargingStations/GetChargingStationsByCustomer/{customerID}/{limit}/{range}/{startIndex}/{sortType}/{sortDirection}
          let action = 'ChargingStations/GetChargingStationsByCustomer/';
          let limit = options.limit; //limit
          let range = 0;
          let startIndex = options.limit * options.offset;
          let sortType = options.prop;
          let sortDirection =
            options.dir === 'asc' ? 'Ascending' : 'Descending';
          let search = options.search;

          this._http
            .get(
              action +
                user.PK_CustomerID +
                '/' +
                limit +
                '/' +
                range +
                '/' +
                startIndex +
                '/' +
                sortType +
                '/' +
                sortDirection +
                '/' +
                search
            )
            .subscribe(
              (res) => {
                if (res.errorName) {
                  resolve([]);
                } else {
                  this.totalStations = res.TotalStations || res.Stations.length;

                  this.stations = res.Stations;
                  resolve(this.stations);
                }
              },
              (err) => {
                resolve([]);
                this.snackBar.open(
                  this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                    errorName: err.errorName
                  }),
                  this.translate.instant('CLOSE')
                );
              }
            );
        });
      }
    });
  }
  toggleActive(stationID, isActive): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .put(
          'ChargingStations/DeactivateChargingStation/' +
            stationID +
            '/' +
            isActive,
          null
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  getAll(): Promise<any> {
    return new Promise((resolve) => {
      this.userService.getUser().subscribe((user) => {
        // ChargingStations/GetChargingStationsByCustomer/{customerID}/{limit}/{range}/{startIndex}/{sortType}/{sortDirection}
        let action = 'ChargingStations/GetChargingStationsByCustomer/';
        let limit = 0; //limit
        let range = 0;
        let startIndex = 0;
        let sortType = 'Name';
        let sortDirection = 'Ascending';

        this._http
          .get(
            action +
              user.PK_CustomerID +
              '/' +
              limit +
              '/' +
              range +
              '/' +
              startIndex +
              '/' +
              sortType +
              '/' +
              sortDirection +
              '/'
          )
          .subscribe(
            (res) => {
              resolve(res.Stations);
              //this.getBIForAllStations();
            },
            (err) => {
              this.snackBar.open(
                this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                  errorName: err.errorName
                }),
                this.translate.instant('CLOSE')
              );
              resolve([]);
            }
          );
      });
    });
  }

  getAllNew(payload?: any) {
    return this._http.post(`ChargingStations/Export`, payload ? payload : {});
  }
  getForMap(): Promise<any> {
    return new Promise((resolve) => {
      this._http
        .get('ChargingStations/GetYourStationsForMap')
        .subscribe((res) => {
          resolve(res);
        });
    });
  }
  getSingle(id, reload?): Promise<any> {
    return new Promise((resolve) => {
      this.chargingPoints = [];
      if (this.selectedStation && !reload) {
        resolve(this.selectedStation);
      } else {
        this._http
          .get('ChargingStations/GetStationForDashboard/' + id + '/true')
          .subscribe(
            (station) => {
              if (station) {
                this.selectedStation = station;
                this.chargingPoints = station.ChargingPoints || [];

                resolve(station);
              } else {
                resolve(null);
              }
            },
            (err) => {
              resolve(null);
            }
          );
      }
    });
  }
  getSettings(stationID): Promise<any> {
    return new Promise((resolve) => {
      this._http.get('ChargingStationSettings/' + stationID).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.snackBar.open(
            this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            }),
            this.translate.instant('CLOSE')
          );
          resolve(null);
        }
      );
    });
  }
  deleteStation(stationID): Promise<any> {
    return new Promise((resolve) => {
      this.dialogService
        .prompt(
          this.translate.instant('CONFIRM_DELETE_STATION'),
          null,
          this.translate.instant('DELETE')
        )
        .subscribe((doDelete) => {
          if (!doDelete) {
            resolve(false);
          } else {
            this._http.delete('ChargingStations/' + stationID).subscribe(
              (res) => {
                resolve(true);
              },
              (err) => {
                this.snackBar.open(
                  this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                    errorName: err.errorName
                  }),
                  this.translate.instant('CLOSE')
                );
                resolve(false);
              }
            );
          }
        });
    });
  }
  clear() {
    this.stations = [];
    this.chargingPoints = [];
    this.selectedStation = null;
    this.selectedPoint = null;
    this.totalStations = 0;
  }
  update(station): Promise<any> {
    return new Promise((resolve) => {
      this._http.put('ChargingStations/PutChargingStation', station).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.snackBar.open(
            this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            }),
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }
  changeOwner(station, companyID) {
    station.FK_OwnedByCompanyID = companyID;
    this._http.put('ChargingStations/PutChargingStation', station).subscribe(
      (res) => {
        this.snackBar.open(
          this.translate.instant('OWNER_CHANGED'),
          null,
          snackbarConfig
        );
      },
      (err) => {
        this.snackBar.open(
          this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
            errorName: err.errorName
          }),
          this.translate.instant('CLOSE')
        );
      }
    );
  }

  getPackageDetails(stationId: number): Observable<PackageStation> {
    return this._http.get(`PackageClients/GetByStation/${stationId}`);
  }

  updatePackage(pack: PackageStation) {
    return this._http.put('PackageClients', pack);
  }

  getPackages() {
    return this._http.get('Packages');
  }

  getPackagesICanAdd(currency: string, typeStation: number = 15) {
    return this._http.get(
      `Packages/PackagesICanAdd/${currency}/${typeStation}`
    );
  }
  getPackagesICanAddNew(countryId: number) {
    return this._http.get(`Packages/PackagesICanAdd/${countryId}`);
  }
  getPackagesOperatorCanAdd(countryId: number, operatorId: number) {
    return this._http.get(
      `Packages/PackagesOperatorCanAdd/${countryId}/${operatorId}`
    );
  }

  getAvailablePackagesForStation(stationID: number) {
    return this._http.get(`Packages/AvailableForStation/${stationID}`);
  }

  getStationPowerContract(stationID: number) {
    return this._http.get(`ChargingStations/PowerContract/${stationID}`);
  }

  getStationByID(stationID: number) {
    return this._http.get(`ChargingStations/${stationID}`);
  }

  getMultipleStations(stationIDs: number[]) {
    return this._http
      .post('ChargingStations/names', stationIDs)
      .pipe(
        map(
          (res) => (res?.datas || []) as { Name: string; StationID: number }[]
        )
      );
  }

  getStationsByCompany(
    companyID: number,
    start = 0,
    limit = 10
  ): Observable<any> {
    return this._http.get(
      `ChargingStations/GetChargingStationsByCompany/${companyID}/${start}/${limit}`
    );
  }

  getLocationSettings(stationID: number) {
    return this._http.get<ChargingStationSettings>(
      `ChargingStationSettings/${stationID}`
    );
  }

  updateLocationSettings(settings: ChargingStationSettings) {
    return this._http.put(`ChargingStationSettings`, settings);
  }
}
