import { on, createReducer } from '@ngrx/store';
import { User } from '../../user/user.model';
import * as CustomerActions from './customer.actions';

export interface CustomerState {
  isFetched: boolean;
  isLoading: boolean;
  selectedCustomer: number;
  users: { [id: number]: User };
}

// Entity adapter
export const initialState: CustomerState = {
  isFetched: false,
  isLoading: false,
  selectedCustomer: null,
  users: {}
};

export const customerReducer = createReducer(
  initialState,
  on(CustomerActions.LoadCustomer, (state, { customerID }) => {
    return {
      ...state,
      selectedCustomer: null,
      isLoading: true
    };
  }),
  on(CustomerActions.CustomerLoaded, (state, { customerID, user }) => {
    return {
      ...state,
      selectedCustomer: customerID,
      isLoading: false,
      users: { ...state.users, [customerID]: user }
    };
  })
);
