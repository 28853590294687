<h2 mat-dialog-title>{{ 'UPDATE_FIRMWARE' | translate }}</h2>
<form [formGroup]="updateFirmwareForm" (ngSubmit)="handleFormSubmit()">
  <div mat-dialog-content class="dialog-content">
    <div *ngIf="!updateFirmwareForm.controls.manualFirmware.value">
      <p class="manual-heading">{{ 'USE_EXISTING_FIRMWARE' | translate }}</p>
      <mat-form-field class="min-w-[35vw]">
        <mat-label>{{ 'URL' | translate }}</mat-label>
        <mat-select
          formControlName="PK_UpdateID">
          <mat-option
            *ngFor="let item of boxUpdates"
            [value]="item?.PK_UpdateID"
            class="with-description">
            {{ item?.Link }}
            <div class="description">{{ item.FirmwareVersion }}</div>
          </mat-option>
        </mat-select>
        <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
      </mat-form-field>
      <button
        mat-icon-button
        class="toolbar-button ml-1"
        type="button"
        *canSee="[userType.Admin, userType.BoxManufacturer]"
        (click)="addFirmwareDialog()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="my-2">
      <mat-slide-toggle
        formControlName="manualFirmware"
        (keydown)="$event.preventDefault()">
        {{ 'CUSTOM_FIRMWARE_UPDATE' | translate }}
      </mat-slide-toggle>
    </div>
    <div *ngIf="updateFirmwareForm.controls.manualFirmware.value">
      <mat-form-field floatLabel="auto" class="min-w-[35vw]">
        <mat-label>{{ 'URL' | translate }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="URLFirmware"/>
        <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
      </mat-form-field>

      <div>
        <mat-form-field floatLabel="auto" class="min-w-[35vw]">
          <mat-label>{{ 'SIGNING_CERTIFICATE' | translate }}</mat-label>
          <textarea
            matInput
            formControlName="SigningCertificate"
            cols="10"
            rows="4"></textarea>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field floatLabel="auto" class="min-w-[35vw]">
          <mat-label>{{ 'SIGNATURE' | translate }}</mat-label>
          <textarea
            matInput
            formControlName="Signature"
            cols="10"
            rows="4"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="mt-4">
      <mat-form-field class="mr-4">
        <mat-label>{{ 'SCHEDULE_INSTALL' | translate }}</mat-label>
        <input
          matInput
          type="datetime-local"
          formControlName="installDate" />
        <mat-hint>UTC</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'SCHEDULE_DOWNLOAD' | translate }}</mat-label>
        <input
          matInput
          type="datetime-local"
          formControlName="retrieveDate" />
        <mat-hint>UTC</mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit">
      {{ 'UPDATE_FIRMWARE' | translate }}
    </button>
    <button mat-raised-button (click)="this.dialogRef.close()" type="button">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</form>
