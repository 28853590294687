import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import {
  catchError,
  delay,
  map,
  mergeMap,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';

import { CountryService, Country } from '../../countries';

import {
  CountriesActions,
  selectAllCountries,
  selectCountriesState,
  CountriesState
} from '.';
import { PaymentMethodService } from '../../payment-method';

@Injectable({ providedIn: 'root' })
export class CountriesEffects {
  loadAllCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountriesActions.LoadAllCountries),
      mergeMap(() =>
        this.countryService.getCountries().pipe(
          map((countries) =>
            CountriesActions.AllCountriesLoaded({ countries })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  loadAvailablePaymentMethods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountriesActions.LoadAvailablePaymentMethodsForCountry),
      mergeMap((action) =>
        this.paymentMethodService.getPaymentMethods(action.PK_CountryID).pipe(
          map((paymentMethods) =>
            CountriesActions.LoadedAvailablePaymentMethodsForCountry({
              paymentMethods,
              PK_CountryID: action.PK_CountryID
            })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private countryService: CountryService,
    private paymentMethodService: PaymentMethodService,
    private store: Store<CountriesState>
  ) {}
}
