import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'convertSeconds'
})
export class ConvertSecondsPipe implements PipeTransform {
  transform(seconds: any, showSeconds = true): any {
    // calculate (and subtract) whole days
    var days = Math.floor(seconds / 86400);
    seconds -= days * 86400;

    // calculate (and subtract) whole hours
    var hours = Math.floor(seconds / 3600) % 24;

    seconds -= hours * 3600;

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(seconds / 60) % 60;
    seconds -= minutes * 60;

    // what's left is seconds
    seconds = seconds % 60;

    let dateString = '';
    if (days) {
      dateString += days + 'd ';
    }
    if (hours) {
      dateString += hours + 'h ';
    }
    if (minutes) {
      dateString += minutes + 'm ';
    }

    if (showSeconds && seconds) {
      dateString += Math.round(seconds) + 's ';
    }
    if (!dateString) {
      dateString = '0h ';
    }

    return dateString.trim();
  }
  pad(num) {
    return ('0' + num).slice(-2);
  }
}
