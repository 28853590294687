<form>
  <mat-form-field
    class="station-search"
    [ngClass]="styleClass"
    [floatLabel]="floatLabel"
    [ngStyle]="{ width: width }">
    <mat-label *ngIf="showLabel">
      {{ customPlaceHolder ? customPlaceHolder : ('SEARCH_FOR_A_LOCATION' | translate) }}
    </mat-label>
    <input
      type="text"
      matInput
      #autoComplete
      [required]="required"
      [matAutocomplete]="auto"
      (keyup)="onSearch($event)"
      (focus)="initSocket()"
      (blur)="clearSocket()"
      [formControl]="searchInputCtrl"
      [placeholder]="!showLabel && (customPlaceHolder ? customPlaceHolder : ('SEARCH_FOR_A_LOCATION' | translate))"
      name="station" />
    <!-- [(ngModel)]="searchInput"-->
    <button
      matSuffix
      mat-icon-button
      type="button"
      class="toolbar-button"
      *ngIf="searchInputCtrl.value"
      (click)="clearSearch()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-error>{{ 'PLEASE_SELECT_STATION' | translate }}</mat-error>
  </mat-form-field>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    class="station-search__autocomplete"
    (optionSelected)="selectStation($event)">
    <mat-option
      *ngIf="enter && searchedStations.length > 1"
      [value]="searchInputCtrl.value">
      <span class="search-option">
        {{ 'SHOW_ALL' | translate }}
        <span *ngIf="searchInputCtrl.value">'{{ searchInputCtrl.value }}'</span>
      </span>
    </mat-option>
    <mat-option
      *ngFor="let station of searchedStations"
      [disabled]="accessComponent && !station.canSee"
      [value]="station">
      <div class="search-option-wrapper">
        <span class="search-option">
          <span class="option-line">
            <span
              [innerHTML]="station.Name | highlight : searchInputCtrl.value"></span>
            <span
              class="address"
              [innerHTML]="station.Address | highlight : searchInputCtrl.value"></span>
          </span>
        </span>
      </div>
    </mat-option>
  </mat-autocomplete>
</form>
<div>
  <div *ngFor="let station of multipleSelected" class="multi-selected-item">
    <p>{{ station.Name }}</p>
    <button mat-icon-button (click)="removeFromSelected(station.ID)">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</div>
