<router-outlet></router-outlet>
<div class="loading">
  <div class="loading-img">
    <!-- <img src="assets/img/current_logo.svg?v=2" alt="CURRENT" />-->
    <img [src]="loaderImage" alt="CURRENT" />
    <div class="spinner">
      <div
        class="bounce1"
        testing="hello"
        another-one="first"
        yet-another-one-loL="hello"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</div>
