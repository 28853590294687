import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import * as fromInsights from './insights.reducer';

// Feature selector
export const insightsState =
  createFeatureSelector<fromInsights.InsightsState>('insights');

export const selectWidgetHelp = createSelector(insightsState, (state) => {
  return state.help;
});

export const selectLoadingState = createSelector(
  insightsState,
  (state) => state.helpStatus
);
