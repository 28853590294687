<h2 mat-dialog-title *ngIf="title">{{ title }}</h2>
<mat-dialog-content
  [ngClass]="{
    'mb-5 whitespace-pre': preWrapContent,
    '!my-5 whitespace-normal': !preWrapContent
  }"
  *ngIf="content">
  {{ content }}
</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="handleOkClick()">
    <span *ngIf="counter > 0; else okButtonTemplate">
      {{ 'EXECUTE' | translate }} {{ ' (' + counter + ') ' }}
    </span>
    <ng-template #okButtonTemplate>
      {{ okButton }}
    </ng-template>
  </button>
  <button
    mat-raised-button
    *ngIf="secondOption"
    (click)="dialogRef.close('second')">
    {{ secondOption }}
  </button>
  <button mat-raised-button (click)="dialogRef.close()">
    {{ cancelButton }}
  </button>
</div>
