import { on, createReducer } from '@ngrx/store';
import { Company } from '../../companies/companies.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as CountriesActions from './countries.actions';
import { AppState } from '..';
import { Country } from '../../countries';
import { AvailablePaymentMethod } from '../../../../app/lib';

interface LoadingMap {
  [name: string]: boolean;
}

interface PaymentMethodsMap {
  [id: string]: AvailablePaymentMethod[];
}

export interface CountriesState extends EntityState<Country> {
  isFetched: boolean;
  isLoading: boolean;
  selectedCountryID: string;
  // Payment methods per country
  availablePaymentMethods: PaymentMethodsMap;
}

// Entity adapter
export const adapter: EntityAdapter<Country> = createEntityAdapter<Country>({
  selectId: (country) => country.PK_CountryID.toString()
});

export const initialState: CountriesState = adapter.getInitialState({
  isFetched: false, // Defines if the companies have been fetched
  isLoading: false, // Defines if it is currently loading
  selectedCountryID: '',
  availablePaymentMethods: {}
});

export const companiesReducer = createReducer(
  initialState,
  on(CountriesActions.LoadAllCountries, (state) => ({
    ...state,
    isLoading: true
  })),

  on(CountriesActions.AllCountriesLoaded, (state, { countries }) => {
    return {
      ...adapter.addMany(countries, state),
      isLoading: false,
      isFetched: true
    };
  }),

  on(CountriesActions.SelectCountryID, (state, { PK_CountryID }) => ({
    ...state,
    selectedCountryID: PK_CountryID
  })),

  on(
    CountriesActions.LoadedAvailablePaymentMethodsForCountry,
    (state, { PK_CountryID, paymentMethods }) => ({
      ...state,
      availablePaymentMethods: {
        ...state.availablePaymentMethods,
        [PK_CountryID]: paymentMethods
      }
    })
  )
);

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
