import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { Observable, map } from 'rxjs';
import { Whitelabel } from 'src/app/classes/Whitelabel';
import { AppTokens, WhitelabelClass } from './types';

@Injectable({
  providedIn: 'root'
})
export class WhitelabelService {

  constructor(private _http: HttpInterceptorService) { }

  getWhitelabel(tokenId: string): Observable<Whitelabel> {
    return this._http
      .get(`Whitelabels/${tokenId}`)
      .pipe(map((res) => res.datas));
  }

  saveWhitelabel(whitelabel: Whitelabel): Observable<Whitelabel> {
    return this._http.put('Whitelabels', whitelabel);
  }

  getNameByToken(tokenId: string | null): string | undefined {
    const whitelabels = new WhitelabelClass();
    const operator = tokenId
      ? Object.entries(AppTokens).find(
        ([_name, token]) => token === tokenId
      )?.[0]
      : 'smartcharge';

    return whitelabels[operator]?.title;
  }


}
export { WhitelabelClass };

