import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

type Position = [number, number];

@Injectable({
  providedIn: 'root'
})
export class SmoothViewportScroller implements ViewportScroller {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly window: Window
  ) {}

  getScrollPosition(): Position {
    return [this.window.scrollX, this.window.scrollY];
  }

  scrollToAnchor(anchor: string): void {
    const element = this.document.getElementById(anchor);

    if (element) {
      this.scrollToElement(element);
    }
  }

  scrollToElement(element: HTMLElement, extraOffset = 0): void {
    const { top } = element.getBoundingClientRect();
    const [, scrollY] = this.getScrollPosition();
    const [, offsetY] = this.offset();

    const scrollPositionY = top + scrollY - offsetY - extraOffset;

    this.scrollTo([0, scrollPositionY]);
  }

  scrollToPosition(position: Position): void {
    this.scrollTo(position);
  }

  setHistoryScrollRestoration(scrollRestoration: 'auto' | 'manual'): void {
    this.window.history.scrollRestoration = scrollRestoration;
  }

  setOffset(offset: Position | (() => Position)): void {
    if (Array.isArray(offset)) {
      this.offset = () => offset;
    } else {
      this.offset = offset;
    }
  }

  private offset: () => Position = () => [0, 0];

  private scrollTo(
    [left, top]: Position,
    behavior: ScrollBehavior = 'smooth'
  ): void {
    this.window.scroll({
      behavior,
      left,
      top
    });
  }
}
