import { map, throwError } from 'rxjs';

import { APIResponse } from '../../api/api.model';

export function mapData() {
  return map((res: APIResponse) => res.datas);
}

export function mapSuccess() {
  return map((res: APIResponse) => {
    if (!res.success) return new Error('Failed api call');
    return res.success;
  });
}
