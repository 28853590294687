import { Injectable } from '@angular/core';
import * as Rx from 'rxjs';
import { Defaults } from '../../defaults';
import { first } from 'rxjs/operators';
import { TokenService } from '../auth/token/token.service';
import {
  webSocket,
  WebSocketSubject,
  WebSocketSubjectConfig
} from 'rxjs/webSocket';

@Injectable()
export class WebsocketService {
  constructor(private defaults: Defaults, private tokenService: TokenService) {}
  init(
    url,
    websocketConfig: WebSocketConfig = new WebSocketConfig()
  ): CustomWS {
    let openObserver = new Rx.Subject();
    let ws: WebSocketSubject<any>;
    let onOpen = new Promise((resolve) => {
      openObserver.pipe(first()).subscribe((res) => {
        if (websocketConfig.sendToken) {
          this.tokenService.check().subscribe((authenticated) => {
            if (authenticated) {
              ws.next(localStorage.getItem('token'));
              resolve(res);
            }
          });
        } else {
          resolve(res);
        }
      });
    });
    let config: WebSocketSubjectConfig<any> = {
      url: (websocketConfig.root || this.defaults.socket) + url,
      protocol: websocketConfig.protocol,
      openObserver: openObserver,

      deserializer: ({ data }) => {
        try {
          return JSON.parse(data);
        } catch (e) {
          return data;
        }
      }
    };
    ws = webSocket(config);

    return { webSocket: ws, onOpen: onOpen };
  }
}

export interface CustomWS {
  onOpen: Promise<any>;
  webSocket: WebSocketSubject<any>;
}

class WebSocketConfig {
  sendToken?: boolean = true;
  root?: string;
  protocol?: string;
}
