import { on, createReducer } from '@ngrx/store';
import { emptyUser, User } from '../../user/user.model';

import * as UserActions from './user.actions';
import { UserPreferences } from '../../user/user-preferences.model';

export interface UserState {
  isFetched: boolean;
  isLoading: boolean;
  user: User;
  preferences?: UserPreferences;
}

// Entity adapter
export const initialState: UserState = {
  isFetched: false, // Defines if the companies have been fetched
  isLoading: false, // Defines if it is currently loading
  user: emptyUser,
  preferences: null
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.LoadUser, (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(UserActions.UserLoaded, (state, { user, userPreferences }) => {
    return {
      ...state,
      isLoading: false,
      preferences: userPreferences,
      user: user
    };
  }),
  on(UserActions.LogoutUser, (state) => {
    return {
      ...state,
      isLoading: false,
      user: null,
      preferences: null
    };
  }),
  on(UserActions.UserUpdated, (state, { user }) => {
    return {
      ...state,
      isLoading: false,
      user: user
    };
  }),
  on(UserActions.PreferencesUpdated, (state, { userPreferences }) => {
    return {
      ...state,
      preferences: userPreferences
    };
  })
);

export const getIsLoading = (state: UserState) => {
  return state.isLoading;
};
