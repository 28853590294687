import { Injectable } from '@angular/core';
import {
  ClientOperatorPackage,
  OperatorPackage
} from 'src/app/classes/OperatorPackage';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class PackagesService {
  constructor(private _http: HttpInterceptorService) {}

  getPackageStation(stationID) {
    return new Promise((resolve) => {
      this._http
        .get('PackageClients/GetByStation/' + stationID + '/false')
        .subscribe((res) => {
          resolve(res);
        });
    });
  }
  getOperatorPackages(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get('OperatorPackagesController').subscribe(
        (res) => {
          if (res.datas) {
            resolve(res.datas);
          } else {
            reject(res);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getOperatorPackagesByCountry(countryID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .get('OperatorPackagesController/PackagesICanSelect/' + countryID)
        .subscribe(
          (res) => {
            if (res.datas) {
              resolve(res.datas);
            } else {
              reject(res);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  getOperatorPackage(packageID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get('OperatorPackagesController/' + packageID).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  createOperatorPackage(operatorPackage: OperatorPackage): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post('OperatorPackagesController', operatorPackage).subscribe(
        (res) => {
          resolve(res.datas);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  updateOperatorPackage(operatorPackage: OperatorPackage): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.put('OperatorPackagesController', operatorPackage).subscribe(
        (res) => {
          resolve(res.success);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  updateOperatorClientPackage(
    clientPackage: ClientOperatorPackage
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.put('Operators/Package', clientPackage).subscribe(
        (res) => {
          resolve(res.success);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getPackagesICanAdd() {
    //Special API?
    //Some filtering can be done here
  }
}
