import { createAction, props } from '@ngrx/store';

import { BoxManufacturer } from 'src/app/classes';

export const AllManufacturersLoaded = createAction(
  '[Manufacturers] All manufacturers loaded',
  props<{ manufacturers: BoxManufacturer[] }>()
);

export const LoadAllManufacturers = createAction(
  '[Manufacturers] Load all manufacturers'
);

export const SetSearchQuery = createAction(
  '[Manufacturers]: Set search query',
  props<{ query: string }>()
);
