import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import {
  catchError,
  filter,
  of,
  repeat,
  retryWhen,
  switchMap,
  take,
  throwError,
  timeout
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommandRequestsService {
  lastRequest: any;

  constructor(private _http: HttpInterceptorService) {}

  getResponsesByRequestID(
    requestID,
    stringify = true,
    waitForAnswer = false
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .get(`Commands/GetCommandRequest/${requestID}`)
        .pipe(
          switchMap((res) => of(this.handleReqResponse(res, stringify))),
          // If `waitForAnswer == true`, repeat the call until all the boxes have `timeAnswer`
          repeat({ delay: 1000 }),
          filter(
            (res) =>
              !waitForAnswer ||
              res.Boxes.every((box) => box.timeAnswer !== null)
          ),
          take(1),
          timeout({
            each: 20000,
            with: () => throwError(() => new Error('Timeout'))
          }),
          catchError((err) => throwError(() => err))
        )
        .subscribe({
          next: (res) => {
            res = this.handleReqResponse(res, stringify);
            this.lastRequest = res;
            resolve(res);
          },
          error: (err) => {
            reject(err);
          }
        });
    });
  }
  handleReqResponse(res, stringify = true) {
    res.totalAnswers = this.calcAnswers(res.Boxes);
    if (stringify) {
      res.Boxes.map((box) => {
        if (box.answer && typeof box.answer === 'object') {
          box.answerObj = box.answer;
          // WTF this is stringified?
          box.answer = JSON.stringify(box.answer);
        }
        return box;
      });
    }
    return res;
  }
  calcAnswers(boxes) {
    if (!boxes) return;
    if (!boxes.length) return 0;
    let answers = 0;
    boxes.forEach((box) => {
      if (box.answer) answers++;
    });

    return answers;
  }
  checkStatus(requestID): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getResponsesByRequestID(requestID).then((res) => {
        for (let i = 0; i < res.Boxes.length; i++) {
          let box = res.Boxes[i];
          if (box.HasFailed) {
            //Failed
            reject(box.answer);
            break;
          }
          if (!box.answer) {
            resolve(false);
            break;
            //Pending
          }
          if (i === res.Boxes.length - 1) {
            resolve(true);
            //Success
          }
        }
      });
    });
  }
}
