import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { Currency } from 'src/app/lib';

@Pipe({
  name: 'scCurrency'
})
export class CustomCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(
    value: any,
    currencyCode: string,
    symbolDisplay: string,
    digits?: string
  ): any {
    const formattedCurrencyCode =
      typeof currencyCode === 'string' ? currencyCode : Currency[currencyCode];

    const currencyFormat = super.transform(
      value,
      formattedCurrencyCode,
      symbolDisplay,
      digits
    );

    if (!currencyFormat) return;

    const firstDigit = currencyFormat.search(/\d/);

    return (
      currencyFormat.substring(0, firstDigit) +
      ' ' +
      currencyFormat.substr(firstDigit)
    );
  }
}
