import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { InsightsState } from './insights.reducer';

import { selectWidgetHelp, selectLoadingState } from './insights.selectors';

import * as InsightsActions from './insights.actions';

@Injectable({
  providedIn: 'root'
})
export class InsightsFacade {
  constructor(private store: Store<InsightsState>) {}
  insightsWidgetHelp$ = this.store.select(selectWidgetHelp);
  widgetHelpLoadingStatus$ = this.store.select(selectLoadingState);

  loadInsightsWidgetHelp() {
    this.store.dispatch(InsightsActions.LoadInsightsWidgetHelp());
  }
}
