import { Injectable } from '@angular/core';
import { HttpInterceptorService } from 'src/app/services/http-interceptor/http-interceptor.service';
import { map } from 'rxjs';
import { RoamingPlatform, RoamingPlatformResponse } from './types';
import { RoamingAuthorization } from '../../roaming/roaming-authorization.model';

@Injectable({
  providedIn: 'root'
})
export class RoamingPlatformService {
  constructor(private _http: HttpInterceptorService) { }

  getRoamingPlatforms(offset = 0, numberOfRow = 10) {
    return this._http
      .get(`RoamingPlatform/from/${offset}/${numberOfRow}`)
      .pipe(map((res) => (res || []) as RoamingPlatformResponse));
  }

  getSingleRoamingPlatform(platformID: number) {
    return this._http.get(`RoamingPlatform/id/${platformID}`);
  }

  getRoamingPlatformsByOperator(OperatorID: number) {
    return this._http
      .get(`RoamingPlatform/linked-to-operator/${OperatorID}`)
      .pipe(map((res) => (res || []) as RoamingAuthorization[]));
  }

  getRoamingPlatformsByLocation(stationID: number) {
    return this._http
      .get(`RoamingPlatform/available-to-location/${stationID}`)
      .pipe(map((res) => (res || []) as RoamingAuthorization[]));
  }

  updateRoamingPlaformAuthorization(roamingPlaformAuthorization: RoamingAuthorization) {
    return this._http.put(`RoamingPlatform/update-authorization`, roamingPlaformAuthorization);
  }

  addRoamingPlaformAuthorization(roamingPlaformAuthorization: RoamingAuthorization) {
    return this._http.post(`RoamingPlatform/post-authorization`, roamingPlaformAuthorization)
  }

  deleteRoamingPlaformAuthorization(roamingPlaformAuthorization: RoamingAuthorization) {
    return this._http.delete(`RoamingPlatform/delete-authorization`, { body: roamingPlaformAuthorization });
  }
}
