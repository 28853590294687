export const Ocpp201Components = [
  "AlignedDataCtrlr",
  "AuthCtrlr",
  "AuthCacheCtrlr",
  "CHAdeMOCtrlr",
  "ClockCtrlr",
  "DeviceDataCtrlr",
  "DisplayMessageCtrlr",
  "LocalAuthListCtrlr",
  "MonitoringCtrlr",
  "OCPPCommCtrlr",
  "ReservationCtrlr",
  "SampledDataCtrlr",
  "SecurityCtrlr",
  "SmartChargingCtrlr",
  "TariffCostCtrlr",
  "TxCtrlr",
  "AccessBarrier",
  "AcDcConverter",
  "AcPhaseSelector",
  "Actuator",
  "AirCoolingSystem",
  "AreaVentilation",
  "BayOccupancySensor",
  "BeaconLighting",
  "CableBreakawaySensor",
  "CaseAccessSensor",
  "ChargingStation",
  "ChargingStatusIndicator",
  "ConnectorHolsterRelease",
  "ConnectorHolsterSensor",
  "ConnectorPlugRetentionLock",
  "ConnectorProtectionRelease",
  "Controller",
  "ControlMetering",
  "CPPWMController",
  "DataLink",
  "Display",
  "DistributionPanel",
  "ElectricalFeed",
  "ELVSupply",
  "EmergencyStopSensor",
  "EnvironmentalLighting",
  "EVRetentionLock",
  "EVSE",
  "ExternalTemperatureSensor",
  "FiscalMetering",
  "FloodSensor",
  "GroundIsolationProtection",
  "Heater",
  "HumiditySensor",
  "LightSensor",
  "LiquidCoolingSystem",
  "LocalAvailabilitySensor",
  "LocalController",
  "LocalEnergyStorage",
  "OverCurrentProtection",
  "OverCurrentProtectionRecloser",
  "Connector",
  "PowerContactor",
  "RCD",
  "RCDRecloser",
  "RealTimeClock",
  "ShockSensor",
  "SpacesCountSignage",
  "Switch",
  "TemperatureSensor",
  "TiltSensor",
  "TokenReader",
  "UpstreamProtectionTrigger",
  "UIInput",
  "VehicleIdSensor",
  "ISO15118Ctrlr"
]

export const Ocpp201Variables = [
  "ACCurrent",
  "Active",
  "ACVoltage",
  "AllowReset",
  "Angle",
  "Attempts",
  "AvailabilityState",
  "Available",
  "Certificate",
  "ChargeProtocol",
  "ChargingCompleteBulk",
  "ChargingCompleteFull",
  "ChargingTime",
  "Color",
  "Complete",
  "ConnectedTime",
  "ConnectorType",
  "Count",
  "Currency",
  "CurrentImbalance",
  "DataText",
  "DateTime",
  "DCCurrent",
  "DCVoltage",
  "DepartureTime",
  "ECVariant",
  "Enabled",
  "Energy",
  "EnergyExport",
  "EnergyExportRegister",
  "EnergyImport",
  "EnergyImportRegister",
  "Entries",
  "EvseId",
  "Fallback",
  "FanSpeed",
  "FirmwareVersion",
  "Force",
  "Formats",
  "Frequency",
  "FuseRating",
  "Height",
  "Humidity",
  "ICCID",
  "Impedance",
  "IMSI",
  "Interval",
  "Length",
  "Light",
  "Manufacturer",
  "Message",
  "MinimumStatusDuration",
  "Mode",
  "Model",
  "NetworkAddress",
  "Operated",
  "OperatingTimes",
  "Overload",
  "Percent",
  "PhaseRotation",
  "PostChargingTime",
  "Power",
  "Problem",
  "Protecting",
  "RemainingTimeBulk",
  "RemainingTimeFull",
  "SerialNumber",
  "SignalStrength",
  "State",
  "StateOfCharge",
  "StateOfChargeBulk",
  "Storage",
  "SupplyPhases",
  "Suspending",
  "Suspension",
  "Temperature",
  "Time",
  "Timeout",
  "Token",
  "TokenType",
  "Tries",
  "Tripped",
  "VehicleId",
  "VersionDate",
  "VersionNumber",
  "VoltageImbalance",
  "AllowNewSessionsPendingFirmwareUpdate",
  "RetryBackOffWaitMinimum",
  "LifeTime",
  "Measurands",
  "AlignedDataInterval",
  "SendDuringIdle",
  "SignReadings",
  "TransactionEventRequest",
  "TxEndedInterval",
  "TxEndedMeasurands",
  "TxStartPoint",
  "TxStopPoint",
  "EVConnectionTimeOut",
  "StopTxOnEVSideDisconnect",
  "TxBeforeAcceptedEnabled",
  "MaxEnergyOnInvalidId",
  "StopTxOnInvalidId",
  "HeartbeatInterval",
  "ActiveNetworkProfile",
  "MessageTimeout",
  "FileTransferProtocols",
  "NetworkConfigurationPriority",
  "NetworkProfileConnectionAttempts",
  "OfflineThreshold",
  "QueueAllMessages",
  "MessageAttempts",
  "MessageAttemptInterval",
  "UnlockOnEVSideDisconnect",
  "WebSocketPingInterval",
  "ResetRetries",
  "PublicKeyWithSignedMeterValue",
  "AuthEnabled",
  "OfflineTxForUnknownIdEnabled",
  "AdditionalInfoItemsPerMessage",
  "AuthorizeRemoteStart",
  "LocalAuthorizeOffline",
  "LocalPreAuthorize",
  "MasterPassGroupId",
  "ItemsPerMessage",
  "BytesPerMessage",
  "ConfigurationValueSize",
  "ReportingValueSize",
  "NtpSource",
  "NtpServerUri",
  "TimeOffset",
  "NextTimeOffsetTransitionDateTime",
  "TimeSource",
  "TimeZone",
  "BasicAuthPassword",
  "Identity",
  "OrganizationName",
  "CertificateEntries",
  "SecurityProfile",
  "AdditionalRootCertificateCheck",
  "MaxCertificateChainSize",
  "TxUpdatedMeasurands",
  "TxUpdatedInterval",
  "TxStartedMeasurands",
  "NonEvseSpecific",
  "ACPhaseSwitchingSupported",
  "ProfileStackLevel",
  "RateUnit",
  "PeriodsPerSchedule",
  "ExternalControlSignalsEnabled",
  "NotifyChargingLimitWithSchedules",
  "Phases3to1",
  "LimitChangeSignificance",
  "TariffFallbackMessage",
  "TotalCostFallbackMessage",
  "OfflineQueuingSeverity",
  "DisplayMessages",
  "SupportedFormats",
  "SupportedPriorities",
  "CustomImplementationEnabled",
  "CentralContractValidationAllowed",
  "ContractValidationOffline"
]

export const ReportBaseTypes = [
  "ConfigurationInventory",
  "FullInventory",
  "SummaryInventory"
];

export const ComponentCriteriaTypes = [
  "Active",
  "Available",
  "Enabled",
  "Problem"
];

export const OCPPInterfaceTypes = [
  "Wired0",
  "Wired1",
  "Wired2",
  "Wired3",
  "Wireless0",
  "Wireless1",
  "Wireless2",
  "Wireless3"
];

export const MonitoringBaseTypes = [
  "All",
  "FactoryDefault",
  "HardWiredOnly"
]

export const TriggerMessageTypes = [
  "StatusNotification",
  "BootNotification",
  "DiagnosticsStatusNotification",
  "FirmwareStatusNotification",
  "Heartbeat",
  "MeterValues",
  "LogStatusNotification",
  "SignChargingStationCertificate",
  "SignV2GCertificate",
  "TransactionEvent",
  "SignCombinedCertificate",
  "PublishFirmwareStatusNotification"
];

export const LogTypes = [
  "DiagnosticsLog",
  "SecurityLog"
];

export const IdTokenTypes = [
  "Central",
  "eMAID",
  "ISO14443",
  "KeyCode",
  "Local",
  "NoAuthorization",
  "ISO15693",
  "MacAddress"
]

export const HashAlgorithmTypes = [
  "SHA256",
  "SHA384",
  "SHA512"
];

export const InstallCertificateTypes = [
  "V2GRootCertificate",
  "MORootCertificate",
  "CSMSRootCertificate",
  "ManufacturerRootCertificate"
]
export const GetCertificateTypes = [
  "V2GRootCertificate",
  "MORootCertificate",
  "CSMSRootCertificate",
  "V2GCertificateChain",
  "ManufacturerRootCertificate"
]

export const ChargingProfilePurposeTypes = [
  "ChargingStationExternalConstraints",
  "ChargingStationMaxProfile",
  "TxDefaultProfile",
  "TxProfile"

]

export type Ocpp201ComponentsType = typeof Ocpp201Components[number];
export type Ocpp201VariblesType = typeof Ocpp201Variables[number];
export type ReportBaseType = typeof ReportBaseTypes[number];
export type ComponentCriteriaType = typeof ComponentCriteriaTypes[number];
export type OCPPInterfaceType = typeof OCPPInterfaceTypes[number];
export type TriggerMessageType = typeof TriggerMessageTypes[number];
export type LogType = typeof LogTypes[number];
export type IdTokenType = typeof IdTokenTypes[number];
export type HashAlgorithmType = typeof HashAlgorithmTypes[number];
export type InstallCertificateType = typeof InstallCertificateTypes[number];
export type GetCertificateType = typeof GetCertificateTypes[number];
export type ChargingProfilePurposeType = typeof ChargingProfilePurposeTypes[number];

export interface ConfigVariable {
  Controller?: Ocpp201ComponentsType | string,
  Key?: Ocpp201VariblesType | string,
  Value?: string
  AttributeType?: string
  ComponentName?: string
  Instance?: string
  EVSEID?: number
}



