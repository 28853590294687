import { StorageDriver } from './types';

export class LocalStorageDriver implements StorageDriver {
  clear(): Promise<void> {
    return Promise.resolve(localStorage.clear());
  }

  get<T = string>(key: string): Promise<T> {
    return Promise.resolve(localStorage.getItem(key) as T);
  }

  remove(key: string): Promise<void> {
    return Promise.resolve(localStorage.removeItem(key));
  }

  set(key: string, value: unknown): Promise<void> {
    return Promise.resolve(localStorage.setItem(key, value as string));
  }
}
