import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CustomerState } from './customer.reducer';
import { UserService } from '../../user';
import * as CustomerActions from './customer.actions';

@Injectable({ providedIn: 'root' })
export class CustomerEffects {
  loadCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.LoadCustomer),
      mergeMap((action) =>
        this.userService.getCustomerByID(action.customerID).pipe(
          map((data) => {
            return CustomerActions.CustomerLoaded({
              customerID: action.customerID,
              user: data
            });
          }),
          catchError((apiError) => {
            return EMPTY;
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<CustomerState>,
    private userService: UserService
  ) {}
}
