<h2 mat-dialog-title>{{ 'Customer information parameters' }}</h2>
<form [formGroup]="customerInformationForm" (ngSubmit)="handleFormSubmit()">
  <div mat-dialog-content class="dialog-content">
    <div class="input-row mb-2 ml-4">
      <mat-checkbox formControlName="clear">Clear</mat-checkbox>
      <mat-checkbox formControlName="report" class="ml-2">Report</mat-checkbox>
    </div>

    <div class="input-row ml-4">
      <mat-form-field floatLabel="auto" class="w-full">
        <mat-label>{{ 'Customer identification' | translate }}</mat-label>
        <input
          matInput
          formControlName="customerIdentifier" />
        <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </div>

    <mat-form-field floatLabel="auto" class="ml-4">
      <mat-label>{{ 'ID token' | translate }}</mat-label>
      <input matInput formControlName="idToken" />
      <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field floatLabel="auto" class="ml-4">
      <mat-label>{{ 'ID token type' | translate }}</mat-label>
      <mat-select formControlName="type">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let type of idTokenTypes" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
      <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
    </mat-form-field>

    <div formArrayName="additionalInfo">
      <div
        class="flex"
        *ngFor="let item of additionalInfo.controls; let i = index"
        [formGroupName]="i">
        <div class="input-row">
          <mat-form-field floatLabel="auto" class="ml-4 mt-2">
            <mat-label>{{ 'Additional ID token' | translate }}</mat-label>
            <input
              matInput
              formControlName="additionalIdToken" />
            <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field floatLabel="auto" class="ml-4 mt-2">
            <mat-label>{{ 'Type' | translate }}</mat-label>
            <input matInput formControlName="type" />
            <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
          </mat-form-field>

          <button
            mat-icon-button
            class="ml-4"
            (click)="deleteAdditionalInfo(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <button mat-raised-button (click)="addAdditionalInfo()" class="!mb-4">
        <mat-icon>add</mat-icon>
        Add additional info
      </button>
    </div>

    <div class="input-row ml-4">
      <mat-form-field floatLabel="auto">
        <mat-label>{{ 'Hash algorithm' | translate }}</mat-label>
        <mat-select formControlName="hashAlgorithm">
          <mat-option [value]="null"></mat-option>
          <mat-option *ngFor="let type of hasAlgorithmTypes" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
        <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="auto">
        <mat-label>{{ 'Issuer name' | translate }}</mat-label>
        <input
          matInput
          formControlName="issuerNameHash"/>
        <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </div>

    <div class="input-row ml-4">
      <mat-form-field floatLabel="auto">
        <mat-label>{{ 'Issuer key' | translate }}</mat-label>
        <input
          matInput
          formControlName="issuerKeyHash"/>
        <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="auto">
        <mat-label>{{ 'Serial number' | translate }}</mat-label>
        <input
          matInput
          formControlName="serialNumber"/>
        <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit">
      {{ 'OK' | translate }}
    </button>
    <button mat-raised-button (click)="this.dialogRef.close()" type="button">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</form>
