import { Injectable } from "@angular/core";
import { HttpInterceptorService } from "src/app/services";
import {
  AddBatchTaskRequest,
  Box,
  BoxConfiguration,
  CertificateSignedRequest,
  ChangeAvailabilityRequest,
  ChangeConfigurationRequest,
  ChangePasswordRequest,
  ClearChargingProfilesRequest,
  ClearDisplayMessageRequest,
  ClearVariableMonitoringRequest,
  CustomerInformationRequest,
  DataTransferRequest,
  DeleteCertificateRequest,
  GenericBoxModelDefaultConfig,
  GenericBoxUpdate,
  GenericCommandRequest,
  GenericCommandResponse,
  GetAllDisplayedMessagesRequest,
  GetBaseReportRequest,
  GetChargingProfilesRequest,
  GetCompositeScheduleRequest,
  GetConfigVariablesRequest,
  GetDiagnosticsRequest,
  GetExpectedConfigurationOfBoxesRequest,
  GetInstalledCertificatesRequest,
  GetLogRequest,
  GetMonitoringReportRequest,
  GetReportRequest,
  GetTransactionStatusRequest,
  InstallCertificateRequest,
  ParametersStartSessionRequest,
  PostLocalList,
  QueryResult,
  RemoteStartTransactionRequest,
  RemoteStartWithProfileRequest,
  ReserveNowRequest,
  ResetType,
  SendChargingProfile,
  SendLocalListRequest,
  SetConfigRequest,
  SetDisplayMessageRequest,
  SetMonitoringBaseRequest,
  SetMonitoringLevelRequest,
  SetNetworkProfileRequest,
  SetVariableMonitoringRequest,
  TriggerMessageRequest,
  UpdateFirmwareRequest
} from "./commands-type";
import { BehaviorSubject, map, of } from "rxjs";

@Injectable()
export class CommandsService {

  lastCommandRequest: BehaviorSubject<LastCommandRequest> = new BehaviorSubject(null);
  constructor(private _http: HttpInterceptorService) { }


  /*** POST requests ***/
  getExpectedConfigurationOfBoxes(includeSetupConfig: boolean, payload: GetExpectedConfigurationOfBoxesRequest) {
    return this._http.post(`Commands/GetExpectedConfigurationOfBoxes/${includeSetupConfig}`, payload);
  }

  addConfigs(payload: GenericBoxModelDefaultConfig[]) {
    return this._http.post(`Commands/AddConfigs`, payload);
  }

  deleteConfigs(payload: GenericBoxModelDefaultConfig[]) {
    return this._http.post(`Commands/DeleteConfigs`, payload);
  }

  addBatchTask(payload: AddBatchTaskRequest[]) {
    return this._http.post(`Commands/AddBatchTask`, payload);
  }

  remoteStart(payload: ParametersStartSessionRequest) {
    return this._http.post(`Commands/RemoteStart`, payload);
  }

  start(payload: ParametersStartSessionRequest) {
    return this._http.post(`Commands/Start`, payload);
  }

  _sendLocalList(deviceID: string, boxID: number, payload: PostLocalList) {
    return this._http.post(`Commands/SendLocalList/${deviceID}/${boxID}`, payload);
  }

  sendLocalList(payload: SendLocalListRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/SendLocalList`, payload });
    return this._http.post(`Commands/SendLocalList`, payload);
  }

  setDisplayMessage(payload: SetDisplayMessageRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/SetDisplayMessage`, payload });
    return this._http.post(`Commands/SetDisplayMessage`, payload);
  }

  changeAvailability(payload: ChangeAvailabilityRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/ChangeAvailability`, payload });
    return this._http.post(`Commands/ChangeAvailability`, payload);
  }

  getConfigVariables(payload: GetConfigVariablesRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/GetVariables`, payload });
    return this._http.post(`Commands/GetVariables`, payload);
  }

  changeConfiguration(payload: ChangeConfigurationRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/ChangeConfiguration`, payload });
    return this._http.post(`Commands/ChangeConfiguration`, payload);
  }

  setMonitoringBase(payload: SetMonitoringBaseRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/SetMonitoringBase`, payload });
    return this._http.post(`Commands/SetMonitoringBase`, payload);
  }

  setMonitoringLevel(payload: SetMonitoringLevelRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/SetMonitoringLevel`, payload });
    return this._http.post(`Commands/SetMonitoringLevel`, payload);
  }

  clearVariableMonitoring(payload: ClearVariableMonitoringRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/ClearVariableMonitoring`, payload });
    return this._http.post(`Commands/ClearVariableMonitoring`, payload);
  }

  setVariableMonitoring(boxID: number, payload: SetVariableMonitoringRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/SetVariableMonitoring/${boxID}`, payload });
    return this._http.post(`Commands/SetVariableMonitoring/${boxID}`, payload);
  }

  customerInformation(payload: CustomerInformationRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/CustomerInformation`, payload });
    return this._http.post(`Commands/CustomerInformation`, payload);
  }

  getMonitoringReport(payload: GetMonitoringReportRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/GetMonitoringReport`, payload });
    return this._http.post(`Commands/GetMonitoringReport`, payload);
  }

  getReport(payload: GetReportRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/GetReport`, payload });
    return this._http.post(`Commands/GetReport`, payload);
  }

  setNetworkProfile(payload: SetNetworkProfileRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/SetNetworkProfile`, payload });
    return this._http.post(`Commands/SetNetworkProfile`, payload);
  }

  getBaseReport(payload: GetBaseReportRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/GetBaseReport`, payload });
    return this._http.post(`Commands/GetBaseReport`, payload);
  }

  clearChargingProfiles(payload: ClearChargingProfilesRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/ClearChargingProfiles`, payload });
    return this._http.post(`Commands/ClearChargingProfiles`, payload);
  }

  getChargingProfiles(payload: GetChargingProfilesRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/GetChargingProfiles`, payload });
    return this._http.post(`Commands/GetChargingProfiles`, payload);
  }

  sendChargingProfile(payload: SendChargingProfile) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/SendChargingProfile`, payload });
    return this._http.post(`Commands/SendChargingProfile`, payload);
  }

  remoteStartWithProfile(payload: RemoteStartWithProfileRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/RemoteStartTestToolTransactionWithChargingProfile`, payload });
    return this._http.post(`Commands/RemoteStartTestToolTransactionWithChargingProfile`, payload);
  }

  reserveNow(payload: ReserveNowRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/ReserveNow`, payload });
    return this._http.post(`Commands/ReserveNow`, payload);
  }

  cancelReservation(boxID: number, reservationID: number) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/CancelReservation/${boxID}/${reservationID}` });
    return this._http.get(`Commands/CancelReservation/${boxID}/${reservationID}`);
  }

  certificateSigned(boxID: number, payload: CertificateSignedRequest) {
    return this._http.post(`Commands/CertificateSigned/${boxID}`, payload);
  }


  costUpdated(boxID: number, transactionId: number, totalCost: number) {
    return this._http.post(`Commands/CostUpdated/${boxID}/${transactionId}/${totalCost}`, {});
  }

  getLog(payload: GetLogRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/GetLog`, payload });
    return this._http.post(`Commands/GetLog`, payload);
  }

  setConfig(payload: SetConfigRequest) {
    return this._http.post(`Commands/SetConfig`, payload);
  }

  getDiagnostics(payload: GetDiagnosticsRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/GetDiagnostics`, payload });
    return this._http.post(`Commands/GetDiagnostics`, payload);
  }

  getTransactionStatus(payload: GetTransactionStatusRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/GetTransactionStatus`, payload });
    return this._http.post(`Commands/GetTransactionStatus`, payload);
  }

  triggerMessage(payload: TriggerMessageRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/TriggerMessage`, payload });
    return this._http.post(`Commands/TriggerMessage`, payload);
  }

  fixRemoteStartTransaction(payload: RemoteStartTransactionRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/RemoteStartTestTool`, payload });
    return this._http.post(`Commands/RemoteStartTestTool`, payload);
  }

  getLocalListVersion(payload: GenericCommandRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/GetLocalListVersion`, payload });
    return this._http.post(`Commands/GetLocalListVersion`, payload)
  }

  addFirmware(payload: FormData) {
    return this._http.post(`ChargingBoxModels/UploadFirmware`, payload);
  }

  getCompositeSchedule(payload: GetCompositeScheduleRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/GetCompositeSchedule`, payload });
    return this._http.post(`Commands/GetCompositeSchedule`, payload);
  }

  getAllDisplayedMessages(payload: GetAllDisplayedMessagesRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/GetAllDisplayedMessages`, payload });
    return this._http.post(`Commands/GetAllDisplayedMessages`, payload);
  }

  clearDisplayMessage(payload: ClearDisplayMessageRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/ClearDisplayMessage`, payload });
    return this._http.post(`Commands/ClearDisplayMessage`, payload)
  }

  changeOcppPassword(payload: ChangePasswordRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/ChangePassword`, payload });
    return this._http.post(`Commands/ChangePassword`, payload);
  }

  installCertificate(payload: InstallCertificateRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/InstallCertificate`, payload });
    return this._http.post(`Commands/InstallCertificate`, payload);
  }

  deleteCertificate(payload: DeleteCertificateRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/DeleteCertificate`, payload });
    return this._http.post(`Commands/DeleteCertificate`, payload);
  }

  getInstalledCertificateIds(payload: GetInstalledCertificatesRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/GetInstalledCertificateIds`, payload });
    return this._http.post(`Commands/GetInstalledCertificateIds`, payload);
  }

  dataTransferRequest(payload: DataTransferRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/DataTransfer`, payload });
    return this._http.post(`Commands/DataTransfer`, payload);
  }

  displayQROnCharger(pointID: number, clear = false) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/display-qrcode-point/${pointID}/${clear}`, payload: {} });
    return this._http.post(`Commands/display-qrcode-point/${pointID}/${clear}`, {})
  }

  displayOperatorLogoOnCharger(pointID: number, clear = false) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/display-logo-point/${pointID}/${clear}`, payload: {} });
    return this._http.post(`Commands/display-logo-point/${pointID}/${clear}`, {})
  }

  displayPriceInfoOnCharger(pointID: number, clear = false) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/display-price-point/${pointID}/${clear}`, payload: {} });
    return this._http.post(`Commands/display-price-point/${pointID}/${clear}`, {})
  }


  /*** GET requests ***/
  getCommandRequestBox(commandRequestBoxID: number) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/GetCommandRequestBox/${commandRequestBoxID}` });
    return this._http.get(`Commands/GetCommandRequestBox/${commandRequestBoxID}`).pipe((map(res => res as Box)));
  }

  getCommandRequest(commandRequestID: number) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/GetCommandRequest/${commandRequestID}` });
    return this._http.get(`Commands/GetCommandRequest/${commandRequestID}`).pipe(map(res => res as GenericCommandResponse));
  }

  getCommandRequests(startIndex: number, number: number) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/GetCommandRequests/${startIndex}/${number}` });
    return this._http.get(`Commands/GetCommandRequests/${startIndex}/${number}`).pipe(map(res => res as GenericCommandResponse));
  }

  getCommandRequestsByStation(stationID: number, startIndex: number, number: number) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/GetCommandRequestsByStation/${stationID}/${startIndex}/${number}` });
    return this._http.get(`Commands/GetCommandRequestsByStation/${stationID}/${startIndex}/${number}`).pipe(map(res => res as GenericCommandResponse));
  }

  getCommandRequestsByBox(chargingBoxID: number, startIndex: number, number: number) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/GetCommandRequestsByBox/${chargingBoxID}/${startIndex}/${number}` });
    return this._http.get(`Commands/GetCommandRequestsByBox/${chargingBoxID}/${startIndex}/${number}`).pipe(map(res => res as GenericCommandResponse));
  }

  getAllBatchTasksToExecute() {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/GetAllBatchTasksToExecute` });
    return this._http.get(`Commands/GetAllBatchTasksToExecute`).pipe(map(res => res as number[]));
  }

  executeBatchTask(commandRequestID: number) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/ExecuteBatchTask/${commandRequestID}` });
    return this._http.get(`Commands/ExecuteBatchTask/${commandRequestID}`).pipe(map(res => res as QueryResult));
  }

  // remoteStartCharger(boxID: number, userKey: number, internalConnector: number, startedWithQR = false) {
  //   return this._http.get(`Commands/RemoteStart/${boxID}/${userKey}/${internalConnector}/${startedWithQR}`).pipe(map(res => res as QueryResult));
  // }

  stop(boxID: number, serviceSessionID: number) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/Stop/${boxID}/${serviceSessionID}` });
    return this._http.get(`Commands/Stop/${boxID}/${serviceSessionID}`).pipe(map(res => res as GenericCommandResponse));
  }

  remoteStop(boxID: number, serviceSessionID: number) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/RemoteStop/${boxID}/${serviceSessionID}` });
    return this._http.get(`Commands/RemoteStop/${boxID}/${serviceSessionID}`).pipe(map(res => res as GenericCommandResponse));
  }

  unlockConnector(boxID: number, evseID: number, connectorID: number = 1) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/UnlockConnector/${boxID}/${evseID}/${connectorID}` });
    return this._http.get(`Commands/UnlockConnector/${boxID}/${evseID}/${connectorID}`).pipe(map(res => res as QueryResult));
  }

  reset(boxID: number, type: ResetType, evseID: number, immediateReset: boolean = false) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/Reset/${boxID}/${type}/${immediateReset}/${evseID}` });
    return this._http.get(`Commands/Reset/${boxID}/${type}/${immediateReset}/${evseID}`).pipe(map(res => res as QueryResult));
  }

  checkIfThePointIsCharging(point: number) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/CheckIfThePointIsCharging/${point}` });
    return this._http.get(`Commands/CheckIfThePointIsCharging/${point}`).pipe(map(res => res as QueryResult));
  }


  setDefaultConfig(boxID: number) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/SetDefaultConfig/${boxID}` });
    return this._http.get(`Commands/SetDefaultConfig/${boxID}`).pipe(map(res => res as QueryResult));
  }

  setDefaultAuthentication(boxID: number, isAuthenticationEnabled: boolean) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/SetDefaultAuthentication/${boxID}/${isAuthenticationEnabled}` });
    return this._http.get(`Commands/SetDefaultAuthentication/${boxID}/${isAuthenticationEnabled}`).pipe(map(res => res as QueryResult));
  }

  clearCache(boxID: number) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/ClearCache/${boxID}` });
    return this._http.get(`Commands/ClearCache/${boxID}`).pipe(map(res => res as QueryResult));
  }

  getConfiguration(boxID: number) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/GetConfiguration/${boxID}` });
    return this._http.get(`Commands/GetConfiguration/${boxID}`).pipe(map(res => res as BoxConfiguration[]));
  }

  getExpectedConfiguration(boxID: number, includeSetupConfig: boolean = false) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/GetExpectedConfiguration/${boxID}/${includeSetupConfig}` });
    return this._http.get(`Commands/GetExpectedConfiguration/${boxID}/${includeSetupConfig}`).pipe(map(res => res as GenericBoxModelDefaultConfig[]));
  }

  getExpectedConfigurationForBoxModel(box_model_ID: number, includeSetupConfig: boolean = false) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/GetExpectedConfigurationForBoxModel/${box_model_ID}/${includeSetupConfig}` });
    return this._http.get(`Commands/GetExpectedConfigurationForBoxModel/${box_model_ID}/${includeSetupConfig}`).pipe(map(res => res as GenericBoxModelDefaultConfig[]));
  }

  updateToLatestFirmware(boxID: number, updateID?: number) {
    if (updateID) {
      this.lastCommandRequest.next({ method: 'GET', url: `Commands/UpdateToLatestFirmware/${boxID}/${updateID}` });
      return this._http.get(`Commands/UpdateToLatestFirmware/${boxID}/${updateID}`).pipe(map(res => res as QueryResult));
    }
    else {
      this.lastCommandRequest.next({ method: 'GET', url: `Commands/UpdateToLatestFirmware/${boxID}` });
      return this._http.get(`Commands/UpdateToLatestFirmware/${boxID}`).pipe(map(res => res as QueryResult));
    }
  }

  updateToFirmware(boxID: number, payload: UpdateFirmwareRequest) {
    this.lastCommandRequest.next({ method: 'POST', url: `Commands/UpdateToFirmware/${boxID}`, payload });
    return this._http.post(`Commands/UpdateToFirmware/${boxID}`, payload);
  }

  getDiagnosticsFiles(commandRequestID: number) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/GetDiagnosticsFiles/${commandRequestID}` });
    return this._http.get(`Commands/GetDiagnosticsFiles/${commandRequestID}`).pipe(map(res => res as string[]));
  }

  setDefaultPermanentCableLocking(pointID: number, isCableLocked: boolean) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/SetDefaultPermanentCableLocking/${pointID}/${isCableLocked}` });
    return this._http.get(`Commands/SetDefaultPermanentCableLocking/${pointID}/${isCableLocked}`).pipe(map(res => res as QueryResult));
  }

  fixRemoteStopTransaction(boxID: number, transactionID: number) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/RemoteStopTestTool/${boxID}/${transactionID}` });
    return this._http.get(`Commands/RemoteStopTestTool/${boxID}/${transactionID}`).pipe(map(res => res as GenericCommandResponse));
  }

  fixRemoteStopSession(boxID: number, sessionID: number) {
    this.lastCommandRequest.next({ method: 'GET', url: `Commands/RemoteStop/${boxID}/${sessionID}` });
    return this._http.get(`Commands/RemoteStop/${boxID}/${sessionID}`).pipe(map(res => res as GenericCommandResponse));
  }

  getAvailableFirwareUpdates(boxModelID: number) {
    return this._http.get(`BoxUpdates/GetUpdatesForTheModel/${boxModelID}`).pipe(map(res => res?.datas || [] as GenericBoxUpdate[]));
  }



  /*** PUT requests ***/
  updateConfigs(payload: GenericBoxModelDefaultConfig[]) {
    return this._http.put(`Commands/UpdateConfigs`, payload).pipe(map(res => res as QueryResult));
  }

  runLastCommand() {
    const value = this.lastCommandRequest.getValue();
    switch (value.method) {
      case 'GET':
        return this._http.get(value.url).pipe(map(res => res as QueryResult));
      case 'POST':
        return this._http.post(value.url, value.payload);
      default:
        return of(null);
    }
  }
}

export interface LastCommandRequest {
  method: string
  url: string
  payload?: any;
}
