import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/lib';
import { CompanyService } from '../company/company.service';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class TermsService {
  constructor(
    private userService: UserService,
    private _http: HttpInterceptorService,
    private dialog: MatDialog,
    private companyService: CompanyService
  ) {}

  checkTerms(stationOwner: boolean = false): Promise<any> {
    return new Promise((resolve) => {
      this.userService.getUser().subscribe((user) => {
        this._http
          .get(
            'Users/CheckIfAcceptedTerms/' + user.FK_UserID + '/' + stationOwner
          )
          .subscribe(
            (res) => {
              resolve(false);
            },
            (err) => {
              //Should check for language here and only send back a URL
              if (err.errorName === 'needsToAcceptTermsAndCondition') {
                //FIX: Should not fallback to norwegian. This is temporary for the api
                const urlByLang =
                  'https://f.hubspotusercontent40.net/hubfs/2283248/Content/SmartCharge_Generelle_betingelser_v2021.06.pdf'; //err.datas.en || err.datas.nb;
                resolve(urlByLang);
              } else {
                resolve(false);
              }

              resolve(err.datas);
            }
          );
      });
    });
  }
  checkTermsForCompany(): Promise<any> {
    return new Promise((resolve) => {
      this.companyService.get().then(
        (company) => {
          this.checkTerms().then((newTerms) => {
            resolve(newTerms);
          });
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }
  acceptTerms(userID?, stationOwner: boolean = false): Promise<void> {
    return new Promise((resolve) => {
      if (userID) {
        this.doAccept(userID, stationOwner).then((res) => {
          resolve();
        });
      } else {
        this.userService.getUser().subscribe((user) => {
          this.doAccept(user.FK_UserID, stationOwner).then((res) => {
            resolve();
          });
        });
      }
    });
  }
  private doAccept(userID, stationOwner): Promise<any> {
    return new Promise((resolve) => {
      this._http
        .put(
          'Users/AcceptTermsAndCondition/' + userID + '/' + stationOwner,
          null
        )
        .subscribe(
          (res) => {
            resolve(false);
          },
          (err) => {
            resolve(false);
          }
        );
    });
  }
}
