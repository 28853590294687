export enum PaymentMethod {
  CARD = 'Braintree',
  INVOICE = 'Invoice',
  SMS = 'SMS',
  PAY_PAL = 'PayPal',
  CompanyPaysForEndUser = 'CompanyPaysForEndUser'
}

export enum InvoiceType {
  EMAIL = 'Email',
  EHF = 'EHF'
}

export type AvailablePaymentMethod = {
  PK_PaymentMethodID: number;
  Name: string;
  Description: string;
  InwardTransactionPercentage: number;
  OutwardTransactionPercentage: number;
  InwardTransactionFix: number;
  OutwardTransactionFix: number;
  Currency: string;
  TransactionFixVATPercentage: number;
  BaseTransactionFeeFixed: number;
  BaseTransactionFeeFixedVAT: number;
  BaseTransactionFeePercentage: number;
  BaseTransactionFeePercentageVAT: number;
  PaymentType: string;
  PaymentTypePaymentMethod: string;
  FK_CountryID: number;
  isActive: boolean;
  ExtraCost_StartupCost_Percentage: number;
  ExtraCost_Unit_Percentage: number;
};
