<h2 mat-dialog-title *ngIf="title">{{ title }}</h2>
<mat-dialog-content class="dialog-content">
  <mat-form-field class="select-field">
    <mat-label>{{ placeholder }}</mat-label>
    <input
      type="text"
      matInput
      [formControl]="searchCtrl"
      [matAutocomplete]="auto"
      required
      name="item" />
  </mat-form-field>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn.bind(this)"
    (optionSelected)="select($event)">
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      <span
        [innerHTML]="item[viewProperty] | highlight : searchCtrl.value"></span>
    </mat-option>
  </mat-autocomplete>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!selectedItem"
    (click)="save()">
    {{ okButton }}
  </button>
  <button mat-raised-button mat-dialog-close>{{ cancelButton }}</button>
</mat-dialog-actions>
