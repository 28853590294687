import { Injectable } from '@angular/core';
import {
  Router,
  NavigationEnd,
  NavigationError,
  ActivatedRoute
} from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';

@Injectable()
export class RouterService {
  previousUrl: string;
  url: string;
  navStack: Array<NavStack> = [];
  currentEnv: string;

  constructor(private router: Router, private location: Location) {}
  init() {
    this.onError();
    this.onNavigate();
  }
  goBack() {
    if (this.previousUrl) {
      this.router.navigateByUrl(this.previousUrl);
    } else {
      this.location.back();
    }
  }
  getParams(route: ActivatedRoute): any {
    let params = {};
    route.pathFromRoot.forEach((route) => {
      params = Object.assign(params, route.snapshot.params);
    });
    return params;
  }
  private onNavigate() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.previousUrl = this.url;
        this.url = e.url;
      });
  }
  private onError() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationError))
      .subscribe((res) => {
        if (res) {
          this.router.navigate(['']);
        }
      });
  }
}
interface NavStack {
  url: string;
  text: string;
}
