import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpInterceptorService } from 'src/app/services';
import { FinancialsService } from '../..';

import * as FinancialsActions from './financials.actions';
import { FinancialsState } from './financials.reducer';
import {
  selectFinancialsStations,
  selectSelectedFinancials
} from './financials.selectors';

@Injectable({
  providedIn: 'root'
})
export class FinancialsFacade {
  constructor(
    private store: Store<FinancialsState>,
    financialsService: FinancialsService,
    private _http: HttpInterceptorService
  ) {}

  companyFinances$ = this.store.select(selectSelectedFinancials);
  companyStations$ = this.store.select(selectFinancialsStations);

  loadCompanyFinancials(
    PK_CompanyID: number,
    startTime: number,
    endTime: number
  ) {
    this.store.dispatch(
      FinancialsActions.LoadCompanyFinancials({
        PK_CompanyID,
        startTime,
        endTime
      })
    );
  }

  updateSelectedCurrency(currencyID: number) {
    this.store.dispatch(
      FinancialsActions.SelectedCurrencyChanged({ currencyID })
    );
  }

  resetFinancialState() {
    this.store.dispatch(FinancialsActions.RestsetFinancialState());
  }
}
