import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import {
  catchError,
  delay,
  map,
  mergeMap,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';

import { CompaniesService } from '../../companies/companies.service';

import {
  CompaniesLoaded,
  CompaniesActionTypes,
  PaymentMethodsLoaded
} from './companies.actions';
import { CompaniesState } from './companies.reducer';
import * as CompaniesActions from './companies.actions';
import { selectCompaniesEntities, selectCompaniesState } from '.';
import { Store } from '@ngrx/store';
import { PaymentMethodService } from '../../payment-method';

@Injectable({ providedIn: 'root' })
export class CompaniesEffects {
  loadCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.LoadCompanies),
      mergeMap((action) =>
        this.companiesService.getAll().pipe(
          map((companies) => CompaniesLoaded({ companies })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  loadPaymentMethods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.LoadPaymentMethods),
      mergeMap((action: any) =>
        this.companiesService.getPaymentMethods(action.PK_CompanyID).pipe(
          map((paymentMethods) =>
            PaymentMethodsLoaded({
              paymentMethods,
              companyId: action.PK_CompanyID
            })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  loadSingleCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.LoadSingleCompany),
      withLatestFrom(this.store.select(selectCompaniesState)),
      mergeMap(([action, state]) => {
        const company = state.entities[action.PK_CompanyID];

        if (company || state.isSingleLoading) {
          // return EMPTY;
        }

        return this.companiesService.getSingle(action.PK_CompanyID).pipe(
          map((company) => CompaniesActions.SingleCompanyLoaded({ company })),
          catchError(() => EMPTY)
        );
      })
    )
  );

  createBraintreeCustomer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompaniesActions.CreateBraintreeCustomer),
      mergeMap((action) => {
        const { companyID } = action;
        return this.companiesService.createBraintreeCustomer(action).pipe(
          mergeMap(() => [
            CompaniesActions.BraintreeCustomerCreated(),
            CompaniesActions.LoadPaymentMethods({ PK_CompanyID: companyID })
          ]),
          catchError((error) => {
            console.log(error);
            return EMPTY;
          })
        );
      })
    );
  });

  addPaymentMethod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompaniesActions.AddPaymentMethod),
      mergeMap((action) => {
        const { FK_CompanyID } = action.payload;
        // const { FK_CompanyID } = action;
        return this.paymentMethodService.addPaymentMethod(action.payload).pipe(
          mergeMap(() => [
            CompaniesActions.LoadPaymentMethods({
              PK_CompanyID: FK_CompanyID
            }),
            CompaniesActions.HideAddPaymentMethodModal()
          ]),
          catchError((error) => {
            console.log(error);
            return EMPTY;
          })
        );
      })
    );
  });

  updatePaymentMethod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompaniesActions.UpdatePaymentMethod),
      mergeMap((action) => {
        const { FK_CompanyID } = action.payload;
        // const { FK_CompanyID } = action;
        return this.paymentMethodService
          .updatePaymentMethod(action.payload)
          .pipe(
            mergeMap(() => [
              CompaniesActions.LoadPaymentMethods({
                PK_CompanyID: FK_CompanyID
              }),
              CompaniesActions.HideAddPaymentMethodModal()
            ]),
            catchError((error) => {
              console.log(error);
              return EMPTY;
            })
          );
      })
    );
  });

  deletePaymentMethod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompaniesActions.DeletePaymentMethod),
      mergeMap((action) => {
        const FK_CompanyID = action.FK_CompanyID;
        return this.paymentMethodService
          .deletePaymentMethod(action.CustomerPaymentMethodID)
          .pipe(
            mergeMap(() => [
              CompaniesActions.LoadPaymentMethods({
                PK_CompanyID: FK_CompanyID
              })
            ]),
            catchError((error) => {
              console.log(error);
              return EMPTY;
            })
          );
      })
    );
  });

  setDefaultPaymentMethod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompaniesActions.SetDefaultPaymentMethod),
      mergeMap((action) => {
        const FK_CompanyID = action.FK_CompanyID;
        return this.paymentMethodService
          .setDefaultPaymnetMethod(action.CustomerPaymentMethodID)
          .pipe(
            mergeMap(() => [
              CompaniesActions.LoadPaymentMethods({
                PK_CompanyID: FK_CompanyID
              })
            ]),
            catchError((error) => {
              console.log(error);
              return EMPTY;
            })
          );
      })
    );
  });

  removeDefaultPaymentMethod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompaniesActions.RemoveDefaultPaymentMethod),
      mergeMap((action) => {
        return this.paymentMethodService
          .removeDefaultPaymnetMethod(action)
          .pipe(
            mergeMap(() => [
              CompaniesActions.LoadPaymentMethods({
                PK_CompanyID: action.FK_CompanyID
              })
            ]),
            catchError((error) => {
              console.log(error);
              return EMPTY;
            })
          );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private companiesService: CompaniesService,
    private paymentMethodService: PaymentMethodService,
    private store: Store<CompaniesState>
  ) {}
}
