import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDatepicker } from '@angular/material/datepicker';
//import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sc-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent {
  @ViewChild('picker') picker: MatDatepicker<any>;
  fromDate: any;
  toDate: any;
  single: boolean = false;
  message: string = '';
  okButton: string = 'Ok';
  cancelButton: string = 'Cancel';
  constructor(
    public dialogRef: MatDialogRef<DateComponent>,
    // private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService
  ) {
    //this.dateAdapter.setLocale(this.translate.currentLang)
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.picker.open();
    }, 200);
  }
  close() {
    if (this.single) {
      this.dialogRef.close(this.fromDate);
    } else {
      this.dialogRef.close({ from: this.fromDate, to: this.toDate });
    }
  }
}
