import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { DialogService } from '../dialog/dialog.service';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  constructor(
    private _http: HttpInterceptorService,
    private dialog: DialogService,
    private translate: TranslateService
  ) {}

  getAllProducts(
    isAdmin: boolean = false,
    operatorID?: number,
    countryID: number = null,
    currency: string = null
  ) {
    return this._http.get(
      isAdmin
        ? 'Services/All'
        : `Services/ServicesOperatorCanAdd/${countryID}/${operatorID}/${currency}`
    );
  }
  updateProduct(product) {
    return this._http.put('Services', product).pipe(map((res) => res.datas));
  }

  // @note: never use this approach with promises
  getForStation(stationID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get('Services/Station/' + stationID).subscribe(
        (res) => {
          if (res.success) {
            resolve(res.datas);
          } else {
            //not sure this ever happens with the httpinterceptor, it should then be sent in err below
            reject(res);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getForGroup(PK_GroupID: string) {
    return this._http
      .get(`Services/Group/${PK_GroupID}`)
      .pipe(map((res) => res.datas));
  }

  removeFromStation(serviceID): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dialog
        .prompt(
          this.translate.instant('CONFIRM_DELETE_PRODUCT'),
          null,
          this.translate.instant('DELETE')
        )
        .subscribe((doRemove) => {
          if (doRemove) {
            this._http
              .delete('Services/RemoveService/' + serviceID, {})
              .subscribe(
                (res) => {
                  resolve(res);
                },
                (err) => {
                  reject(err);
                }
              );
          } else {
            resolve(null);
          }
        });
    });
  }

  deactivateService(serviceID: number, WillBeDeactivatedOn: string) {
    return this._http.put(`/Services/DeactivateService`, {
      ServiceID: serviceID,
      WillBe_DeactivatedOn: WillBeDeactivatedOn
    });
  }

  /**
   *
   * @param serviceID
   * @param date UTC date
   * @returns
   */
  activate(serviceID, date): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .put('Services/ActivateService', {
          ServiceID: serviceID,
          WillBe_ActivatedOn: date
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getAvailable(stationID) {
    return this._http
      .get('Services/ServicesICanAdd/' + stationID)
      .pipe(map((res) => res.datas));
  }

  getAvailableForGroup(groupID: string) {
    return this._http
      .get(`Services/ServicesICanAddForAGroup/${groupID}`)
      .pipe(map((res) => res.datas));
  }
  // group
  update(product) {
    return this._http
      .put('Services/PutService', product)
      .pipe(map((res) => res.datas));
  }
  add(product) {
    return this._http
      .post('Services/AddService', product)
      .pipe(map((res) => res.datas));
  }
  create(product): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post('Services', product).subscribe(
        (res) => {
          if (res.success) {
            resolve(res.datas);
          } else {
            //not sure this ever happens with the httpinterceptor, it should then be sent in err below
            reject(res);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
