// @TODO: Create seperate file
import { createFeatureSelector, createSelector, select } from '@ngrx/store';

import * as fromOperatorPackage from './operator-package.reducer';

import { OperatorPackage } from '../../operator-package';

// Feature selector
export const selectOperatorPackageState =
  createFeatureSelector<fromOperatorPackage.OperatorPackageState>(
    'operatorPackage'
  );

// export const selectCountriesState =
//   createFeatureSelector<fromOperatorPackage.OperatorPackageState>('countries');

export const selectAllOperatorPackage = createSelector(
  selectOperatorPackageState,
  fromOperatorPackage.selectAll
);

export const selectSelectedOperatorPackage = createSelector(
  selectOperatorPackageState,
  (state) => {
    return state.entities[state.selectedOperatorPackageID];
  }
);

export const selectIsLoading = createSelector(
  selectOperatorPackageState,
  (state) => state.isLoading
);

export const selectIsModalOpen = createSelector(
  selectOperatorPackageState,
  (state) => state.isModalOpen
);

export const selectSelectedCountryID = createSelector(
  selectOperatorPackageState,
  (state) => state.selectedCountryID
);

export const selectOperatorPackagesForSelectedCountry = createSelector(
  selectAllOperatorPackage,
  selectSelectedCountryID,
  (operatorPackages, countryID) => {
    return operatorPackages.filter(
      (operatorPackage) => operatorPackage.FK_CountryID === Number(countryID)
    );
  }
);
