import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secMinHour'
})
export class SecMinHourPipe implements PipeTransform {
  transform(seconds: string, sec = true, min = true, hour = true) {
    if (!seconds) return;
    let t = Number(seconds);
    let h = Math.floor(t / 3600);
    let m = Math.floor((t % 3600) / 60);
    let s = Math.floor((t % 3600) % 60);
    let hDisplay = '';
    let mDisplay = '';
    let sDisplay = '';

    if (h > 0 && hour) {
      hDisplay = h + (h == 1 ? ' hour' : ' hours');
    }
    if (m > 0 && min) {
      //if(hDisplay) mDisplay = ", ";
      mDisplay += m + (m == 1 ? ' minute' : ' minutes');
    }
    if (s > 0 && sec) {
      //if(mDisplay) sDisplay = ", ";
      sDisplay = s + (s == 1 ? ' second' : ' seconds');
    }
    let and = ' and ';

    if (hDisplay && mDisplay && sDisplay) {
      //h, m and s
      return hDisplay + ', ' + mDisplay + and + sDisplay;
    } else if (!hDisplay && mDisplay && sDisplay) {
      //m and s
      return mDisplay + and + sDisplay;
    } else if (hDisplay && mDisplay && !sDisplay) {
      //h and m
      return hDisplay + and + mDisplay;
    } else if (hDisplay && !mDisplay && sDisplay) {
      //h and s
      return hDisplay + and + sDisplay;
    }
    return hDisplay + mDisplay + sDisplay;
  }
}
