import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LetDirective, PushPipe } from '@ngrx/component';

import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  imports: [CommonModule, LetDirective, PushPipe],
  exports: [TranslateModule, LetDirective, PushPipe, PipesModule]
})
export class SharedModule {}
