import { Directive, ElementRef, OnInit } from '@angular/core';
import { FormControlName, NgControl } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControlName], [formControl]'
})
export class FormControlElementInjectorDirective implements OnInit {
  private get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  constructor(
    private readonly control: NgControl,
    private readonly elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    if (this.control instanceof FormControlName) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.control.control as any).nativeElement = this.element;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.control as any).nativeElement = this.element;
    }
  }
}
