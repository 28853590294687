import { on, createReducer } from '@ngrx/store';
import { Company } from '../../companies/companies.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as CompaniesActions from './companies.actions';
import { AppState } from '..';

export interface CompaniesState extends EntityState<Company> {
  selectedCompanyId: string | null;
  isFetched: boolean;
  isLoading: boolean;
  paymentMethods: any;
  paymentMethodsLoading: any;
  isSingleLoading: boolean;
  shouldShowAddPaymentModal: boolean;
  query: string;
}

// Entity adapter
export const adapter: EntityAdapter<Company> = createEntityAdapter<Company>({
  selectId: (company: Company) => company.PK_CompanyID.toString()
});

export const initialState: CompaniesState = adapter.getInitialState({
  isFetched: false, // Defines if the companies have been fetched
  isLoading: false, // Defines if it is currently loading
  selectedCompanyId: null,
  paymentMethods: {},
  paymentMethodsLoading: {},
  isSingleLoading: false,
  shouldShowAddPaymentModal: false,
  query: ''
});

export const companiesReducer = createReducer(
  initialState,
  on(CompaniesActions.CompaniesLoaded, (state, { companies }) => {
    return {
      ...adapter.addMany(companies, state),
      isLoading: false
    };
  }),
  on(CompaniesActions.LoadSingleCompany, (state) => {
    return {
      ...state,
      isSingleLoading: true
    };
  }),
  on(CompaniesActions.LoadCompanies, (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(CompaniesActions.SingleCompanyLoaded, (state, { company }) => {
    if (state.entities[company.PK_CompanyID]) {
      return {
        ...adapter.updateOne(
          {
            id: company.PK_CompanyID,
            changes: { FK_OperatorID: company.FK_OperatorID }
          },
          state
        ),
        selectedCompanyId: String(company.PK_CompanyID),
        isSingleLoading: false
      };
    } else {
      return {
        ...adapter.addOne(company, state),
        selectedCompanyId: String(company.PK_CompanyID),
        isSingleLoading: false
      };
    }
  }),
  on(CompaniesActions.SelectCompanyId, (state, { PK_CompanyID }) => {
    return {
      ...state,
      selectedCompanyId: String(PK_CompanyID)
    };
  }),
  on(CompaniesActions.LoadPaymentMethods, (state, { PK_CompanyID }) => {
    return {
      ...state,
      paymentMethodsLoading: {
        ...state.paymentMethodsLoading,
        [String(PK_CompanyID)]: true
      }
    };
  }),
  on(
    CompaniesActions.PaymentMethodsLoaded,
    (state, { paymentMethods, companyId }) => {
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          [String(companyId)]: paymentMethods
        },
        paymentMethodsLoading: {
          ...state.paymentMethodsLoading,
          [String(companyId)]: false
        }
      };
    }
  ),
  on(CompaniesActions.ShowAddPaymentMethodModal, (state) => {
    return {
      ...state,
      shouldShowAddPaymentModal: true
    };
  }),
  on(CompaniesActions.HideAddPaymentMethodModal, (state) => {
    return {
      ...state,
      shouldShowAddPaymentModal: false
    };
  }),
  on(CompaniesActions.SetSearchQuery, (state, { query }) => {
    return {
      ...state,
      query: query
    };
  })
);

export const getSelectedCompanyId = (state: AppState) => {
  return state.companies.selectedCompanyId;
};

export const getIsSingleLoading = (state: CompaniesState) => {
  return state.isSingleLoading;
};

export const getShouldShowAddPaymentModal = (state: CompaniesState) => {
  return state.shouldShowAddPaymentModal;
};

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectCompaniesIds = selectIds;
export const selectCompaniesEntities = selectEntities;
export const selectAllCompanies = selectAll;
