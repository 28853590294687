import { Injectable } from '@angular/core';
import { StationService } from '../station/station.service';
import { PointService } from '../point/point.service';
import { TokenService } from '../auth/token/token.service';
import { CompanyService } from '../company/company.service';
import { GroupService } from '../group/group.service';
import { StyleService } from '../style/style.service';

@Injectable()
export class ClearService {
  constructor(
    private stationService: StationService,
    private pointService: PointService,
    private tokenService: TokenService,
    private companyService: CompanyService,
    private groupService: GroupService,
    private styleService: StyleService
  ) {}
  clearAll() {
    this.stationService.clear();
    this.pointService.clear();
    this.tokenService.clear();
    this.companyService.clear();
    this.groupService.clear();
    this.styleService.clear();
  }
  clear(service: string) {
    //FIX: Not complete
    switch (service) {
      case 'station':
        this.stationService.clear();
        break;
      //etc
    }
  }
}
