<div class="dialog-container">
  <h1 mat-dialog-title>{{ 'SELECT_A_USER' | translate }}</h1>
  <div mat-dialog-content class="dialog-content">
    <sc-user-search
      (itemSelected)="selectCustomer($event)"
      (clear)="clearSearch()"
      [enter]="false"></sc-user-search>
  </div>
  <div mat-dialog-actions>
    <button
      mat-raised-button
      [disabled]="!customer"
      color="primary"
      (click)="dialogRef.close(customer)">
      {{ 'SELECT' | translate }}
    </button>
    <button mat-raised-button (click)="dialogRef.close()">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
