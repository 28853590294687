import { on, createReducer } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as OperatorActions from './operator.actions';
import { AppState } from '..';
import { Operator } from '../../operator';

export interface OperatorState extends EntityState<Operator> {
  isFetched: boolean;
  isLoading: boolean;
  selectedOperatorID: string;
}

// Entity adapter
export const adapter: EntityAdapter<Operator> = createEntityAdapter<Operator>({
  selectId: (_) => _.PK_OperatorID.toString()
});

export const initialState: OperatorState = adapter.getInitialState({
  isFetched: false, // Defines if the companies have been fetched
  isLoading: false, // Defines if it is currently loading
  selectedOperatorID: ''
});

export const operatorReducer = createReducer(
  initialState,
  on(OperatorActions.LoadAllOperators, (state) => ({
    ...state,
    isLoading: true
  })),

  on(OperatorActions.AllOperatorsLoaded, (state, { operators }) => {
    return {
      ...adapter.addMany(operators, state),
      isLoading: false,
      isFetched: true
    };
  }),

  on(OperatorActions.SelectOperator, (state, { PK_OperatorID }) => ({
    ...state,
    selectedOperatorID: PK_OperatorID.toString()
  }))
);

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
