import {
  Component,
  HostListener,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { AuthService } from '../../services/auth/auth.service';
import { menuItems, MenuItem } from './menu-items';
import { Defaults } from '../../defaults';
import { StyleService, DefaultStyle } from '../../services/style/style.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { adminMenuItems } from '../admin/admin-menu-items';
import { RolesService } from 'src/app/services/roles/roles.service';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { AuthAsService } from 'src/app/services/auth/auth-as/auth-as.service';
import { UserType } from 'src/app/user-roles';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { StationSearchDialogSimpleComponent } from '../stations/station-search/station-search-dialog-simple/station-search-dialog-simple.component';
import { CompanyService } from 'src/app/services/company/company.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/lib';
import { SmoothViewportScroller } from '@smartcharge/shared/services/smooth-viewport-scroller.service';
import { WhitelabelInstance } from 'src/app/services/whitelabel/whitelabel-instance.service';

@Component({
  selector: 'sc-main-sidenav',
  templateUrl: './main-sidenav.component.html',
  styleUrls: ['./main-sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainSidenavComponent {
  @ViewChild('sidenav') sidenav: any;
  menu: any = { open: true, mode: 'side' };
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = event?.target?.innerWidth || 1200;
    this.watchMenu(width);
  }
  user: any = {};
  menuItems: MenuItem[];
  version: string;
  style: any = {};

  access: any = { BusinessReports: false, UserReports: false, Reports: false };
  homeUrl: string = '/';
  loggedInAs: boolean = false;
  userType = UserType;
  showSearch: boolean = false;
  mustApproveCompany: boolean = false;
  showEnv: boolean = false;
  env: string = '';
  ownsStation: boolean = this.rolesService.hasHighlevelAccess(
    UserType.OwnsStations
  );
  languageEmoji: string = '';
  instance: any;
  showBell: boolean = false;

  headwayConfig = {
    selector: '#headway-badge',
    account: 'yZmo1J',
    callbacks: {
      onWidgetReady: () => {
        this.showBell = true;
      }
    }
  };
  supportURL: string = '';
  title: string;
  constructor(
    private userService: UserService,
    private auth: AuthService,
    private defaults: Defaults,
    private styleService: StyleService,
    public router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private rolesService: RolesService,
    private permissionService: PermissionsService,
    private authAsService: AuthAsService,
    private dialog: MatDialog,
    public companyService: CompanyService,
    public translate: TranslateService,
    private whiteLabelInstance: WhitelabelInstance,
    private viewportScroller: SmoothViewportScroller
  ) {
    this.title = this.whiteLabelInstance.whitelabel.title || 'CURRENT';
    //this.stationService.onLoad();
    this.version = this.defaults.version;

    this.styleService.get().then((style: DefaultStyle) => {
      this.style = this.styleService.style; //Two-way
    });
  }

  ngOnInit() {
    // Init headway asynchronously to it doesn't block APIs call.
    setTimeout(() => {
      (window as any).Headway.init(this.headwayConfig);
    }, 1000);

    // Getting support URL
    this.env = localStorage.getItem('environment') || 'production';
    this.showEnv = ['staging', 'local', 'debug', 'qa', 'sandbox'].includes(
      this.env
    );
    this.checkCompanyApproved();

    this.getMenuItems();
    this.getAccess();
    this.watchMenu(window?.innerWidth || 1200);
    //this.initStationMenu();

    this.checkLoggedInAs();

    this.userService.getUser().subscribe((user) => {
      this.user = user;
    });

    // Make it so that this updates when the route changes
    if (
      this.router.url.includes('/admin') &&
      this.rolesService.hasHighlevelAccess(this.userType.Admin)
    ) {
      this.menuItems = adminMenuItems;
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      let lang = params.lang;
      if (lang) {
        localStorage.setItem('lang', lang);
        let url = this.router.url.substr(0, this.router.url.lastIndexOf('?'));
        this.location.replaceState(url);
        location.reload();
      }
    });
  }

  notInBilling() {
    return !this.router.url.includes('/billing');
  }

  toggleSearch() {
    let dialogRef: MatDialogRef<StationSearchDialogSimpleComponent>;
    dialogRef = this.dialog.open(StationSearchDialogSimpleComponent);
  }

  toggleLocale() {
    const { currentLang } = this.translate;
    if (currentLang === 'nb') this.translate.use('en');
    if (currentLang === 'en') this.translate.use('nb');
  }

  getMenuItems() {
    let items = [...menuItems];

    this.menuItems = items.filter((menuItem: MenuItem) => {
      if (this.rolesService.hasHighlevelAccess(menuItem.roles)) {
        let pages = [...menuItem.pages];
        menuItem.pages = pages.filter((subPage) => {
          if (this.rolesService.hasHighlevelAccess(subPage.roles)) {
            return subPage;
          }
        });
        return menuItem;
      }
    });
  }

  getAccess() {
    this.access.BusinessReports = this.permissionService.checkAccessAll(
      'BusinessReports',
      'Allowed'
    );
    this.access.UserReports = this.permissionService.checkAccessAll(
      'UserReports',
      'Allowed'
    );

    if (this.access.BusinessReports || this.access.UserReports)
      this.access.Reports = true;
  }

  checkCompanyApproved() {
    //if(this.router.url.includes('/billing') || this.router.url.includes('/auth')) return;
    this.companyService.get().then(
      (res) => {},
      (err) => {}
    );
  }

  logout() {
    this.auth.logout(true);
  }

  menuClick() {
    if (this.menu.mode === 'over') {
      this.sidenav.toggle();
    }
  }
  watchMenu(width: number) {
    if (width < 1200) {
      this.menu.open = false;
      if (this.menu.mode !== 'over') {
        this.menu.mode = 'over';
        this.viewportScroller.setOffset([0, 64]);
      }
    } else {
      this.menu.open = true;
      if (this.menu.mode !== 'side') {
        this.menu.mode = 'side';
        this.viewportScroller.setOffset([0, 0]);
      }
    }
  }

  openChangelog() {}

  checkLoggedInAs() {
    if (localStorage.getItem('loggedInUser')) {
      this.loggedInAs = true;
    }
  }

  logoutAs() {
    //Clear
    //0485C71ACA5281
    this.authAsService.logout();
  }

  goToSupport() {
    window.open(this.supportURL);
  }
}
