export interface Company {
  PK_CompanyID: number;
  FK_BaseID: number;
  OwnedByCustomerID: number;
  ModifiedByUserID: number;
  Name: string;
  Phone: string;
  Email: string;
  Address: string;
  PostCode: string;
  City: string;
  VATNumber: string;
  isCompany: boolean;
  CompanyNumber: string;
  InvoiceEmail: string;
  StateOrProvince: string;
  Country: string;
  FK_CountryID: number;
  CreatedOn: string; // date?
  isActive: boolean;
  Keys: string[]; // not sure
  OwnerEmail: string;
  OwnerFirstName: string;
  OwnerMiddleName: string;
  OwnerLastName: string;
  InvoiceName: string;
  InvoiceAddress: string;
  InvoiceAddress2: string;
  InvoicePostCode: string;
  InvoiceCity: string;
  FK_InvoiceCoutnryID: number;
  HasVATBeenApproved: boolean;
  FK_CompanyIDPaying: number;
  BIC_SWIFT: string;
  IBAN: string;
  BankAccountNumber: string;
  InvoiceReference: string;
  InvoiceReferencePayouts: string;
  InvoicingLanguage: string;
  InvoiceCountry: string;
  ApprovedByCustomer: boolean;
  DateApprovedByCustomer: string;
  ApprovedByAdmin: boolean;
  DateApprovedByAdmin: string;
  PayoutsDelayInDays: number;
  Currency: string;
  InvoiceFrequence: number;
  FK_attn_CustomerID: number;
  FK_our_contact_CustomerID: number;
  FK_their_contat_CustomerID: number;
  AttnCustomerName: string;
  OurContactCustomerName: string;
  TheirContactCustomerName: string;
  FK_OperatorID: number;
  FK_InstallerID: number;
  ApproveDescription: string;
  FullyApprovedDate: string;
  DateApprovedByAdminFormatted: string;
  DateApprovedByCustomerFormatted: string;
}

export const emptyCompany: Company = {
  PK_CompanyID: 0,
  FK_BaseID: 0,
  OwnedByCustomerID: 0,
  ModifiedByUserID: 0,
  Name: '',
  Phone: '',
  Email: '',
  Address: '',
  PostCode: '',
  City: '',
  VATNumber: '',
  isCompany: false,
  CompanyNumber: '',
  InvoiceEmail: '',
  StateOrProvince: '',
  Country: '',
  FK_CountryID: 0,
  CreatedOn: '', // date?
  isActive: false,
  Keys: [], // not sure
  OwnerEmail: '',
  OwnerFirstName: '',
  OwnerMiddleName: '',
  OwnerLastName: '',
  InvoiceName: '',
  InvoiceAddress: '',
  InvoiceAddress2: '',
  InvoicePostCode: '',
  InvoiceCity: '',
  FK_InvoiceCoutnryID: 0,
  HasVATBeenApproved: false,
  FK_CompanyIDPaying: 0,
  BIC_SWIFT: '',
  IBAN: '',
  BankAccountNumber: '',
  InvoiceReference: '',
  InvoiceReferencePayouts: '',
  InvoicingLanguage: '',
  InvoiceCountry: '',
  ApprovedByCustomer: false,
  DateApprovedByCustomer: '',
  ApprovedByAdmin: false,
  DateApprovedByAdmin: '',
  PayoutsDelayInDays: 0,
  Currency: '',
  InvoiceFrequence: 0,
  FK_attn_CustomerID: 0,
  FK_our_contact_CustomerID: 0,
  FK_their_contat_CustomerID: 0,
  AttnCustomerName: '',
  OurContactCustomerName: '',
  TheirContactCustomerName: '',
  FK_OperatorID: 0,
  FK_InstallerID: 0,
  ApproveDescription: '',
  FullyApprovedDate: '',
  DateApprovedByAdminFormatted: '',
  DateApprovedByCustomerFormatted: ''
};

enum PaymentType {
  VISA = 'Visa',
  INVOICE = 'Invoice'
}

enum InvoiceSendMethod {
  EMAIL = 'EMAIL'
}

export interface PaymentMethod {
  PaymentMethodID: number;
  CustomerPaymentMethodID: number;
  PaymentMethodTypeID: number; //add enum
  AddedOn: string; // date?
  CustomerID: number | null;
  /* Defines the company associated with the payment method */
  FK_CompanyID: number;
  IsDefault: boolean;
  Identifyer: string; // what does this do?
  /* Payment type: Defines the payment type */ PaymentType: PaymentType;
  IsExpired: boolean;
  IsInvalid: boolean;
  DeactivatedOn: string | null; // date?
  IsActive: boolean;
  ProcessorResponse: string | null;
  ProcessorResponseCode: string | null;
  Is3DSecure: boolean;
  ProcessorInformation: string | null;
  InvoiceSendMethod: InvoiceSendMethod;
}
