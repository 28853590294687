/**
 *
 */
type UserRole =
  | 'SuperUser'
  | 'Admin'
  | 'Support'
  | 'BoxManufacturer'
  | 'Programmer'
  | 'DemoUser'
  | 'Owner'
  | 'BoxManufacturerOwner'
  | 'PlatformOwner'
  | 'Operator'
  | 'UserAdmin';
export type UserRoles = UserRole[];
export class AllRolesInterface {
  AdminGroup: any;
  SupportGroup: any;
  BoxManufacturerGroup: any;
  OwnerGroup: any;
  PlatformOwnerGroup: any;
  OperatorGroup: any;
  UserAdminGroup: any;
  SupportGroupExAdmin: any;
  BoxManufacturerGroupExAdmin: any;
  OwnerGroupExAdmin: any;

  SuperUser: any;
  Admin: any;
  Support: any;
  BoxManufacturer: any;
  Programmer: any;
  DemoUser: any;
  BoxManufacturerOwner: any;
  Owner: any;
  PlatformOwner: any;
  Operator: any;
  UserAdmin: any;
}
export const SuperAdmin: string[] = [
  'victor@meshcrafts.com',
  'einar@meshcrafts.com',
  'elmir@meshcrafts.com'
];
export const TempFinancialAdmin: string[] = [
  'tom@meshcrafts.com',
  'victor@meshcrafts.com',
  'einar@meshcrafts.com',
  'tom@current.eco'
];
//Single user roles
export const SuperUser: UserRole = 'SuperUser'; //Internal
export const Admin: UserRole = 'Admin'; //Internal
export const Support: UserRole = 'Support'; //External
export const Owner: UserRole = 'Owner'; //External
export const PlatformOwner: UserRole = 'PlatformOwner'; //External
export const BoxManufacturer: UserRole = 'BoxManufacturer'; //External
export const BoxManufacturerOwner: UserRole = 'BoxManufacturerOwner'; //External
export const Programmer: UserRole = 'Programmer'; //Internal
export const DemoUser: UserRole = 'DemoUser'; //External
export const Operator: UserRole = 'Operator'; //External
export const UserAdmin: UserRole = 'UserAdmin'; //External

//Groups of roles
/***
 * TIP! Use groups instead of single roles. Then we always get admin access and other correct access
 * If only one specific role should see something, you can use the single one.
 */
export const AdminGroup: UserRoles = [SuperUser, Admin, Programmer];
export const SupportGroup: UserRoles = [...AdminGroup, Support, PlatformOwner];
export const BoxManufacturerGroup: UserRoles = [
  ...AdminGroup,
  BoxManufacturer,
  BoxManufacturerOwner,
  PlatformOwner
];
export const OwnerGroup: UserRoles = [...AdminGroup, Owner, PlatformOwner];
export const OperatorGroup: UserRoles = [...AdminGroup, Operator];
export const UserAdminGroup: UserRoles = [...AdminGroup, UserAdmin];
export const PlatformOwnerGroup: UserRoles = [...AdminGroup, PlatformOwner];

export const SupportGroupExAdmin: UserRoles = [Support, PlatformOwner];
export const BoxManufacturerGroupExAdmin: UserRoles = [
  BoxManufacturer,
  BoxManufacturerOwner,
  PlatformOwner
];
export const OwnerGroupExAdmin: UserRoles = [Owner, PlatformOwner];

/** Backend types:
 *
 * Default = 0,
SuperUser = 1,
Admin = 2,
Owner = 3,
Support = 4,
BoxManufacturer = 5,
BoxManufacturerOwner = 6,
ClaimedUser = 7,
 */

export type UserTypeStrings = keyof typeof UserType;
export enum UserType {
  EV_Driver = 0,
  /**Demo user */
  DemoUser = 1 << 0,
  /**api access? */
  ExternalAPIAccess = 1 << 1,
  /**Support access? */
  Support = 1 << 2,
  /**Has access to all the boxes of one box type */
  BoxManufacturer = 1 << 3,
  /**Can integrate boxes in meshcrafts? */
  IntegrationTest = 1 << 4,
  /**Is an operator */
  Operator = 1 << 5,
  /**/ //  */
  Installer = 1 << 6,
  /**/ //  */
  Other7 = 1 << 7,
  /**/ //  */
  Other8 = 1 << 8,
  /**/ //  */
  Other9 = 1 << 9,
  /**/ //  */
  Other10 = 1 << 10,
  /**/ //  */
  Other11 = 1 << 11,
  Other12 = 1 << 12,
  Other13 = 1 << 13,
  Other14 = 1 << 14,
  Other15 = 1 << 15,
  Other16 = 1 << 16,
  OwnsCompany = 1 << 17,
  FinancialsCurrent = 1 << 18,
  CanEditUsers = 1 << 19,
  /**not stored in database, just set when loging in */
  OwnsStations = 1 << 20,
  Programmer = Support |
    Operator |
    OwnsStations |
    EV_Driver |
    DemoUser |
    ExternalAPIAccess |
    BoxManufacturer |
    IntegrationTest |
    Installer |
    Other7 |
    Other8 |
    Other9 |
    Other10 |
    Other11 |
    Other12 |
    Other13 |
    Other14 |
    Other15 |
    Other16 |
    OwnsCompany |
    FinancialsCurrent |
    CanEditUsers,
  Admin = (1 << 21) | Programmer,
  SuperUser = (1 << 22) | Admin
}
