import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TableOptions } from 'src/app/classes/table-options';
import { TableService } from 'src/app/services/table/table.service';
import { Router } from '@angular/router';
import { UserType } from 'src/app/user-roles';
import { RolesService } from 'src/app/services/roles/roles.service';

@Component({
  selector: 'sc-station-search-dialog-simple',
  templateUrl: './station-search-dialog-simple.component.html',
  styleUrls: ['./station-search-dialog-simple.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StationSearchDialogSimpleComponent implements OnInit {
  station: any;
  tableOptions: TableOptions = new TableOptions();
  userType = UserType;

  constructor(
    public dialogRef: MatDialogRef<StationSearchDialogSimpleComponent>,
    private tableService: TableService,
    private router: Router,
    private rolesService: RolesService
  ) {}

  ngOnInit() {}

  selectStation(station) {
    this.station = station;
  }
  clearSearch() {
    this.station = null;
  }
  selectItem(item) {
    if (item.PK_ChargePointID) {
      //Point
      this.router.navigate([
        'station',
        item.FK_ChargingStationsID,
        'point',
        item.PK_ChargePointID,
        'sessions'
      ]);
      //this.router.navigateByUrl('/station/'+item.FK_ChargingStationsID+'/point/'+item.PK_ChargePointID+'/sessions')
    } else {
      //Station
      //this.router.navigateByUrl('/station/'+item.ID+'/points');
      this.router.navigate(['station', item.ID, 'points']);
    }
    this.dialogRef.close();
  }
  onSearch(search) {
    //Go to stations and set search to "search"
    this.tableOptions = new TableOptions();
    this.tableOptions.search = search;

    this.tableService.setOptions(this.tableOptions, 'stations');
    //NAVIGATE
    this.router.navigate(['stations', search]);

    this.dialogRef.close();
  }
}
