import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { map } from 'rxjs/operators';

@Injectable()
export class QRCodeService {
  qrCodes: any = [];

  constructor(
    private _http: HttpInterceptorService,
  ) {}

  getStationPDF(id: number): Promise<any> {
    return new Promise((resolve) => {
        this._http
          .get('QRCodes/GetStationPDF/' + id, { responseType: "blob" })
          .subscribe(
            (pdf) => {
              if (pdf) {
                resolve(pdf);
              } else {
                resolve(null);
              }
            },
            (err) => {
              resolve(null);
            }
          );
    });
  }
  unpair(qrID){
    return this._http.patch(`QRCodes/Unpair/${qrID}`, {});
  }
  pair(pairObject){
    return this._http.patch(`QRCodes/Pair`, pairObject);
  }
  getAllCodes(index, limit, filter){
    return this._http.post(`QRCodes/List/${index}/${limit}`, filter);
  }
  getCodesForStation(stationID){
    return this._http.get(`QRCodes/${stationID}`);
  }
  getByCode(code){
    return this._http.get(`QRCodes/${code}`);
  }
  createUnpaired(amount=1, companyID){
    return this._http.post(`QRCodes/Unpaired/${amount}/${companyID}`, {})
  }
  createForStation(stationID, amount=1){
    return this._http.post(`QRCodes/Station/${stationID}/${amount}`, {})
  }
  createForPointsInStation(stationID){
    return this._http.post(`QRCodes/Points/${stationID}`, {})
  }
  createForPoint(pointID){
    return this._http.post(`QRCodes/Point/${pointID}`, {})
  }

  generateLabel(qrID, body){
    let options: any = {};
    options.responseType = 'text';
    return this._http.postNonJson(`QRCodes/Generate/${qrID}`, body, options);
  }
  generateLabels(filters, designOptions, format){
    let options: any = {};
    options.responseType = 'blob';
    let body: any = Object.assign({}, format, {Filters: filters, Options: designOptions});

    return this._http.postNonJson(`QRCodes/Generate`, body, options);
  }
  printLabels(filters, designOptions, format){
    let options: any = {};
    // options.responseType = 'blob';
    let pageGeneration = Object.assign({}, format, {Filters: filters, Options: designOptions})
    pageGeneration.Language = designOptions.Language;
    pageGeneration.CountryID = designOptions.CountryID;
    let body: any = Object.assign({}, {
      PageGeneration: pageGeneration,
      PrintingPriceWithoutVAT: designOptions.CostPerLabel,
      ShippingCostWithoutVAT: designOptions.ShippingCost,
      Currency: designOptions.Currency,
      CompanyIDInvoiced: designOptions.InvoiceTo,
      NumberToCreate: designOptions.NumberToCreate
    });

    return this._http.postNonJson(`QRCodes/Print`, body, options);
  }

  getSupportedLanguages() {
    return this._http.get('Users/GetSupportedLanguages').pipe((map(res => res.datas)));
  }
  getAllCountries() {
    return this._http.get('Countries/GetAll').pipe((map(res => res.datas)));
  }
  getOperators() {
    return this._http.get('Operators/OperatorsICanSee').pipe((map(res => res.datas)));
  }
  getInstallers() {
    return this._http.get('Installers/InstallersICanSee').pipe((map(res => res.datas)));
  }
}
export class QRFilter{
  ExcludePairedCodes: boolean = false;
  ExcludeUnpairedCodes: boolean = false;
  StationIDs: number[] = [];
  QRCodesIDs: number[] = [];
  OperatorIDs: number[] = [];
  InstallerIDs: number[] = [];
}
export class QRExportFormat{
  PageFormat: string = "SVG_LIST";
  DocumentType: DocumentType = "SVG_BASE64";
  NbColumns: number = 3;
  NbRows: number = 8;
  MarginBetweenColumns: number = 0.2;
  MarginBetweenRows: number = 0.2;
  MarginHorizontalDocument: number = 0;
  MarginVerticalDocument: number = 0;
  FillBackground: boolean = false;
  Width_One_QR: number = 7;
  Height_One_QR: number = 3.7;
}
const DocumentTypes = ['PNG', 'PDF', 'HTML', 'SVG', 'SVG_BASE64'] as const
type DocumentType = typeof DocumentTypes[number];
