import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, catchError, exhaustMap, map } from 'rxjs';

import * as ManufacturersActions from './manufacturers.actions';
import { ManufacturerService } from 'src/app/services/manufacturer/manufacturer.service';

@Injectable()
export class ManufacturersEffects {
  loadManufacturers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManufacturersActions.LoadAllManufacturers),
      exhaustMap(() =>
        this.manufacturerService.getAll().pipe(
          map((manufacturers) =>
            ManufacturersActions.AllManufacturersLoaded({ manufacturers })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private manufacturerService: ManufacturerService
  ) {}
}
