/* NB! Not to be included in services.ts */

/* Handles route guards */
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, Route } from '@angular/router';

import { AuthService } from './auth.service';
import { TokenService } from './token/token.service';
import { Observable } from 'rxjs';
import { RolesService } from '../roles/roles.service';
import { PermissionsService } from '../permissions/permissions.service';
import { Location } from '@angular/common';
import { UserService } from 'src/app/lib';

@Injectable()
export class AuthGuard  {
  constructor(
    private auth: AuthService,
    private router: Router,
    private tokenService: TokenService,
    private rolesService: RolesService,
    private permissionService: PermissionsService,
    private location: Location,
    private userService: UserService
  ) {}

  canLoad(route: Route): Observable<any> {
    return new Observable<boolean>((observer) => {
      this.tokenService.check().subscribe((authenticated) => {
        if (authenticated) {
          let roles = route.data['roles'];
          let environmentAccess = route.data['environmentAccess'];

          if (
            this.rolesService.hasHighlevelAccess(roles) &&
            this.hasEnvironmentAccess(environmentAccess)
          ) {
            observer.next(true);
            observer.complete();
          } else {
            this.router.navigate(['']);
            observer.next(false);
            observer.complete();
          }
        } else {
          this.auth.redirectTo = this.location.path();

          this.router.navigate(['auth/login']);
          observer.next(false);
          observer.complete();
        }
      });
    });
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return new Observable<boolean>((observer) => {
      this.tokenService.check().subscribe((authenticated) => {
        if (authenticated) {
          let roles = route.data['roles'];
          let hlAccess = route.data['hlAccess'];
          let component = route.data['component'];
          let reqAccess = route.data['access'];
          let environmentAccess = route.data['environmentAccess'];

          this.userService.getLocalUser();

          if (
            (this.rolesService.hasHighlevelAccess(roles) &&
              this.hasAccess(component, route, reqAccess) &&
              this.hasEnvironmentAccess(environmentAccess)) ||
            (hlAccess && this.rolesService.hasHighlevelAccess(hlAccess))
          ) {
            observer.next(true);
            observer.complete();
          } else {
            this.router.navigate(['']);
            observer.next(false);
            observer.complete();
          }
        } else {
          this.auth.redirectTo = this.location.path();

          this.router.navigate(['auth/login']);
          observer.next(false);
          observer.complete();
        }
      });
    });
  }
  private hasEnvironmentAccess(environmentAccess): boolean {
    //Checks if there are special environment access rules

    //No roles provided, do not check access
    if (!environmentAccess) return true;

    //Get environment
    let currentEnvironment =
      localStorage.getItem('environment') || 'production';

    //Get roles for the current environment
    let roles = environmentAccess[currentEnvironment];

    //No roles provided for the current environment, everyone has access
    if (!roles || !roles.length) return true;

    //Check if the user has one of the roles
    return this.rolesService.hasHighlevelAccess(roles);
  }
  hasAccess(component, route, requiredAccess): boolean {
    //Checks the access to a specific component

    if (!component) return true; //No need to check access if no component should be checked

    let stationIDRoute = route.pathFromRoot.filter(
      (checkRoute) => checkRoute.params.stationID
    )[0]; //Get stationID from the route

    if (!stationIDRoute) {
      //Should check if access in any location? Takes to long?
      return this.permissionService.checkAccessAll(
        component,
        requiredAccess || 'Allowed'
      );
      //return false; //No location ID
    } else {
      let access = this.permissionService.checkAccess(
        component,
        stationIDRoute.params.stationID
      );

      if (!access) {
        return false; //No permission exist for this location
      } else {
        if (requiredAccess) {
          //A specific access is required to have access. Check here
          switch (requiredAccess) {
            case 'CanModify':
              return access.canEdit;
            case 'CanExport':
              return access.canExport;
            case 'CanDelete':
              return access.canDelete;
            default:
              return access.canSee;
          }
        } else {
          return access.canSee; //Return permission
        }
      }
    }
  }
}
