import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { BoxManufacturer } from 'src/app/classes';
import * as ManufacturersActions from './manufacturers.actions';

export interface ManufacturersState extends EntityState<BoxManufacturer> {
  isFetched: boolean;
  isLoading: boolean;
  manufacturers: BoxManufacturer[];
  query: string;
  selectedManufacturerId: number | null;
}

export function selectManufacturerId(manufacturer: BoxManufacturer): number {
  return manufacturer.PK_ChargerManufacturerID;
}

export const adapter: EntityAdapter<BoxManufacturer> =
  createEntityAdapter<BoxManufacturer>({
    selectId: selectManufacturerId
  });

export const initialState: ManufacturersState = adapter.getInitialState({
  isFetched: false,
  isLoading: false,
  manufacturers: [],
  query: '',
  selectedManufacturerId: null
});

export const manufacturersReducer = createReducer(
  initialState,
  on(
    ManufacturersActions.AllManufacturersLoaded,
    (state, { manufacturers }) => {
      return {
        ...state,
        manufacturers,
        isFetched: true,
        isLoading: false
      };
    }
  ),
  on(ManufacturersActions.LoadAllManufacturers, (state) => {
    return { ...state, isLoading: true };
  }),
  on(ManufacturersActions.SetSearchQuery, (state, { query }) => {
    return {
      ...state,
      query: query
    };
  })
);

export const getSelectedManufacturerId = (state: ManufacturersState) =>
  state.selectedManufacturerId;

export const { selectIds, selectAll, selectEntities, selectTotal } =
  adapter.getSelectors();
