import { AvailableWidgetOptions } from 'src/app/lib/state/insights/types';
import * as InsightsActions from './insights.actions';
import { LoadingStatus } from '../..';
import { createReducer, on } from '@ngrx/store';

export interface InsightsState {
  help: AvailableWidgetOptions;
  helpStatus: LoadingStatus;
}

export const initialState: InsightsState = {
  help: null,
  helpStatus: LoadingStatus?.NotLoaded
};

export const insightsReducer = createReducer(
  initialState,
  on(InsightsActions.LoadInsightsWidgetHelp, (state) => {
    return {
      ...state,
      helpStatus: LoadingStatus.Loading
    };
  }),
  on(InsightsActions.InsightsWidgetHelpLoaded, (state, { data }) => {
    return {
      ...state,
      helpStatus: LoadingStatus.Loaded,
      help: data
    };
  })
);
