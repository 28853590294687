import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { WallSetting } from '../../classes/wallsetting';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PointService } from '../point/point.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class WallSettingService {
  constructor(
    private _http: HttpInterceptorService,
    private snackbar: MatSnackBar,
    private pointService: PointService,
    private translate: TranslateService
  ) {}

  getForStation(stationID): Promise<any> {
    return new Promise((resolve) => {
      this._http
        .get('WallSettings/ByStation/' + stationID)
        .subscribe((wallsettings) => {
          resolve(wallsettings);
        });
    });
  }
  getSingle(wallsettingID): Promise<any> {
    return new Promise((resolve) => {
      this._http
        .get('WallSettings/' + wallsettingID)
        .subscribe((wallsetting) => {
          resolve(wallsetting);
        });
    });
  }
  update(wallsetting: WallSetting): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.put('WallSettings', wallsetting).subscribe(
        (res) => {
          if (res.success && res.datas) {
            resolve(res.datas);
          } else {
            resolve(false);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  addNew(wallsetting: WallSetting): Promise<any> {
    return new Promise((resolve) => {
      this._http.post('WallSettings', wallsetting).subscribe(
        (res) => {
          if (res.success && res.datas) {
            resolve(res.datas);
          } else {
            this.snackbar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        },
        (err) => {
          this.snackbar.open(
            this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            }),
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }
  delete(wallsetting: WallSetting): Promise<any> {
    return new Promise((resolve) => {
      this._http
        .delete('WallSettings/' + wallsetting.PK_WallSettingsID)
        .subscribe(
          (res) => {
            if (res.success) {
              resolve(res.success);
            } else {
              resolve(false);
            }
          },
          (err) => {
            resolve(false);
          }
        );
    });
  }
}
