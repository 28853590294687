// @TODO: Create seperate file
import { createFeatureSelector, createSelector, select } from '@ngrx/store';

import * as fromCountries from './countries.reducer';

import { Country } from '../../countries';

// Feature selector
export const selectCountriesState =
  createFeatureSelector<fromCountries.CountriesState>('countries');

export const selectAllCountries = createSelector(
  selectCountriesState,
  fromCountries.selectAll
);

export const selectSelectedCountry = createSelector(
  selectCountriesState,
  (state) => {
    return state.entities[state.selectedCountryID];
  }
);

export const selectActiveCountries = createSelector(
  selectAllCountries,
  (countries) => {
    return countries.filter((country) => country.canAddStationsInCountry);
  }
);

export const selectAvailablePaymentMethods = createSelector(
  selectCountriesState,
  (state) => {
    return state.availablePaymentMethods[state.selectedCountryID];
  }
);

export const selectIsLoading = createSelector(
  selectCountriesState,
  (state) => state.isLoading
);
