import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromManufacturers from './manufacturers.reducer';
import { selectAllCompanies } from '../companies';
import { BoxManufacturer } from 'src/app/classes';
import { isEmpty } from 'lodash';

export const selectManufacturersState =
  createFeatureSelector<fromManufacturers.ManufacturersState>('manufacturers');

export const selectAllManufacturers = createSelector(
  selectManufacturersState,
  (state) => {
    return state.manufacturers;
  }
);

export const selectAllManufacturersAndCompanies = createSelector(
  selectAllManufacturers,
  selectAllCompanies,
  (manufacturers, companies): BoxManufacturer[] => {
    return manufacturers.map((manufacturer: BoxManufacturer) => {
      const companyName = companies.find(
        (company) => company.PK_CompanyID === manufacturer.FK_CompanyID
      )?.Name;

      return { ...manufacturer, CompanyName: companyName };
    });
  }
);

export const selectSearchQuery = createSelector(
  selectManufacturersState,
  (state) => state.query
);

export const selectSearchedManufacturers = createSelector(
  selectAllManufacturersAndCompanies,
  selectSearchQuery,
  (manufacturers: BoxManufacturer[], query: string) => {
    if (isEmpty(query)) {
      return manufacturers;
    }

    return manufacturers.filter((manufacturer) => {
      if (manufacturer.Name.toLowerCase().includes(query)) {
        return true;
      } else if (manufacturer.CompanyName?.toLowerCase().includes(query)) {
        return true;
      }

      return false;
    });
  }
);

export const selectIsLoading = createSelector(
  selectManufacturersState,
  (state) => state.isLoading
);
