import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';

@Injectable()
export class UrlTitleService {
  constructor(private location: Location, private title: Title) {}

  set(url, title?) {
    title = title ? title + ' - CURRENT' : 'CURRENT';

    this.location.replaceState(url);
    this.title.setTitle(title);
  }
  setTitle(title) {
    title = title ? title + ' - CURRENT' : 'CURRENT';
  }
  setUrl(url) {
    this.location.replaceState(url);
  }
}
