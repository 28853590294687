<h2 mat-dialog-title>{{ 'ACTIVATION_OF_ROAMING' | translate }}</h2>

<div class="main-content h-[70vh]" mat-dialog-content>
  <p>
    {{ 'ROAMING_DEFINATION_TEXT' | translate }}
  </p>
  <p>
    {{ 'ROAMING_FEE_DEFINATION' | translate }}
  </p>
  <hr size="2" width="100%" />
  <iframe
    #iframe
    (load)="onFrameLoad()"
    style="overflow: hidden; height: 65%; width: 100%"
    height="65%"
    width="100%"
    frameborder="0"
    wmode="transparent"></iframe>

  <hr size="2" width="100%" />

  <p>
    {{ 'UNDERSTOOD_TERMS_ROAMING' | translate}}
  </p>
</div>

<div mat-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    type="submit"
    [disabled]="!scrollBottom"
    (click)="close(true)">
    {{ 'ACCEPT_AND_ACTIVATE_ROAMING' | translate }}
  </button>
  <button mat-raised-button (click)="close()" type="button">
    {{ 'CANCEL' | translate }}
  </button>
</div>
