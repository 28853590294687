import { Injectable } from '@angular/core';
import { ReportsByMethod, Report } from 'src/app/classes/Report';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor() {}

  combineMethods(reportsByMethod): ReportsByMethod {
    //Combining credit/debit cards
    let cardPayments = reportsByMethod.filter(
      (report) =>
        (report.PaymentType || report.PaymentTypePaymentMethod) &&
        report.PaymentMethod &&
        (report.PaymentMethod.PaymentType ||
          report.PaymentMethod.PaymentTypePaymentMethod) === 'Braintree' &&
        (report.PaymentType || report.PaymentTypePaymentMethod) !== 'PayPal'
    );
    let freeSessions: Report[] = reportsByMethod.filter(
      (report) => !report.PaymentType && report.NbChargingSessionsFree
    );
    let unknownSessions: Report[] = reportsByMethod.filter(
      (report) => !report.PaymentType && report.NbChargingSessions
    );

    let combine = (reports: Report[]) => {
      let cards: Report = new Report();
      for (let btType of reports) {
        for (let key in new Report()) {
          if (!isNaN(btType[key])) {
            cards[key] += btType[key];
          }
        }
      }
      return cards;
    };

    let reports: ReportsByMethod = new ReportsByMethod();

    reports = {
      SMS:
        reportsByMethod.filter(
          (report) =>
            (report.PaymentType || report.PaymentTypePaymentMethod) === 'SMS'
        )[0] || new Report(),
      PayPal:
        reportsByMethod.filter(
          (report) =>
            (report.PaymentType || report.PaymentTypePaymentMethod) === 'PayPal'
        )[0] || new Report(),
      Card: combine(cardPayments),
      Invoice:
        reportsByMethod.filter(
          (report) =>
            (report.PaymentType || report.PaymentTypePaymentMethod) ===
            'Invoice'
        )[0] || new Report(),
      Free: combine(freeSessions), // && !report.NbChargingSessions [0]
      Unknown: combine(unknownSessions)
    };

    return reports;
  }
}
