import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

/* Pipes */
import { SecMinHourPipe } from './sec-min-hour/sec-min-hour.pipe';
import { ConvertSecondsPipe } from './convert-seconds/convert-seconds.pipe';
import { ActionPipe } from './action/action.pipe';
import { CDatePipe } from './c-date/c-date.pipe';
import { PointsOnlinePipe } from './point-online/point-online.pipe';
import { IsChargingPipe } from './is-charging/is-charging.pipe';
import { HighlightPipe } from './highlight/highlight.pipe';
import { DurationSincePipe } from './duration-since/duration-since.pipe';
import { CalculatePowerPipe } from './calculate-kw/calculate-kw.pipe';
import { AppCardPipe } from './app-card/app-card.pipe';
import { CCurrencyPipe } from './c-currency/c-currency.pipe';
import { CurrencySymbolPipe } from './currency-symbol/currency-symbol.pipe';
import { CustomCurrencyPipe } from './custom-currency/custom-currency.pipe';

import { TranslateModule } from '@ngx-translate/core';
import { CompactNumberPipe } from './compact-number/compact-number.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    CDatePipe,
    ConvertSecondsPipe,
    ActionPipe,
    SecMinHourPipe,
    PointsOnlinePipe,
    IsChargingPipe,
    HighlightPipe,
    DurationSincePipe,
    CalculatePowerPipe,
    AppCardPipe,
    CCurrencyPipe,
    CurrencySymbolPipe,
    CustomCurrencyPipe,
    CompactNumberPipe
  ],
  providers: [
    CDatePipe,
    ConvertSecondsPipe,
    ActionPipe,
    SecMinHourPipe,
    PointsOnlinePipe,
    IsChargingPipe,
    HighlightPipe,
    DurationSincePipe,
    CalculatePowerPipe,
    AppCardPipe,
    CCurrencyPipe,
    CurrencyPipe,
    CurrencySymbolPipe,
    CustomCurrencyPipe,
    CompactNumberPipe,
    { provide: LOCALE_ID, useValue: 'nb' }
  ],
  exports: [
    CDatePipe,
    ConvertSecondsPipe,
    ActionPipe,
    SecMinHourPipe,
    PointsOnlinePipe,
    IsChargingPipe,
    HighlightPipe,
    DurationSincePipe,
    CalculatePowerPipe,
    AppCardPipe,
    CCurrencyPipe,
    CurrencySymbolPipe,
    CustomCurrencyPipe,
    TranslateModule,
    CompactNumberPipe
  ]
})
export class PipesModule {}
