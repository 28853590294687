import { on, createReducer } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as SettlementsActions from './settlements.actions';
import { AppState } from '..';

import { Settlement } from '../..';

export interface SettlementsState extends EntityState<Settlement> {
  isFetched: boolean;
  isLoading: boolean;
  selectedSettlementID: string;
}

// Entity adapter
export const adapter: EntityAdapter<Settlement> =
  createEntityAdapter<Settlement>({
    selectId: (_) => _.PK_SettlementID.toString()
  });

export const initialState: SettlementsState = adapter.getInitialState({
  isFetched: false, // Defines if the companies have been fetched
  isLoading: false, // Defines if it is currently loading
  selectedSettlementID: ''
});

export const settlementsReducer = createReducer(
  initialState,
  on(SettlementsActions.LoadAllSettlements, (state) => ({
    ...state,
    isLoading: true
  })),

  on(SettlementsActions.AllSettlementsLoaded, (state, { settlements }) => {
    return {
      ...adapter.addMany(settlements, state),
      isLoading: false,
      isFetched: true
    };
  }),

  on(SettlementsActions.SelectSettlementsID, (state, { PK_SettlementID }) => ({
    ...state,
    selectedSettlementsID: PK_SettlementID
  }))
);

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
