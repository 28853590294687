<form (submit)="handleOnSubmit($event)" [formGroup]="form">
  <h2 mat-dialog-title>Set display messages</h2>

  <div class="input-row">
    <mat-form-field floatLabel="auto" class="mr-4">
      <mat-label>ID</mat-label>
      <input matInput formControlName="id" />
      <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label floatLabel="auto" class="mr-4">Priority</mat-label>
      <mat-select formControlName="priority">
        <mat-option [value]="'AlwaysFront'">AlwaysFront</mat-option>
        <mat-option [value]="'InFront'">InFront</mat-option>
        <mat-option [value]="'NormalCycle'">NormalCycle</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="input-row">
    <mat-form-field floatLabel="auto" class="mr-4">
      <mat-label>State</mat-label>
      <mat-select formControlName="state">
        <mat-option [value]="'Charging'">Charging</mat-option>
        <mat-option [value]="'Faulted'">Faulted</mat-option>
        <mat-option [value]="'Faulted'">Faulted</mat-option>
        <mat-option [value]="'Unavailable'">Unavailable</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="auto">
      <mat-label>Transaction Id</mat-label>
      <input
        matInput
        formControlName="transactionId" />
    </mat-form-field>
  </div>

  <div class="input-row">
    <mat-form-field floatLabel="auto" class="mr-4">
      <mat-label>Start time</mat-label>
      <input
        matInput
        type="datetime-local"
        formControlName="startDateTime" />
    </mat-form-field>
    <mat-form-field floatLabel="auto" >
      <mat-label>End time</mat-label>
      <input
        matInput
        type="datetime-local"
        formControlName="endDateTime" />
    </mat-form-field>

  </div>

  <div class="input-row">
    <mat-form-field floatLabel="auto" class="mr-4">
      <mat-label>Message format</mat-label>
      <mat-select formControlName="format">
        <mat-option [value]="'ASCII'">ASCII</mat-option>
        <mat-option [value]="'HTML'">HTML</mat-option>
        <mat-option [value]="'URI'">URI</mat-option>
        <mat-option [value]="'UTF8'">UTF8</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="auto">
      <mat-label>Message language</mat-label>
      <input
        matInput
        formControlName="language" />
    </mat-form-field>
  </div>
  <div class="input-row">
    <mat-form-field floatLabel="auto" class="w-full">
      <mat-label>Message content</mat-label>
      <textarea matInput rows="4" formControlName="content"></textarea>
      <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
    </mat-form-field>
  </div>
  <div class="input-row">
    <mat-form-field floatLabel="auto" class="mr-4">
      <mat-label>Display component name</mat-label>
      <input matInput formControlName="DisplayComponentName" />
    </mat-form-field>
    <mat-form-field floatLabel="auto" class="mr-4">
      <mat-label>Display instance name</mat-label>
      <input matInput formControlName="DisplayInstanceName" />
    </mat-form-field>

  </div>
  <div class="input-row">
    <mat-form-field floatLabel="auto" class="mr-4">
      <mat-label>Display EVSE ID</mat-label>
      <input matInput formControlName="DisplayEVSEID" />
    </mat-form-field>
    <mat-form-field floatLabel="auto">
      <mat-label>Display Connector ID</mat-label>
      <input matInput formControlName="DisplayConnectorID" />
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit">
      {{ 'SET' | translate }}
    </button>
    <button mat-raised-button (click)="close()" type="button">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</form>
