<form (submit)="handleOnSubmit($event)" [formGroup]="configForm">
  <h2 mat-dialog-title>Set variable monitoring</h2>

  <div mat-dialog-content class="dialog-content">
    <div formArrayName="MonitoringData">
      <div
        class="mb-4 flex"
        *ngFor="let item of MonitoringData.controls; let i = index"
        [formGroupName]="i">
        <div class="border-1 rounded-md border-dotted border-gray-300 p-4">
          <div class="input-row">
            <mat-form-field floatLabel="auto" class="mr-4">
              <mat-label>Value</mat-label>
              <input
                matInput
                formControlName="value"
                type="number" />
            </mat-form-field>
            <mat-form-field class="mr-4">
              <mat-label>Monitor type</mat-label>
              <mat-select formControlName="type">
                <mat-option [value]="'UpperThreshold'">
                  UpperThreshold
                </mat-option>
                <mat-option [value]="'LowerThreshold'">
                  LowerThreshold
                </mat-option>
                <mat-option [value]="'Delta'">Delta</mat-option>
                <mat-option [value]="'Periodic'">Periodic</mat-option>
                <mat-option [value]="'PeriodicClockAligned'">
                  PeriodicClockAligned
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field floatLabel="auto" class="mr-4">
              <mat-label>Severity</mat-label>
              <input
                matInput
                formControlName="severity"
                type="number" />
            </mat-form-field>
            <mat-form-field floatLabel="auto" class="mr-4">
              <mat-label>Controller</mat-label>
              <input
                type="text"
                matInput
                (focus)="filterComponents($event)"
                (input)="filterComponents($event)"
                formControlName="Controller"
                [matAutocomplete]="autoComponents" />
              <mat-autocomplete #autoComponents="matAutocomplete">
                <mat-option *ngFor="let item of componentsList" [value]="item">
                  {{ item }}
                </mat-option>
              </mat-autocomplete>
              <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field floatLabel="auto">
              <mat-label>Key</mat-label>
              <input
                type="text"
                matInput
                (focus)="filteVariable($event)"
                (input)="filteVariable($event)"
                formControlName="Key"
                [matAutocomplete]="autoVariables"/>
              <mat-autocomplete #autoVariables="matAutocomplete">
                <mat-option *ngFor="let item of variablesList" [value]="item">
                  {{ item }}
                </mat-option>
              </mat-autocomplete>
              <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="input-row">
            <mat-form-field floatLabel="auto" class="mr-4">
              <mat-label>ID</mat-label>
              <input matInput  formControlName="id" />
            </mat-form-field>
            <mat-checkbox formControlName="transaction" class="mr-4">
              Transaction
            </mat-checkbox>
            <mat-form-field floatLabel="auto" class="mr-4">
              <mat-label>Instance</mat-label>
              <input
                matInput
                formControlName="Instance" />
            </mat-form-field>

            <mat-form-field floatLabel="auto">
              <mat-label>EVSE ID</mat-label>
              <input
                matInput
                formControlName="EVSEID"
                type="number" />
            </mat-form-field>
          </div>
        </div>
        <div>
          <button mat-icon-button class="ml-4" (click)="deleteDataItem(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="mb-4">
      <button mat-button (click)="addDataItem()" type="button">
        <mat-icon>add</mat-icon>
        {{ 'ADD' | translate }}
      </button>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit">
      {{ 'SET' | translate }}
    </button>
    <button mat-raised-button (click)="close()" type="button">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</form>
