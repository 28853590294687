import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import * as fromRoamingPlatforms from './roaming-platform.reducer';

// Feature selector
export const roamingPlatformState =
  createFeatureSelector<fromRoamingPlatforms.RoamingPlatformState>('roamingPlatform');

export const selectRoamingPlatform = createSelector(roamingPlatformState, (state) => {
  return state.roamingPlatforms;
});

export const selectRoamingLoadingState = createSelector(
  roamingPlatformState,
  (state) => state.status
);
