import { Pipe, PipeTransform } from '@angular/core';
import { ConvertSecondsPipe } from '../convert-seconds/convert-seconds.pipe';

@Pipe({
  name: 'durationSince'
})
export class DurationSincePipe implements PipeTransform {
  constructor(private convertSeconds: ConvertSecondsPipe) {}

  transform(date: any): any {
    if (!date) return null;

    date = new Date(date);
    let now = new Date();

    let duration = now.getTime() - date.getTime();

    return this.convertSeconds.transform(duration / 1000);
  }
}
