// @TODO: Create seperate file
import { createFeatureSelector, createSelector, select } from '@ngrx/store';

import * as fromFinancials from './financials.reducer';

// Feature selector
export const selectFinancialState =
  createFeatureSelector<fromFinancials.FinancialsState>('financials');

export const selectSelectedFinancials = createSelector(
  selectFinancialState,
  (state) => {
    return state.companyFinancials;
  }
);

export const selectFinancialsStations = createSelector(
  selectFinancialState,
  (state) => {
    return state.companyStations;
  }
);

export const selectLoadingState = createSelector(
  selectFinancialState,
  (state) => state.status
);
