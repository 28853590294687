import { Action, createAction, props } from '@ngrx/store';

import { Settlement } from 'src/app/lib';

export const SelectSettlementsID = createAction(
  '[Settlements] Select Settlements ID',
  props<{ PK_SettlementID: string }>()
);

export const LoadAllSettlements = createAction(
  '[Settlements] Load all Settlementss'
);

export const AllSettlementsLoaded = createAction(
  '[Settlements] All Settlements loaded',
  props<{ settlements: Settlement[] }>()
);
