import { UserType } from 'src/app/user-roles';

export const WidgetCategoryTypes = [
  'Points',
  'Stations',
  'Users',
  'Financials'
] as const;

export const WidgetDataTypes = [
  'Points',
  'Points_Uptime',
  'Points_Occupancy',
  'Points_KWh',
  'Points_SessionsStarted',
  'Points_SessionsStopped',
  'Points_MaxKW',
  'Points_AvgKwWhenCharging',
  'Points_PercentageGreenEnergy',
  'Points_BoxErrors',
  'Points_PointErrors',
  'Points_SessionsWarning',
  'Points_SessionsStoppedWithoutWarning',
  'Points_TimeConnected',
  'Points_TimeCharging',
  'Points_AvgKwhPerSession',
  'Points_Income',
  'Points_SessionsStoppedWithWarning',
  'Stations',
  'Stations_KWh',
  'Stations_SessionsStarted',
  'Stations_SessionsStopped',
  'Stations_SessionsWarning',
  'Stations_BoxErrors',
  'Stations_MaxKW',
  'Stations_AvgKwhPerSession',
  'Stations_SessionsStoppedWithoutWarning',
  'Stations_TimeConnected',
  'Stations_TimeCharging',
  'Stations_Income',
  'Stations_SessionsStoppedWithWarning',
  'Stations_Uptime',
  'Stations_Occupancy',
  'Stations_PercentageGreenEnergy',
  'Stations_IncomeExVAT',
  'Stations_PointErrors'
] as const;

export const WidgetDataAggregationTypes = [
  'Sum',
  'Average',
  'Count',
  'Max',
  'Min'
] as const;

export const WidgeGranularityTypes = [
  'TotalPerGroup',
  'PerDay',
  'PerMonth',
  'PerYear'
] as const;

export const InsightsDateRangeTypes = [
  'LastDay',
  'LastWeek',
  'LastMonth',
  'LastYear',
  'Custom'
] as const;

export const FilterTypes = [
  'BoxModel', // 1
  'PointModel', // 2
  'Manufacturer', // 3
  'ConnectorType', // 4
  'IsDC', // 5
  'IsActive', // 8
  'CompanyOwner', // 23
  'Operator', // 24
  'Installer', // 25
  'PackageType', // 26
  'Station', // 27
  'Currency', // 28
  'Country', // 29
  'Points' // 30
] as const;

export const ChartTypes = [
  'Line chart',
  'Stacked line chart',
  'Stacked bar chart',
  'Doughnut',
  'Treemap',
  // "Sunburst",
  'Table'
] as const;

export const WidgetTypes = ['KPI', 'CHART_OR_TABLE'] as const;

export type WidgetCategoryType = (typeof WidgetCategoryTypes)[number];
export type WidgetDataType = (typeof WidgetDataTypes)[number];
export type WidgetDataAggregationType =
  (typeof WidgetDataAggregationTypes)[number];
export type WidgeGranularityType = (typeof WidgeGranularityTypes)[number];
export type FilterType = (typeof FilterTypes)[number];
export type ChartType = (typeof ChartTypes)[number];
export type WidgetType = (typeof WidgetTypes)[number];
export type InsightsDateRangeType = (typeof InsightsDateRangeTypes)[number];

export type FilterValueType = number[] | string[] | boolean;

export interface WidgetConfig {
  dataType: WidgetDataOption;
  aggregationType: WidgetDataAggregationType;
  granularityType: WidgeGranularityType;
  filters: FilteringOption[];
  groupedBy: GroupingOption[];
  chartType: ChartType;
  fromDate: Date;
  toDate: Date;
  dateRangeType: InsightsDateRangeType;
  widgetType: WidgetType;
  position?: number;
  name?: string;
}

export interface WidgetDataOption {
  Category: WidgetCategoryType;
  DataType: WidgetDataType;
  ValueType?: InsightsValueType;
  AggregationsPossible?: WidgetDataAggregationType[];
  GranularitiesPossible?: WidgeGranularityType[];
  DateMin?: Date;
}
export interface FilteringOption {
  FilteringOption: FilterType;
  TypeName: string;
  Values?: FilterValueType;
}

export interface GroupingOption {
  GroupingOption: string;
}
export interface WidgetCategory {
  Category: WidgetCategoryType;
  DataOptions: WidgetDataOption[];
  FiltersPossible: FilteringOption[];
  GroupingPossible: GroupingOption[];
}

export interface Restrictions {
  MaxDaysAllowed_PerDay: number;
  MaxDaysAllowed_PerMonth: number;
  MaxDaysAllowed_PerYear: number;
}

export interface AvailableWidgetOptions {
  Categories: WidgetCategory[];
  Restrictions: Restrictions;
}

export interface InsightsRequest {
  DataType: string;
  Aggregation: WidgetDataAggregationType;
  Granularity: WidgeGranularityType;
  DateRangeType: InsightsDateRangeType;
  StartDate: Date;
  EndDate: Date;
  Filters: { FilterType: FilterType; FilterValue: FilterValueType }[];
  GroupedBy: string[];
}

export interface GroupData {
  GroupName: string;
  Value: any;
  Currency: string;
}

export interface DataPerDate {
  DateLocal: Date;
  Groups: GroupData[];
}

export enum InsightsValueType {
  Currency = 0,
  Amount = 1,
  KWH = 2,
  KW = 3,
  Seconds = 4,
  Percentage = 5,
  Minutes = 6
}

export interface InsightsResponse {
  DataPerDate: DataPerDate[];
  ValueType: InsightsValueType;
}

export interface WidgetDashboard {
  DateCreated?: Date;
  DateUpdated?: Date;
  FK_UserID?: number;
  Name: string;
  PK_DashboardID?: number;
  Widgets?: WidgetConfig[];
}

export interface CreateDashboardResponse {
  success: boolean;
  datas: WidgetDashboardDB;
}

export interface WidgetDashboardDB {
  PK_DashboardID: number;
  FK_UserID?: number;
  DateCreated?: Date;
  DateUpdated?: Date;
  Name?: string;
  Widgets?: WidgetDBConfig[];
}

export interface WidgetDBConfig {
  dataType: string;
  aggregationType: string;
  granularityType: string;
  filters: string[];
  groupedBy: string[];
  chartType: string;
  fromDate: string;
  toDate: string;
  quickDate: string;
  widgetType: string;
  position?: number;
  name?: string;
}

export interface WidgetTemplate {
  templateID: number;
  templateName: string;
  canSee: UserType[];
  widget: WidgetConfig;
}

export interface WidgetTemplateData {
  MaxDaysAllowed?: number;
  Restrictions: Restrictions;
}
