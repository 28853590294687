import { ActionReducerMap } from '@ngrx/store';

import * as fromCompanies from './companies/companies.reducer';
import * as fromUser from './user/user.reducer';
import * as fromCountries from './countries/countries.reducer';
import * as fromOperatorPackage from './operator-package/operator-package.reducer';
import * as fromOperator from './operator/operator.reducer';
import * as fromSettlements from './settlements/settlements.reducer';
import * as fromStations from './stations/station.reducer';
import * as fromFinancials from './financials/financials.reducer';
import * as fromCustomer from './customer/customer.reducer';
import * as fromInsights from './insights/insights.reducer';
import * as fromRoamingPlatforms from './roaming-platform/roaming-platform.reducer';
import * as fromManufacturers from './manufacturers/manufacturers.reducer';

// Updated the shape of the entire application state
export interface AppState {
  companies: fromCompanies.CompaniesState;
  user: fromUser.UserState;
  countries: fromCountries.CountriesState;
  operatorPackage: fromOperatorPackage.OperatorPackageState;
  operators: fromOperator.OperatorState;
  settlements: fromSettlements.SettlementsState;
  stations: fromStations.StationState;
  financials: fromFinancials.FinancialsState;
  customer: fromCustomer.CustomerState;
  insights: fromInsights.InsightsState;
  roamingPlatform: fromRoamingPlatforms.RoamingPlatformState;
  manufacturers: fromManufacturers.ManufacturersState;
}

// Add in feature reducer into combined reducer
export const reducers: ActionReducerMap<AppState> = {
  companies: fromCompanies.companiesReducer,
  user: fromUser.userReducer,
  countries: fromCountries.companiesReducer,
  operatorPackage: fromOperatorPackage.operatorPackageReducer,
  settlements: fromSettlements.settlementsReducer,
  operators: fromOperator.operatorReducer,
  stations: fromStations.stationReducer,
  financials: fromFinancials.financialsReducer,
  customer: fromCustomer.customerReducer,
  insights: fromInsights.insightsReducer,
  roamingPlatform: fromRoamingPlatforms.roamingPlatformReducer,
  manufacturers: fromManufacturers.manufacturersReducer
};
