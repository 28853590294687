
import * as RoamingPlatFormActions from './roaming-platform.actions';
import { LoadingStatus } from '../..';
import { createReducer, on } from '@ngrx/store';
import { RoamingPlatform } from './types';

export interface RoamingPlatformState {
  roamingPlatforms: RoamingPlatform[];
  status: LoadingStatus;
}

export const initialState: RoamingPlatformState = {
  roamingPlatforms: [],
  status: LoadingStatus?.NotLoaded
};

export const roamingPlatformReducer = createReducer(
  initialState,
  on(RoamingPlatFormActions.LoadRoamingPlatforms, (state) => {
    return {
      ...state,
      status: LoadingStatus.Loading
    };
  }),
  on(RoamingPlatFormActions.LoadRoamingPlatformsLoaded, (state, { data }) => {
    return {
      ...state,
      status: LoadingStatus.Loaded,
      roamingPlatforms: data
    };
  })
);
