import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import {
  OperatorPackageState,
  selectAllOperatorPackage,
  selectIsLoading
} from '.';

import * as OperatorPackageActions from './operator-package.actions';
import { ClientOperatorPackage, OperatorPackage } from '../../operator-package';
import { AppState } from '..';
import {
  selectIsModalOpen,
  selectOperatorPackagesForSelectedCountry,
  selectSelectedCountryID,
  selectSelectedOperatorPackage
} from './operator-package.selectors';

@Injectable({
  providedIn: 'root'
})
export class OperatorPackageFacade {
  allOperatorPackages$ = this.store.pipe(
    tap(({ operatorPackage }) => {
      if (!operatorPackage.isLoading && !operatorPackage.isFetched) {
        this.getAll();
      }
    }),
    select(selectAllOperatorPackage)
  );

  operatorPackagesForSelectedCountry$ = this.store.pipe(
    select(selectOperatorPackagesForSelectedCountry)
  );

  selectedOperatorPackage$ = this.store.pipe(
    select(selectSelectedOperatorPackage)
  );

  selectedCountryID$ = this.store.pipe(select(selectSelectedCountryID));
  isModalOpen$ = this.store.pipe(select(selectIsModalOpen));
  isLoading$ = this.store.pipe(select(selectIsLoading));

  constructor(private store: Store<AppState>) {}

  getAll() {
    this.store.dispatch(OperatorPackageActions.LoadAllOperatorPackage());
  }

  getAndSelectOperatorPackage(PK_OperatorPackageID: number) {
    this.getOperatorPackage(PK_OperatorPackageID);
    this.selectOperatorPackage(PK_OperatorPackageID);
  }

  getOperatorPackage(PK_OperatorPackageID: number) {
    this.store.dispatch(
      OperatorPackageActions.LoadOperatorPackage({ PK_OperatorPackageID })
    );
  }

  selectOperatorPackage(PK_OperatorPackageID: number) {
    this.store.dispatch(
      OperatorPackageActions.SelectOperatorPackageID({ PK_OperatorPackageID })
    );
  }

  createOperatorPacakge(operatorPackage: OperatorPackage) {
    return this.store.dispatch(
      OperatorPackageActions.CreateOperatorPackage({ operatorPackage })
    );
  }

  openModal() {
    this.store.dispatch(OperatorPackageActions.OpenModal());
  }

  updateOperatorPacakgeSuccess(operatorPackage: OperatorPackage) {
    return this.store.dispatch(
      OperatorPackageActions.UpdateOperatorPackageSuccess({ operatorPackage })
    );
  }

  closeModal() {
    this.store.dispatch(OperatorPackageActions.CloseModal());
  }

  setSelectedCountryID(PK_CountryID: number) {
    this.store.dispatch(
      OperatorPackageActions.SetSelectedCountryID({ PK_CountryID })
    );
  }
}
