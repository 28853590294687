export const ChargingProfilePurposes = [
  'ChargingStationExternalConstraints',
  'ChargingStationMaxProfile',
  'TxDefaultProfile',
  'TxProfile'
];

export const ChargingLimitSources = [
  'EMS',
  'Other',
  'SO',
  'SO'
];
type ChargingProfilePurpose = (typeof ChargingProfilePurposes)[number];
type ChargingLimitSource = (typeof ChargingLimitSources)[number];

export const ChargingProfileKinds = ['Absolute', 'Recurring', 'Relative'];
type ChargingProfileKind = (typeof ChargingProfileKinds)[number];

export const RecurrencyKinds = ['Daily', 'Weekly'];
type RecurrencyKind = (typeof RecurrencyKinds)[number];

export const ChargingRateUnits = ['A', 'W'];
type ChargingRateUnit = (typeof ChargingRateUnits)[number];

export const CostKinds = ['CarbonDioxideEmission', 'RelativePricePercentage', 'RenewableGenerationPercentage'];
type CostKind = (typeof CostKinds)[number];

export class ChargingProfile {
  /**
   * Required. Master resource identifier, unique within an exchange context. It is defined within the OCPP context as a positive Integer value (greater or equal to zero).
   */
  id?: number;

  /**
  * Required. Value determining level in hierarchy stack of profiles. Higher values have precedence over lower values. Lowest level is 0.
  */
  stackLevel: number;

  /**
   * Optional. Indicates whether the limit schedule is the primary limit schedule or an alternative limit schedule that can be used during negotiation according to ISO 15118
   */
  primary: boolean;

  /**
   * Required. Defines the purpose of the schedule transferred by this message.
   */
  chargingProfilePurpose: ChargingProfilePurpose;

  /**
   * Required.Indicates the kind of schedule.
   */
  chargingProfileKind: ChargingProfileKind;

  /**
  * Optional. Indicates the start point of a recurrence.
  */
  recurrencyKind?: RecurrencyKind;

  /**
  * Optional. Point in time at which the profile starts to be valid. If absent, the profile is valid as soon as it is received by the Charge Point. Not to be used when ChargingProfilePurpose is TxProfile.
  */
  validFrom?: string;

  /**
  * Optional. Point in time at which the profile stops to be valid. If absent, the profile is valid until it is replaced by another profile. Not to be used when ChargingProfilePurpose is TxProfile.
  */
  validTo?: string;

  /**
   * Optional. Only valid if ChargingProfilePurpose is set to TxProfile, the transactionId MAY be used to match the profile to a specific transaction.
   */
  transactionId?: number;

  /**
   * Required. Contains limits for the available power or current over time.
   */
  chargingSchedule: ChargingSchedule[];

  constructor(profile?: ChargingProfile) {
    if (profile) {
      Object.assign(this, profile);
    }
  }
}

export class ChargingProfileCriterion {
  chargingProfilePurpose?: ChargingProfilePurpose;
  stackLevel?: number;
  chargingProfileId?: number[];
  chargingLimitSource?: ChargingLimitSource[]
}

class ChargingSchedule {

  id?: number
  /**
   * Optional. Duration of the charging schedule in seconds. If the duration is left empty, the last period will continue indefinitely or until end of the transaction in case startSchedule is absent.
   */
  duration?: number;
  /**
   * Optional. Starting point of an absolute schedule. If absent the schedule will be relative to start of charging.
   */
  startSchedule?: string;
  /**
   * Required. The unit of measure Limit is expressed in.
   */
  chargingRateUnit: ChargingRateUnit;
  /**
   * Required.List of ChargingSchedulePeriod elements defining maximum power or current usage over time
   */
  chargingSchedulePeriod: ChargingSchedulePeriod[];
  /**
   * Optional. Minimum charging rate supported by the electric vehicle. The unit of measure is defined by the chargingRateUnit. This parameter is intended to be used by a local smart charging algorithm to optimize the power allocation for in the case a charging process is inefficient at lower charging rates. Accepts at most one digit fraction (e.g. 8.1)
   */
  minChargingRate?: number;

  salesTariff?: SalesTariff
}

class ChargingSchedulePeriod {
  /**
   * Required. Start of the period, in seconds from the start of schedule. The value of StartPeriod also defines the stop time of the previous period.
   */
  startPeriod: number;
  /**
   * Required. Power limit during the schedule period, expressed in Amperes. Accepts at most one digit fraction (e.g. 8.1).
   */
  limit: number;
  /**
   * Optional. The number of phases that can be used for charging. If a number of phases is needed, numberPhases=3 will be assumed unless another number is given.
   */
  numberPhases?: number;
  /**
   * Optional. Values: 1..3, Used if numberPhases=1 and if the EVSE is capable of switching the phase connected to the EV,
   */
  phaseToUse?: number;
}

class SalesTariff {
  id?: number;
  salesTariffDescription?: string;
  numEPriceLevels?: number;
  salesTariffEntry?: SalesTariffEntry[]
}

class SalesTariffEntry {
  ePriceLevel?: number;
  relativeTimeInterval?: RelativeTimeInterval;
  consumptionCost: ConsumptionCost[]
}

class RelativeTimeInterval {
  start?: number;
  duration?: number;
}

class ConsumptionCost {
  startValue?: number
  cost?: CostType[]
}

class CostType {
  costKind?: CostKind
  amount?: number
  amountMultiplier?: number
}
