import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'sc-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  public title: string;
  public list: any[] = [];
  public viewProperty: string;
  public okButton: string = 'Ok';
  public cancelButton: string = 'Cancel';
  public placeholder: string = '';
  selectedItem: any;
  searchCtrl: FormControl;
  filteredItems: any = [];

  constructor(public dialogRef: MatDialogRef<SelectComponent>) {
    if (!this.placeholder) this.placeholder = this.title;
  }

  ngOnInit() {
    this.searchCtrl = new FormControl();

    this.filteredItems = this.searchCtrl.valueChanges

      .pipe(startWith(null))
      .pipe(
        map((item) =>
          item && typeof item === 'object' ? item[this.viewProperty] : item
        )
      )
      .pipe(map((searchVal) => this.filterItems(searchVal)));
  }
  select(ev) {
    if (!ev || !ev.option || !ev.option.value) return;
    this.selectedItem = ev.option.value;

    //this.dialogRef.close(this.selectedItem)
  }
  save() {
    if (!this.selectedItem) return;
    this.dialogRef.close(this.selectedItem);
  }
  filterItems(searchVal: string) {
    if (!searchVal) return this.list;
    return this.list.filter(
      (item) =>
        item[this.viewProperty] &&
        item[this.viewProperty]
          .toLowerCase()
          .indexOf(searchVal.toLowerCase()) === 0
    );
  }
  displayFn(item: any): string {
    return item ? item[this.viewProperty] : item;
  }
}
