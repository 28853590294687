import { createAction, props } from '@ngrx/store';

export const LoadCompanyFinancials = createAction(
  '[financials] Load finances for company',
  props<{ PK_CompanyID: number; startTime: number; endTime: number }>()
);

// define type
export const CompanyFinancialsLoaded = createAction(
  '[financials] finances for company loaded',
  props<{ data: any }>()
);

export const CompanyFinancialsLoadFailed = createAction(
  '[financials] finances for company API failed',
  props<{ error: any }>()
);

export const SelectedCurrencyChanged = createAction(
  '[financials] selected currency changed',
  props<{ currencyID: number }>()
);

export const RestsetFinancialState = createAction('[financials] Reset state');
