import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

type Sheet = {
  data: any[];
  name: string;
  print: any[];
};

@Injectable()
export class ExportService {
  constructor() {}

  exportCSV(data, name, print?: Array<any>, seperator = ',') {
    if (!data || !data.length) return null;

    if (print) data = this.modifyArray(data, print);

    let count = 0;
    let keys = Object.keys(data[0]);
    let result: any = keys.join(seperator);

    result += '\n';

    data.forEach((item) => {
      count = 0;
      keys.forEach((key) => {
        if (count > 0) result += seperator;
        if (item[key] !== null && item[key] !== undefined) {
          result += String(item[key]).replace(/[,\s]/gi, ' ');
        }
        count++;
      });
      result += '\n';
    });

    let file = new Blob(['\ufeff', result], { type: 'text/csv;charset=UTF-8' });
    saveAs(file, name + '.csv');
    return result;
  }
  exportXLSX(data: any, name: string, print?: Array<any>) {
    if (!data || !data.length) return null;

    if (print) data = this.modifyArray(data, print);

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    // generate workbook and add the worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws);

    // save to file
    XLSX.writeFile(wb, name + '.xlsx');
  }

  simpleExcelExport = (data: any, name: string, dateColumnNames?: string[]) => {
    if (!data || !data.length) return null;
    if (dateColumnNames?.length) {
      // modify for date.
      data.map((item) => {
        dateColumnNames.forEach((dateColumKey) => {
          item[dateColumKey] = item[dateColumKey]
            ? new Date(item[dateColumKey])
            : item[dateColumKey];
        });
        return item;
      });
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    // generate workbook and add the worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws);

    // save to file
    XLSX.writeFile(wb, name + '.xlsx');
  };
  exportXLSXMultipleSheets(
    sheets: { data: any; print?: any[]; name?: string }[],
    name: string
  ) {
    if (!sheets || !sheets.length) return;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    for (let sheet of sheets) {
      if (sheet.print) sheet.data = this.modifyArray(sheet.data, sheet.print);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheet.data);

      XLSX.utils.book_append_sheet(wb, ws, sheet.name);
    }
    // save to file
    XLSX.writeFile(wb, name + '.xlsx');
  }
  exportJSON(data, name, print?: any[]) {
    if (!data || !data.length) return null;
    if (print) data = this.modifyArray(data, print);
    let jsonString = JSON.stringify(data);
    let file = new Blob([jsonString], { type: 'application/json' });
    saveAs(file, name + '.json');
  }
  renameProperties(newNames, oldNames, data) {
    let newArray = [];
    for (let obj of data) {
      let newObject = {};
      for (let i = 0; i < newNames.length; i++) {
        if (obj.hasOwnProperty(oldNames[i])) {
          newObject[newNames[i]] = obj[oldNames[i]];
        }
      }
      newArray.push(newObject);
    }
    return newArray;
  }
  modifyArray(data, print) {
    let modifiedArray = [];
    for (let obj of data) {
      let newObject = {};

      for (let key of print) {
        if (obj.hasOwnProperty(key)) {
          newObject[key] = obj[key];
        }
      }
      modifiedArray.push(newObject);
    }
    return modifiedArray;
  }
  exportHTMLTable(table: HTMLTableElement, name: string) {
    const result = XLSX.utils.table_to_book(table);
    XLSX.writeFile(result, name + '.xlsx');
  }
}
