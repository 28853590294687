import { Injectable } from '@angular/core';
import { UserType, UserTypeStrings } from 'src/app/user-roles';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OperatorAccess } from 'src/app/classes/OperatorAccess';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  operatorAccess: any = OperatorAccess;
  accessLevel: string;

  constructor() {}

  /**
   * Check high level access like _Operator_, _Location owner_ and _Box manufacturer_
   *
   * @param requiredRole User need to have this role(s) in order to get access.
   * @param exact User has to be exactly the _requiredRole_. Otherwise it will only check if it is included
   */
  hasHighlevelAccess(
    requiredRole: UserType | UserType[],
    exact: boolean = false
  ) {
    //If no specific role is required we allow access
    if (!requiredRole) return true;

    let userType = this.getUserType();

    //Check if usertype exists and if it is a valid type
    if (!userType || typeof userType !== 'number') return false;

    if (Array.isArray(requiredRole)) {
      //Check if either of the roles has access
      return !!requiredRole.filter((singleRole) =>
        this.checkSingleAccess(singleRole, userType, exact)
      ).length;
    } else {
      return this.checkSingleAccess(requiredRole, userType, exact);
    }
  }
  /**
   * Boolean object with all highLevel roles
   */
  getHighLevelAccess() {
    //Not done. Planned to loop the usertypes and check each against the actual usertype
  }
  private checkSingleAccess(
    requiredRole: UserType | UserTypeStrings,
    userType,
    exact: boolean = false
  ) {
    if (typeof requiredRole == 'string') {
      //Convert to enum if string type is provided
      requiredRole = UserType[requiredRole];
    }

    //Checks the access
    if (exact) {
      //Checks exact match
      return userType == requiredRole;
    } else {
      //Checks if included
      return (userType & requiredRole) == requiredRole;
    }
  }
  getUserType(): any {
    let token: any = localStorage.getItem('token');
    if (!token) return false;
    try {
      token = helper.decodeToken(token);
      if (!token.UserType) return false;

      //FIX: Temporary to allow old UserTypes. Remove when both backend and frontend is synced
      if (isNaN(parseInt(token.UserType)))
        token.UserType = UserType[token.UserType];

      return parseInt(token.UserType);
    } catch (err) {
      //Could not decode token, by default we don't give access
      return false;
    }
  }

  getOperators(): number[] {
    let token: any = localStorage.getItem('token');
    if (!token) return [];
    try {
      token = helper.decodeToken(token);
      if (!token.UserType) return [];
      return token.Operators.split(',').map((id: string) => Number(id));
    } catch (err) {
      return [];
    }
  }
}
