import { Injectable } from '@angular/core';
import { AppState } from '..';
import { Store, select } from '@ngrx/store';

import * as CompaniesActions from '../companies/companies.actions';
import * as ManufacturersActions from './manufacturers.actions';
import {
  selectAllManufacturersAndCompanies,
  selectIsLoading,
  selectSearchedManufacturers
} from './manufacturers.selector';

@Injectable({ providedIn: 'root' })
export class ManufacturersFacade {
  allManufacturers$ = this.store.select(selectAllManufacturersAndCompanies);
  isLoading$ = this.store.select(selectIsLoading);
  searchedManufacturers$ = this.store.pipe(select(selectSearchedManufacturers));

  constructor(private store: Store<AppState>) {}

  getAll() {
    this.store.dispatch(CompaniesActions.LoadCompanies({ refresh: false }));
    this.store.dispatch(ManufacturersActions.LoadAllManufacturers());
  }

  search(query: string) {
    this.store.dispatch(ManufacturersActions.SetSearchQuery({ query }));
  }
}
