import { Injectable } from '@angular/core';
//import { tokenNotExpired, JwtHelper } from 'angular2-jwt';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

import { Defaults } from '../../../defaults';
import { Router } from '@angular/router';
import { AES } from 'jscrypto/es6/AES';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

const helper = new JwtHelperService();

@Injectable()
export class TokenService {
  token: string;
  refreshing: any;

  constructor(
    //private jwtHelper: JwtHelper,
    private http: HttpClient,
    private defaults: Defaults,
    private router: Router,
    private translate: TranslateService
  ) {}
  check(noredirect?: boolean): Observable<boolean> {
    let permissions = localStorage.getItem('ac');
    if (!this.isExpired(this.token) && permissions) {
      //Token not expired, return true
      return Observable.create((observer) => {
        observer.next(true);
        observer.complete();
      });
    } else if (!permissions) {
      return Observable.create((observer) => {
        observer.next(false);
        observer.complete();
        this.logout();
      });
    } else {
      return Observable.create((observer) => {
        this.refresh(noredirect).then(
          (token) => {
            observer.next(true);
            observer.complete();
          },
          (err) => {
            observer.next(false);
            observer.complete();
          }
        );
      });
    }
  }
  /**
   * Returns a new token if the old is expired
   */
  checkAndGetNewToken(): Observable<string> {
    let permissions = localStorage.getItem('ac');
    if (!this.isExpired(this.token) && permissions) {
      //Token not expired
      return Observable.create((observer) => {
        observer.next();
        observer.complete();
      });
    } else if (!permissions) {
      //No permissions, should not have access
      return Observable.create((observer) => {
        observer.next(false);
        observer.complete();
        this.logout();
      });
    } else {
      //Token expired
      return Observable.create((observer) => {
        this.refresh().then(
          (token) => {
            //New token
            observer.next(token);
            observer.complete();
          },
          (err) => {
            //Could not refresh
            observer.next(false);
            observer.complete();
          }
        );
      });
    }
  }
  getToken() {
    let token = localStorage.getItem('token');
    try {
      return helper.decodeToken(token);
    } catch {
      return null;
    }
  }
  refresh(noredirect?: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem('token');
      if (!this.isExpired(token)) {
        //Token not expired, no need to refresh
        this.token = token;
        resolve(token);
      } else {
        //Token expired, refresh token
        this.doRefresh().then(
          (res) => {
            resolve(res);
          },
          (err: TokenError) => {
            if (err.prompt_login) {
              this.logout();
            }
            reject(err);
          }
        );
      }
    });
  }
  isExpired(token): boolean {
    if (token && !helper.isTokenExpired(token)) {
      //!this.jwtHelper.isTokenExpired(token)){
      //Token not expired
      return false;
    } else {
      //Token expired
      return true;
    }
  }
  logout() {
    this.clearToken();

    this.router.navigate(['auth/login']);
  }
  clearToken() {
    this.token = null;
    localStorage.removeItem('aaToken');
    localStorage.removeItem('token');
    localStorage.removeItem('rToken');
  }
  clear() {
    this.token = null;
  }
  refreshToken(): Promise<any> {
    return new Promise((resolve) => {
      if (this.refreshing) {
        resolve(this.refreshing);
      } else {
        this.refreshing = this.doRefresh();

        resolve(this.refreshing);
      }
    });
  }
  private refreshAuthAs() {}
  private doRefresh(): Promise<any> {
    return new Promise((resolve, reject) => {
      let rToken = localStorage.getItem('rToken');
      let appID = localStorage.getItem('appID');
      let aaToken = localStorage.getItem('aaToken');
      if (aaToken) {
        // this.refreshAuthAs(aaToken);
      }
      if (rToken) {
        let data = {
          appID: appID,
          rToken: rToken
        };
        let options = { headers: {} };

        options.headers['Cache-Control'] = 'no-cache';
        options.headers['Pragma'] = 'no-cache';

        this.http
          .post<any>(
            this.defaults.base +
              'Security/RefreshAccessTokenDashboard?n=' +
              new Date().getTime(),
            data,
            options
          )
          //.pipe(map(res=>res.json()))
          .subscribe(
            (res) => {
              let newToken = res.Result.datas;
              if (!this.isExpired(newToken)) {
                localStorage.setItem('token', newToken);
                this.token = newToken;
                this.refreshAccess(rToken);
                resolve(newToken);
              } else {
                //Hopefully this won't happen, but if it does, the user must log in again
                reject(
                  new TokenError(
                    true,
                    this.translate.instant('USER_NOT_AUTHENTICATED')
                  )
                );
              }
            },
            (err) => {
              if (err.error != null && err.error.Message == 'wrongParameter') {
                reject(
                  new TokenError(
                    true,
                    this.translate.instant('USER_NOT_AUTHENTICATED')
                  )
                );
              }
            }
          );
      } else {
        reject(
          new TokenError(true, this.translate.instant('USER_NOT_AUTHENTICATED'))
        );
      }
    });
  }
  refreshAccess(rToken) {
    //Get new access/roles/permissions when token is refreshed
    let options = { headers: {} };

    options.headers['Cache-Control'] = 'no-cache';
    options.headers['Authorization'] =
      'Bearer ' + localStorage.getItem('token');

    this.http
      .get<any>(this.defaults.base + 'UserRoles/RolesOfAUser', options)
      //.pipe(map(res=>res.json()))
      .subscribe((res) => {
        let permissions = res.Result.datas;
        permissions = AES.encrypt(JSON.stringify(permissions), rToken);
        localStorage.setItem('ac', permissions);
      });
  }
  private checkValidity(token): Promise<any> {
    return new Promise((resolve, reject) => {
      //let decodedToken = this.jwtHelper.decodeToken(token);
      //Validate token
      //If not valid: remove token and refresh()
      //localStorage.setItem('type', decodedToken.role);
    });
  }
}
export class TokenError {
  prompt_login: boolean;
  message: string;
  constructor(_login?, _message?) {
    this.prompt_login = _login;
    this.message = _message;
  }
}
