import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Company } from '../..';
import { emptyCompany } from '../../companies/companies.model';

import * as fromCompanies from './companies.reducer';

export const selectCompaniesState =
  createFeatureSelector<fromCompanies.CompaniesState>('companies');

export const selectProjectIds = createSelector(
  selectCompaniesState,
  fromCompanies.selectCompaniesIds
);

export const selectCompaniesEntities = createSelector(
  selectCompaniesState,
  fromCompanies.selectCompaniesEntities
);

export const selectAllCompanies = createSelector(
  selectCompaniesState,
  fromCompanies.selectAllCompanies
);

export const selectIsLoading = createSelector(
  selectCompaniesState,
  (state) => state.isLoading
);

export const selectSearchQuery = createSelector(
  selectCompaniesState,
  (state) => state.query
);

export const selectSelectedCompany = createSelector(
  selectCompaniesEntities,
  fromCompanies.getSelectedCompanyId,
  (companies, selectedCompanyId): Company => {
    return selectedCompanyId ? companies[selectedCompanyId] : emptyCompany;
  }
);

export const selectIsSingleLoading = createSelector(
  selectCompaniesState,
  fromCompanies.getIsSingleLoading
);

export const selectSelectedCompanyPaymentMethods = createSelector(
  selectCompaniesState,
  fromCompanies.getSelectedCompanyId,
  (state, id) => {
    return state.paymentMethods[id];
  }
);

export const selectSelectedCompanyPaymentMethodsIsLoading = createSelector(
  selectCompaniesState,
  fromCompanies.getSelectedCompanyId,
  (state, id) => {
    return state.paymentMethodsLoading[id];
  }
);

export const selectSearchedCompanies = createSelector(
  selectAllCompanies,
  selectSearchQuery,
  (companies, query) => {
    if (!query) {
      return companies;
    }
    return companies.filter((company) => {
      if (
        company.PK_CompanyID &&
        company.PK_CompanyID.toString().indexOf(query.toString()) > -1
      ) {
        return company;
      }
      if (
        company.Name &&
        company.Name.toLowerCase().indexOf(query.toLowerCase()) > -1
      ) {
        return company;
      }
      if (
        company.OwnerEmail &&
        company.OwnerEmail.toLowerCase().indexOf(query.toLowerCase()) > -1
      ) {
        return company;
      }
      if (
        company.OwnerFirstName &&
        company.OwnerFirstName.toLowerCase().indexOf(query.toLowerCase()) > -1
      ) {
        return company;
      }
      if (
        company.OwnerLastName &&
        company.OwnerLastName.toString().indexOf(query) > -1
      ) {
        return company;
      }
      if (
        company.Email &&
        company.Email.toString().toLowerCase().indexOf(query.toLowerCase()) > -1
      ) {
        return company;
      }
      if (
        company.InvoiceEmail &&
        company.InvoiceEmail.toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) > -1
      ) {
        return company;
      }

      if (company.OwnerFirstName && company.OwnerLastName) {
        const fullName = company.OwnerFirstName + ' ' + company.OwnerLastName;
        const newVal = query.replace(/\s\s+/g, ' ');
        if (fullName.toLowerCase().indexOf(newVal.toLowerCase()) > -1) {
          return company;
        }
      }
    });
  }
);

export const selectShouldShowAddPaymentModal = createSelector(
  selectCompaniesState,
  fromCompanies.getShouldShowAddPaymentModal
);

export const selectCanInvoice = createSelector(
  selectSelectedCompany,
  (company) => {
    return company.FK_CountryID === 1;
  }
);
