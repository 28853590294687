import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from './user.reducer';

export const selectUsersState =
  createFeatureSelector<fromUser.UserState>('user');

export const selectUser = createSelector(
  selectUsersState,
  (state: fromUser.UserState) => state.user
);

export const selectUserPreferences = createSelector(
  selectUsersState,
  (state: fromUser.UserState) => state.preferences
);
