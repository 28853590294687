import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { SuperSearchModule } from '../super-search/super-search.module';
import { StationSearchDialogSimpleComponent } from './station-search-dialog-simple/station-search-dialog-simple.component';
import { StationSearchDialogComponent } from './station-search-dialog/station-search-dialog.component';
import { StationSearchComponent } from './station-search.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDialogModule,
    SuperSearchModule,
    DirectivesModule
  ],
  declarations: [
    StationSearchComponent,
    StationSearchDialogComponent,
    StationSearchDialogSimpleComponent
  ],
  exports: [StationSearchComponent]
})
export class StationSearchModule {}
