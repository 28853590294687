import { Pipe, PipeTransform } from '@angular/core';

//Returns the total kW for a list of points
@Pipe({
  name: 'calculatePower'
})
export class CalculatePowerPipe implements PipeTransform {
  transform(points: any, type: string): any {
    if (!points || !type) return null;

    if (type === 'kW') {
      let totalkW = 0;
      points.forEach((point) => {
        if (!isNaN(point.LivekW)) {
          totalkW += point.LivekW;
        }
      });
      return totalkW;
    }
    if (type === 'amp') {
      let totalAmp = 0;
      points.forEach((point) => {
        if (!isNaN(point.LiveAmps)) {
          totalAmp += point.LiveAmps;
        }
      });

      return totalAmp;
    }
  }
}
