<h2 mat-dialog-title>
  {{ single ? ('SELECT_DATE' | translate) : ('SELECT_DATES' | translate) }}
</h2>
<div mat-dialog-content class="dialog-content">
  <p *ngIf="message">{{ message }}</p>
  <mat-form-field>
    <mat-label>
      {{ single ? ('DATE' | translate) : ('FROM' | translate) }}
    </mat-label>
    <input
      matInput
      (click)="picker.open()"
      [(ngModel)]="fromDate"
      name="fromDate"
      [matDatepicker]="picker" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field *ngIf="!single">
    <mat-label>{{ 'TO' | translate }}</mat-label>
    <input
      matInput
      (click)="picker2.open()"
      [(ngModel)]="toDate"
      name="toDate"
      [matDatepicker]="picker2" />
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="close()">
    {{ okButton }}
  </button>
  <button mat-raised-button (click)="dialogRef.close()">
    {{ cancelButton }}
  </button>
</div>
