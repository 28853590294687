import { createAction, props } from '@ngrx/store';
import { RoamingPlatform } from './types';

export const LoadRoamingPlatforms = createAction(
  '[RoamingPlatform] Load roaming platforms'
);

export const LoadRoamingPlatformsLoaded = createAction(
  '[RoamingPlatform] Roaming platforms loaded',
  props<{ data: RoamingPlatform[] }>()
);

