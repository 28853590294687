import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from '../dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GroupService {
  private group: any;
  private groups: any;
  constructor(
    private _http: HttpInterceptorService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialogService: DialogService,
    private translate: TranslateService
  ) {}

  add(group): Promise<any> {
    return new Promise((resolve) => {
      this._http.post('Groups/PostGroup', group).subscribe(
        (res) => {
          resolve(res.datas);
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }
  clear() {
    this.group = null;
    this.groups = null;
  }
  openGroup(group, edit?) {
    this.group = group;
    edit = edit || 'group';
    this.router.navigate(['groups', edit, group.PK_GroupID]);
  }
  delete(group): Promise<any> {
    return new Promise((resolve) => {
      this.dialogService
        .prompt(
          this.translate.instant('CONFIRM_DELETE_GROUP'),
          null,
          this.translate.instant('DELETE')
        )
        .subscribe((doDelete) => {
          if (doDelete) {
            this._http
              .delete('Groups/DeleteGroup/' + group.PK_GroupID)
              .subscribe(
                (res) => {
                  resolve(res);
                },
                (err) => {
                  this.snackBar.open(
                    this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                      errorName: err.errorName
                    }),
                    this.translate.instant('CLOSE')
                  );
                  resolve(false);
                }
              );
          } else {
            resolve(false);
          }
        });
    });
  }
  get(id, reload?): Promise<any> {
    return new Promise((resolve) => {
      if (!reload && this.group && this.group.PK_GroupID === id) {
        resolve(this.group);
      } else {
        this._http.get('Groups/' + id).subscribe((group) => {
          if (group) {
            this.group = group;
            resolve(group);
          } else {
            resolve(null);
          }
        });
      }
    });
  }
  getGroup(PK_GroupID: string) {
    return this._http.get(`Groups/${PK_GroupID}`);
  }
  update(group): Promise<any> {
    return new Promise((resolve) => {
      this._http.put('Groups/PutGroup', group).subscribe(
        (res) => {
          resolve(true);
        },
        (err) => {
          this.snackBar.open(
            this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            }),
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }

  getGroupsVisible(index = 0, limit = 10): Promise<any> {
    return new Promise((resolve) => {
      //this.userService.getUser().then(user=>{
      //'Groups/GroupsVisibleForAUser/'+user.FK_UserID

      this._http
        .get('Groups/GroupsVisibleForAUser/' + index + '/' + limit)
        .subscribe(
          (groups) => {
            if (groups) {
              resolve(groups);
            } else {
              resolve([]);
            }
          },
          (err) => {
            resolve([]);
          }
        );
      // })
    });
  }
  addUser(userObject: any) {
    return this._http.post('Groups/AddCustomerToGroupFromEmail/', userObject);
  }
  deleteUser(customerID, groupID): Promise<any> {
    return new Promise((resolve) => {
      this._http
        .get('Groups/RemoveCustomerFromGroup/' + groupID + '/' + customerID)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getGroupsByCustomer(customerId: number) {
    return this._http.get(`Groups/GroupsOfCustomer/${customerId}`);
  }
  getSupportedLanguages() {
    return this._http.get('Users/GetSupportedLanguages');
  }
}
