import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { LogoutUser } from './user.actions';
import * as UserActions from './user.actions';

import { UserService } from '../../user/user.service';

@Injectable({ providedIn: 'root' })
export class UserEffects {
  logoutUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.LogoutUser),
      mergeMap(() =>
        this.userService.logoutUser().pipe(
          map(() => LogoutUser()),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(private actions$: Actions, private userService: UserService) {}
}
