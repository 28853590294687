<form (submit)="handleOnSubmit($event)" [formGroup]="configForm">
  <h2 mat-dialog-title>Get monitoring report</h2>

  <div mat-dialog-content class="dialog-content">
    <mat-form-field>
      <mat-label>Monitoring criterion</mat-label>
      <mat-select formControlName="monitoringCriteria" multiple>
        <mat-option [value]="'ThresholdMonitoring'">
          ThresholdMonitoring
        </mat-option>
        <mat-option [value]="'DeltaMonitoring'">DeltaMonitoring</mat-option>
        <mat-option [value]="'PeriodicMonitoring'">
          PeriodicMonitoring
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div formArrayName="Configurations">
      <div
        class="mb-4 flex"
        *ngFor="let item of Configurations.controls; let i = index"
        [formGroupName]="i">
        <mat-form-field floatLabel="auto">
          <mat-label>{{ 'Controller' | translate }}</mat-label>
          <input
            type="text"
            matInput
            (focus)="filterComponents($event)"
            (input)="filterComponents($event)"
            formControlName="Controller"
            [matAutocomplete]="autoComponents" />
          <mat-autocomplete #autoComponents="matAutocomplete">
            <mat-option *ngFor="let item of componentsList" [value]="item">
              {{ item }}
            </mat-option>
          </mat-autocomplete>
          <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field floatLabel="auto" class="ml-4">
          <mat-label>{{ 'Key' | translate }}</mat-label>
          <input
            type="text"
            matInput
            (focus)="filteVariable($event)"
            (input)="filteVariable($event)"
            formControlName="Key"
            [matAutocomplete]="autoVariables" />
          <mat-autocomplete #autoVariables="matAutocomplete">
            <mat-option *ngFor="let item of variablesList" [value]="item">
              {{ item }}
            </mat-option>
          </mat-autocomplete>
          <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field floatLabel="auto" class="ml-4">
          <mat-label>{{ 'EVSE ID' | translate }}</mat-label>
          <input
            matInput
            formControlName="EVSEID"
            type="number" />
        </mat-form-field>

        <button mat-icon-button class="ml-4" (click)="deleteComponentValue(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

    <div class="mb-4">
      <button mat-button (click)="addComponentValue()" type="button">
        <mat-icon>add</mat-icon>
        {{ 'ADD' | translate }}
      </button>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit">
      {{ 'GET' | translate }}
    </button>
    <button mat-raised-button (click)="close()" type="button">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</form>
