import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { filter, first, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';

import {
  selectAllCompanies,
  selectCompaniesState,
  selectIsSingleLoading,
  selectSelectedCompany,
  selectSelectedCompanyPaymentMethods,
  selectSelectedCompanyPaymentMethodsIsLoading,
  selectShouldShowAddPaymentModal
} from '.';

import { CompaniesState } from './companies.reducer';

import * as CompaniesActions from './companies.actions';
import { Company, PaymentMethod } from '../../companies/companies.model';
import { CreateBraintreeCustomerProps } from './companies.actions';
import {
  selectCanInvoice,
  selectIsLoading,
  selectSearchedCompanies
} from './companies.selectors';
import { AddPaymentMethodDTO, CustomerPaymentMethod } from 'src/app/dtos';

// @notes: Isolate to seperate file?
interface GetSingleOptions {
  PK_CompanyID: number;
  withPaymentMethods?: boolean;
  forceFetch?: boolean;
}

interface AddPaymentMethodOptions {
  PK_CompanyID: number;
  paymentMethod: PaymentMethod;
}

@Injectable({
  providedIn: 'root'
})
export class CompaniesFacade {
  allCompanies$ = this.store.pipe(select(selectAllCompanies));
  companiesState$ = this.store.pipe(select(selectCompaniesState));
  selectedCompany$ = this.store.pipe(select(selectSelectedCompany));
  selectedCompanyPaymentMethods$ = this.store.pipe(
    select(selectSelectedCompanyPaymentMethods)
  );
  selectedCompanyPaymentMethodsIsLoading$ = this.store.pipe(
    select(selectSelectedCompanyPaymentMethodsIsLoading)
  );
  isSingleLoading$ = this.store.pipe(select(selectIsSingleLoading));
  shouldShowAddPaymentMethod$ = this.store.pipe(
    select(selectShouldShowAddPaymentModal)
  );
  searchedCompanies$ = this.store.pipe(select(selectSearchedCompanies));
  isLoading$ = this.store.pipe(select(selectIsLoading));
  canInvoice$ = this.store.pipe(select(selectCanInvoice));

  constructor(
    private store: Store<CompaniesState>,
    private actions$: ActionsSubject
  ) {}

  selectCompany(PK_CompanyID: number) {
    this.store.dispatch(CompaniesActions.SelectCompanyId({ PK_CompanyID }));
    this.getPaymentMethodsForSelectedCompany();
  }

  getAll(refresh: boolean = false) {
    this.store.dispatch(CompaniesActions.LoadCompanies({ refresh }));
  }

  getSingle(options: GetSingleOptions) {
    const {
      PK_CompanyID,
      withPaymentMethods = false,
      forceFetch = false
    } = options;

    combineLatest([this.isSingleLoading$, this.companiesState$])
      .pipe(first())
      .subscribe(([isLoading, state]) => {
        const shouldFetch =
          (isLoading === false && !state.entities[PK_CompanyID]) || forceFetch;

        if (shouldFetch) {
          if (withPaymentMethods) {
            this.getPaymentMethodsForCompany(PK_CompanyID);
          }

          this.store.dispatch(
            CompaniesActions.LoadSingleCompany({ PK_CompanyID })
          );
        }
      });
  }

  getPaymentMethodsForSelectedCompany() {
    this.selectedCompany$.subscribe((company) => {
      if (!company) return;
      this.store.dispatch(
        CompaniesActions.LoadPaymentMethods({
          PK_CompanyID: company?.PK_CompanyID
        })
      );
    });
  }

  getPaymentMethodsForCompany(PK_CompanyID: number) {
    this.store.dispatch(CompaniesActions.LoadPaymentMethods({ PK_CompanyID }));
  }

  addPaymentMethod(options: AddPaymentMethodDTO) {
    this.store.dispatch(
      CompaniesActions.AddPaymentMethod({ payload: options })
    );
  }

  updatePaymentMethod(options: CustomerPaymentMethod) {
    this.store.dispatch(
      CompaniesActions.UpdatePaymentMethod({ payload: options })
    );
  }

  // @todo: not sure if this is used
  paymentMethodAdded(options: AddPaymentMethodDTO | any) {
    this.store.dispatch(CompaniesActions.PaymentMethodAdded(options));
  }

  createBraintreeCustomer(props: CreateBraintreeCustomerProps) {
    this.store.dispatch(CompaniesActions.CreateBraintreeCustomer(props));
  }

  showAddPaymentMethodModal() {
    this.store.dispatch(CompaniesActions.ShowAddPaymentMethodModal());
  }

  hideAddPaymentMethodModal() {
    this.store.dispatch(CompaniesActions.HideAddPaymentMethodModal());
  }

  search(query: string) {
    this.store.dispatch(CompaniesActions.SetSearchQuery({ query }));
  }

  deletePaymentMethod(FK_CompanyID: number, CustomerPaymentMethodID: number) {
    this.store.dispatch(
      CompaniesActions.DeletePaymentMethod({
        FK_CompanyID,
        CustomerPaymentMethodID
      })
    );
  }

  setDefaultPaymentMethod(
    FK_CompanyID: number,
    CustomerPaymentMethodID: number
  ) {
    this.store.dispatch(
      CompaniesActions.SetDefaultPaymentMethod({
        FK_CompanyID,
        CustomerPaymentMethodID
      })
    );
  }

  removeDefaultPaymentMethod(paymentMethod: any) {
    this.store.dispatch(
      CompaniesActions.RemoveDefaultPaymentMethod(paymentMethod)
    );
  }
}
