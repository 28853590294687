import { NgModule } from '@angular/core';

import { AuthService } from './auth.service';
import { AuthGuard } from './auth-guard.service';
import { TokenService } from './token/token.service';
import { AuthAsService } from './auth-as/auth-as.service';
import { TermsAndConditionsModule } from 'src/app/components/terms-and-conditions/terms-and-conditions.module';

@NgModule({
  imports: [TermsAndConditionsModule],
  providers: [AuthService, AuthGuard, AuthAsService, TokenService]
})
export class AuthModule {}
