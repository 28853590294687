import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import {
  catchError,
  delay,
  map,
  mergeMap,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';

import { OperatorService, Operator } from '../../operator';

import { OperatorActions, OperatorState } from '.';

@Injectable({ providedIn: 'root' })
export class OperatorEffects {
  loadAllOperators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperatorActions.LoadAllOperators),
      mergeMap(() =>
        this.operatorService.getOperatorsCanSee().pipe(
          map((operators) => OperatorActions.AllOperatorsLoaded({ operators })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<OperatorState>,
    private operatorService: OperatorService
  ) {}
}
