import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import {
  OperatorState,
  selectAllOperators,
  selectSelectedOperator,
  selectIsLoading
} from '.';

import * as OperatorActions from './operator.actions';
import { Operator } from '../../operator';
import { AppState } from '..';

@Injectable({
  providedIn: 'root'
})
export class OperatorFacade {
  allOperators$ = this.store
    // @TODO: Could be written nicer
    .pipe(
      tap(({ operators }) => {
        if (!operators.isLoading && !operators.isFetched) {
          this.getAll();
        }
      }),
      select(selectAllOperators)
    );

  selectedOperator$ = this.store.pipe(select(selectSelectedOperator));

  constructor(private store: Store<AppState>) {}

  getAll() {
    this.store.subscribe(({ operators: operatorStore }) => {
      if (!operatorStore.isLoading && !operatorStore.isFetched) {
        this.store.dispatch(OperatorActions.LoadAllOperators());
      }
    });
    // this.store.dispatch(TEMPLATEActions.LoadAllTEMPLATE());
  }

  getAndSelectOperatorPackage(PK_OperatorID: number) {
    this.getOperator(PK_OperatorID);
    this.selectOperator(PK_OperatorID);
  }

  getOperator(PK_OperatorID: number) {
    this.store.dispatch(OperatorActions.LoadOperator({ PK_OperatorID }));
  }

  selectOperator(PK_OperatorID: number) {
    this.store.dispatch(OperatorActions.SelectOperator({ PK_OperatorID }));
  }
}
