import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigFileService {
  constructor(
    private _http: HttpInterceptorService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  createSingleConfig(point) {
    this._http
      .get('Commands/GetExpectedConfiguration/' + point.PK_ChargeBoxID)
      .subscribe((res) => {
        if (!res.datas || !res.datas.length) {
          this.snackBar.open(
            this.translate.instant('NO_CONFIG_BOX_MODEL'),
            this.translate.instant('CLOSE')
          );
        } else {
          let cf: ConfigFile = new ConfigFile();
          saveAs(cf.create(res.datas), point.Name + '_config.cfg');
        }
      });
  }
  createMultipleConfigs(points): Promise<any> {
    return new Promise((resolve) => {
      let boxIDs = [];
      //Create a list of ID's to send to backend
      for (let point of points) {
        boxIDs.push(point.PK_ChargeBoxID);
      }
      this._http
        .post('Commands/GetExpectedConfigurationOfBoxes/true', {
          Boxes: boxIDs
        })
        .subscribe(
          (res) => {
            let cf: ConfigFile = new ConfigFile();
            let zip = new JSZip();
            let folder = zip.folder('Box configurations');

            for (let box of res.datas) {
              //Create all the files and folder them
              folder.file(
                box.BoxName + '_config.cfg',
                cf.create(box.ConfigList)
              );
            }

            //Zip and save
            zip.generateAsync({ type: 'blob' }).then((content) => {
              saveAs(content, 'Box configurations.zip');
              resolve(content);
            });
          },
          (err) => {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                errorName: err.errorName
              }),
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        );
    });
  }
}

class ConfigFile {
  private type: string = 'text/plain';

  create(keyValuePairs: KeyValue[]) {
    let fileContent = '';
    for (let conf of keyValuePairs) {
      fileContent += this.line(conf.Key, conf.Value);
    }
    return new Blob([fileContent], { type: this.type });
  }
  private line(key?, value?) {
    let content = key + '= ' + value;
    content += '\r\n';
    return content;
  }
}
interface KeyValue {
  Key: string;
  Value: any;
}
