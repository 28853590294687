import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnChanges
} from '@angular/core';
import { RolesService } from '../services/roles/roles.service';
@Directive({
  selector: '[canSee]'
})
export class CanSeeDirective implements OnChanges {
  @Input() canSee;
  @Input() canSeeStation; //Can be used to send stationID.
  @Input() canSeePoint; //Can be used to send pointID

  hasAccess: boolean;
  hasView: boolean = false;

  constructor(
    private rolesService: RolesService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  ngOnChanges() {
    const hasAccess = this.rolesService.hasHighlevelAccess(this.canSee);

    if (hasAccess && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!hasAccess && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }

  }
}
