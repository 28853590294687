import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ComponentCriteriaTypes,
  ConfigVariable,
  Ocpp201Components,
  Ocpp201Variables
} from 'src/app/lib/commands';

@Component({
  selector: 'sc-ocpp-monitoring-report',
  templateUrl: './monitoring-report.component.html',
  styleUrls: ['./monitoring-report.component.scss']
})
export class MonitoringReportComponent implements OnInit {
  configForm: FormGroup;
  Configurations: FormArray;
  componentsList = Ocpp201Components;
  variablesList = Ocpp201Variables;
  ComponentCriteriaList = ComponentCriteriaTypes;

  constructor(public dialogRef: MatDialogRef<MonitoringReportComponent>) {}

  ngOnInit() {
    this.configForm = new FormGroup({
      monitoringCriteria: new FormControl(),
      Configurations: new FormArray([this.createConfigFormItem()])
    });
    this.Configurations = this.configForm.get('Configurations') as FormArray;
  }

  createConfigFormItem() {
    return new FormGroup({
      Controller: new FormControl(''),
      Key: new FormControl(''),
      EVSEID: new FormControl('')
    });
  }

  filterComponents(event) {
    const searchText = (event.target.value || '').toLowerCase();
    if (searchText) {
      this.componentsList = [...Ocpp201Components]
        .slice()
        .filter((item) => item.toLowerCase().includes(searchText));
    } else {
      this.componentsList = [...Ocpp201Components].slice();
    }
  }

  filteVariable(event) {
    const searchText = (event.target.value || '').toLowerCase();
    if (searchText) {
      this.variablesList = [...Ocpp201Variables]
        .slice()
        .filter((item) => item.toLowerCase().includes(searchText));
    } else {
      this.variablesList = [...Ocpp201Variables].slice();
    }
  }

  addComponentValue() {
    this.Configurations.push(this.createConfigFormItem());
  }
  deleteComponentValue(index: number) {
    this.Configurations.removeAt(index);
  }

  handleOnSubmit(event) {
    event.preventDefault();
    if (this.configForm.valid) {
      const values = this.configForm.value.Configurations.map((item) => {
        const config: ConfigVariable = {};
        config.Controller = item.Controller;
        config.Key = item.Key;
        config.EVSEID = item.EVSEID;
        return config;
      });
      this.dialogRef.close({
        Configurations: values,
        MonitoringCriteria: this.configForm.value.monitoringCriteria
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
