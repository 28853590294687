<form (submit)="handleOnSubmit($event)" [formGroup]="form">
  <h2 mat-dialog-title>Get all display messages</h2>


  <div class="input-row">
    <mat-form-field>
      <mat-label>Priority</mat-label>
      <mat-select formControlName="priority">
        <mat-option [value]="'AlwaysFront'">AlwaysFront</mat-option>
        <mat-option [value]="'InFront'">InFront</mat-option>
        <mat-option [value]="'NormalCycle'">NormalCycle</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="input-row">
    <mat-form-field>
      <mat-label>State</mat-label>
      <mat-select formControlName="state">
        <mat-option [value]="'Charging'">Charging</mat-option>
        <mat-option [value]="'Faulted'">Faulted</mat-option>
        <mat-option [value]="'Faulted'">Faulted</mat-option>
        <mat-option [value]="'Unavailable'">Unavailable</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div formArrayName="ids">
    <div
      class="mb-4 flex"
      *ngFor="let item of ids.controls; let i = index"
      [formGroupName]="i">
      <mat-form-field floatLabel="auto" class="mr-4">
        <mat-label>{{ 'ID' | translate }}</mat-label>
        <input matInput formControlName="ID" type="number" />
      </mat-form-field>

      <button mat-icon-button class="ml-4" (click)="deleteInput(i)" type="button">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>

  <div class="mb-4">
    <button mat-button (click)="addInput()" type="button">
      <mat-icon>add</mat-icon>
      {{ 'ADD' | translate }}
    </button>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit">
      {{ 'GET' | translate }}
    </button>
    <button mat-raised-button (click)="close()" type="button">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</form>
