<div class="dialog-container">
  <h2 mat-dialog-title>{{ 'CONFIRM_REMOVE_OWNER' | translate }}</h2>
  <div mat-dialog-content class="!p-4">
    <form>
      <div class="flex items-center">
        <mat-checkbox [formControl]="deactivateCharger">
          {{ 'DEACTIVATE_CHARGER' | translate }}
        </mat-checkbox>
        <button
          mat-icon-button
          class="toolbar-button"
          matTooltip="{{ 'DEACTIVATE_CHARGER_HELP_TEXT' | translate }}">
          <mat-icon>info</mat-icon>
        </button>
      </div>
    </form>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="removeOwner()">
      {{ 'REMOVE' | translate }}
    </button>
    <button mat-raised-button (click)="dialogRef.close()">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
