import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommandsService, GenericBoxUpdate } from 'src/app/lib/commands';

@Component({
  selector: 'sc-add-firmware',
  templateUrl: './add-firmware.component.html',
  styleUrls: ['./add-firmware.component.scss']
})
export class AddFirmwareComponent implements OnInit {
  addFirmwareForm: FormGroup;
  fileToUpload: File | null = null;

  @Input() boxModelID: number;
  @Input() hideIsLatestFirmware: boolean = false;
  isAdding = false;

  constructor(
    public dialogRef: MatDialogRef<AddFirmwareComponent>,
    private commandService: CommandsService
  ) {}

  ngOnInit() {
    this.addFirmwareForm = new FormGroup({
      FirmwareVersion: new FormControl('', [Validators.required]),
      ReleaseDate: new FormControl(''),
      MinFirmwareVersionRequired: new FormControl(''),
      IsLatestFirmware: new FormControl(false, []),
      MustRebootWhenDone: new FormControl(false, []),
      UseManufacturersCertificate: new FormControl(false, []),
      SigningCertificate: new FormControl('', []),
      Signature: new FormControl('', [])
    });
  }

  handleFormSubmit() {
    if (this.addFirmwareForm.valid && this.fileToUpload !== null) {
      this.isAdding = true;
      let data = new FormData();
      data.append('file', this.fileToUpload);
      data.append(
        'parameters',
        JSON.stringify({
          ...this.addFirmwareForm.value,
          FK_BoxModelID: this.boxModelID
        })
      );
      this.commandService.addFirmware(data).subscribe({
        next: (res) => {
          this.isAdding = false;
          if (res?.datas) {
            const boxUpdate: GenericBoxUpdate = {
              FK_CertificateID: res.datas?.FK_CertificateID,
              FK_ChargingBoxModelID: res.datas?.FK_ChargingBoxModelID,
              FirmwareVersion: res.datas?.FirmwareVersion,
              Link: res.datas?.Link,
              PK_UpdateID: res.datas?.PK_UpdateID,
              Password: res.datas?.Password,
              PreviousUpdatesNeeded: res.datas?.PreviousUpdatesNeeded,
              Username: res.datas?.Username,
              VersionMinRequired: res.datas?.VersionMinRequired,
              ReleaseDate: res?.datas?.ReleaseDate
            };
            this.dialogRef.close(boxUpdate);
          } else {
            this.dialogRef.close();
          }
        },
        error: (err) => {
          this.isAdding = false;
          console.log(err);
          this.dialogRef.close();
        }
      });
    } else {
      console.log(this.addFirmwareForm);
    }
  }

  handleFile(event: any) {
    this.fileToUpload = event.target?.files.item(0);
  }
}
