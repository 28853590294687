import { Injectable } from '@angular/core';
import { Subject, switchMap } from 'rxjs';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';

@Injectable()
export class OrdersService {

  ordersHistory$: Subject<OrderHistoryParams> = new Subject();

  constructor(private _http: HttpInterceptorService) {
    this.ordersHistory$.pipe(switchMap((params: OrderHistoryParams) =>
      this.fetchOrderHistory(params)
    ));
  }
  getOrderHistory(params: OrderHistoryParams) {
    this.ordersHistory$.next(params);
  }

  handleError(err: any) {
    this.ordersHistory$.error(err);
  }

  fetchOrderHistory(params: OrderHistoryParams) {
    return this._http.get(
      `ChargingHistory/${params.action}/${params.id}/${params.limit}/${params.startIndex}/${params.from}/${params.to}/${params.calculateTotalPrice}`
    )
  }
}

interface OrderHistoryParams {
  action: string
  id?: number
  limit?: number
  startIndex?: number
  from?: number | string
  to?: number | string
  calculateTotalPrice?: string
}
