import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, getCurrencySymbol } from '@angular/common';

import { Currency } from 'src/app/lib';
/**
 * Custom currency pipe that can be used to add currency letter to the symbol
 * i.e. NOK = Nkr, SEK = Skr
 */
@Pipe({
  name: 'cCurrency'
})
export class CCurrencyPipe implements PipeTransform {
  constructor(private cPipe: CurrencyPipe) {}
  transform(
    value: any,
    currency?: string,
    format?: string | boolean,
    digitsInfo?: string,
    locale?: string
  ): any {
    if (value === null || value === undefined) return null;

    //Format normally
    let formatted = this.cPipe.transform(
      value,
      currency,
      format,
      digitsInfo,
      locale
    );
    if (format === 'symbol-narrow') {
      //Get the symbol
      let symbol = getCurrencySymbol(currency, 'narrow');

      //Only do this for kr for now
      if (symbol === 'kr') {
        let index = formatted.indexOf(symbol);
        //Add the first currency letter to the symbol
        formatted =
          formatted.slice(0, index) +
          currency.charAt(0).toUpperCase() +
          formatted.slice(index);
      }
    } else if (format === 'currency-code') {
      // temp. fix for Kr to show NOK/DKK/ISK/SEK
      let symbol = getCurrencySymbol(currency, 'narrow');
      if (symbol === 'kr') {
        formatted = this.cPipe.transform(
          value,
          currency,
          currency,
          digitsInfo,
          locale
        );
      } else {
        formatted = this.cPipe.transform(
          value,
          currency,
          'symbol-narrow',
          '1.2-2'
        );
      }
    }

    return formatted;
  }
}
