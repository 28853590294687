import { Injectable } from '@angular/core';

@Injectable()
export class StationTabsService {
  tabs: any = [];
  showStations: boolean = false;
  constructor() {}
  setupTabs(stations) {
    if (stations.length > 1) {
      this.showStations = true;
    } else if (stations.length === 1) {
      this.tabs.push({
        label: stations[0].Name,
        link: '/station/' + stations[0].PK_ChargingStationID,
        id: stations[0].PK_ChargingStationID
      });
    }
  }
  putTab(station) {
    const index: number = this.tabs.findIndex((item) => item.id !== null);
    if (index !== -1) this.tabs.splice(index, 1);

    this.tabs.push({
      label: station.Name,
      link: '/station/' + station.PK_ChargingStationID,
      id: station.PK_ChargingStationID
    });
  }
  closeTab(tab) {
    const index: number = this.tabs.findIndex((item) => item.id === tab.id);
    this.tabs.splice(index, 1);

    //this.tabs = this.tabs.filter(item => item.id !== tab.id);
  }
}
