import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { SearchModule } from '../../search/search-module.module';
import { SuperSearchDialogComponent } from './super-search-dialog/super-search-dialog.component';
import { SuperSearchComponent } from './super-search.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatTooltipModule,
    DirectivesModule,
    SearchModule
  ],
  declarations: [SuperSearchComponent, SuperSearchDialogComponent],
  exports: [SuperSearchComponent]
})
export class SuperSearchModule {}
