import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { CreateCustomerModule } from '../../admin/create-customer/create-customer.module';
import { UserSearchModule } from '../../admin/users/user-search/user-search.module';
import { CompanyInfoComponent } from './company-info.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    UserSearchModule,
    MatIconModule,
    CreateCustomerModule,
    DirectivesModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatRadioModule,
    MatChipsModule,
    PipesModule,
    MatSlideToggleModule
  ],
  declarations: [CompanyInfoComponent],
  exports: [CompanyInfoComponent]
})
export class CompanyInfoModule {}
