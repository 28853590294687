import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  OnChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UsersService } from 'src/app/services/users/users.service';
import { UserService } from 'src/app/lib';
import {
  WebsocketService,
  CustomWS
} from 'src/app/services/websocket/websocket.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { RolesService } from 'src/app/services/roles/roles.service';
import { UserType } from 'src/app/user-roles';
import { FloatLabelType as FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'sc-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit, OnChanges {
  @ViewChild('autoComplete', { read: MatAutocompleteTrigger })
  auto: MatAutocompleteTrigger;
  @Input('enter') enter: boolean = true;
  @Input('customerID') customerID: any;
  @Input('styleClass') styleClass: string = '';
  @Input('floatLabel') floatLabel: FloatLabelType = 'auto';
  @Input('required') required: boolean = false;

  @Output() search: EventEmitter<any> = new EventEmitter();
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();
  @Output() clear: EventEmitter<any> = new EventEmitter();

  @Input('searchInput') searchInput: string = '';
  @Input('accessComponent') accessComponent: string;

  searchInputCtrl: FormControl = new FormControl('');
  socket: any;
  searchedUsers: any = [];
  searchInited: boolean = false;
  socketRetries: number = 0;
  usersWS: CustomWS;
  userType = UserType;
  isAdmin: boolean = this.rolesService.hasHighlevelAccess(this.userType.Admin);

  // NEw stuff
  loading: boolean = true;
  socketURL: string = '';
  selectedUser: { Email: string };

  constructor(
    private userService: UserService,
    private usersService: UsersService,
    private rolesService: RolesService
  ) {}

  ngOnInit() {
    this.initSocket(null);
  }

  ngOnChanges() {
    if (this.customerID) {
      this.getCustomer(this.customerID);
    }
  }

  getCustomer(id) {
    this.usersService.getUser(id, true).then((user) => {
      user.ID = user.PK_CustomerID;
      this.selectedUser = { Email: user.Email };
      this.itemSelected.emit(user);
    });
  }

  initSocket(ev) {
    this.userService.getUser().subscribe((user) => {
      this.socketURL = 'Users/SearchUser/' + user.PK_CustomerID;
      this.loading = false;
    });
  }
  selectUser(user) {
    this.itemSelected.emit(user);
  }
  closePanel() {
    this.auto?.closePanel();
  }
  clearSearch() {
    //this.searchInput = "";
    this.closePanel();
    this.clear.emit(null);
  }
  clearSocket() {
    if (this.usersWS) {
      this.searchInited = false;
      this.usersWS.webSocket.complete();
      this.usersWS = null;
    }
  }
  ngOnDestroy() {
    if (this.search) {
      this.search = null;
      this.clearSocket();
    }
  }
  displayFn(customer: any): string {
    let display: string = '';
    if (customer && typeof customer == 'object') {
      if (customer.FirstName) display = customer.FirstName;
      if (customer.LastName) display += ' ' + customer.LastName;
      if (!display.trim() && customer.Email) display = customer.Email;
      if (!display.trim() && customer.PhoneNumber)
        display = customer.PhoneNumber;
    } else {
      display = customer;
    }
    return display.trim();
  }

  handleSearch(searchString: string) {
    this.search.emit(searchString);
  }
}
