<form>
  <h2 mat-dialog-title>{{ title || ('IMPORT_FILE' | translate) }}</h2>

  <div mat-dialog-content class="dialog-content">
    <p>
      {{ 'IMPORT_FILE_TEXT_128' | translate }}
    </p>
    <div>
      <input
        type="file"
        (change)="fileFromClick($event)"
        #fileSelect
        hidden
        accept=".xlsx, .xls" />

      <div
        ng2FileDrop
        (click)="fileSelect.click()"
        [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver, 'has-file': file }"
        (fileOver)="fileOverBase($event)"
        [uploader]="uploader"
        (onFileDrop)="fileDropped($event)"
        class="my-drop-zone drop-zone-100">
        <span *ngIf="!file">
          {{ 'DROP_YOUR_FILE_HERE' | translate }}
          <br />
          {{ 'OR_CLICK_TO_UPLOAD' | translate }}
        </span>
        <span *ngIf="file">
          <b>{{ file.name }}</b>
        </span>
        <div class="file-overlay"></div>
      </div>
    </div>

    <!--<input type="file" style="display: inline-block;" (change)="incomingfile($event)" ="Upload file" accept=".xlsx">-->

    <p>{{ 'REQUIRED_COLUMNS' | translate }}</p>
    <ul *ngIf="!parameters.length">
      <li *ngFor="let reqField of requiredFields">{{ reqField }}</li>
    </ul>
    <table class="margin-top table" *ngIf="parameters.length">
      <!--formArrayName="sheetFields"-->
      <tr *ngFor="let reqField of matchingFields">
        <!--*ngFor="let reqField of sheetControls;let last = last;let i = index;"-->
        <td>{{ reqField.field }}</td>
        <td class="padding-horizontal"><mat-icon>compare_arrows</mat-icon></td>
        <td>
          <mat-form-field class="normal-input margin-right" floatLabel="auto">
            <mat-label>{{ 'SELECT_COLUMN' | translate }}</mat-label>
            <mat-select [formControl]="reqField.match">
              <!--[(ngModel)]="reqField.match"-->
              <mat-option *ngFor="let param of parameters" [value]="param">
                {{ param }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
    </table>
  </div>
  <div mat-dialog-actions>
    <button
      mat-raised-button
      [disabled]="!parameters.length"
      color="primary"
      (click)="import()">
      {{ 'IMPORT' | translate }}
    </button>
    <button mat-raised-button (click)="dialogRef.close()">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</form>
