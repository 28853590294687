import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import {
  catchError,
  delay,
  map,
  mergeMap,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';

import {
  OperatorPackageService,
  OperatorPackage
} from '../../operator-package';

import {
  OperatorPackageActions,
  selectAllOperatorPackage,
  selectOperatorPackageState,
  OperatorPackageState
} from '.';

@Injectable({ providedIn: 'root' })
export class OperatorPackageEffects {
  loadAllOperatorPackages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperatorPackageActions.LoadAllOperatorPackage),
      mergeMap(() =>
        this.operatorPackageService.getOperatorPackages().pipe(
          map((operatorPackages) =>
            OperatorPackageActions.AllOperatorPackageLoaded({
              operatorPackages
            })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createOperatorPackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperatorPackageActions.CreateOperatorPackage),
      mergeMap((action) => {
        return this.operatorPackageService
          .createOperatorPackage(action.operatorPackage)
          .pipe(
            map((PK_OperatorPackageID) =>
              OperatorPackageActions.CreateOperatorPackageSuccess({
                operatorPackage: {
                  PK_OperatorPackageID,
                  ...action.operatorPackage
                }
              })
            ),
            catchError(() => EMPTY)
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<OperatorPackageState>,
    private operatorPackageService: OperatorPackageService
  ) {}
}
