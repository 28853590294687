import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { InstallerUser } from 'src/app/classes/Installer';

@Injectable({
  providedIn: 'root'
})
export class InstallerService {
  constructor(private _http: HttpInterceptorService) {}

  //Installer general
  getAll() {
    return this._http.get(`Installers/GetAll`);
  }
  getInstallersICanSee() {
    return this._http.get(`Installers/InstallersICanSee`);
  }
  get(installerID) {
    return this._http.get(`Installers/${installerID}`);
  }
  saveInstaller(installer) {
    return this._http.put(`Installers`, installer);
  }
  createInstaller(installer, defaultSettings) {
    return this._http.post('Installers', {
      Installer: installer,
      DefaultSettings: defaultSettings
    });
  }
  deleteInstaller(installerID) {
    return this._http.delete(`Installers/${installerID}`);
  }

  //Users & access
  getUsers(installerID) {
    return this._http.get(`Installers/GetAllUsers/${installerID}`);
  }
  addUser(user: InstallerUser) {
    return this._http.post('Installers/AddUserAccess', user);
  }
  editUserAccess(user) {
    return this._http.put('Installers/ChangeUserAccess', user);
  }
  removeUser(userID, installerID) {
    return this._http.delete(
      `Installers/RemoveUserAccess/${userID}/${installerID}`
    );
  }

  //Default settings
  getDefaultSettings(installerID) {
    return this._http.get(`Installers/GetDefaultSettings/${installerID}`);
  }
  updateDefaultSettings(settings) {
    return this._http.put(`Installers/DefaultSettings`, settings);
  }
  createDefaultSettings(settings) {
    return this._http.post(`Installers/DefaultSettings`, settings);
  }

  //Locations
  getLocations(installerID, startIndex = 0, limit = 10) {
    return this._http.get(
      `ChargingStations/ByInstaller/${installerID}/${startIndex}/${limit}`
    );
  }
  changeLocationInstaller(locationID, installerID, kickbackAC, kickbackDC) {
    return this._http.patch(
      `ChargingStations/ChangeInstaller/${locationID}/${installerID}`,
      {
        Subscription_KickBack_AC_ExVAT: kickbackAC,
        Subscription_KickBack_DC_ExVAT: kickbackDC
      }
    );
  }

  getInstallerByID(installerID: number) {
    return this._http.get(`Installers/${installerID}`)
  }
}
