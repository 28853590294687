/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const alarms = /* GraphQL */ `
  query Alarms($homeId: ID!) {
    alarms(homeId: $homeId) {
      alarms {
        ... on WaterAlarm {
          id
          state
          triggeredAt
          snoozeExpiresAt
          areas {
            id
            areaName
            areaType
            size
            floor
            children
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              manufacturer {
                id
                name
                __typename
              }
              deviceTemplate {
                id
                deviceType
                name
                primaryPairingMode
                secondaryPairingModes
                __typename
              }
              image {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              area {
                id
                areaName
                areaType
                size
                floor
                children
                __typename
              }
              traits {
                traitType
                commands
                __typename
              }
              accessPolicy {
                read
                write
                delete
                expiresAt
                __typename
              }
              __typename
            }
            accessPolicies {
              actionType
              create
              update
              delete
              read
              __typename
            }
            __typename
          }
          notificationLifecycle {
            id
            notifications {
              ... on EmergencyContactsAlertAlarmNotificationInactive {
                reason
              }
              ... on EmergencyContactsAlertAlarmNotificationTriggerCountdown {
                alertTriggersAt
              }
              ... on EmergencyContactsAlertAlarmNotification {
                status
              }
              ... on EmergencyContactsImOkAlarmNotification {
                status
              }
            }
            __typename
          }
          alarmStationNotificationLifecycle {
            deviceEvents {
              deviceId
              events {
                triggeredAt
                eventType
                status
                __typename
              }
              __typename
            }
            __typename
          }
        }
        ... on FireAlarm {
          id
          state
          triggeredAt
          snoozeExpiresAt
          areas {
            id
            areaName
            areaType
            size
            floor
            children
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              manufacturer {
                id
                name
                __typename
              }
              deviceTemplate {
                id
                deviceType
                name
                primaryPairingMode
                secondaryPairingModes
                __typename
              }
              image {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              area {
                id
                areaName
                areaType
                size
                floor
                children
                __typename
              }
              traits {
                traitType
                commands
                __typename
              }
              accessPolicy {
                read
                write
                delete
                expiresAt
                __typename
              }
              __typename
            }
            accessPolicies {
              actionType
              create
              update
              delete
              read
              __typename
            }
            __typename
          }
          notificationLifecycle {
            id
            notifications {
              ... on EmergencyContactsAlertAlarmNotificationInactive {
                reason
              }
              ... on EmergencyContactsAlertAlarmNotificationTriggerCountdown {
                alertTriggersAt
              }
              ... on EmergencyContactsAlertAlarmNotification {
                status
              }
              ... on EmergencyContactsImOkAlarmNotification {
                status
              }
            }
            __typename
          }
          alarmStationNotificationLifecycle {
            deviceEvents {
              deviceId
              events {
                triggeredAt
                eventType
                status
                __typename
              }
              __typename
            }
            __typename
          }
        }
        ... on BurglarAlarm {
          id
          state
          triggeredAt
          snoozeExpiresAt
          areas {
            id
            areaName
            areaType
            size
            floor
            children
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              manufacturer {
                id
                name
                __typename
              }
              deviceTemplate {
                id
                deviceType
                name
                primaryPairingMode
                secondaryPairingModes
                __typename
              }
              image {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              area {
                id
                areaName
                areaType
                size
                floor
                children
                __typename
              }
              traits {
                traitType
                commands
                __typename
              }
              accessPolicy {
                read
                write
                delete
                expiresAt
                __typename
              }
              __typename
            }
            accessPolicies {
              actionType
              create
              update
              delete
              read
              __typename
            }
            __typename
          }
          notificationLifecycle {
            id
            notifications {
              ... on EmergencyContactsAlertAlarmNotificationInactive {
                reason
              }
              ... on EmergencyContactsAlertAlarmNotificationTriggerCountdown {
                alertTriggersAt
              }
              ... on EmergencyContactsAlertAlarmNotification {
                status
              }
              ... on EmergencyContactsImOkAlarmNotification {
                status
              }
            }
            __typename
          }
          alarmStationNotificationLifecycle {
            deviceEvents {
              deviceId
              events {
                triggeredAt
                eventType
                status
                __typename
              }
              __typename
            }
            __typename
          }
        }
        ... on SecurityAlarm {
          id
          state
          isArmed
          triggeredAt
          snoozeExpiresAt
          areas {
            id
            areaName
            areaType
            size
            floor
            children
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              manufacturer {
                id
                name
                __typename
              }
              deviceTemplate {
                id
                deviceType
                name
                primaryPairingMode
                secondaryPairingModes
                __typename
              }
              image {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              area {
                id
                areaName
                areaType
                size
                floor
                children
                __typename
              }
              traits {
                traitType
                commands
                __typename
              }
              accessPolicy {
                read
                write
                delete
                expiresAt
                __typename
              }
              __typename
            }
            accessPolicies {
              actionType
              create
              update
              delete
              read
              __typename
            }
            __typename
          }
          notificationLifecycle {
            id
            notifications {
              ... on EmergencyContactsAlertAlarmNotificationInactive {
                reason
              }
              ... on EmergencyContactsAlertAlarmNotificationTriggerCountdown {
                alertTriggersAt
              }
              ... on EmergencyContactsAlertAlarmNotification {
                status
              }
              ... on EmergencyContactsImOkAlarmNotification {
                status
              }
            }
            __typename
          }
          alarmStationNotificationLifecycle {
            deviceEvents {
              deviceId
              events {
                triggeredAt
                eventType
                status
                __typename
              }
              __typename
            }
            __typename
          }
        }
      }
      __typename
    }
  }
`;
export const cloudConnections = /* GraphQL */ `
  query CloudConnections($homeId: ID!) {
    cloudConnections(homeId: $homeId) {
      cloudConnections {
        ... on ElhubCloudConnection {
          providerId
          status
          connectedAt
          connectionError
          template {
            providerName
            providerDescription
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            __typename
          }
          energyMeterIds
        }
        ... on NordPoolCloudConnection {
          providerId
          status
          connectionError
          template {
            providerName
            providerDescription
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            __typename
          }
          region
          priceMap {
            url
            urlSmall
            urlMedium
            urlLarge
            __typename
          }
        }
      }
      __typename
    }
  }
`;
export const deviceIdentifyBySerialNumber = /* GraphQL */ `
  query DeviceIdentifyBySerialNumber($serialNumber: String!) {
    deviceIdentifyBySerialNumber(serialNumber: $serialNumber) {
      deviceInfo {
        id
        macAddress
        firmwareVersion
        serialNumber
        registered
        __typename
      }
      errors {
        ... on InvalidSerialNumberFormatError {
          message
          path
        }
        ... on DeviceDoesNotExistError {
          message
          path
        }
      }
      __typename
    }
  }
`;
export const deviceIdentifyByQRCode = /* GraphQL */ `
  query DeviceIdentifyByQRCode($qrCode: String!) {
    deviceIdentifyByQRCode(qrCode: $qrCode) {
      deviceInfo {
        id
        macAddress
        firmwareVersion
        serialNumber
        registered
        __typename
      }
      errors {
        ... on InvalidSerialNumberFormatError {
          message
          path
        }
        ... on DeviceDoesNotExistError {
          message
          path
        }
      }
      __typename
    }
  }
`;
export const deviceManufacturers = /* GraphQL */ `
  query DeviceManufacturers {
    deviceManufacturers {
      id
      name
      logo {
        url
        urlSmall
        urlMedium
        urlLarge
        __typename
      }
      __typename
    }
  }
`;
export const deviceTemplates = /* GraphQL */ `
  query DeviceTemplates(
    $manufacturerId: ID
    $deviceType: DeviceType
    $language: String
  ) {
    deviceTemplates(
      manufacturerId: $manufacturerId
      deviceType: $deviceType
      language: $language
    ) {
      id
      deviceType
      name
      primaryPairingMode
      secondaryPairingModes
      manufacturer {
        id
        name
        logo {
          url
          urlSmall
          urlMedium
          urlLarge
          __typename
        }
        __typename
      }
      image {
        url
        urlSmall
        urlMedium
        urlLarge
        __typename
      }
      imageWithBg {
        url
        urlSmall
        urlMedium
        urlLarge
        __typename
      }
      installationInstructions {
        language
        header
        description
        installationAsset {
          url
          assetType
          __typename
        }
        connectionAsset {
          url
          assetType
          __typename
        }
        __typename
      }
      instructions {
        installation {
          description {
            description
            additionalInfo
            __typename
          }
          assetUrl
          __typename
        }
        configuration {
          description {
            description
            additionalInfo
            __typename
          }
          assetUrl
          __typename
        }
        help {
          description {
            description
            additionalInfo
            __typename
          }
          assetUrl
          __typename
        }
        connectionAssetUrl
        __typename
      }
      __typename
    }
  }
`;
export const statsEnergySummary = /* GraphQL */ `
  query StatsEnergySummary($homeId: ID!) {
    statsEnergySummary(homeId: $homeId) {
      relativeConsumption {
        consumptionUnit
        mostEfficientConsumption
        averageConsumption
        leastEfficientConsumption
        actualConsumption
        actualVsAverageConsumptionComparison
        actualVsAverageConsumptionPercent
        __typename
      }
      errors {
        ... on DataNotAvailableError {
          message
          path
        }
        ... on MissingMeteringPointsError {
          message
          path
        }
      }
      __typename
    }
  }
`;
export const statsTimeSeries = /* GraphQL */ `
  query StatsTimeSeries(
    $select: [TimeSeriesSelectInput!]!
    $scope: StatsScopeInput!
  ) {
    statsTimeSeries(select: $select, scope: $scope) {
      result {
        metadata {
          tag
          ref {
            timeSeriesId
            category
            homeId
            areaId
            deviceId
            __typename
          }
          unit
          __typename
        }
        values {
          timestamp
          values {
            tag
            value
            __typename
          }
          __typename
        }
        aggregates {
          tag
          sum
          __typename
        }
        __typename
      }
      errors {
        ... on InvalidRangeTimeSeriesError {
          message
          path
        }
        ... on InvalidGranularityTimeSeriesError {
          message
          path
        }
        ... on MissingParameterTimeSeriesError {
          message
          path
        }
        ... on TagNotUniqueTimeSeriesError {
          message
          path
        }
        ... on UnknownMarketBalanceAreaError {
          message
          path
        }
        ... on MissingMeteringPointsError {
          message
          path
        }
        ... on VATValueNotFoundError {
          message
          path
        }
      }
      __typename
    }
  }
`;
export const home = /* GraphQL */ `
  query Home($homeId: ID!) {
    home(homeId: $homeId) {
      id
      locationInfo {
        country
        city
        postalCode
        street
        streetNumber
        unitNumber
        geoLocation {
          latitude
          longitude
          __typename
        }
        __typename
      }
      homeInfo {
        nickname
        avatarUrl
        size
        ageOfBuilding
        numberOfFloors
        numberOfAdults
        numberOfChildren
        homeType
        dwellingUnit
        heatingType
        cooling
        ventilation
        propertyManagerName
        constructionType
        buildingYear
        __typename
      }
      avatar {
        url
        urlSmall
        urlMedium
        urlLarge
        uploadUrl
        __typename
      }
      areas {
        id
        areaName
        areaType
        size
        floor
        children
        devices {
          id
          deviceType
          name
          serialNumber
          firmwareVersion
          imei
          macAddress
          ipAddress
          roomPlacement
          manufacturer {
            id
            name
            logo {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            __typename
          }
          deviceTemplate {
            id
            deviceType
            name
            primaryPairingMode
            secondaryPairingModes
            manufacturer {
              id
              name
              __typename
            }
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            imageWithBg {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            installationInstructions {
              language
              header
              description
              __typename
            }
            instructions {
              connectionAssetUrl
              __typename
            }
            __typename
          }
          image {
            url
            urlSmall
            urlMedium
            urlLarge
            __typename
          }
          favorite
          favoriteChangedAt
          registeredAt
          canBeUnregistered
          area {
            id
            areaName
            areaType
            size
            floor
            children
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              __typename
            }
            accessPolicies {
              actionType
              create
              update
              delete
              read
              __typename
            }
            __typename
          }
          traits {
            traitType
            commands
            attributes {
              ... on TraitAttributeAvailableArmLevels {
                name
                dataType
                editable
              }
              ... on TraitAttributeIsArmed {
                name
                dataType
                editable
                isArmed
              }
              ... on TraitAttributeArmLevel {
                name
                dataType
                editable
                armLevel
              }
              ... on TraitAttributeDisarmDefaultTimeout {
                name
                dataType
                editable
                disarmDefaultTimeout
              }
              ... on TraitAttributeIsConnected {
                name
                dataType
                editable
                isConnected
              }
              ... on TraitAttributeHeartbeatInterval {
                name
                dataType
                editable
                heartbeatInterval
              }
              ... on TraitAttributeHeartbeatsThreshold {
                name
                dataType
                editable
                heartbeatsThreshold
              }
              ... on TraitAttributeGatewayConnected {
                name
                dataType
                editable
                ethernetConnected
                wifiConnected
                cellularConnected
              }
              ... on TraitAttributeWifiConfigured {
                name
                dataType
                editable
                wifiConfigured
              }
              ... on TraitAttributeNetworkSsid {
                name
                dataType
                editable
                networkSsid
              }
              ... on TraitAttributeNetworkCredentials {
                name
                dataType
                editable
                networkCredentials
              }
              ... on TraitAttributeCellularApn {
                name
                dataType
                editable
                cellularApn
              }
              ... on TraitAttributeCellularOperator {
                name
                dataType
                editable
                cellularOperator
              }
              ... on TraitAttributeBrightness {
                name
                dataType
                editable
                brightness
              }
              ... on TraitAttributeColor {
                name
                dataType
                editable
                hue
                saturation
              }
              ... on TraitAttributeIsOn {
                name
                dataType
                editable
                isOn
              }
              ... on TraitAttributeOpenPercent {
                name
                dataType
                editable
                openPercent
              }
              ... on TraitAttributeCurrentPowerSourceLevel {
                name
                dataType
                editable
                currentPowerSourceLevel
              }
              ... on TraitAttributeLowBatteryWarning {
                name
                dataType
                editable
                lowBatteryWarning
              }
              ... on TraitAttributeCurrentPowerSource {
                name
                dataType
                editable
                currentPowerSource
              }
              ... on TraitAttributeStatusCharging {
                name
                dataType
                editable
                statusCharging
              }
              ... on TraitAttributeCurrentPowerMode {
                name
                dataType
                editable
                currentPowerMode
              }
              ... on TraitAttributeAvailablePowerSources {
                name
                dataType
                editable
                availablePowerSources
              }
              ... on TraitAttributeBatteryVoltage {
                name
                dataType
                editable
                batteryVoltage
              }
              ... on TraitAttributeMainsVoltage {
                name
                dataType
                editable
                mainsVoltage
              }
              ... on TraitAttributeRunningPowerConsumption {
                name
                dataType
                editable
                runningPowerConsumption
              }
              ... on TraitAttributePriority {
                name
                dataType
                editable
                priority
              }
              ... on TraitAttributeAirQuality {
                name
                dataType
                editable
                airQuality
              }
              ... on TraitAttributeCo2 {
                name
                dataType
                editable
                co2
              }
              ... on TraitAttributeHumidity {
                name
                dataType
                editable
                humidity
                humidityIndicator
              }
              ... on TraitAttributeTemperature {
                name
                dataType
                editable
                temperature
              }
              ... on TraitAttributeVoc {
                name
                dataType
                editable
                voc
              }
              ... on TraitAttributeIsUnlocked {
                name
                dataType
                editable
                isUnlocked
              }
              ... on TraitAttributeChildLock {
                name
                dataType
                editable
                childLock
              }
              ... on TraitAttributeFault {
                name
                dataType
                editable
              }
              ... on TraitAttributeTemperatureSetpoint {
                name
                dataType
                editable
                temperatureSetpoint
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeTemperatureSetpointPercentage {
                name
                dataType
                editable
                temperatureSetpointPercentage
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeAvailableClimateModes {
                name
                dataType
                editable
                availableClimateModes
              }
              ... on TraitAttributeClimateMode {
                name
                dataType
                editable
                climateMode
              }
              ... on TraitAttributeMaximumFloorTemperature {
                name
                dataType
                editable
                maximumFloorTemperature
              }
              ... on TraitAttributeFrostGuard {
                name
                dataType
                editable
                frostGuard
              }
              ... on TraitAttributeNightSwitch {
                name
                dataType
                editable
                nightSwitch
              }
              ... on TraitAttributeRegulatorMode {
                name
                dataType
                editable
                regulatorMode
              }
              ... on TraitAttributeHeatingElementActive {
                name
                dataType
                editable
                heatingElementActive
              }
              ... on TraitAttributeAlarmSound {
                name
                dataType
                editable
                alarmSound
              }
              ... on TraitAttributeAlarm {
                name
                dataType
                editable
                alarm
                snoozed
              }
              ... on TraitAttributeWaterDetectorCableConnected {
                name
                dataType
                editable
                waterDetectorCableConnected
              }
              ... on TraitAttributeWaterValvesConnected {
                name
                dataType
                editable
                waterValvesConnected
              }
              ... on TraitAttributeBracket {
                name
                dataType
                editable
                bracket
              }
              ... on TraitAttributeDisabled {
                name
                dataType
                editable
                disabled
              }
              ... on TraitAttributeLocation {
                name
                dataType
                editable
                latitude
                longitude
              }
              ... on TraitAttributeMotionDetected {
                name
                dataType
                editable
                lastMotionDetectedAt
              }
              ... on TraitAttributeValveInfo {
                name
                dataType
                editable
                valveType
                typeOfValve
                numValves
              }
              ... on TraitAttributeElectricVehicleCharger {
                name
                dataType
                editable
                status
                chargingStartedAt
                kwhCharged
                chargingSessionCost
              }
              ... on TraitAttributeTestMode {
                name
                dataType
                editable
                isOn
                lastTriggeredAt
              }
              ... on TraitAttributePing {
                name
                dataType
                editable
                ping
              }
            }
            __typename
          }
          accessPolicy {
            read
            write
            delete
            expiresAt
            __typename
          }
          __typename
        }
        accessPolicies {
          actionType
          create
          update
          delete
          read
          __typename
        }
        __typename
      }
      devices {
        id
        deviceType
        name
        serialNumber
        firmwareVersion
        imei
        macAddress
        ipAddress
        roomPlacement
        manufacturer {
          id
          name
          logo {
            url
            urlSmall
            urlMedium
            urlLarge
            __typename
          }
          __typename
        }
        deviceTemplate {
          id
          deviceType
          name
          primaryPairingMode
          secondaryPairingModes
          manufacturer {
            id
            name
            logo {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            __typename
          }
          image {
            url
            urlSmall
            urlMedium
            urlLarge
            __typename
          }
          imageWithBg {
            url
            urlSmall
            urlMedium
            urlLarge
            __typename
          }
          installationInstructions {
            language
            header
            description
            installationAsset {
              url
              assetType
              __typename
            }
            connectionAsset {
              url
              assetType
              __typename
            }
            __typename
          }
          instructions {
            installation {
              assetUrl
              __typename
            }
            configuration {
              assetUrl
              __typename
            }
            help {
              assetUrl
              __typename
            }
            connectionAssetUrl
            __typename
          }
          __typename
        }
        image {
          url
          urlSmall
          urlMedium
          urlLarge
          __typename
        }
        favorite
        favoriteChangedAt
        registeredAt
        canBeUnregistered
        area {
          id
          areaName
          areaType
          size
          floor
          children
          devices {
            id
            deviceType
            name
            serialNumber
            firmwareVersion
            imei
            macAddress
            ipAddress
            roomPlacement
            manufacturer {
              id
              name
              __typename
            }
            deviceTemplate {
              id
              deviceType
              name
              primaryPairingMode
              secondaryPairingModes
              __typename
            }
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            favorite
            favoriteChangedAt
            registeredAt
            canBeUnregistered
            area {
              id
              areaName
              areaType
              size
              floor
              children
              __typename
            }
            traits {
              traitType
              commands
              __typename
            }
            accessPolicy {
              read
              write
              delete
              expiresAt
              __typename
            }
            __typename
          }
          accessPolicies {
            actionType
            create
            update
            delete
            read
            __typename
          }
          __typename
        }
        traits {
          traitType
          commands
          attributes {
            ... on TraitAttributeAvailableArmLevels {
              name
              dataType
              editable
              availableArmLevels {
                id
                description
                __typename
              }
            }
            ... on TraitAttributeIsArmed {
              name
              dataType
              editable
              isArmed
            }
            ... on TraitAttributeArmLevel {
              name
              dataType
              editable
              armLevel
            }
            ... on TraitAttributeDisarmDefaultTimeout {
              name
              dataType
              editable
              disarmDefaultTimeout
            }
            ... on TraitAttributeIsConnected {
              name
              dataType
              editable
              isConnected
            }
            ... on TraitAttributeHeartbeatInterval {
              name
              dataType
              editable
              heartbeatInterval
            }
            ... on TraitAttributeHeartbeatsThreshold {
              name
              dataType
              editable
              heartbeatsThreshold
            }
            ... on TraitAttributeGatewayConnected {
              name
              dataType
              editable
              ethernetConnected
              wifiConnected
              cellularConnected
            }
            ... on TraitAttributeWifiConfigured {
              name
              dataType
              editable
              wifiConfigured
            }
            ... on TraitAttributeNetworkSsid {
              name
              dataType
              editable
              networkSsid
            }
            ... on TraitAttributeNetworkCredentials {
              name
              dataType
              editable
              networkCredentials
            }
            ... on TraitAttributeCellularApn {
              name
              dataType
              editable
              cellularApn
            }
            ... on TraitAttributeCellularOperator {
              name
              dataType
              editable
              cellularOperator
            }
            ... on TraitAttributeBrightness {
              name
              dataType
              editable
              brightness
            }
            ... on TraitAttributeColor {
              name
              dataType
              editable
              hue
              saturation
            }
            ... on TraitAttributeIsOn {
              name
              dataType
              editable
              isOn
            }
            ... on TraitAttributeOpenPercent {
              name
              dataType
              editable
              openPercent
            }
            ... on TraitAttributeCurrentPowerSourceLevel {
              name
              dataType
              editable
              currentPowerSourceLevel
            }
            ... on TraitAttributeLowBatteryWarning {
              name
              dataType
              editable
              lowBatteryWarning
            }
            ... on TraitAttributeCurrentPowerSource {
              name
              dataType
              editable
              currentPowerSource
            }
            ... on TraitAttributeStatusCharging {
              name
              dataType
              editable
              statusCharging
            }
            ... on TraitAttributeCurrentPowerMode {
              name
              dataType
              editable
              currentPowerMode
            }
            ... on TraitAttributeAvailablePowerSources {
              name
              dataType
              editable
              availablePowerSources
            }
            ... on TraitAttributeBatteryVoltage {
              name
              dataType
              editable
              batteryVoltage
            }
            ... on TraitAttributeMainsVoltage {
              name
              dataType
              editable
              mainsVoltage
            }
            ... on TraitAttributeRunningPowerConsumption {
              name
              dataType
              editable
              runningPowerConsumption
            }
            ... on TraitAttributePriority {
              name
              dataType
              editable
              priority
            }
            ... on TraitAttributeAirQuality {
              name
              dataType
              editable
              airQuality
            }
            ... on TraitAttributeCo2 {
              name
              dataType
              editable
              co2
            }
            ... on TraitAttributeHumidity {
              name
              dataType
              editable
              humidity
              humidityIndicator
            }
            ... on TraitAttributeTemperature {
              name
              dataType
              editable
              temperature
            }
            ... on TraitAttributeVoc {
              name
              dataType
              editable
              voc
            }
            ... on TraitAttributeIsUnlocked {
              name
              dataType
              editable
              isUnlocked
            }
            ... on TraitAttributeChildLock {
              name
              dataType
              editable
              childLock
            }
            ... on TraitAttributeFault {
              name
              dataType
              editable
              fault {
                id
                name
                code
                description
                consumer
                guiPriority
                __typename
              }
            }
            ... on TraitAttributeTemperatureSetpoint {
              name
              dataType
              editable
              temperatureSetpoint
              minSetpoint
              maxSetpoint
            }
            ... on TraitAttributeTemperatureSetpointPercentage {
              name
              dataType
              editable
              temperatureSetpointPercentage
              minSetpoint
              maxSetpoint
            }
            ... on TraitAttributeAvailableClimateModes {
              name
              dataType
              editable
              availableClimateModes
            }
            ... on TraitAttributeClimateMode {
              name
              dataType
              editable
              climateMode
            }
            ... on TraitAttributeMaximumFloorTemperature {
              name
              dataType
              editable
              maximumFloorTemperature
            }
            ... on TraitAttributeFrostGuard {
              name
              dataType
              editable
              frostGuard
            }
            ... on TraitAttributeNightSwitch {
              name
              dataType
              editable
              nightSwitch
            }
            ... on TraitAttributeRegulatorMode {
              name
              dataType
              editable
              regulatorMode
            }
            ... on TraitAttributeHeatingElementActive {
              name
              dataType
              editable
              heatingElementActive
            }
            ... on TraitAttributeAlarmSound {
              name
              dataType
              editable
              alarmSound
            }
            ... on TraitAttributeAlarm {
              name
              dataType
              editable
              alarm
              snoozed
            }
            ... on TraitAttributeWaterDetectorCableConnected {
              name
              dataType
              editable
              waterDetectorCableConnected
            }
            ... on TraitAttributeWaterValvesConnected {
              name
              dataType
              editable
              waterValvesConnected
            }
            ... on TraitAttributeBracket {
              name
              dataType
              editable
              bracket
            }
            ... on TraitAttributeDisabled {
              name
              dataType
              editable
              disabled
            }
            ... on TraitAttributeLocation {
              name
              dataType
              editable
              latitude
              longitude
            }
            ... on TraitAttributeMotionDetected {
              name
              dataType
              editable
              lastMotionDetectedAt
            }
            ... on TraitAttributeValveInfo {
              name
              dataType
              editable
              valveType
              typeOfValve
              numValves
            }
            ... on TraitAttributeElectricVehicleCharger {
              name
              dataType
              editable
              status
              chargingStartedAt
              kwhCharged
              chargingSessionCost
            }
            ... on TraitAttributeTestMode {
              name
              dataType
              editable
              isOn
              lastTriggeredAt
            }
            ... on TraitAttributePing {
              name
              dataType
              editable
              ping
            }
          }
          __typename
        }
        accessPolicy {
          read
          write
          delete
          expiresAt
          __typename
        }
        __typename
      }
      hubs {
        id
        name
        hubType
        brandName
        firmwareVersion
        serialNumber
        macAddress
        manufacturerInformation
        wasWaterSensorTapePreviouslyConnected
        waterGuard {
          wasWaterSensorTapePreviouslyConnected
          mode
          tamperModeWarningTriggeredAt
          tamperModeTriggersAt
          overrideModeTriggeredAt
          showLevel1Warning
          showLevel2Warning
          __typename
        }
        hubTemplate {
          id
          name
          hubAssetUrl
          hubWithBgAssetUrl
          hubQrCodeAssetUrl
          hubConnectionAssetUrl
          hubSearchAssetUrl
          waterLeakDetectorAssetUrl
          waterLeakDetectorInstructionsAssetUrl
          waterLeakDetectorHelpAssetUrl
          waterSensorTapeAssetUrl
          waterSensorTapeInstructionsAssetUrl
          waterLeakSystemPressHubButtonAssetUrl
          sintefLogoAssetUrl
          __typename
        }
        area {
          id
          areaName
          areaType
          size
          floor
          children
          devices {
            id
            deviceType
            name
            serialNumber
            firmwareVersion
            imei
            macAddress
            ipAddress
            roomPlacement
            manufacturer {
              id
              name
              __typename
            }
            deviceTemplate {
              id
              deviceType
              name
              primaryPairingMode
              secondaryPairingModes
              __typename
            }
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            favorite
            favoriteChangedAt
            registeredAt
            canBeUnregistered
            area {
              id
              areaName
              areaType
              size
              floor
              children
              __typename
            }
            traits {
              traitType
              commands
              __typename
            }
            accessPolicy {
              read
              write
              delete
              expiresAt
              __typename
            }
            __typename
          }
          accessPolicies {
            actionType
            create
            update
            delete
            read
            __typename
          }
          __typename
        }
        devices {
          id
          deviceType
          name
          serialNumber
          firmwareVersion
          imei
          macAddress
          ipAddress
          roomPlacement
          manufacturer {
            id
            name
            logo {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            __typename
          }
          deviceTemplate {
            id
            deviceType
            name
            primaryPairingMode
            secondaryPairingModes
            manufacturer {
              id
              name
              __typename
            }
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            imageWithBg {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            installationInstructions {
              language
              header
              description
              __typename
            }
            instructions {
              connectionAssetUrl
              __typename
            }
            __typename
          }
          image {
            url
            urlSmall
            urlMedium
            urlLarge
            __typename
          }
          favorite
          favoriteChangedAt
          registeredAt
          canBeUnregistered
          area {
            id
            areaName
            areaType
            size
            floor
            children
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              __typename
            }
            accessPolicies {
              actionType
              create
              update
              delete
              read
              __typename
            }
            __typename
          }
          traits {
            traitType
            commands
            attributes {
              ... on TraitAttributeAvailableArmLevels {
                name
                dataType
                editable
              }
              ... on TraitAttributeIsArmed {
                name
                dataType
                editable
                isArmed
              }
              ... on TraitAttributeArmLevel {
                name
                dataType
                editable
                armLevel
              }
              ... on TraitAttributeDisarmDefaultTimeout {
                name
                dataType
                editable
                disarmDefaultTimeout
              }
              ... on TraitAttributeIsConnected {
                name
                dataType
                editable
                isConnected
              }
              ... on TraitAttributeHeartbeatInterval {
                name
                dataType
                editable
                heartbeatInterval
              }
              ... on TraitAttributeHeartbeatsThreshold {
                name
                dataType
                editable
                heartbeatsThreshold
              }
              ... on TraitAttributeGatewayConnected {
                name
                dataType
                editable
                ethernetConnected
                wifiConnected
                cellularConnected
              }
              ... on TraitAttributeWifiConfigured {
                name
                dataType
                editable
                wifiConfigured
              }
              ... on TraitAttributeNetworkSsid {
                name
                dataType
                editable
                networkSsid
              }
              ... on TraitAttributeNetworkCredentials {
                name
                dataType
                editable
                networkCredentials
              }
              ... on TraitAttributeCellularApn {
                name
                dataType
                editable
                cellularApn
              }
              ... on TraitAttributeCellularOperator {
                name
                dataType
                editable
                cellularOperator
              }
              ... on TraitAttributeBrightness {
                name
                dataType
                editable
                brightness
              }
              ... on TraitAttributeColor {
                name
                dataType
                editable
                hue
                saturation
              }
              ... on TraitAttributeIsOn {
                name
                dataType
                editable
                isOn
              }
              ... on TraitAttributeOpenPercent {
                name
                dataType
                editable
                openPercent
              }
              ... on TraitAttributeCurrentPowerSourceLevel {
                name
                dataType
                editable
                currentPowerSourceLevel
              }
              ... on TraitAttributeLowBatteryWarning {
                name
                dataType
                editable
                lowBatteryWarning
              }
              ... on TraitAttributeCurrentPowerSource {
                name
                dataType
                editable
                currentPowerSource
              }
              ... on TraitAttributeStatusCharging {
                name
                dataType
                editable
                statusCharging
              }
              ... on TraitAttributeCurrentPowerMode {
                name
                dataType
                editable
                currentPowerMode
              }
              ... on TraitAttributeAvailablePowerSources {
                name
                dataType
                editable
                availablePowerSources
              }
              ... on TraitAttributeBatteryVoltage {
                name
                dataType
                editable
                batteryVoltage
              }
              ... on TraitAttributeMainsVoltage {
                name
                dataType
                editable
                mainsVoltage
              }
              ... on TraitAttributeRunningPowerConsumption {
                name
                dataType
                editable
                runningPowerConsumption
              }
              ... on TraitAttributePriority {
                name
                dataType
                editable
                priority
              }
              ... on TraitAttributeAirQuality {
                name
                dataType
                editable
                airQuality
              }
              ... on TraitAttributeCo2 {
                name
                dataType
                editable
                co2
              }
              ... on TraitAttributeHumidity {
                name
                dataType
                editable
                humidity
                humidityIndicator
              }
              ... on TraitAttributeTemperature {
                name
                dataType
                editable
                temperature
              }
              ... on TraitAttributeVoc {
                name
                dataType
                editable
                voc
              }
              ... on TraitAttributeIsUnlocked {
                name
                dataType
                editable
                isUnlocked
              }
              ... on TraitAttributeChildLock {
                name
                dataType
                editable
                childLock
              }
              ... on TraitAttributeFault {
                name
                dataType
                editable
              }
              ... on TraitAttributeTemperatureSetpoint {
                name
                dataType
                editable
                temperatureSetpoint
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeTemperatureSetpointPercentage {
                name
                dataType
                editable
                temperatureSetpointPercentage
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeAvailableClimateModes {
                name
                dataType
                editable
                availableClimateModes
              }
              ... on TraitAttributeClimateMode {
                name
                dataType
                editable
                climateMode
              }
              ... on TraitAttributeMaximumFloorTemperature {
                name
                dataType
                editable
                maximumFloorTemperature
              }
              ... on TraitAttributeFrostGuard {
                name
                dataType
                editable
                frostGuard
              }
              ... on TraitAttributeNightSwitch {
                name
                dataType
                editable
                nightSwitch
              }
              ... on TraitAttributeRegulatorMode {
                name
                dataType
                editable
                regulatorMode
              }
              ... on TraitAttributeHeatingElementActive {
                name
                dataType
                editable
                heatingElementActive
              }
              ... on TraitAttributeAlarmSound {
                name
                dataType
                editable
                alarmSound
              }
              ... on TraitAttributeAlarm {
                name
                dataType
                editable
                alarm
                snoozed
              }
              ... on TraitAttributeWaterDetectorCableConnected {
                name
                dataType
                editable
                waterDetectorCableConnected
              }
              ... on TraitAttributeWaterValvesConnected {
                name
                dataType
                editable
                waterValvesConnected
              }
              ... on TraitAttributeBracket {
                name
                dataType
                editable
                bracket
              }
              ... on TraitAttributeDisabled {
                name
                dataType
                editable
                disabled
              }
              ... on TraitAttributeLocation {
                name
                dataType
                editable
                latitude
                longitude
              }
              ... on TraitAttributeMotionDetected {
                name
                dataType
                editable
                lastMotionDetectedAt
              }
              ... on TraitAttributeValveInfo {
                name
                dataType
                editable
                valveType
                typeOfValve
                numValves
              }
              ... on TraitAttributeElectricVehicleCharger {
                name
                dataType
                editable
                status
                chargingStartedAt
                kwhCharged
                chargingSessionCost
              }
              ... on TraitAttributeTestMode {
                name
                dataType
                editable
                isOn
                lastTriggeredAt
              }
              ... on TraitAttributePing {
                name
                dataType
                editable
                ping
              }
            }
            __typename
          }
          accessPolicy {
            read
            write
            delete
            expiresAt
            __typename
          }
          __typename
        }
        repeaters {
          id
          name
          hubType
          brandName
          firmwareVersion
          serialNumber
          macAddress
          manufacturerInformation
          wasWaterSensorTapePreviouslyConnected
          waterGuard {
            wasWaterSensorTapePreviouslyConnected
            mode
            tamperModeWarningTriggeredAt
            tamperModeTriggersAt
            overrideModeTriggeredAt
            showLevel1Warning
            showLevel2Warning
            __typename
          }
          hubTemplate {
            id
            name
            hubAssetUrl
            hubWithBgAssetUrl
            hubQrCodeAssetUrl
            hubConnectionAssetUrl
            hubSearchAssetUrl
            waterLeakDetectorAssetUrl
            waterLeakDetectorInstructionsAssetUrl
            waterLeakDetectorHelpAssetUrl
            waterSensorTapeAssetUrl
            waterSensorTapeInstructionsAssetUrl
            waterLeakSystemPressHubButtonAssetUrl
            sintefLogoAssetUrl
            __typename
          }
          area {
            id
            areaName
            areaType
            size
            floor
            children
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              __typename
            }
            accessPolicies {
              actionType
              create
              update
              delete
              read
              __typename
            }
            __typename
          }
          devices {
            id
            deviceType
            name
            serialNumber
            firmwareVersion
            imei
            macAddress
            ipAddress
            roomPlacement
            manufacturer {
              id
              name
              __typename
            }
            deviceTemplate {
              id
              deviceType
              name
              primaryPairingMode
              secondaryPairingModes
              __typename
            }
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            favorite
            favoriteChangedAt
            registeredAt
            canBeUnregistered
            area {
              id
              areaName
              areaType
              size
              floor
              children
              __typename
            }
            traits {
              traitType
              commands
              __typename
            }
            accessPolicy {
              read
              write
              delete
              expiresAt
              __typename
            }
            __typename
          }
          repeaters {
            id
            name
            hubType
            brandName
            firmwareVersion
            serialNumber
            macAddress
            manufacturerInformation
            wasWaterSensorTapePreviouslyConnected
            waterGuard {
              wasWaterSensorTapePreviouslyConnected
              mode
              tamperModeWarningTriggeredAt
              tamperModeTriggersAt
              overrideModeTriggeredAt
              showLevel1Warning
              showLevel2Warning
              __typename
            }
            hubTemplate {
              id
              name
              hubAssetUrl
              hubWithBgAssetUrl
              hubQrCodeAssetUrl
              hubConnectionAssetUrl
              hubSearchAssetUrl
              waterLeakDetectorAssetUrl
              waterLeakDetectorInstructionsAssetUrl
              waterLeakDetectorHelpAssetUrl
              waterSensorTapeAssetUrl
              waterSensorTapeInstructionsAssetUrl
              waterLeakSystemPressHubButtonAssetUrl
              sintefLogoAssetUrl
              __typename
            }
            area {
              id
              areaName
              areaType
              size
              floor
              children
              __typename
            }
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              __typename
            }
            repeaters {
              id
              name
              hubType
              brandName
              firmwareVersion
              serialNumber
              macAddress
              manufacturerInformation
              wasWaterSensorTapePreviouslyConnected
              __typename
            }
            traits {
              traitType
              commands
              __typename
            }
            __typename
          }
          traits {
            traitType
            commands
            attributes {
              ... on TraitAttributeAvailableArmLevels {
                name
                dataType
                editable
              }
              ... on TraitAttributeIsArmed {
                name
                dataType
                editable
                isArmed
              }
              ... on TraitAttributeArmLevel {
                name
                dataType
                editable
                armLevel
              }
              ... on TraitAttributeDisarmDefaultTimeout {
                name
                dataType
                editable
                disarmDefaultTimeout
              }
              ... on TraitAttributeIsConnected {
                name
                dataType
                editable
                isConnected
              }
              ... on TraitAttributeHeartbeatInterval {
                name
                dataType
                editable
                heartbeatInterval
              }
              ... on TraitAttributeHeartbeatsThreshold {
                name
                dataType
                editable
                heartbeatsThreshold
              }
              ... on TraitAttributeGatewayConnected {
                name
                dataType
                editable
                ethernetConnected
                wifiConnected
                cellularConnected
              }
              ... on TraitAttributeWifiConfigured {
                name
                dataType
                editable
                wifiConfigured
              }
              ... on TraitAttributeNetworkSsid {
                name
                dataType
                editable
                networkSsid
              }
              ... on TraitAttributeNetworkCredentials {
                name
                dataType
                editable
                networkCredentials
              }
              ... on TraitAttributeCellularApn {
                name
                dataType
                editable
                cellularApn
              }
              ... on TraitAttributeCellularOperator {
                name
                dataType
                editable
                cellularOperator
              }
              ... on TraitAttributeBrightness {
                name
                dataType
                editable
                brightness
              }
              ... on TraitAttributeColor {
                name
                dataType
                editable
                hue
                saturation
              }
              ... on TraitAttributeIsOn {
                name
                dataType
                editable
                isOn
              }
              ... on TraitAttributeOpenPercent {
                name
                dataType
                editable
                openPercent
              }
              ... on TraitAttributeCurrentPowerSourceLevel {
                name
                dataType
                editable
                currentPowerSourceLevel
              }
              ... on TraitAttributeLowBatteryWarning {
                name
                dataType
                editable
                lowBatteryWarning
              }
              ... on TraitAttributeCurrentPowerSource {
                name
                dataType
                editable
                currentPowerSource
              }
              ... on TraitAttributeStatusCharging {
                name
                dataType
                editable
                statusCharging
              }
              ... on TraitAttributeCurrentPowerMode {
                name
                dataType
                editable
                currentPowerMode
              }
              ... on TraitAttributeAvailablePowerSources {
                name
                dataType
                editable
                availablePowerSources
              }
              ... on TraitAttributeBatteryVoltage {
                name
                dataType
                editable
                batteryVoltage
              }
              ... on TraitAttributeMainsVoltage {
                name
                dataType
                editable
                mainsVoltage
              }
              ... on TraitAttributeRunningPowerConsumption {
                name
                dataType
                editable
                runningPowerConsumption
              }
              ... on TraitAttributePriority {
                name
                dataType
                editable
                priority
              }
              ... on TraitAttributeAirQuality {
                name
                dataType
                editable
                airQuality
              }
              ... on TraitAttributeCo2 {
                name
                dataType
                editable
                co2
              }
              ... on TraitAttributeHumidity {
                name
                dataType
                editable
                humidity
                humidityIndicator
              }
              ... on TraitAttributeTemperature {
                name
                dataType
                editable
                temperature
              }
              ... on TraitAttributeVoc {
                name
                dataType
                editable
                voc
              }
              ... on TraitAttributeIsUnlocked {
                name
                dataType
                editable
                isUnlocked
              }
              ... on TraitAttributeChildLock {
                name
                dataType
                editable
                childLock
              }
              ... on TraitAttributeFault {
                name
                dataType
                editable
              }
              ... on TraitAttributeTemperatureSetpoint {
                name
                dataType
                editable
                temperatureSetpoint
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeTemperatureSetpointPercentage {
                name
                dataType
                editable
                temperatureSetpointPercentage
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeAvailableClimateModes {
                name
                dataType
                editable
                availableClimateModes
              }
              ... on TraitAttributeClimateMode {
                name
                dataType
                editable
                climateMode
              }
              ... on TraitAttributeMaximumFloorTemperature {
                name
                dataType
                editable
                maximumFloorTemperature
              }
              ... on TraitAttributeFrostGuard {
                name
                dataType
                editable
                frostGuard
              }
              ... on TraitAttributeNightSwitch {
                name
                dataType
                editable
                nightSwitch
              }
              ... on TraitAttributeRegulatorMode {
                name
                dataType
                editable
                regulatorMode
              }
              ... on TraitAttributeHeatingElementActive {
                name
                dataType
                editable
                heatingElementActive
              }
              ... on TraitAttributeAlarmSound {
                name
                dataType
                editable
                alarmSound
              }
              ... on TraitAttributeAlarm {
                name
                dataType
                editable
                alarm
                snoozed
              }
              ... on TraitAttributeWaterDetectorCableConnected {
                name
                dataType
                editable
                waterDetectorCableConnected
              }
              ... on TraitAttributeWaterValvesConnected {
                name
                dataType
                editable
                waterValvesConnected
              }
              ... on TraitAttributeBracket {
                name
                dataType
                editable
                bracket
              }
              ... on TraitAttributeDisabled {
                name
                dataType
                editable
                disabled
              }
              ... on TraitAttributeLocation {
                name
                dataType
                editable
                latitude
                longitude
              }
              ... on TraitAttributeMotionDetected {
                name
                dataType
                editable
                lastMotionDetectedAt
              }
              ... on TraitAttributeValveInfo {
                name
                dataType
                editable
                valveType
                typeOfValve
                numValves
              }
              ... on TraitAttributeElectricVehicleCharger {
                name
                dataType
                editable
                status
                chargingStartedAt
                kwhCharged
                chargingSessionCost
              }
              ... on TraitAttributeTestMode {
                name
                dataType
                editable
                isOn
                lastTriggeredAt
              }
              ... on TraitAttributePing {
                name
                dataType
                editable
                ping
              }
            }
            __typename
          }
          __typename
        }
        traits {
          traitType
          commands
          attributes {
            ... on TraitAttributeAvailableArmLevels {
              name
              dataType
              editable
              availableArmLevels {
                id
                description
                __typename
              }
            }
            ... on TraitAttributeIsArmed {
              name
              dataType
              editable
              isArmed
            }
            ... on TraitAttributeArmLevel {
              name
              dataType
              editable
              armLevel
            }
            ... on TraitAttributeDisarmDefaultTimeout {
              name
              dataType
              editable
              disarmDefaultTimeout
            }
            ... on TraitAttributeIsConnected {
              name
              dataType
              editable
              isConnected
            }
            ... on TraitAttributeHeartbeatInterval {
              name
              dataType
              editable
              heartbeatInterval
            }
            ... on TraitAttributeHeartbeatsThreshold {
              name
              dataType
              editable
              heartbeatsThreshold
            }
            ... on TraitAttributeGatewayConnected {
              name
              dataType
              editable
              ethernetConnected
              wifiConnected
              cellularConnected
            }
            ... on TraitAttributeWifiConfigured {
              name
              dataType
              editable
              wifiConfigured
            }
            ... on TraitAttributeNetworkSsid {
              name
              dataType
              editable
              networkSsid
            }
            ... on TraitAttributeNetworkCredentials {
              name
              dataType
              editable
              networkCredentials
            }
            ... on TraitAttributeCellularApn {
              name
              dataType
              editable
              cellularApn
            }
            ... on TraitAttributeCellularOperator {
              name
              dataType
              editable
              cellularOperator
            }
            ... on TraitAttributeBrightness {
              name
              dataType
              editable
              brightness
            }
            ... on TraitAttributeColor {
              name
              dataType
              editable
              hue
              saturation
            }
            ... on TraitAttributeIsOn {
              name
              dataType
              editable
              isOn
            }
            ... on TraitAttributeOpenPercent {
              name
              dataType
              editable
              openPercent
            }
            ... on TraitAttributeCurrentPowerSourceLevel {
              name
              dataType
              editable
              currentPowerSourceLevel
            }
            ... on TraitAttributeLowBatteryWarning {
              name
              dataType
              editable
              lowBatteryWarning
            }
            ... on TraitAttributeCurrentPowerSource {
              name
              dataType
              editable
              currentPowerSource
            }
            ... on TraitAttributeStatusCharging {
              name
              dataType
              editable
              statusCharging
            }
            ... on TraitAttributeCurrentPowerMode {
              name
              dataType
              editable
              currentPowerMode
            }
            ... on TraitAttributeAvailablePowerSources {
              name
              dataType
              editable
              availablePowerSources
            }
            ... on TraitAttributeBatteryVoltage {
              name
              dataType
              editable
              batteryVoltage
            }
            ... on TraitAttributeMainsVoltage {
              name
              dataType
              editable
              mainsVoltage
            }
            ... on TraitAttributeRunningPowerConsumption {
              name
              dataType
              editable
              runningPowerConsumption
            }
            ... on TraitAttributePriority {
              name
              dataType
              editable
              priority
            }
            ... on TraitAttributeAirQuality {
              name
              dataType
              editable
              airQuality
            }
            ... on TraitAttributeCo2 {
              name
              dataType
              editable
              co2
            }
            ... on TraitAttributeHumidity {
              name
              dataType
              editable
              humidity
              humidityIndicator
            }
            ... on TraitAttributeTemperature {
              name
              dataType
              editable
              temperature
            }
            ... on TraitAttributeVoc {
              name
              dataType
              editable
              voc
            }
            ... on TraitAttributeIsUnlocked {
              name
              dataType
              editable
              isUnlocked
            }
            ... on TraitAttributeChildLock {
              name
              dataType
              editable
              childLock
            }
            ... on TraitAttributeFault {
              name
              dataType
              editable
              fault {
                id
                name
                code
                description
                consumer
                guiPriority
                __typename
              }
            }
            ... on TraitAttributeTemperatureSetpoint {
              name
              dataType
              editable
              temperatureSetpoint
              minSetpoint
              maxSetpoint
            }
            ... on TraitAttributeTemperatureSetpointPercentage {
              name
              dataType
              editable
              temperatureSetpointPercentage
              minSetpoint
              maxSetpoint
            }
            ... on TraitAttributeAvailableClimateModes {
              name
              dataType
              editable
              availableClimateModes
            }
            ... on TraitAttributeClimateMode {
              name
              dataType
              editable
              climateMode
            }
            ... on TraitAttributeMaximumFloorTemperature {
              name
              dataType
              editable
              maximumFloorTemperature
            }
            ... on TraitAttributeFrostGuard {
              name
              dataType
              editable
              frostGuard
            }
            ... on TraitAttributeNightSwitch {
              name
              dataType
              editable
              nightSwitch
            }
            ... on TraitAttributeRegulatorMode {
              name
              dataType
              editable
              regulatorMode
            }
            ... on TraitAttributeHeatingElementActive {
              name
              dataType
              editable
              heatingElementActive
            }
            ... on TraitAttributeAlarmSound {
              name
              dataType
              editable
              alarmSound
            }
            ... on TraitAttributeAlarm {
              name
              dataType
              editable
              alarm
              snoozed
            }
            ... on TraitAttributeWaterDetectorCableConnected {
              name
              dataType
              editable
              waterDetectorCableConnected
            }
            ... on TraitAttributeWaterValvesConnected {
              name
              dataType
              editable
              waterValvesConnected
            }
            ... on TraitAttributeBracket {
              name
              dataType
              editable
              bracket
            }
            ... on TraitAttributeDisabled {
              name
              dataType
              editable
              disabled
            }
            ... on TraitAttributeLocation {
              name
              dataType
              editable
              latitude
              longitude
            }
            ... on TraitAttributeMotionDetected {
              name
              dataType
              editable
              lastMotionDetectedAt
            }
            ... on TraitAttributeValveInfo {
              name
              dataType
              editable
              valveType
              typeOfValve
              numValves
            }
            ... on TraitAttributeElectricVehicleCharger {
              name
              dataType
              editable
              status
              chargingStartedAt
              kwhCharged
              chargingSessionCost
            }
            ... on TraitAttributeTestMode {
              name
              dataType
              editable
              isOn
              lastTriggeredAt
            }
            ... on TraitAttributePing {
              name
              dataType
              editable
              ping
            }
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const homes = /* GraphQL */ `
  query Homes($sort: HomeSortInput) {
    homes(sort: $sort) {
      id
      locationInfo {
        country
        city
        postalCode
        street
        streetNumber
        unitNumber
        geoLocation {
          latitude
          longitude
          __typename
        }
        __typename
      }
      homeInfo {
        nickname
        avatarUrl
        size
        ageOfBuilding
        numberOfFloors
        numberOfAdults
        numberOfChildren
        homeType
        dwellingUnit
        heatingType
        cooling
        ventilation
        propertyManagerName
        constructionType
        buildingYear
        __typename
      }
      avatar {
        url
        urlSmall
        urlMedium
        urlLarge
        uploadUrl
        __typename
      }
      areas {
        id
        areaName
        areaType
        size
        floor
        children
        devices {
          id
          deviceType
          name
          serialNumber
          firmwareVersion
          imei
          macAddress
          ipAddress
          roomPlacement
          manufacturer {
            id
            name
            logo {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            __typename
          }
          deviceTemplate {
            id
            deviceType
            name
            primaryPairingMode
            secondaryPairingModes
            manufacturer {
              id
              name
              __typename
            }
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            imageWithBg {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            installationInstructions {
              language
              header
              description
              __typename
            }
            instructions {
              connectionAssetUrl
              __typename
            }
            __typename
          }
          image {
            url
            urlSmall
            urlMedium
            urlLarge
            __typename
          }
          favorite
          favoriteChangedAt
          registeredAt
          canBeUnregistered
          area {
            id
            areaName
            areaType
            size
            floor
            children
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              __typename
            }
            accessPolicies {
              actionType
              create
              update
              delete
              read
              __typename
            }
            __typename
          }
          traits {
            traitType
            commands
            attributes {
              ... on TraitAttributeAvailableArmLevels {
                name
                dataType
                editable
              }
              ... on TraitAttributeIsArmed {
                name
                dataType
                editable
                isArmed
              }
              ... on TraitAttributeArmLevel {
                name
                dataType
                editable
                armLevel
              }
              ... on TraitAttributeDisarmDefaultTimeout {
                name
                dataType
                editable
                disarmDefaultTimeout
              }
              ... on TraitAttributeIsConnected {
                name
                dataType
                editable
                isConnected
              }
              ... on TraitAttributeHeartbeatInterval {
                name
                dataType
                editable
                heartbeatInterval
              }
              ... on TraitAttributeHeartbeatsThreshold {
                name
                dataType
                editable
                heartbeatsThreshold
              }
              ... on TraitAttributeGatewayConnected {
                name
                dataType
                editable
                ethernetConnected
                wifiConnected
                cellularConnected
              }
              ... on TraitAttributeWifiConfigured {
                name
                dataType
                editable
                wifiConfigured
              }
              ... on TraitAttributeNetworkSsid {
                name
                dataType
                editable
                networkSsid
              }
              ... on TraitAttributeNetworkCredentials {
                name
                dataType
                editable
                networkCredentials
              }
              ... on TraitAttributeCellularApn {
                name
                dataType
                editable
                cellularApn
              }
              ... on TraitAttributeCellularOperator {
                name
                dataType
                editable
                cellularOperator
              }
              ... on TraitAttributeBrightness {
                name
                dataType
                editable
                brightness
              }
              ... on TraitAttributeColor {
                name
                dataType
                editable
                hue
                saturation
              }
              ... on TraitAttributeIsOn {
                name
                dataType
                editable
                isOn
              }
              ... on TraitAttributeOpenPercent {
                name
                dataType
                editable
                openPercent
              }
              ... on TraitAttributeCurrentPowerSourceLevel {
                name
                dataType
                editable
                currentPowerSourceLevel
              }
              ... on TraitAttributeLowBatteryWarning {
                name
                dataType
                editable
                lowBatteryWarning
              }
              ... on TraitAttributeCurrentPowerSource {
                name
                dataType
                editable
                currentPowerSource
              }
              ... on TraitAttributeStatusCharging {
                name
                dataType
                editable
                statusCharging
              }
              ... on TraitAttributeCurrentPowerMode {
                name
                dataType
                editable
                currentPowerMode
              }
              ... on TraitAttributeAvailablePowerSources {
                name
                dataType
                editable
                availablePowerSources
              }
              ... on TraitAttributeBatteryVoltage {
                name
                dataType
                editable
                batteryVoltage
              }
              ... on TraitAttributeMainsVoltage {
                name
                dataType
                editable
                mainsVoltage
              }
              ... on TraitAttributeRunningPowerConsumption {
                name
                dataType
                editable
                runningPowerConsumption
              }
              ... on TraitAttributePriority {
                name
                dataType
                editable
                priority
              }
              ... on TraitAttributeAirQuality {
                name
                dataType
                editable
                airQuality
              }
              ... on TraitAttributeCo2 {
                name
                dataType
                editable
                co2
              }
              ... on TraitAttributeHumidity {
                name
                dataType
                editable
                humidity
                humidityIndicator
              }
              ... on TraitAttributeTemperature {
                name
                dataType
                editable
                temperature
              }
              ... on TraitAttributeVoc {
                name
                dataType
                editable
                voc
              }
              ... on TraitAttributeIsUnlocked {
                name
                dataType
                editable
                isUnlocked
              }
              ... on TraitAttributeChildLock {
                name
                dataType
                editable
                childLock
              }
              ... on TraitAttributeFault {
                name
                dataType
                editable
              }
              ... on TraitAttributeTemperatureSetpoint {
                name
                dataType
                editable
                temperatureSetpoint
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeTemperatureSetpointPercentage {
                name
                dataType
                editable
                temperatureSetpointPercentage
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeAvailableClimateModes {
                name
                dataType
                editable
                availableClimateModes
              }
              ... on TraitAttributeClimateMode {
                name
                dataType
                editable
                climateMode
              }
              ... on TraitAttributeMaximumFloorTemperature {
                name
                dataType
                editable
                maximumFloorTemperature
              }
              ... on TraitAttributeFrostGuard {
                name
                dataType
                editable
                frostGuard
              }
              ... on TraitAttributeNightSwitch {
                name
                dataType
                editable
                nightSwitch
              }
              ... on TraitAttributeRegulatorMode {
                name
                dataType
                editable
                regulatorMode
              }
              ... on TraitAttributeHeatingElementActive {
                name
                dataType
                editable
                heatingElementActive
              }
              ... on TraitAttributeAlarmSound {
                name
                dataType
                editable
                alarmSound
              }
              ... on TraitAttributeAlarm {
                name
                dataType
                editable
                alarm
                snoozed
              }
              ... on TraitAttributeWaterDetectorCableConnected {
                name
                dataType
                editable
                waterDetectorCableConnected
              }
              ... on TraitAttributeWaterValvesConnected {
                name
                dataType
                editable
                waterValvesConnected
              }
              ... on TraitAttributeBracket {
                name
                dataType
                editable
                bracket
              }
              ... on TraitAttributeDisabled {
                name
                dataType
                editable
                disabled
              }
              ... on TraitAttributeLocation {
                name
                dataType
                editable
                latitude
                longitude
              }
              ... on TraitAttributeMotionDetected {
                name
                dataType
                editable
                lastMotionDetectedAt
              }
              ... on TraitAttributeValveInfo {
                name
                dataType
                editable
                valveType
                typeOfValve
                numValves
              }
              ... on TraitAttributeElectricVehicleCharger {
                name
                dataType
                editable
                status
                chargingStartedAt
                kwhCharged
                chargingSessionCost
              }
              ... on TraitAttributeTestMode {
                name
                dataType
                editable
                isOn
                lastTriggeredAt
              }
              ... on TraitAttributePing {
                name
                dataType
                editable
                ping
              }
            }
            __typename
          }
          accessPolicy {
            read
            write
            delete
            expiresAt
            __typename
          }
          __typename
        }
        accessPolicies {
          actionType
          create
          update
          delete
          read
          __typename
        }
        __typename
      }
      devices {
        id
        deviceType
        name
        serialNumber
        firmwareVersion
        imei
        macAddress
        ipAddress
        roomPlacement
        manufacturer {
          id
          name
          logo {
            url
            urlSmall
            urlMedium
            urlLarge
            __typename
          }
          __typename
        }
        deviceTemplate {
          id
          deviceType
          name
          primaryPairingMode
          secondaryPairingModes
          manufacturer {
            id
            name
            logo {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            __typename
          }
          image {
            url
            urlSmall
            urlMedium
            urlLarge
            __typename
          }
          imageWithBg {
            url
            urlSmall
            urlMedium
            urlLarge
            __typename
          }
          installationInstructions {
            language
            header
            description
            installationAsset {
              url
              assetType
              __typename
            }
            connectionAsset {
              url
              assetType
              __typename
            }
            __typename
          }
          instructions {
            installation {
              assetUrl
              __typename
            }
            configuration {
              assetUrl
              __typename
            }
            help {
              assetUrl
              __typename
            }
            connectionAssetUrl
            __typename
          }
          __typename
        }
        image {
          url
          urlSmall
          urlMedium
          urlLarge
          __typename
        }
        favorite
        favoriteChangedAt
        registeredAt
        canBeUnregistered
        area {
          id
          areaName
          areaType
          size
          floor
          children
          devices {
            id
            deviceType
            name
            serialNumber
            firmwareVersion
            imei
            macAddress
            ipAddress
            roomPlacement
            manufacturer {
              id
              name
              __typename
            }
            deviceTemplate {
              id
              deviceType
              name
              primaryPairingMode
              secondaryPairingModes
              __typename
            }
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            favorite
            favoriteChangedAt
            registeredAt
            canBeUnregistered
            area {
              id
              areaName
              areaType
              size
              floor
              children
              __typename
            }
            traits {
              traitType
              commands
              __typename
            }
            accessPolicy {
              read
              write
              delete
              expiresAt
              __typename
            }
            __typename
          }
          accessPolicies {
            actionType
            create
            update
            delete
            read
            __typename
          }
          __typename
        }
        traits {
          traitType
          commands
          attributes {
            ... on TraitAttributeAvailableArmLevels {
              name
              dataType
              editable
              availableArmLevels {
                id
                description
                __typename
              }
            }
            ... on TraitAttributeIsArmed {
              name
              dataType
              editable
              isArmed
            }
            ... on TraitAttributeArmLevel {
              name
              dataType
              editable
              armLevel
            }
            ... on TraitAttributeDisarmDefaultTimeout {
              name
              dataType
              editable
              disarmDefaultTimeout
            }
            ... on TraitAttributeIsConnected {
              name
              dataType
              editable
              isConnected
            }
            ... on TraitAttributeHeartbeatInterval {
              name
              dataType
              editable
              heartbeatInterval
            }
            ... on TraitAttributeHeartbeatsThreshold {
              name
              dataType
              editable
              heartbeatsThreshold
            }
            ... on TraitAttributeGatewayConnected {
              name
              dataType
              editable
              ethernetConnected
              wifiConnected
              cellularConnected
            }
            ... on TraitAttributeWifiConfigured {
              name
              dataType
              editable
              wifiConfigured
            }
            ... on TraitAttributeNetworkSsid {
              name
              dataType
              editable
              networkSsid
            }
            ... on TraitAttributeNetworkCredentials {
              name
              dataType
              editable
              networkCredentials
            }
            ... on TraitAttributeCellularApn {
              name
              dataType
              editable
              cellularApn
            }
            ... on TraitAttributeCellularOperator {
              name
              dataType
              editable
              cellularOperator
            }
            ... on TraitAttributeBrightness {
              name
              dataType
              editable
              brightness
            }
            ... on TraitAttributeColor {
              name
              dataType
              editable
              hue
              saturation
            }
            ... on TraitAttributeIsOn {
              name
              dataType
              editable
              isOn
            }
            ... on TraitAttributeOpenPercent {
              name
              dataType
              editable
              openPercent
            }
            ... on TraitAttributeCurrentPowerSourceLevel {
              name
              dataType
              editable
              currentPowerSourceLevel
            }
            ... on TraitAttributeLowBatteryWarning {
              name
              dataType
              editable
              lowBatteryWarning
            }
            ... on TraitAttributeCurrentPowerSource {
              name
              dataType
              editable
              currentPowerSource
            }
            ... on TraitAttributeStatusCharging {
              name
              dataType
              editable
              statusCharging
            }
            ... on TraitAttributeCurrentPowerMode {
              name
              dataType
              editable
              currentPowerMode
            }
            ... on TraitAttributeAvailablePowerSources {
              name
              dataType
              editable
              availablePowerSources
            }
            ... on TraitAttributeBatteryVoltage {
              name
              dataType
              editable
              batteryVoltage
            }
            ... on TraitAttributeMainsVoltage {
              name
              dataType
              editable
              mainsVoltage
            }
            ... on TraitAttributeRunningPowerConsumption {
              name
              dataType
              editable
              runningPowerConsumption
            }
            ... on TraitAttributePriority {
              name
              dataType
              editable
              priority
            }
            ... on TraitAttributeAirQuality {
              name
              dataType
              editable
              airQuality
            }
            ... on TraitAttributeCo2 {
              name
              dataType
              editable
              co2
            }
            ... on TraitAttributeHumidity {
              name
              dataType
              editable
              humidity
              humidityIndicator
            }
            ... on TraitAttributeTemperature {
              name
              dataType
              editable
              temperature
            }
            ... on TraitAttributeVoc {
              name
              dataType
              editable
              voc
            }
            ... on TraitAttributeIsUnlocked {
              name
              dataType
              editable
              isUnlocked
            }
            ... on TraitAttributeChildLock {
              name
              dataType
              editable
              childLock
            }
            ... on TraitAttributeFault {
              name
              dataType
              editable
              fault {
                id
                name
                code
                description
                consumer
                guiPriority
                __typename
              }
            }
            ... on TraitAttributeTemperatureSetpoint {
              name
              dataType
              editable
              temperatureSetpoint
              minSetpoint
              maxSetpoint
            }
            ... on TraitAttributeTemperatureSetpointPercentage {
              name
              dataType
              editable
              temperatureSetpointPercentage
              minSetpoint
              maxSetpoint
            }
            ... on TraitAttributeAvailableClimateModes {
              name
              dataType
              editable
              availableClimateModes
            }
            ... on TraitAttributeClimateMode {
              name
              dataType
              editable
              climateMode
            }
            ... on TraitAttributeMaximumFloorTemperature {
              name
              dataType
              editable
              maximumFloorTemperature
            }
            ... on TraitAttributeFrostGuard {
              name
              dataType
              editable
              frostGuard
            }
            ... on TraitAttributeNightSwitch {
              name
              dataType
              editable
              nightSwitch
            }
            ... on TraitAttributeRegulatorMode {
              name
              dataType
              editable
              regulatorMode
            }
            ... on TraitAttributeHeatingElementActive {
              name
              dataType
              editable
              heatingElementActive
            }
            ... on TraitAttributeAlarmSound {
              name
              dataType
              editable
              alarmSound
            }
            ... on TraitAttributeAlarm {
              name
              dataType
              editable
              alarm
              snoozed
            }
            ... on TraitAttributeWaterDetectorCableConnected {
              name
              dataType
              editable
              waterDetectorCableConnected
            }
            ... on TraitAttributeWaterValvesConnected {
              name
              dataType
              editable
              waterValvesConnected
            }
            ... on TraitAttributeBracket {
              name
              dataType
              editable
              bracket
            }
            ... on TraitAttributeDisabled {
              name
              dataType
              editable
              disabled
            }
            ... on TraitAttributeLocation {
              name
              dataType
              editable
              latitude
              longitude
            }
            ... on TraitAttributeMotionDetected {
              name
              dataType
              editable
              lastMotionDetectedAt
            }
            ... on TraitAttributeValveInfo {
              name
              dataType
              editable
              valveType
              typeOfValve
              numValves
            }
            ... on TraitAttributeElectricVehicleCharger {
              name
              dataType
              editable
              status
              chargingStartedAt
              kwhCharged
              chargingSessionCost
            }
            ... on TraitAttributeTestMode {
              name
              dataType
              editable
              isOn
              lastTriggeredAt
            }
            ... on TraitAttributePing {
              name
              dataType
              editable
              ping
            }
          }
          __typename
        }
        accessPolicy {
          read
          write
          delete
          expiresAt
          __typename
        }
        __typename
      }
      hubs {
        id
        name
        hubType
        brandName
        firmwareVersion
        serialNumber
        macAddress
        manufacturerInformation
        wasWaterSensorTapePreviouslyConnected
        waterGuard {
          wasWaterSensorTapePreviouslyConnected
          mode
          tamperModeWarningTriggeredAt
          tamperModeTriggersAt
          overrideModeTriggeredAt
          showLevel1Warning
          showLevel2Warning
          __typename
        }
        hubTemplate {
          id
          name
          hubAssetUrl
          hubWithBgAssetUrl
          hubQrCodeAssetUrl
          hubConnectionAssetUrl
          hubSearchAssetUrl
          waterLeakDetectorAssetUrl
          waterLeakDetectorInstructionsAssetUrl
          waterLeakDetectorHelpAssetUrl
          waterSensorTapeAssetUrl
          waterSensorTapeInstructionsAssetUrl
          waterLeakSystemPressHubButtonAssetUrl
          sintefLogoAssetUrl
          __typename
        }
        area {
          id
          areaName
          areaType
          size
          floor
          children
          devices {
            id
            deviceType
            name
            serialNumber
            firmwareVersion
            imei
            macAddress
            ipAddress
            roomPlacement
            manufacturer {
              id
              name
              __typename
            }
            deviceTemplate {
              id
              deviceType
              name
              primaryPairingMode
              secondaryPairingModes
              __typename
            }
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            favorite
            favoriteChangedAt
            registeredAt
            canBeUnregistered
            area {
              id
              areaName
              areaType
              size
              floor
              children
              __typename
            }
            traits {
              traitType
              commands
              __typename
            }
            accessPolicy {
              read
              write
              delete
              expiresAt
              __typename
            }
            __typename
          }
          accessPolicies {
            actionType
            create
            update
            delete
            read
            __typename
          }
          __typename
        }
        devices {
          id
          deviceType
          name
          serialNumber
          firmwareVersion
          imei
          macAddress
          ipAddress
          roomPlacement
          manufacturer {
            id
            name
            logo {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            __typename
          }
          deviceTemplate {
            id
            deviceType
            name
            primaryPairingMode
            secondaryPairingModes
            manufacturer {
              id
              name
              __typename
            }
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            imageWithBg {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            installationInstructions {
              language
              header
              description
              __typename
            }
            instructions {
              connectionAssetUrl
              __typename
            }
            __typename
          }
          image {
            url
            urlSmall
            urlMedium
            urlLarge
            __typename
          }
          favorite
          favoriteChangedAt
          registeredAt
          canBeUnregistered
          area {
            id
            areaName
            areaType
            size
            floor
            children
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              __typename
            }
            accessPolicies {
              actionType
              create
              update
              delete
              read
              __typename
            }
            __typename
          }
          traits {
            traitType
            commands
            attributes {
              ... on TraitAttributeAvailableArmLevels {
                name
                dataType
                editable
              }
              ... on TraitAttributeIsArmed {
                name
                dataType
                editable
                isArmed
              }
              ... on TraitAttributeArmLevel {
                name
                dataType
                editable
                armLevel
              }
              ... on TraitAttributeDisarmDefaultTimeout {
                name
                dataType
                editable
                disarmDefaultTimeout
              }
              ... on TraitAttributeIsConnected {
                name
                dataType
                editable
                isConnected
              }
              ... on TraitAttributeHeartbeatInterval {
                name
                dataType
                editable
                heartbeatInterval
              }
              ... on TraitAttributeHeartbeatsThreshold {
                name
                dataType
                editable
                heartbeatsThreshold
              }
              ... on TraitAttributeGatewayConnected {
                name
                dataType
                editable
                ethernetConnected
                wifiConnected
                cellularConnected
              }
              ... on TraitAttributeWifiConfigured {
                name
                dataType
                editable
                wifiConfigured
              }
              ... on TraitAttributeNetworkSsid {
                name
                dataType
                editable
                networkSsid
              }
              ... on TraitAttributeNetworkCredentials {
                name
                dataType
                editable
                networkCredentials
              }
              ... on TraitAttributeCellularApn {
                name
                dataType
                editable
                cellularApn
              }
              ... on TraitAttributeCellularOperator {
                name
                dataType
                editable
                cellularOperator
              }
              ... on TraitAttributeBrightness {
                name
                dataType
                editable
                brightness
              }
              ... on TraitAttributeColor {
                name
                dataType
                editable
                hue
                saturation
              }
              ... on TraitAttributeIsOn {
                name
                dataType
                editable
                isOn
              }
              ... on TraitAttributeOpenPercent {
                name
                dataType
                editable
                openPercent
              }
              ... on TraitAttributeCurrentPowerSourceLevel {
                name
                dataType
                editable
                currentPowerSourceLevel
              }
              ... on TraitAttributeLowBatteryWarning {
                name
                dataType
                editable
                lowBatteryWarning
              }
              ... on TraitAttributeCurrentPowerSource {
                name
                dataType
                editable
                currentPowerSource
              }
              ... on TraitAttributeStatusCharging {
                name
                dataType
                editable
                statusCharging
              }
              ... on TraitAttributeCurrentPowerMode {
                name
                dataType
                editable
                currentPowerMode
              }
              ... on TraitAttributeAvailablePowerSources {
                name
                dataType
                editable
                availablePowerSources
              }
              ... on TraitAttributeBatteryVoltage {
                name
                dataType
                editable
                batteryVoltage
              }
              ... on TraitAttributeMainsVoltage {
                name
                dataType
                editable
                mainsVoltage
              }
              ... on TraitAttributeRunningPowerConsumption {
                name
                dataType
                editable
                runningPowerConsumption
              }
              ... on TraitAttributePriority {
                name
                dataType
                editable
                priority
              }
              ... on TraitAttributeAirQuality {
                name
                dataType
                editable
                airQuality
              }
              ... on TraitAttributeCo2 {
                name
                dataType
                editable
                co2
              }
              ... on TraitAttributeHumidity {
                name
                dataType
                editable
                humidity
                humidityIndicator
              }
              ... on TraitAttributeTemperature {
                name
                dataType
                editable
                temperature
              }
              ... on TraitAttributeVoc {
                name
                dataType
                editable
                voc
              }
              ... on TraitAttributeIsUnlocked {
                name
                dataType
                editable
                isUnlocked
              }
              ... on TraitAttributeChildLock {
                name
                dataType
                editable
                childLock
              }
              ... on TraitAttributeFault {
                name
                dataType
                editable
              }
              ... on TraitAttributeTemperatureSetpoint {
                name
                dataType
                editable
                temperatureSetpoint
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeTemperatureSetpointPercentage {
                name
                dataType
                editable
                temperatureSetpointPercentage
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeAvailableClimateModes {
                name
                dataType
                editable
                availableClimateModes
              }
              ... on TraitAttributeClimateMode {
                name
                dataType
                editable
                climateMode
              }
              ... on TraitAttributeMaximumFloorTemperature {
                name
                dataType
                editable
                maximumFloorTemperature
              }
              ... on TraitAttributeFrostGuard {
                name
                dataType
                editable
                frostGuard
              }
              ... on TraitAttributeNightSwitch {
                name
                dataType
                editable
                nightSwitch
              }
              ... on TraitAttributeRegulatorMode {
                name
                dataType
                editable
                regulatorMode
              }
              ... on TraitAttributeHeatingElementActive {
                name
                dataType
                editable
                heatingElementActive
              }
              ... on TraitAttributeAlarmSound {
                name
                dataType
                editable
                alarmSound
              }
              ... on TraitAttributeAlarm {
                name
                dataType
                editable
                alarm
                snoozed
              }
              ... on TraitAttributeWaterDetectorCableConnected {
                name
                dataType
                editable
                waterDetectorCableConnected
              }
              ... on TraitAttributeWaterValvesConnected {
                name
                dataType
                editable
                waterValvesConnected
              }
              ... on TraitAttributeBracket {
                name
                dataType
                editable
                bracket
              }
              ... on TraitAttributeDisabled {
                name
                dataType
                editable
                disabled
              }
              ... on TraitAttributeLocation {
                name
                dataType
                editable
                latitude
                longitude
              }
              ... on TraitAttributeMotionDetected {
                name
                dataType
                editable
                lastMotionDetectedAt
              }
              ... on TraitAttributeValveInfo {
                name
                dataType
                editable
                valveType
                typeOfValve
                numValves
              }
              ... on TraitAttributeElectricVehicleCharger {
                name
                dataType
                editable
                status
                chargingStartedAt
                kwhCharged
                chargingSessionCost
              }
              ... on TraitAttributeTestMode {
                name
                dataType
                editable
                isOn
                lastTriggeredAt
              }
              ... on TraitAttributePing {
                name
                dataType
                editable
                ping
              }
            }
            __typename
          }
          accessPolicy {
            read
            write
            delete
            expiresAt
            __typename
          }
          __typename
        }
        repeaters {
          id
          name
          hubType
          brandName
          firmwareVersion
          serialNumber
          macAddress
          manufacturerInformation
          wasWaterSensorTapePreviouslyConnected
          waterGuard {
            wasWaterSensorTapePreviouslyConnected
            mode
            tamperModeWarningTriggeredAt
            tamperModeTriggersAt
            overrideModeTriggeredAt
            showLevel1Warning
            showLevel2Warning
            __typename
          }
          hubTemplate {
            id
            name
            hubAssetUrl
            hubWithBgAssetUrl
            hubQrCodeAssetUrl
            hubConnectionAssetUrl
            hubSearchAssetUrl
            waterLeakDetectorAssetUrl
            waterLeakDetectorInstructionsAssetUrl
            waterLeakDetectorHelpAssetUrl
            waterSensorTapeAssetUrl
            waterSensorTapeInstructionsAssetUrl
            waterLeakSystemPressHubButtonAssetUrl
            sintefLogoAssetUrl
            __typename
          }
          area {
            id
            areaName
            areaType
            size
            floor
            children
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              __typename
            }
            accessPolicies {
              actionType
              create
              update
              delete
              read
              __typename
            }
            __typename
          }
          devices {
            id
            deviceType
            name
            serialNumber
            firmwareVersion
            imei
            macAddress
            ipAddress
            roomPlacement
            manufacturer {
              id
              name
              __typename
            }
            deviceTemplate {
              id
              deviceType
              name
              primaryPairingMode
              secondaryPairingModes
              __typename
            }
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            favorite
            favoriteChangedAt
            registeredAt
            canBeUnregistered
            area {
              id
              areaName
              areaType
              size
              floor
              children
              __typename
            }
            traits {
              traitType
              commands
              __typename
            }
            accessPolicy {
              read
              write
              delete
              expiresAt
              __typename
            }
            __typename
          }
          repeaters {
            id
            name
            hubType
            brandName
            firmwareVersion
            serialNumber
            macAddress
            manufacturerInformation
            wasWaterSensorTapePreviouslyConnected
            waterGuard {
              wasWaterSensorTapePreviouslyConnected
              mode
              tamperModeWarningTriggeredAt
              tamperModeTriggersAt
              overrideModeTriggeredAt
              showLevel1Warning
              showLevel2Warning
              __typename
            }
            hubTemplate {
              id
              name
              hubAssetUrl
              hubWithBgAssetUrl
              hubQrCodeAssetUrl
              hubConnectionAssetUrl
              hubSearchAssetUrl
              waterLeakDetectorAssetUrl
              waterLeakDetectorInstructionsAssetUrl
              waterLeakDetectorHelpAssetUrl
              waterSensorTapeAssetUrl
              waterSensorTapeInstructionsAssetUrl
              waterLeakSystemPressHubButtonAssetUrl
              sintefLogoAssetUrl
              __typename
            }
            area {
              id
              areaName
              areaType
              size
              floor
              children
              __typename
            }
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              __typename
            }
            repeaters {
              id
              name
              hubType
              brandName
              firmwareVersion
              serialNumber
              macAddress
              manufacturerInformation
              wasWaterSensorTapePreviouslyConnected
              __typename
            }
            traits {
              traitType
              commands
              __typename
            }
            __typename
          }
          traits {
            traitType
            commands
            attributes {
              ... on TraitAttributeAvailableArmLevels {
                name
                dataType
                editable
              }
              ... on TraitAttributeIsArmed {
                name
                dataType
                editable
                isArmed
              }
              ... on TraitAttributeArmLevel {
                name
                dataType
                editable
                armLevel
              }
              ... on TraitAttributeDisarmDefaultTimeout {
                name
                dataType
                editable
                disarmDefaultTimeout
              }
              ... on TraitAttributeIsConnected {
                name
                dataType
                editable
                isConnected
              }
              ... on TraitAttributeHeartbeatInterval {
                name
                dataType
                editable
                heartbeatInterval
              }
              ... on TraitAttributeHeartbeatsThreshold {
                name
                dataType
                editable
                heartbeatsThreshold
              }
              ... on TraitAttributeGatewayConnected {
                name
                dataType
                editable
                ethernetConnected
                wifiConnected
                cellularConnected
              }
              ... on TraitAttributeWifiConfigured {
                name
                dataType
                editable
                wifiConfigured
              }
              ... on TraitAttributeNetworkSsid {
                name
                dataType
                editable
                networkSsid
              }
              ... on TraitAttributeNetworkCredentials {
                name
                dataType
                editable
                networkCredentials
              }
              ... on TraitAttributeCellularApn {
                name
                dataType
                editable
                cellularApn
              }
              ... on TraitAttributeCellularOperator {
                name
                dataType
                editable
                cellularOperator
              }
              ... on TraitAttributeBrightness {
                name
                dataType
                editable
                brightness
              }
              ... on TraitAttributeColor {
                name
                dataType
                editable
                hue
                saturation
              }
              ... on TraitAttributeIsOn {
                name
                dataType
                editable
                isOn
              }
              ... on TraitAttributeOpenPercent {
                name
                dataType
                editable
                openPercent
              }
              ... on TraitAttributeCurrentPowerSourceLevel {
                name
                dataType
                editable
                currentPowerSourceLevel
              }
              ... on TraitAttributeLowBatteryWarning {
                name
                dataType
                editable
                lowBatteryWarning
              }
              ... on TraitAttributeCurrentPowerSource {
                name
                dataType
                editable
                currentPowerSource
              }
              ... on TraitAttributeStatusCharging {
                name
                dataType
                editable
                statusCharging
              }
              ... on TraitAttributeCurrentPowerMode {
                name
                dataType
                editable
                currentPowerMode
              }
              ... on TraitAttributeAvailablePowerSources {
                name
                dataType
                editable
                availablePowerSources
              }
              ... on TraitAttributeBatteryVoltage {
                name
                dataType
                editable
                batteryVoltage
              }
              ... on TraitAttributeMainsVoltage {
                name
                dataType
                editable
                mainsVoltage
              }
              ... on TraitAttributeRunningPowerConsumption {
                name
                dataType
                editable
                runningPowerConsumption
              }
              ... on TraitAttributePriority {
                name
                dataType
                editable
                priority
              }
              ... on TraitAttributeAirQuality {
                name
                dataType
                editable
                airQuality
              }
              ... on TraitAttributeCo2 {
                name
                dataType
                editable
                co2
              }
              ... on TraitAttributeHumidity {
                name
                dataType
                editable
                humidity
                humidityIndicator
              }
              ... on TraitAttributeTemperature {
                name
                dataType
                editable
                temperature
              }
              ... on TraitAttributeVoc {
                name
                dataType
                editable
                voc
              }
              ... on TraitAttributeIsUnlocked {
                name
                dataType
                editable
                isUnlocked
              }
              ... on TraitAttributeChildLock {
                name
                dataType
                editable
                childLock
              }
              ... on TraitAttributeFault {
                name
                dataType
                editable
              }
              ... on TraitAttributeTemperatureSetpoint {
                name
                dataType
                editable
                temperatureSetpoint
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeTemperatureSetpointPercentage {
                name
                dataType
                editable
                temperatureSetpointPercentage
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeAvailableClimateModes {
                name
                dataType
                editable
                availableClimateModes
              }
              ... on TraitAttributeClimateMode {
                name
                dataType
                editable
                climateMode
              }
              ... on TraitAttributeMaximumFloorTemperature {
                name
                dataType
                editable
                maximumFloorTemperature
              }
              ... on TraitAttributeFrostGuard {
                name
                dataType
                editable
                frostGuard
              }
              ... on TraitAttributeNightSwitch {
                name
                dataType
                editable
                nightSwitch
              }
              ... on TraitAttributeRegulatorMode {
                name
                dataType
                editable
                regulatorMode
              }
              ... on TraitAttributeHeatingElementActive {
                name
                dataType
                editable
                heatingElementActive
              }
              ... on TraitAttributeAlarmSound {
                name
                dataType
                editable
                alarmSound
              }
              ... on TraitAttributeAlarm {
                name
                dataType
                editable
                alarm
                snoozed
              }
              ... on TraitAttributeWaterDetectorCableConnected {
                name
                dataType
                editable
                waterDetectorCableConnected
              }
              ... on TraitAttributeWaterValvesConnected {
                name
                dataType
                editable
                waterValvesConnected
              }
              ... on TraitAttributeBracket {
                name
                dataType
                editable
                bracket
              }
              ... on TraitAttributeDisabled {
                name
                dataType
                editable
                disabled
              }
              ... on TraitAttributeLocation {
                name
                dataType
                editable
                latitude
                longitude
              }
              ... on TraitAttributeMotionDetected {
                name
                dataType
                editable
                lastMotionDetectedAt
              }
              ... on TraitAttributeValveInfo {
                name
                dataType
                editable
                valveType
                typeOfValve
                numValves
              }
              ... on TraitAttributeElectricVehicleCharger {
                name
                dataType
                editable
                status
                chargingStartedAt
                kwhCharged
                chargingSessionCost
              }
              ... on TraitAttributeTestMode {
                name
                dataType
                editable
                isOn
                lastTriggeredAt
              }
              ... on TraitAttributePing {
                name
                dataType
                editable
                ping
              }
            }
            __typename
          }
          __typename
        }
        traits {
          traitType
          commands
          attributes {
            ... on TraitAttributeAvailableArmLevels {
              name
              dataType
              editable
              availableArmLevels {
                id
                description
                __typename
              }
            }
            ... on TraitAttributeIsArmed {
              name
              dataType
              editable
              isArmed
            }
            ... on TraitAttributeArmLevel {
              name
              dataType
              editable
              armLevel
            }
            ... on TraitAttributeDisarmDefaultTimeout {
              name
              dataType
              editable
              disarmDefaultTimeout
            }
            ... on TraitAttributeIsConnected {
              name
              dataType
              editable
              isConnected
            }
            ... on TraitAttributeHeartbeatInterval {
              name
              dataType
              editable
              heartbeatInterval
            }
            ... on TraitAttributeHeartbeatsThreshold {
              name
              dataType
              editable
              heartbeatsThreshold
            }
            ... on TraitAttributeGatewayConnected {
              name
              dataType
              editable
              ethernetConnected
              wifiConnected
              cellularConnected
            }
            ... on TraitAttributeWifiConfigured {
              name
              dataType
              editable
              wifiConfigured
            }
            ... on TraitAttributeNetworkSsid {
              name
              dataType
              editable
              networkSsid
            }
            ... on TraitAttributeNetworkCredentials {
              name
              dataType
              editable
              networkCredentials
            }
            ... on TraitAttributeCellularApn {
              name
              dataType
              editable
              cellularApn
            }
            ... on TraitAttributeCellularOperator {
              name
              dataType
              editable
              cellularOperator
            }
            ... on TraitAttributeBrightness {
              name
              dataType
              editable
              brightness
            }
            ... on TraitAttributeColor {
              name
              dataType
              editable
              hue
              saturation
            }
            ... on TraitAttributeIsOn {
              name
              dataType
              editable
              isOn
            }
            ... on TraitAttributeOpenPercent {
              name
              dataType
              editable
              openPercent
            }
            ... on TraitAttributeCurrentPowerSourceLevel {
              name
              dataType
              editable
              currentPowerSourceLevel
            }
            ... on TraitAttributeLowBatteryWarning {
              name
              dataType
              editable
              lowBatteryWarning
            }
            ... on TraitAttributeCurrentPowerSource {
              name
              dataType
              editable
              currentPowerSource
            }
            ... on TraitAttributeStatusCharging {
              name
              dataType
              editable
              statusCharging
            }
            ... on TraitAttributeCurrentPowerMode {
              name
              dataType
              editable
              currentPowerMode
            }
            ... on TraitAttributeAvailablePowerSources {
              name
              dataType
              editable
              availablePowerSources
            }
            ... on TraitAttributeBatteryVoltage {
              name
              dataType
              editable
              batteryVoltage
            }
            ... on TraitAttributeMainsVoltage {
              name
              dataType
              editable
              mainsVoltage
            }
            ... on TraitAttributeRunningPowerConsumption {
              name
              dataType
              editable
              runningPowerConsumption
            }
            ... on TraitAttributePriority {
              name
              dataType
              editable
              priority
            }
            ... on TraitAttributeAirQuality {
              name
              dataType
              editable
              airQuality
            }
            ... on TraitAttributeCo2 {
              name
              dataType
              editable
              co2
            }
            ... on TraitAttributeHumidity {
              name
              dataType
              editable
              humidity
              humidityIndicator
            }
            ... on TraitAttributeTemperature {
              name
              dataType
              editable
              temperature
            }
            ... on TraitAttributeVoc {
              name
              dataType
              editable
              voc
            }
            ... on TraitAttributeIsUnlocked {
              name
              dataType
              editable
              isUnlocked
            }
            ... on TraitAttributeChildLock {
              name
              dataType
              editable
              childLock
            }
            ... on TraitAttributeFault {
              name
              dataType
              editable
              fault {
                id
                name
                code
                description
                consumer
                guiPriority
                __typename
              }
            }
            ... on TraitAttributeTemperatureSetpoint {
              name
              dataType
              editable
              temperatureSetpoint
              minSetpoint
              maxSetpoint
            }
            ... on TraitAttributeTemperatureSetpointPercentage {
              name
              dataType
              editable
              temperatureSetpointPercentage
              minSetpoint
              maxSetpoint
            }
            ... on TraitAttributeAvailableClimateModes {
              name
              dataType
              editable
              availableClimateModes
            }
            ... on TraitAttributeClimateMode {
              name
              dataType
              editable
              climateMode
            }
            ... on TraitAttributeMaximumFloorTemperature {
              name
              dataType
              editable
              maximumFloorTemperature
            }
            ... on TraitAttributeFrostGuard {
              name
              dataType
              editable
              frostGuard
            }
            ... on TraitAttributeNightSwitch {
              name
              dataType
              editable
              nightSwitch
            }
            ... on TraitAttributeRegulatorMode {
              name
              dataType
              editable
              regulatorMode
            }
            ... on TraitAttributeHeatingElementActive {
              name
              dataType
              editable
              heatingElementActive
            }
            ... on TraitAttributeAlarmSound {
              name
              dataType
              editable
              alarmSound
            }
            ... on TraitAttributeAlarm {
              name
              dataType
              editable
              alarm
              snoozed
            }
            ... on TraitAttributeWaterDetectorCableConnected {
              name
              dataType
              editable
              waterDetectorCableConnected
            }
            ... on TraitAttributeWaterValvesConnected {
              name
              dataType
              editable
              waterValvesConnected
            }
            ... on TraitAttributeBracket {
              name
              dataType
              editable
              bracket
            }
            ... on TraitAttributeDisabled {
              name
              dataType
              editable
              disabled
            }
            ... on TraitAttributeLocation {
              name
              dataType
              editable
              latitude
              longitude
            }
            ... on TraitAttributeMotionDetected {
              name
              dataType
              editable
              lastMotionDetectedAt
            }
            ... on TraitAttributeValveInfo {
              name
              dataType
              editable
              valveType
              typeOfValve
              numValves
            }
            ... on TraitAttributeElectricVehicleCharger {
              name
              dataType
              editable
              status
              chargingStartedAt
              kwhCharged
              chargingSessionCost
            }
            ... on TraitAttributeTestMode {
              name
              dataType
              editable
              isOn
              lastTriggeredAt
            }
            ... on TraitAttributePing {
              name
              dataType
              editable
              ping
            }
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const homeMemberInvitations = /* GraphQL */ `
  query HomeMemberInvitations($homeId: ID!) {
    homeMemberInvitations(homeId: $homeId) {
      invitations {
        id
        firstName
        lastName
        email
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        role
        status
        expiresAt
        __typename
      }
      __typename
    }
  }
`;
export const homeMemberInvitation = /* GraphQL */ `
  query HomeMemberInvitation($homeMemberInvitationId: ID!, $token: String!) {
    homeMemberInvitation(
      homeMemberInvitationId: $homeMemberInvitationId
      token: $token
    ) {
      id
      createdAt
      home {
        id
        name
        type
        locationInfo {
          country
          city
          postalCode
          street
          streetNumber
          unitNumber
          geoLocation {
            latitude
            longitude
            __typename
          }
          __typename
        }
        __typename
      }
      invitingUser {
        firstName
        lastName
        __typename
      }
      role
      expiresAt
      __typename
    }
  }
`;
export const homeMember = /* GraphQL */ `
  query HomeMember($homeMemberId: ID!) {
    homeMember(homeMemberId: $homeMemberId) {
      ... on HomeOwner {
        id
        firstName
        lastName
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        account {
          id
          email
          avatar {
            url
            urlSmall
            urlMedium
            urlLarge
            uploadUrl
            __typename
          }
          __typename
        }
        isEmergencyContact
        isAlarmStationContact
      }
      ... on HomeAdministrator {
        id
        firstName
        lastName
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        account {
          id
          email
          avatar {
            url
            urlSmall
            urlMedium
            urlLarge
            uploadUrl
            __typename
          }
          __typename
        }
        isEmergencyContact
        isAlarmStationContact
      }
      ... on HomeUser {
        id
        firstName
        lastName
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        account {
          id
          email
          avatar {
            url
            urlSmall
            urlMedium
            urlLarge
            uploadUrl
            __typename
          }
          __typename
        }
        isEmergencyContact
        isAlarmStationContact
      }
      ... on HomeChild {
        id
        firstName
        lastName
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        account {
          id
          email
          avatar {
            url
            urlSmall
            urlMedium
            urlLarge
            uploadUrl
            __typename
          }
          __typename
        }
        isEmergencyContact
        isAlarmStationContact
      }
      ... on HomeGuest {
        id
        firstName
        lastName
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        account {
          id
          email
          avatar {
            url
            urlSmall
            urlMedium
            urlLarge
            uploadUrl
            __typename
          }
          __typename
        }
        isEmergencyContact
        isAlarmStationContact
      }
    }
  }
`;
export const homeMembers = /* GraphQL */ `
  query HomeMembers($homeId: ID!) {
    homeMembers(homeId: $homeId) {
      homeMembers {
        ... on HomeOwner {
          id
          firstName
          lastName
          phoneNumber {
            countryCode
            nationalNumber
            __typename
          }
          account {
            id
            email
            avatar {
              url
              urlSmall
              urlMedium
              urlLarge
              uploadUrl
              __typename
            }
            __typename
          }
          isEmergencyContact
          isAlarmStationContact
        }
        ... on HomeAdministrator {
          id
          firstName
          lastName
          phoneNumber {
            countryCode
            nationalNumber
            __typename
          }
          account {
            id
            email
            avatar {
              url
              urlSmall
              urlMedium
              urlLarge
              uploadUrl
              __typename
            }
            __typename
          }
          isEmergencyContact
          isAlarmStationContact
        }
        ... on HomeUser {
          id
          firstName
          lastName
          phoneNumber {
            countryCode
            nationalNumber
            __typename
          }
          account {
            id
            email
            avatar {
              url
              urlSmall
              urlMedium
              urlLarge
              uploadUrl
              __typename
            }
            __typename
          }
          isEmergencyContact
          isAlarmStationContact
        }
        ... on HomeChild {
          id
          firstName
          lastName
          phoneNumber {
            countryCode
            nationalNumber
            __typename
          }
          account {
            id
            email
            avatar {
              url
              urlSmall
              urlMedium
              urlLarge
              uploadUrl
              __typename
            }
            __typename
          }
          isEmergencyContact
          isAlarmStationContact
        }
        ... on HomeGuest {
          id
          firstName
          lastName
          phoneNumber {
            countryCode
            nationalNumber
            __typename
          }
          account {
            id
            email
            avatar {
              url
              urlSmall
              urlMedium
              urlLarge
              uploadUrl
              __typename
            }
            __typename
          }
          isEmergencyContact
          isAlarmStationContact
        }
      }
      __typename
    }
  }
`;
export const hubIdentifyByMacAddress = /* GraphQL */ `
  query HubIdentifyByMacAddress($macAddress: String!) {
    hubIdentifyByMacAddress(macAddress: $macAddress) {
      hubInfo {
        id
        firmwareVersion
        serialNumber
        registered
        homeToClaim
        homeId
        __typename
      }
      errors {
        ... on InvalidSerialNumberFormatError {
          message
          path
        }
        ... on HubDoesNotExistError {
          message
          path
        }
      }
      __typename
    }
  }
`;
export const hubIdentifyBySerialNumber = /* GraphQL */ `
  query HubIdentifyBySerialNumber($serialNumber: String!) {
    hubIdentifyBySerialNumber(serialNumber: $serialNumber) {
      hubInfo {
        id
        firmwareVersion
        serialNumber
        registered
        homeToClaim
        homeId
        __typename
      }
      errors {
        ... on InvalidSerialNumberFormatError {
          message
          path
        }
        ... on HubDoesNotExistError {
          message
          path
        }
      }
      __typename
    }
  }
`;
export const hubIdentifyByQrCode = /* GraphQL */ `
  query HubIdentifyByQrCode($qrCode: String!) {
    hubIdentifyByQrCode(qrCode: $qrCode) {
      hubInfo {
        id
        firmwareVersion
        serialNumber
        registered
        homeToClaim
        homeId
        __typename
      }
      errors {
        ... on InvalidSerialNumberFormatError {
          message
          path
        }
        ... on HubDoesNotExistError {
          message
          path
        }
      }
      __typename
    }
  }
`;
export const hubGetDppPayload = /* GraphQL */ `
  query HubGetDppPayload($hubId: String!, $homeKitSetupPayload: String!) {
    hubGetDppPayload(hubId: $hubId, homeKitSetupPayload: $homeKitSetupPayload) {
      dppPayload
      errors {
        ... on HubDoesNotExistError {
          message
          path
        }
        ... on HubInvalidHomeKitSetupPayloadError {
          message
          path
        }
      }
      __typename
    }
  }
`;
export const hubTemplates = /* GraphQL */ `
  query HubTemplates {
    hubTemplates {
      id
      name
      hubAssetUrl
      hubWithBgAssetUrl
      hubQrCodeAssetUrl
      hubConnectionAssetUrl
      hubSearchAssetUrl
      waterLeakDetectorAssetUrl
      waterLeakDetectorInstructionsAssetUrl
      waterLeakDetectorHelpAssetUrl
      waterSensorTapeAssetUrl
      waterSensorTapeInstructionsAssetUrl
      waterLeakSystemPressHubButtonAssetUrl
      sintefLogoAssetUrl
      __typename
    }
  }
`;
export const userMyAccount = /* GraphQL */ `
  query UserMyAccount {
    userMyAccount {
      id
      email
      emailVerified
      phoneNumber {
        countryCode
        nationalNumber
        __typename
      }
      firstName
      lastName
      avatar {
        url
        urlSmall
        urlMedium
        urlLarge
        uploadUrl
        __typename
      }
      roles
      __typename
    }
  }
`;
export const userPreferences = /* GraphQL */ `
  query UserPreferences {
    userPreferences {
      alarmPreferences {
        securityShowArmConfirmationModal
        __typename
      }
      notificationPreferences {
        notificationsEnabled
        __typename
      }
      __typename
    }
  }
`;
export const notificationsInbox = /* GraphQL */ `
  query NotificationsInbox {
    notificationsInbox {
      notifications {
        id
        message {
          title
          body
          __typename
        }
        deepLink
        receivedAt
        read
        context {
          kind
          source
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const userAccount = /* GraphQL */ `
  query UserAccount($userId: ID!) {
    userAccount(userId: $userId) {
      id
      email
      emailVerified
      phoneNumber {
        countryCode
        nationalNumber
        __typename
      }
      firstName
      lastName
      avatar {
        url
        urlSmall
        urlMedium
        urlLarge
        uploadUrl
        __typename
      }
      roles
      __typename
    }
  }
`;
export const invitedUserHome = /* GraphQL */ `
  query InvitedUserHome($homeId: ID!) {
    invitedUserHome(homeId: $homeId) {
      id
      name
      type
      locationInfo {
        country
        city
        postalCode
        street
        streetNumber
        unitNumber
        geoLocation {
          latitude
          longitude
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const homeOrganizations = /* GraphQL */ `
  query HomeOrganizations($homeId: ID!) {
    homeOrganizations(homeId: $homeId) {
      id
      name
      address
      email
      phoneNumbers {
        countryCode
        nationalNumber
        __typename
      }
      __typename
    }
  }
`;
export const deviceAccessPolicyForUser = /* GraphQL */ `
  query DeviceAccessPolicyForUser($deviceId: ID!, $userId: ID!) {
    deviceAccessPolicyForUser(deviceId: $deviceId, userId: $userId) {
      read
      write
      delete
      expiresAt
      __typename
    }
  }
`;
export const deviceUsersWithAccess = /* GraphQL */ `
  query DeviceUsersWithAccess($deviceId: ID!) {
    deviceUsersWithAccess(deviceId: $deviceId) {
      ... on HomeOwner {
        id
        firstName
        lastName
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        account {
          id
          email
          avatar {
            url
            urlSmall
            urlMedium
            urlLarge
            uploadUrl
            __typename
          }
          __typename
        }
        isEmergencyContact
        isAlarmStationContact
      }
      ... on HomeAdministrator {
        id
        firstName
        lastName
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        account {
          id
          email
          avatar {
            url
            urlSmall
            urlMedium
            urlLarge
            uploadUrl
            __typename
          }
          __typename
        }
        isEmergencyContact
        isAlarmStationContact
      }
      ... on HomeUser {
        id
        firstName
        lastName
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        account {
          id
          email
          avatar {
            url
            urlSmall
            urlMedium
            urlLarge
            uploadUrl
            __typename
          }
          __typename
        }
        isEmergencyContact
        isAlarmStationContact
      }
      ... on HomeChild {
        id
        firstName
        lastName
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        account {
          id
          email
          avatar {
            url
            urlSmall
            urlMedium
            urlLarge
            uploadUrl
            __typename
          }
          __typename
        }
        isEmergencyContact
        isAlarmStationContact
      }
      ... on HomeGuest {
        id
        firstName
        lastName
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        account {
          id
          email
          avatar {
            url
            urlSmall
            urlMedium
            urlLarge
            uploadUrl
            __typename
          }
          __typename
        }
        isEmergencyContact
        isAlarmStationContact
      }
    }
  }
`;
export const areaAccessPoliciesForUser = /* GraphQL */ `
  query AreaAccessPoliciesForUser($areaId: ID!, $userId: ID!) {
    areaAccessPoliciesForUser(areaId: $areaId, userId: $userId) {
      actionType
      create
      update
      delete
      read
      __typename
    }
  }
`;
export const areaUsersWithAccess = /* GraphQL */ `
  query AreaUsersWithAccess($areaId: ID!) {
    areaUsersWithAccess(areaId: $areaId) {
      ... on HomeOwner {
        id
        firstName
        lastName
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        account {
          id
          email
          avatar {
            url
            urlSmall
            urlMedium
            urlLarge
            uploadUrl
            __typename
          }
          __typename
        }
        isEmergencyContact
        isAlarmStationContact
      }
      ... on HomeAdministrator {
        id
        firstName
        lastName
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        account {
          id
          email
          avatar {
            url
            urlSmall
            urlMedium
            urlLarge
            uploadUrl
            __typename
          }
          __typename
        }
        isEmergencyContact
        isAlarmStationContact
      }
      ... on HomeUser {
        id
        firstName
        lastName
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        account {
          id
          email
          avatar {
            url
            urlSmall
            urlMedium
            urlLarge
            uploadUrl
            __typename
          }
          __typename
        }
        isEmergencyContact
        isAlarmStationContact
      }
      ... on HomeChild {
        id
        firstName
        lastName
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        account {
          id
          email
          avatar {
            url
            urlSmall
            urlMedium
            urlLarge
            uploadUrl
            __typename
          }
          __typename
        }
        isEmergencyContact
        isAlarmStationContact
      }
      ... on HomeGuest {
        id
        firstName
        lastName
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        account {
          id
          email
          avatar {
            url
            urlSmall
            urlMedium
            urlLarge
            uploadUrl
            __typename
          }
          __typename
        }
        isEmergencyContact
        isAlarmStationContact
      }
    }
  }
`;
export const installerProject = /* GraphQL */ `
  query InstallerProject($projectId: ID!) {
    installerProject(projectId: $projectId) {
      ... on TransferredProject {
        id
        installerId
        avatar {
          url {
            original
            small
            medium
            large
            __typename
          }
          uploadUrl
          __typename
        }
        buildingType
        buildingYear
        buildingSize
        locationInfo {
          country
          city
          postalCode
          street
          streetNumber
          unitNumber
          geoLocation {
            latitude
            longitude
            __typename
          }
          __typename
        }
        constructionType
        description {
          projectNumber
          orderReferenceNumber
          buildingTitle
          __typename
        }
        hubs {
          area {
            areaName
            areaType
            __typename
          }
          __typename
        }
        devices {
          area {
            areaName
            areaType
            __typename
          }
          deviceType
          __typename
        }
      }
      ... on OngoingProject {
        id
        installerId
        avatar {
          url {
            original
            small
            medium
            large
            __typename
          }
          uploadUrl
          __typename
        }
        home {
          id
          locationInfo {
            country
            city
            postalCode
            street
            streetNumber
            unitNumber
            geoLocation {
              latitude
              longitude
              __typename
            }
            __typename
          }
          homeInfo {
            nickname
            avatarUrl
            size
            ageOfBuilding
            numberOfFloors
            numberOfAdults
            numberOfChildren
            homeType
            dwellingUnit
            heatingType
            cooling
            ventilation
            propertyManagerName
            constructionType
            buildingYear
            __typename
          }
          avatar {
            url
            urlSmall
            urlMedium
            urlLarge
            uploadUrl
            __typename
          }
          areas {
            id
            areaName
            areaType
            size
            floor
            children
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              manufacturer {
                id
                name
                __typename
              }
              deviceTemplate {
                id
                deviceType
                name
                primaryPairingMode
                secondaryPairingModes
                __typename
              }
              image {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              area {
                id
                areaName
                areaType
                size
                floor
                children
                __typename
              }
              traits {
                traitType
                commands
                __typename
              }
              accessPolicy {
                read
                write
                delete
                expiresAt
                __typename
              }
              __typename
            }
            accessPolicies {
              actionType
              create
              update
              delete
              read
              __typename
            }
            __typename
          }
          devices {
            id
            deviceType
            name
            serialNumber
            firmwareVersion
            imei
            macAddress
            ipAddress
            roomPlacement
            manufacturer {
              id
              name
              logo {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              __typename
            }
            deviceTemplate {
              id
              deviceType
              name
              primaryPairingMode
              secondaryPairingModes
              manufacturer {
                id
                name
                __typename
              }
              image {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              imageWithBg {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              installationInstructions {
                language
                header
                description
                __typename
              }
              instructions {
                connectionAssetUrl
                __typename
              }
              __typename
            }
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            favorite
            favoriteChangedAt
            registeredAt
            canBeUnregistered
            area {
              id
              areaName
              areaType
              size
              floor
              children
              devices {
                id
                deviceType
                name
                serialNumber
                firmwareVersion
                imei
                macAddress
                ipAddress
                roomPlacement
                favorite
                favoriteChangedAt
                registeredAt
                canBeUnregistered
                __typename
              }
              accessPolicies {
                actionType
                create
                update
                delete
                read
                __typename
              }
              __typename
            }
            traits {
              traitType
              commands
              attributes {
                ... on TraitAttributeAvailableArmLevels {
                  name
                  dataType
                  editable
                }
                ... on TraitAttributeIsArmed {
                  name
                  dataType
                  editable
                  isArmed
                }
                ... on TraitAttributeArmLevel {
                  name
                  dataType
                  editable
                  armLevel
                }
                ... on TraitAttributeDisarmDefaultTimeout {
                  name
                  dataType
                  editable
                  disarmDefaultTimeout
                }
                ... on TraitAttributeIsConnected {
                  name
                  dataType
                  editable
                  isConnected
                }
                ... on TraitAttributeHeartbeatInterval {
                  name
                  dataType
                  editable
                  heartbeatInterval
                }
                ... on TraitAttributeHeartbeatsThreshold {
                  name
                  dataType
                  editable
                  heartbeatsThreshold
                }
                ... on TraitAttributeGatewayConnected {
                  name
                  dataType
                  editable
                  ethernetConnected
                  wifiConnected
                  cellularConnected
                }
                ... on TraitAttributeWifiConfigured {
                  name
                  dataType
                  editable
                  wifiConfigured
                }
                ... on TraitAttributeNetworkSsid {
                  name
                  dataType
                  editable
                  networkSsid
                }
                ... on TraitAttributeNetworkCredentials {
                  name
                  dataType
                  editable
                  networkCredentials
                }
                ... on TraitAttributeCellularApn {
                  name
                  dataType
                  editable
                  cellularApn
                }
                ... on TraitAttributeCellularOperator {
                  name
                  dataType
                  editable
                  cellularOperator
                }
                ... on TraitAttributeBrightness {
                  name
                  dataType
                  editable
                  brightness
                }
                ... on TraitAttributeColor {
                  name
                  dataType
                  editable
                  hue
                  saturation
                }
                ... on TraitAttributeIsOn {
                  name
                  dataType
                  editable
                  isOn
                }
                ... on TraitAttributeOpenPercent {
                  name
                  dataType
                  editable
                  openPercent
                }
                ... on TraitAttributeCurrentPowerSourceLevel {
                  name
                  dataType
                  editable
                  currentPowerSourceLevel
                }
                ... on TraitAttributeLowBatteryWarning {
                  name
                  dataType
                  editable
                  lowBatteryWarning
                }
                ... on TraitAttributeCurrentPowerSource {
                  name
                  dataType
                  editable
                  currentPowerSource
                }
                ... on TraitAttributeStatusCharging {
                  name
                  dataType
                  editable
                  statusCharging
                }
                ... on TraitAttributeCurrentPowerMode {
                  name
                  dataType
                  editable
                  currentPowerMode
                }
                ... on TraitAttributeAvailablePowerSources {
                  name
                  dataType
                  editable
                  availablePowerSources
                }
                ... on TraitAttributeBatteryVoltage {
                  name
                  dataType
                  editable
                  batteryVoltage
                }
                ... on TraitAttributeMainsVoltage {
                  name
                  dataType
                  editable
                  mainsVoltage
                }
                ... on TraitAttributeRunningPowerConsumption {
                  name
                  dataType
                  editable
                  runningPowerConsumption
                }
                ... on TraitAttributePriority {
                  name
                  dataType
                  editable
                  priority
                }
                ... on TraitAttributeAirQuality {
                  name
                  dataType
                  editable
                  airQuality
                }
                ... on TraitAttributeCo2 {
                  name
                  dataType
                  editable
                  co2
                }
                ... on TraitAttributeHumidity {
                  name
                  dataType
                  editable
                  humidity
                  humidityIndicator
                }
                ... on TraitAttributeTemperature {
                  name
                  dataType
                  editable
                  temperature
                }
                ... on TraitAttributeVoc {
                  name
                  dataType
                  editable
                  voc
                }
                ... on TraitAttributeIsUnlocked {
                  name
                  dataType
                  editable
                  isUnlocked
                }
                ... on TraitAttributeChildLock {
                  name
                  dataType
                  editable
                  childLock
                }
                ... on TraitAttributeFault {
                  name
                  dataType
                  editable
                }
                ... on TraitAttributeTemperatureSetpoint {
                  name
                  dataType
                  editable
                  temperatureSetpoint
                  minSetpoint
                  maxSetpoint
                }
                ... on TraitAttributeTemperatureSetpointPercentage {
                  name
                  dataType
                  editable
                  temperatureSetpointPercentage
                  minSetpoint
                  maxSetpoint
                }
                ... on TraitAttributeAvailableClimateModes {
                  name
                  dataType
                  editable
                  availableClimateModes
                }
                ... on TraitAttributeClimateMode {
                  name
                  dataType
                  editable
                  climateMode
                }
                ... on TraitAttributeMaximumFloorTemperature {
                  name
                  dataType
                  editable
                  maximumFloorTemperature
                }
                ... on TraitAttributeFrostGuard {
                  name
                  dataType
                  editable
                  frostGuard
                }
                ... on TraitAttributeNightSwitch {
                  name
                  dataType
                  editable
                  nightSwitch
                }
                ... on TraitAttributeRegulatorMode {
                  name
                  dataType
                  editable
                  regulatorMode
                }
                ... on TraitAttributeHeatingElementActive {
                  name
                  dataType
                  editable
                  heatingElementActive
                }
                ... on TraitAttributeAlarmSound {
                  name
                  dataType
                  editable
                  alarmSound
                }
                ... on TraitAttributeAlarm {
                  name
                  dataType
                  editable
                  alarm
                  snoozed
                }
                ... on TraitAttributeWaterDetectorCableConnected {
                  name
                  dataType
                  editable
                  waterDetectorCableConnected
                }
                ... on TraitAttributeWaterValvesConnected {
                  name
                  dataType
                  editable
                  waterValvesConnected
                }
                ... on TraitAttributeBracket {
                  name
                  dataType
                  editable
                  bracket
                }
                ... on TraitAttributeDisabled {
                  name
                  dataType
                  editable
                  disabled
                }
                ... on TraitAttributeLocation {
                  name
                  dataType
                  editable
                  latitude
                  longitude
                }
                ... on TraitAttributeMotionDetected {
                  name
                  dataType
                  editable
                  lastMotionDetectedAt
                }
                ... on TraitAttributeValveInfo {
                  name
                  dataType
                  editable
                  valveType
                  typeOfValve
                  numValves
                }
                ... on TraitAttributeElectricVehicleCharger {
                  name
                  dataType
                  editable
                  status
                  chargingStartedAt
                  kwhCharged
                  chargingSessionCost
                }
                ... on TraitAttributeTestMode {
                  name
                  dataType
                  editable
                  isOn
                  lastTriggeredAt
                }
                ... on TraitAttributePing {
                  name
                  dataType
                  editable
                  ping
                }
              }
              __typename
            }
            accessPolicy {
              read
              write
              delete
              expiresAt
              __typename
            }
            __typename
          }
          hubs {
            id
            name
            hubType
            brandName
            firmwareVersion
            serialNumber
            macAddress
            manufacturerInformation
            wasWaterSensorTapePreviouslyConnected
            waterGuard {
              wasWaterSensorTapePreviouslyConnected
              mode
              tamperModeWarningTriggeredAt
              tamperModeTriggersAt
              overrideModeTriggeredAt
              showLevel1Warning
              showLevel2Warning
              __typename
            }
            hubTemplate {
              id
              name
              hubAssetUrl
              hubWithBgAssetUrl
              hubQrCodeAssetUrl
              hubConnectionAssetUrl
              hubSearchAssetUrl
              waterLeakDetectorAssetUrl
              waterLeakDetectorInstructionsAssetUrl
              waterLeakDetectorHelpAssetUrl
              waterSensorTapeAssetUrl
              waterSensorTapeInstructionsAssetUrl
              waterLeakSystemPressHubButtonAssetUrl
              sintefLogoAssetUrl
              __typename
            }
            area {
              id
              areaName
              areaType
              size
              floor
              children
              devices {
                id
                deviceType
                name
                serialNumber
                firmwareVersion
                imei
                macAddress
                ipAddress
                roomPlacement
                favorite
                favoriteChangedAt
                registeredAt
                canBeUnregistered
                __typename
              }
              accessPolicies {
                actionType
                create
                update
                delete
                read
                __typename
              }
              __typename
            }
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              manufacturer {
                id
                name
                __typename
              }
              deviceTemplate {
                id
                deviceType
                name
                primaryPairingMode
                secondaryPairingModes
                __typename
              }
              image {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              area {
                id
                areaName
                areaType
                size
                floor
                children
                __typename
              }
              traits {
                traitType
                commands
                __typename
              }
              accessPolicy {
                read
                write
                delete
                expiresAt
                __typename
              }
              __typename
            }
            repeaters {
              id
              name
              hubType
              brandName
              firmwareVersion
              serialNumber
              macAddress
              manufacturerInformation
              wasWaterSensorTapePreviouslyConnected
              waterGuard {
                wasWaterSensorTapePreviouslyConnected
                mode
                tamperModeWarningTriggeredAt
                tamperModeTriggersAt
                overrideModeTriggeredAt
                showLevel1Warning
                showLevel2Warning
                __typename
              }
              hubTemplate {
                id
                name
                hubAssetUrl
                hubWithBgAssetUrl
                hubQrCodeAssetUrl
                hubConnectionAssetUrl
                hubSearchAssetUrl
                waterLeakDetectorAssetUrl
                waterLeakDetectorInstructionsAssetUrl
                waterLeakDetectorHelpAssetUrl
                waterSensorTapeAssetUrl
                waterSensorTapeInstructionsAssetUrl
                waterLeakSystemPressHubButtonAssetUrl
                sintefLogoAssetUrl
                __typename
              }
              area {
                id
                areaName
                areaType
                size
                floor
                children
                __typename
              }
              devices {
                id
                deviceType
                name
                serialNumber
                firmwareVersion
                imei
                macAddress
                ipAddress
                roomPlacement
                favorite
                favoriteChangedAt
                registeredAt
                canBeUnregistered
                __typename
              }
              repeaters {
                id
                name
                hubType
                brandName
                firmwareVersion
                serialNumber
                macAddress
                manufacturerInformation
                wasWaterSensorTapePreviouslyConnected
                __typename
              }
              traits {
                traitType
                commands
                __typename
              }
              __typename
            }
            traits {
              traitType
              commands
              attributes {
                ... on TraitAttributeAvailableArmLevels {
                  name
                  dataType
                  editable
                }
                ... on TraitAttributeIsArmed {
                  name
                  dataType
                  editable
                  isArmed
                }
                ... on TraitAttributeArmLevel {
                  name
                  dataType
                  editable
                  armLevel
                }
                ... on TraitAttributeDisarmDefaultTimeout {
                  name
                  dataType
                  editable
                  disarmDefaultTimeout
                }
                ... on TraitAttributeIsConnected {
                  name
                  dataType
                  editable
                  isConnected
                }
                ... on TraitAttributeHeartbeatInterval {
                  name
                  dataType
                  editable
                  heartbeatInterval
                }
                ... on TraitAttributeHeartbeatsThreshold {
                  name
                  dataType
                  editable
                  heartbeatsThreshold
                }
                ... on TraitAttributeGatewayConnected {
                  name
                  dataType
                  editable
                  ethernetConnected
                  wifiConnected
                  cellularConnected
                }
                ... on TraitAttributeWifiConfigured {
                  name
                  dataType
                  editable
                  wifiConfigured
                }
                ... on TraitAttributeNetworkSsid {
                  name
                  dataType
                  editable
                  networkSsid
                }
                ... on TraitAttributeNetworkCredentials {
                  name
                  dataType
                  editable
                  networkCredentials
                }
                ... on TraitAttributeCellularApn {
                  name
                  dataType
                  editable
                  cellularApn
                }
                ... on TraitAttributeCellularOperator {
                  name
                  dataType
                  editable
                  cellularOperator
                }
                ... on TraitAttributeBrightness {
                  name
                  dataType
                  editable
                  brightness
                }
                ... on TraitAttributeColor {
                  name
                  dataType
                  editable
                  hue
                  saturation
                }
                ... on TraitAttributeIsOn {
                  name
                  dataType
                  editable
                  isOn
                }
                ... on TraitAttributeOpenPercent {
                  name
                  dataType
                  editable
                  openPercent
                }
                ... on TraitAttributeCurrentPowerSourceLevel {
                  name
                  dataType
                  editable
                  currentPowerSourceLevel
                }
                ... on TraitAttributeLowBatteryWarning {
                  name
                  dataType
                  editable
                  lowBatteryWarning
                }
                ... on TraitAttributeCurrentPowerSource {
                  name
                  dataType
                  editable
                  currentPowerSource
                }
                ... on TraitAttributeStatusCharging {
                  name
                  dataType
                  editable
                  statusCharging
                }
                ... on TraitAttributeCurrentPowerMode {
                  name
                  dataType
                  editable
                  currentPowerMode
                }
                ... on TraitAttributeAvailablePowerSources {
                  name
                  dataType
                  editable
                  availablePowerSources
                }
                ... on TraitAttributeBatteryVoltage {
                  name
                  dataType
                  editable
                  batteryVoltage
                }
                ... on TraitAttributeMainsVoltage {
                  name
                  dataType
                  editable
                  mainsVoltage
                }
                ... on TraitAttributeRunningPowerConsumption {
                  name
                  dataType
                  editable
                  runningPowerConsumption
                }
                ... on TraitAttributePriority {
                  name
                  dataType
                  editable
                  priority
                }
                ... on TraitAttributeAirQuality {
                  name
                  dataType
                  editable
                  airQuality
                }
                ... on TraitAttributeCo2 {
                  name
                  dataType
                  editable
                  co2
                }
                ... on TraitAttributeHumidity {
                  name
                  dataType
                  editable
                  humidity
                  humidityIndicator
                }
                ... on TraitAttributeTemperature {
                  name
                  dataType
                  editable
                  temperature
                }
                ... on TraitAttributeVoc {
                  name
                  dataType
                  editable
                  voc
                }
                ... on TraitAttributeIsUnlocked {
                  name
                  dataType
                  editable
                  isUnlocked
                }
                ... on TraitAttributeChildLock {
                  name
                  dataType
                  editable
                  childLock
                }
                ... on TraitAttributeFault {
                  name
                  dataType
                  editable
                }
                ... on TraitAttributeTemperatureSetpoint {
                  name
                  dataType
                  editable
                  temperatureSetpoint
                  minSetpoint
                  maxSetpoint
                }
                ... on TraitAttributeTemperatureSetpointPercentage {
                  name
                  dataType
                  editable
                  temperatureSetpointPercentage
                  minSetpoint
                  maxSetpoint
                }
                ... on TraitAttributeAvailableClimateModes {
                  name
                  dataType
                  editable
                  availableClimateModes
                }
                ... on TraitAttributeClimateMode {
                  name
                  dataType
                  editable
                  climateMode
                }
                ... on TraitAttributeMaximumFloorTemperature {
                  name
                  dataType
                  editable
                  maximumFloorTemperature
                }
                ... on TraitAttributeFrostGuard {
                  name
                  dataType
                  editable
                  frostGuard
                }
                ... on TraitAttributeNightSwitch {
                  name
                  dataType
                  editable
                  nightSwitch
                }
                ... on TraitAttributeRegulatorMode {
                  name
                  dataType
                  editable
                  regulatorMode
                }
                ... on TraitAttributeHeatingElementActive {
                  name
                  dataType
                  editable
                  heatingElementActive
                }
                ... on TraitAttributeAlarmSound {
                  name
                  dataType
                  editable
                  alarmSound
                }
                ... on TraitAttributeAlarm {
                  name
                  dataType
                  editable
                  alarm
                  snoozed
                }
                ... on TraitAttributeWaterDetectorCableConnected {
                  name
                  dataType
                  editable
                  waterDetectorCableConnected
                }
                ... on TraitAttributeWaterValvesConnected {
                  name
                  dataType
                  editable
                  waterValvesConnected
                }
                ... on TraitAttributeBracket {
                  name
                  dataType
                  editable
                  bracket
                }
                ... on TraitAttributeDisabled {
                  name
                  dataType
                  editable
                  disabled
                }
                ... on TraitAttributeLocation {
                  name
                  dataType
                  editable
                  latitude
                  longitude
                }
                ... on TraitAttributeMotionDetected {
                  name
                  dataType
                  editable
                  lastMotionDetectedAt
                }
                ... on TraitAttributeValveInfo {
                  name
                  dataType
                  editable
                  valveType
                  typeOfValve
                  numValves
                }
                ... on TraitAttributeElectricVehicleCharger {
                  name
                  dataType
                  editable
                  status
                  chargingStartedAt
                  kwhCharged
                  chargingSessionCost
                }
                ... on TraitAttributeTestMode {
                  name
                  dataType
                  editable
                  isOn
                  lastTriggeredAt
                }
                ... on TraitAttributePing {
                  name
                  dataType
                  editable
                  ping
                }
              }
              __typename
            }
            __typename
          }
          __typename
        }
        devices {
          id
          deviceType
          name
          serialNumber
          firmwareVersion
          imei
          macAddress
          ipAddress
          roomPlacement
          manufacturer {
            id
            name
            logo {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            __typename
          }
          deviceTemplate {
            id
            deviceType
            name
            primaryPairingMode
            secondaryPairingModes
            manufacturer {
              id
              name
              logo {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              __typename
            }
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            imageWithBg {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            installationInstructions {
              language
              header
              description
              installationAsset {
                url
                assetType
                __typename
              }
              connectionAsset {
                url
                assetType
                __typename
              }
              __typename
            }
            instructions {
              installation {
                assetUrl
                __typename
              }
              configuration {
                assetUrl
                __typename
              }
              help {
                assetUrl
                __typename
              }
              connectionAssetUrl
              __typename
            }
            __typename
          }
          image {
            url
            urlSmall
            urlMedium
            urlLarge
            __typename
          }
          favorite
          favoriteChangedAt
          registeredAt
          canBeUnregistered
          area {
            id
            areaName
            areaType
            size
            floor
            children
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              manufacturer {
                id
                name
                __typename
              }
              deviceTemplate {
                id
                deviceType
                name
                primaryPairingMode
                secondaryPairingModes
                __typename
              }
              image {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              area {
                id
                areaName
                areaType
                size
                floor
                children
                __typename
              }
              traits {
                traitType
                commands
                __typename
              }
              accessPolicy {
                read
                write
                delete
                expiresAt
                __typename
              }
              __typename
            }
            accessPolicies {
              actionType
              create
              update
              delete
              read
              __typename
            }
            __typename
          }
          traits {
            traitType
            commands
            attributes {
              ... on TraitAttributeAvailableArmLevels {
                name
                dataType
                editable
                availableArmLevels {
                  id
                  description
                  __typename
                }
              }
              ... on TraitAttributeIsArmed {
                name
                dataType
                editable
                isArmed
              }
              ... on TraitAttributeArmLevel {
                name
                dataType
                editable
                armLevel
              }
              ... on TraitAttributeDisarmDefaultTimeout {
                name
                dataType
                editable
                disarmDefaultTimeout
              }
              ... on TraitAttributeIsConnected {
                name
                dataType
                editable
                isConnected
              }
              ... on TraitAttributeHeartbeatInterval {
                name
                dataType
                editable
                heartbeatInterval
              }
              ... on TraitAttributeHeartbeatsThreshold {
                name
                dataType
                editable
                heartbeatsThreshold
              }
              ... on TraitAttributeGatewayConnected {
                name
                dataType
                editable
                ethernetConnected
                wifiConnected
                cellularConnected
              }
              ... on TraitAttributeWifiConfigured {
                name
                dataType
                editable
                wifiConfigured
              }
              ... on TraitAttributeNetworkSsid {
                name
                dataType
                editable
                networkSsid
              }
              ... on TraitAttributeNetworkCredentials {
                name
                dataType
                editable
                networkCredentials
              }
              ... on TraitAttributeCellularApn {
                name
                dataType
                editable
                cellularApn
              }
              ... on TraitAttributeCellularOperator {
                name
                dataType
                editable
                cellularOperator
              }
              ... on TraitAttributeBrightness {
                name
                dataType
                editable
                brightness
              }
              ... on TraitAttributeColor {
                name
                dataType
                editable
                hue
                saturation
              }
              ... on TraitAttributeIsOn {
                name
                dataType
                editable
                isOn
              }
              ... on TraitAttributeOpenPercent {
                name
                dataType
                editable
                openPercent
              }
              ... on TraitAttributeCurrentPowerSourceLevel {
                name
                dataType
                editable
                currentPowerSourceLevel
              }
              ... on TraitAttributeLowBatteryWarning {
                name
                dataType
                editable
                lowBatteryWarning
              }
              ... on TraitAttributeCurrentPowerSource {
                name
                dataType
                editable
                currentPowerSource
              }
              ... on TraitAttributeStatusCharging {
                name
                dataType
                editable
                statusCharging
              }
              ... on TraitAttributeCurrentPowerMode {
                name
                dataType
                editable
                currentPowerMode
              }
              ... on TraitAttributeAvailablePowerSources {
                name
                dataType
                editable
                availablePowerSources
              }
              ... on TraitAttributeBatteryVoltage {
                name
                dataType
                editable
                batteryVoltage
              }
              ... on TraitAttributeMainsVoltage {
                name
                dataType
                editable
                mainsVoltage
              }
              ... on TraitAttributeRunningPowerConsumption {
                name
                dataType
                editable
                runningPowerConsumption
              }
              ... on TraitAttributePriority {
                name
                dataType
                editable
                priority
              }
              ... on TraitAttributeAirQuality {
                name
                dataType
                editable
                airQuality
              }
              ... on TraitAttributeCo2 {
                name
                dataType
                editable
                co2
              }
              ... on TraitAttributeHumidity {
                name
                dataType
                editable
                humidity
                humidityIndicator
              }
              ... on TraitAttributeTemperature {
                name
                dataType
                editable
                temperature
              }
              ... on TraitAttributeVoc {
                name
                dataType
                editable
                voc
              }
              ... on TraitAttributeIsUnlocked {
                name
                dataType
                editable
                isUnlocked
              }
              ... on TraitAttributeChildLock {
                name
                dataType
                editable
                childLock
              }
              ... on TraitAttributeFault {
                name
                dataType
                editable
                fault {
                  id
                  name
                  code
                  description
                  consumer
                  guiPriority
                  __typename
                }
              }
              ... on TraitAttributeTemperatureSetpoint {
                name
                dataType
                editable
                temperatureSetpoint
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeTemperatureSetpointPercentage {
                name
                dataType
                editable
                temperatureSetpointPercentage
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeAvailableClimateModes {
                name
                dataType
                editable
                availableClimateModes
              }
              ... on TraitAttributeClimateMode {
                name
                dataType
                editable
                climateMode
              }
              ... on TraitAttributeMaximumFloorTemperature {
                name
                dataType
                editable
                maximumFloorTemperature
              }
              ... on TraitAttributeFrostGuard {
                name
                dataType
                editable
                frostGuard
              }
              ... on TraitAttributeNightSwitch {
                name
                dataType
                editable
                nightSwitch
              }
              ... on TraitAttributeRegulatorMode {
                name
                dataType
                editable
                regulatorMode
              }
              ... on TraitAttributeHeatingElementActive {
                name
                dataType
                editable
                heatingElementActive
              }
              ... on TraitAttributeAlarmSound {
                name
                dataType
                editable
                alarmSound
              }
              ... on TraitAttributeAlarm {
                name
                dataType
                editable
                alarm
                snoozed
              }
              ... on TraitAttributeWaterDetectorCableConnected {
                name
                dataType
                editable
                waterDetectorCableConnected
              }
              ... on TraitAttributeWaterValvesConnected {
                name
                dataType
                editable
                waterValvesConnected
              }
              ... on TraitAttributeBracket {
                name
                dataType
                editable
                bracket
              }
              ... on TraitAttributeDisabled {
                name
                dataType
                editable
                disabled
              }
              ... on TraitAttributeLocation {
                name
                dataType
                editable
                latitude
                longitude
              }
              ... on TraitAttributeMotionDetected {
                name
                dataType
                editable
                lastMotionDetectedAt
              }
              ... on TraitAttributeValveInfo {
                name
                dataType
                editable
                valveType
                typeOfValve
                numValves
              }
              ... on TraitAttributeElectricVehicleCharger {
                name
                dataType
                editable
                status
                chargingStartedAt
                kwhCharged
                chargingSessionCost
              }
              ... on TraitAttributeTestMode {
                name
                dataType
                editable
                isOn
                lastTriggeredAt
              }
              ... on TraitAttributePing {
                name
                dataType
                editable
                ping
              }
            }
            __typename
          }
          accessPolicy {
            read
            write
            delete
            expiresAt
            __typename
          }
          __typename
        }
        hubs {
          id
          name
          hubType
          brandName
          firmwareVersion
          serialNumber
          macAddress
          manufacturerInformation
          wasWaterSensorTapePreviouslyConnected
          waterGuard {
            wasWaterSensorTapePreviouslyConnected
            mode
            tamperModeWarningTriggeredAt
            tamperModeTriggersAt
            overrideModeTriggeredAt
            showLevel1Warning
            showLevel2Warning
            __typename
          }
          hubTemplate {
            id
            name
            hubAssetUrl
            hubWithBgAssetUrl
            hubQrCodeAssetUrl
            hubConnectionAssetUrl
            hubSearchAssetUrl
            waterLeakDetectorAssetUrl
            waterLeakDetectorInstructionsAssetUrl
            waterLeakDetectorHelpAssetUrl
            waterSensorTapeAssetUrl
            waterSensorTapeInstructionsAssetUrl
            waterLeakSystemPressHubButtonAssetUrl
            sintefLogoAssetUrl
            __typename
          }
          area {
            id
            areaName
            areaType
            size
            floor
            children
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              manufacturer {
                id
                name
                __typename
              }
              deviceTemplate {
                id
                deviceType
                name
                primaryPairingMode
                secondaryPairingModes
                __typename
              }
              image {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              area {
                id
                areaName
                areaType
                size
                floor
                children
                __typename
              }
              traits {
                traitType
                commands
                __typename
              }
              accessPolicy {
                read
                write
                delete
                expiresAt
                __typename
              }
              __typename
            }
            accessPolicies {
              actionType
              create
              update
              delete
              read
              __typename
            }
            __typename
          }
          devices {
            id
            deviceType
            name
            serialNumber
            firmwareVersion
            imei
            macAddress
            ipAddress
            roomPlacement
            manufacturer {
              id
              name
              logo {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              __typename
            }
            deviceTemplate {
              id
              deviceType
              name
              primaryPairingMode
              secondaryPairingModes
              manufacturer {
                id
                name
                __typename
              }
              image {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              imageWithBg {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              installationInstructions {
                language
                header
                description
                __typename
              }
              instructions {
                connectionAssetUrl
                __typename
              }
              __typename
            }
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            favorite
            favoriteChangedAt
            registeredAt
            canBeUnregistered
            area {
              id
              areaName
              areaType
              size
              floor
              children
              devices {
                id
                deviceType
                name
                serialNumber
                firmwareVersion
                imei
                macAddress
                ipAddress
                roomPlacement
                favorite
                favoriteChangedAt
                registeredAt
                canBeUnregistered
                __typename
              }
              accessPolicies {
                actionType
                create
                update
                delete
                read
                __typename
              }
              __typename
            }
            traits {
              traitType
              commands
              attributes {
                ... on TraitAttributeAvailableArmLevels {
                  name
                  dataType
                  editable
                }
                ... on TraitAttributeIsArmed {
                  name
                  dataType
                  editable
                  isArmed
                }
                ... on TraitAttributeArmLevel {
                  name
                  dataType
                  editable
                  armLevel
                }
                ... on TraitAttributeDisarmDefaultTimeout {
                  name
                  dataType
                  editable
                  disarmDefaultTimeout
                }
                ... on TraitAttributeIsConnected {
                  name
                  dataType
                  editable
                  isConnected
                }
                ... on TraitAttributeHeartbeatInterval {
                  name
                  dataType
                  editable
                  heartbeatInterval
                }
                ... on TraitAttributeHeartbeatsThreshold {
                  name
                  dataType
                  editable
                  heartbeatsThreshold
                }
                ... on TraitAttributeGatewayConnected {
                  name
                  dataType
                  editable
                  ethernetConnected
                  wifiConnected
                  cellularConnected
                }
                ... on TraitAttributeWifiConfigured {
                  name
                  dataType
                  editable
                  wifiConfigured
                }
                ... on TraitAttributeNetworkSsid {
                  name
                  dataType
                  editable
                  networkSsid
                }
                ... on TraitAttributeNetworkCredentials {
                  name
                  dataType
                  editable
                  networkCredentials
                }
                ... on TraitAttributeCellularApn {
                  name
                  dataType
                  editable
                  cellularApn
                }
                ... on TraitAttributeCellularOperator {
                  name
                  dataType
                  editable
                  cellularOperator
                }
                ... on TraitAttributeBrightness {
                  name
                  dataType
                  editable
                  brightness
                }
                ... on TraitAttributeColor {
                  name
                  dataType
                  editable
                  hue
                  saturation
                }
                ... on TraitAttributeIsOn {
                  name
                  dataType
                  editable
                  isOn
                }
                ... on TraitAttributeOpenPercent {
                  name
                  dataType
                  editable
                  openPercent
                }
                ... on TraitAttributeCurrentPowerSourceLevel {
                  name
                  dataType
                  editable
                  currentPowerSourceLevel
                }
                ... on TraitAttributeLowBatteryWarning {
                  name
                  dataType
                  editable
                  lowBatteryWarning
                }
                ... on TraitAttributeCurrentPowerSource {
                  name
                  dataType
                  editable
                  currentPowerSource
                }
                ... on TraitAttributeStatusCharging {
                  name
                  dataType
                  editable
                  statusCharging
                }
                ... on TraitAttributeCurrentPowerMode {
                  name
                  dataType
                  editable
                  currentPowerMode
                }
                ... on TraitAttributeAvailablePowerSources {
                  name
                  dataType
                  editable
                  availablePowerSources
                }
                ... on TraitAttributeBatteryVoltage {
                  name
                  dataType
                  editable
                  batteryVoltage
                }
                ... on TraitAttributeMainsVoltage {
                  name
                  dataType
                  editable
                  mainsVoltage
                }
                ... on TraitAttributeRunningPowerConsumption {
                  name
                  dataType
                  editable
                  runningPowerConsumption
                }
                ... on TraitAttributePriority {
                  name
                  dataType
                  editable
                  priority
                }
                ... on TraitAttributeAirQuality {
                  name
                  dataType
                  editable
                  airQuality
                }
                ... on TraitAttributeCo2 {
                  name
                  dataType
                  editable
                  co2
                }
                ... on TraitAttributeHumidity {
                  name
                  dataType
                  editable
                  humidity
                  humidityIndicator
                }
                ... on TraitAttributeTemperature {
                  name
                  dataType
                  editable
                  temperature
                }
                ... on TraitAttributeVoc {
                  name
                  dataType
                  editable
                  voc
                }
                ... on TraitAttributeIsUnlocked {
                  name
                  dataType
                  editable
                  isUnlocked
                }
                ... on TraitAttributeChildLock {
                  name
                  dataType
                  editable
                  childLock
                }
                ... on TraitAttributeFault {
                  name
                  dataType
                  editable
                }
                ... on TraitAttributeTemperatureSetpoint {
                  name
                  dataType
                  editable
                  temperatureSetpoint
                  minSetpoint
                  maxSetpoint
                }
                ... on TraitAttributeTemperatureSetpointPercentage {
                  name
                  dataType
                  editable
                  temperatureSetpointPercentage
                  minSetpoint
                  maxSetpoint
                }
                ... on TraitAttributeAvailableClimateModes {
                  name
                  dataType
                  editable
                  availableClimateModes
                }
                ... on TraitAttributeClimateMode {
                  name
                  dataType
                  editable
                  climateMode
                }
                ... on TraitAttributeMaximumFloorTemperature {
                  name
                  dataType
                  editable
                  maximumFloorTemperature
                }
                ... on TraitAttributeFrostGuard {
                  name
                  dataType
                  editable
                  frostGuard
                }
                ... on TraitAttributeNightSwitch {
                  name
                  dataType
                  editable
                  nightSwitch
                }
                ... on TraitAttributeRegulatorMode {
                  name
                  dataType
                  editable
                  regulatorMode
                }
                ... on TraitAttributeHeatingElementActive {
                  name
                  dataType
                  editable
                  heatingElementActive
                }
                ... on TraitAttributeAlarmSound {
                  name
                  dataType
                  editable
                  alarmSound
                }
                ... on TraitAttributeAlarm {
                  name
                  dataType
                  editable
                  alarm
                  snoozed
                }
                ... on TraitAttributeWaterDetectorCableConnected {
                  name
                  dataType
                  editable
                  waterDetectorCableConnected
                }
                ... on TraitAttributeWaterValvesConnected {
                  name
                  dataType
                  editable
                  waterValvesConnected
                }
                ... on TraitAttributeBracket {
                  name
                  dataType
                  editable
                  bracket
                }
                ... on TraitAttributeDisabled {
                  name
                  dataType
                  editable
                  disabled
                }
                ... on TraitAttributeLocation {
                  name
                  dataType
                  editable
                  latitude
                  longitude
                }
                ... on TraitAttributeMotionDetected {
                  name
                  dataType
                  editable
                  lastMotionDetectedAt
                }
                ... on TraitAttributeValveInfo {
                  name
                  dataType
                  editable
                  valveType
                  typeOfValve
                  numValves
                }
                ... on TraitAttributeElectricVehicleCharger {
                  name
                  dataType
                  editable
                  status
                  chargingStartedAt
                  kwhCharged
                  chargingSessionCost
                }
                ... on TraitAttributeTestMode {
                  name
                  dataType
                  editable
                  isOn
                  lastTriggeredAt
                }
                ... on TraitAttributePing {
                  name
                  dataType
                  editable
                  ping
                }
              }
              __typename
            }
            accessPolicy {
              read
              write
              delete
              expiresAt
              __typename
            }
            __typename
          }
          repeaters {
            id
            name
            hubType
            brandName
            firmwareVersion
            serialNumber
            macAddress
            manufacturerInformation
            wasWaterSensorTapePreviouslyConnected
            waterGuard {
              wasWaterSensorTapePreviouslyConnected
              mode
              tamperModeWarningTriggeredAt
              tamperModeTriggersAt
              overrideModeTriggeredAt
              showLevel1Warning
              showLevel2Warning
              __typename
            }
            hubTemplate {
              id
              name
              hubAssetUrl
              hubWithBgAssetUrl
              hubQrCodeAssetUrl
              hubConnectionAssetUrl
              hubSearchAssetUrl
              waterLeakDetectorAssetUrl
              waterLeakDetectorInstructionsAssetUrl
              waterLeakDetectorHelpAssetUrl
              waterSensorTapeAssetUrl
              waterSensorTapeInstructionsAssetUrl
              waterLeakSystemPressHubButtonAssetUrl
              sintefLogoAssetUrl
              __typename
            }
            area {
              id
              areaName
              areaType
              size
              floor
              children
              devices {
                id
                deviceType
                name
                serialNumber
                firmwareVersion
                imei
                macAddress
                ipAddress
                roomPlacement
                favorite
                favoriteChangedAt
                registeredAt
                canBeUnregistered
                __typename
              }
              accessPolicies {
                actionType
                create
                update
                delete
                read
                __typename
              }
              __typename
            }
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              manufacturer {
                id
                name
                __typename
              }
              deviceTemplate {
                id
                deviceType
                name
                primaryPairingMode
                secondaryPairingModes
                __typename
              }
              image {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              area {
                id
                areaName
                areaType
                size
                floor
                children
                __typename
              }
              traits {
                traitType
                commands
                __typename
              }
              accessPolicy {
                read
                write
                delete
                expiresAt
                __typename
              }
              __typename
            }
            repeaters {
              id
              name
              hubType
              brandName
              firmwareVersion
              serialNumber
              macAddress
              manufacturerInformation
              wasWaterSensorTapePreviouslyConnected
              waterGuard {
                wasWaterSensorTapePreviouslyConnected
                mode
                tamperModeWarningTriggeredAt
                tamperModeTriggersAt
                overrideModeTriggeredAt
                showLevel1Warning
                showLevel2Warning
                __typename
              }
              hubTemplate {
                id
                name
                hubAssetUrl
                hubWithBgAssetUrl
                hubQrCodeAssetUrl
                hubConnectionAssetUrl
                hubSearchAssetUrl
                waterLeakDetectorAssetUrl
                waterLeakDetectorInstructionsAssetUrl
                waterLeakDetectorHelpAssetUrl
                waterSensorTapeAssetUrl
                waterSensorTapeInstructionsAssetUrl
                waterLeakSystemPressHubButtonAssetUrl
                sintefLogoAssetUrl
                __typename
              }
              area {
                id
                areaName
                areaType
                size
                floor
                children
                __typename
              }
              devices {
                id
                deviceType
                name
                serialNumber
                firmwareVersion
                imei
                macAddress
                ipAddress
                roomPlacement
                favorite
                favoriteChangedAt
                registeredAt
                canBeUnregistered
                __typename
              }
              repeaters {
                id
                name
                hubType
                brandName
                firmwareVersion
                serialNumber
                macAddress
                manufacturerInformation
                wasWaterSensorTapePreviouslyConnected
                __typename
              }
              traits {
                traitType
                commands
                __typename
              }
              __typename
            }
            traits {
              traitType
              commands
              attributes {
                ... on TraitAttributeAvailableArmLevels {
                  name
                  dataType
                  editable
                }
                ... on TraitAttributeIsArmed {
                  name
                  dataType
                  editable
                  isArmed
                }
                ... on TraitAttributeArmLevel {
                  name
                  dataType
                  editable
                  armLevel
                }
                ... on TraitAttributeDisarmDefaultTimeout {
                  name
                  dataType
                  editable
                  disarmDefaultTimeout
                }
                ... on TraitAttributeIsConnected {
                  name
                  dataType
                  editable
                  isConnected
                }
                ... on TraitAttributeHeartbeatInterval {
                  name
                  dataType
                  editable
                  heartbeatInterval
                }
                ... on TraitAttributeHeartbeatsThreshold {
                  name
                  dataType
                  editable
                  heartbeatsThreshold
                }
                ... on TraitAttributeGatewayConnected {
                  name
                  dataType
                  editable
                  ethernetConnected
                  wifiConnected
                  cellularConnected
                }
                ... on TraitAttributeWifiConfigured {
                  name
                  dataType
                  editable
                  wifiConfigured
                }
                ... on TraitAttributeNetworkSsid {
                  name
                  dataType
                  editable
                  networkSsid
                }
                ... on TraitAttributeNetworkCredentials {
                  name
                  dataType
                  editable
                  networkCredentials
                }
                ... on TraitAttributeCellularApn {
                  name
                  dataType
                  editable
                  cellularApn
                }
                ... on TraitAttributeCellularOperator {
                  name
                  dataType
                  editable
                  cellularOperator
                }
                ... on TraitAttributeBrightness {
                  name
                  dataType
                  editable
                  brightness
                }
                ... on TraitAttributeColor {
                  name
                  dataType
                  editable
                  hue
                  saturation
                }
                ... on TraitAttributeIsOn {
                  name
                  dataType
                  editable
                  isOn
                }
                ... on TraitAttributeOpenPercent {
                  name
                  dataType
                  editable
                  openPercent
                }
                ... on TraitAttributeCurrentPowerSourceLevel {
                  name
                  dataType
                  editable
                  currentPowerSourceLevel
                }
                ... on TraitAttributeLowBatteryWarning {
                  name
                  dataType
                  editable
                  lowBatteryWarning
                }
                ... on TraitAttributeCurrentPowerSource {
                  name
                  dataType
                  editable
                  currentPowerSource
                }
                ... on TraitAttributeStatusCharging {
                  name
                  dataType
                  editable
                  statusCharging
                }
                ... on TraitAttributeCurrentPowerMode {
                  name
                  dataType
                  editable
                  currentPowerMode
                }
                ... on TraitAttributeAvailablePowerSources {
                  name
                  dataType
                  editable
                  availablePowerSources
                }
                ... on TraitAttributeBatteryVoltage {
                  name
                  dataType
                  editable
                  batteryVoltage
                }
                ... on TraitAttributeMainsVoltage {
                  name
                  dataType
                  editable
                  mainsVoltage
                }
                ... on TraitAttributeRunningPowerConsumption {
                  name
                  dataType
                  editable
                  runningPowerConsumption
                }
                ... on TraitAttributePriority {
                  name
                  dataType
                  editable
                  priority
                }
                ... on TraitAttributeAirQuality {
                  name
                  dataType
                  editable
                  airQuality
                }
                ... on TraitAttributeCo2 {
                  name
                  dataType
                  editable
                  co2
                }
                ... on TraitAttributeHumidity {
                  name
                  dataType
                  editable
                  humidity
                  humidityIndicator
                }
                ... on TraitAttributeTemperature {
                  name
                  dataType
                  editable
                  temperature
                }
                ... on TraitAttributeVoc {
                  name
                  dataType
                  editable
                  voc
                }
                ... on TraitAttributeIsUnlocked {
                  name
                  dataType
                  editable
                  isUnlocked
                }
                ... on TraitAttributeChildLock {
                  name
                  dataType
                  editable
                  childLock
                }
                ... on TraitAttributeFault {
                  name
                  dataType
                  editable
                }
                ... on TraitAttributeTemperatureSetpoint {
                  name
                  dataType
                  editable
                  temperatureSetpoint
                  minSetpoint
                  maxSetpoint
                }
                ... on TraitAttributeTemperatureSetpointPercentage {
                  name
                  dataType
                  editable
                  temperatureSetpointPercentage
                  minSetpoint
                  maxSetpoint
                }
                ... on TraitAttributeAvailableClimateModes {
                  name
                  dataType
                  editable
                  availableClimateModes
                }
                ... on TraitAttributeClimateMode {
                  name
                  dataType
                  editable
                  climateMode
                }
                ... on TraitAttributeMaximumFloorTemperature {
                  name
                  dataType
                  editable
                  maximumFloorTemperature
                }
                ... on TraitAttributeFrostGuard {
                  name
                  dataType
                  editable
                  frostGuard
                }
                ... on TraitAttributeNightSwitch {
                  name
                  dataType
                  editable
                  nightSwitch
                }
                ... on TraitAttributeRegulatorMode {
                  name
                  dataType
                  editable
                  regulatorMode
                }
                ... on TraitAttributeHeatingElementActive {
                  name
                  dataType
                  editable
                  heatingElementActive
                }
                ... on TraitAttributeAlarmSound {
                  name
                  dataType
                  editable
                  alarmSound
                }
                ... on TraitAttributeAlarm {
                  name
                  dataType
                  editable
                  alarm
                  snoozed
                }
                ... on TraitAttributeWaterDetectorCableConnected {
                  name
                  dataType
                  editable
                  waterDetectorCableConnected
                }
                ... on TraitAttributeWaterValvesConnected {
                  name
                  dataType
                  editable
                  waterValvesConnected
                }
                ... on TraitAttributeBracket {
                  name
                  dataType
                  editable
                  bracket
                }
                ... on TraitAttributeDisabled {
                  name
                  dataType
                  editable
                  disabled
                }
                ... on TraitAttributeLocation {
                  name
                  dataType
                  editable
                  latitude
                  longitude
                }
                ... on TraitAttributeMotionDetected {
                  name
                  dataType
                  editable
                  lastMotionDetectedAt
                }
                ... on TraitAttributeValveInfo {
                  name
                  dataType
                  editable
                  valveType
                  typeOfValve
                  numValves
                }
                ... on TraitAttributeElectricVehicleCharger {
                  name
                  dataType
                  editable
                  status
                  chargingStartedAt
                  kwhCharged
                  chargingSessionCost
                }
                ... on TraitAttributeTestMode {
                  name
                  dataType
                  editable
                  isOn
                  lastTriggeredAt
                }
                ... on TraitAttributePing {
                  name
                  dataType
                  editable
                  ping
                }
              }
              __typename
            }
            __typename
          }
          traits {
            traitType
            commands
            attributes {
              ... on TraitAttributeAvailableArmLevels {
                name
                dataType
                editable
                availableArmLevels {
                  id
                  description
                  __typename
                }
              }
              ... on TraitAttributeIsArmed {
                name
                dataType
                editable
                isArmed
              }
              ... on TraitAttributeArmLevel {
                name
                dataType
                editable
                armLevel
              }
              ... on TraitAttributeDisarmDefaultTimeout {
                name
                dataType
                editable
                disarmDefaultTimeout
              }
              ... on TraitAttributeIsConnected {
                name
                dataType
                editable
                isConnected
              }
              ... on TraitAttributeHeartbeatInterval {
                name
                dataType
                editable
                heartbeatInterval
              }
              ... on TraitAttributeHeartbeatsThreshold {
                name
                dataType
                editable
                heartbeatsThreshold
              }
              ... on TraitAttributeGatewayConnected {
                name
                dataType
                editable
                ethernetConnected
                wifiConnected
                cellularConnected
              }
              ... on TraitAttributeWifiConfigured {
                name
                dataType
                editable
                wifiConfigured
              }
              ... on TraitAttributeNetworkSsid {
                name
                dataType
                editable
                networkSsid
              }
              ... on TraitAttributeNetworkCredentials {
                name
                dataType
                editable
                networkCredentials
              }
              ... on TraitAttributeCellularApn {
                name
                dataType
                editable
                cellularApn
              }
              ... on TraitAttributeCellularOperator {
                name
                dataType
                editable
                cellularOperator
              }
              ... on TraitAttributeBrightness {
                name
                dataType
                editable
                brightness
              }
              ... on TraitAttributeColor {
                name
                dataType
                editable
                hue
                saturation
              }
              ... on TraitAttributeIsOn {
                name
                dataType
                editable
                isOn
              }
              ... on TraitAttributeOpenPercent {
                name
                dataType
                editable
                openPercent
              }
              ... on TraitAttributeCurrentPowerSourceLevel {
                name
                dataType
                editable
                currentPowerSourceLevel
              }
              ... on TraitAttributeLowBatteryWarning {
                name
                dataType
                editable
                lowBatteryWarning
              }
              ... on TraitAttributeCurrentPowerSource {
                name
                dataType
                editable
                currentPowerSource
              }
              ... on TraitAttributeStatusCharging {
                name
                dataType
                editable
                statusCharging
              }
              ... on TraitAttributeCurrentPowerMode {
                name
                dataType
                editable
                currentPowerMode
              }
              ... on TraitAttributeAvailablePowerSources {
                name
                dataType
                editable
                availablePowerSources
              }
              ... on TraitAttributeBatteryVoltage {
                name
                dataType
                editable
                batteryVoltage
              }
              ... on TraitAttributeMainsVoltage {
                name
                dataType
                editable
                mainsVoltage
              }
              ... on TraitAttributeRunningPowerConsumption {
                name
                dataType
                editable
                runningPowerConsumption
              }
              ... on TraitAttributePriority {
                name
                dataType
                editable
                priority
              }
              ... on TraitAttributeAirQuality {
                name
                dataType
                editable
                airQuality
              }
              ... on TraitAttributeCo2 {
                name
                dataType
                editable
                co2
              }
              ... on TraitAttributeHumidity {
                name
                dataType
                editable
                humidity
                humidityIndicator
              }
              ... on TraitAttributeTemperature {
                name
                dataType
                editable
                temperature
              }
              ... on TraitAttributeVoc {
                name
                dataType
                editable
                voc
              }
              ... on TraitAttributeIsUnlocked {
                name
                dataType
                editable
                isUnlocked
              }
              ... on TraitAttributeChildLock {
                name
                dataType
                editable
                childLock
              }
              ... on TraitAttributeFault {
                name
                dataType
                editable
                fault {
                  id
                  name
                  code
                  description
                  consumer
                  guiPriority
                  __typename
                }
              }
              ... on TraitAttributeTemperatureSetpoint {
                name
                dataType
                editable
                temperatureSetpoint
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeTemperatureSetpointPercentage {
                name
                dataType
                editable
                temperatureSetpointPercentage
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeAvailableClimateModes {
                name
                dataType
                editable
                availableClimateModes
              }
              ... on TraitAttributeClimateMode {
                name
                dataType
                editable
                climateMode
              }
              ... on TraitAttributeMaximumFloorTemperature {
                name
                dataType
                editable
                maximumFloorTemperature
              }
              ... on TraitAttributeFrostGuard {
                name
                dataType
                editable
                frostGuard
              }
              ... on TraitAttributeNightSwitch {
                name
                dataType
                editable
                nightSwitch
              }
              ... on TraitAttributeRegulatorMode {
                name
                dataType
                editable
                regulatorMode
              }
              ... on TraitAttributeHeatingElementActive {
                name
                dataType
                editable
                heatingElementActive
              }
              ... on TraitAttributeAlarmSound {
                name
                dataType
                editable
                alarmSound
              }
              ... on TraitAttributeAlarm {
                name
                dataType
                editable
                alarm
                snoozed
              }
              ... on TraitAttributeWaterDetectorCableConnected {
                name
                dataType
                editable
                waterDetectorCableConnected
              }
              ... on TraitAttributeWaterValvesConnected {
                name
                dataType
                editable
                waterValvesConnected
              }
              ... on TraitAttributeBracket {
                name
                dataType
                editable
                bracket
              }
              ... on TraitAttributeDisabled {
                name
                dataType
                editable
                disabled
              }
              ... on TraitAttributeLocation {
                name
                dataType
                editable
                latitude
                longitude
              }
              ... on TraitAttributeMotionDetected {
                name
                dataType
                editable
                lastMotionDetectedAt
              }
              ... on TraitAttributeValveInfo {
                name
                dataType
                editable
                valveType
                typeOfValve
                numValves
              }
              ... on TraitAttributeElectricVehicleCharger {
                name
                dataType
                editable
                status
                chargingStartedAt
                kwhCharged
                chargingSessionCost
              }
              ... on TraitAttributeTestMode {
                name
                dataType
                editable
                isOn
                lastTriggeredAt
              }
              ... on TraitAttributePing {
                name
                dataType
                editable
                ping
              }
            }
            __typename
          }
          __typename
        }
        description {
          projectNumber
          orderReferenceNumber
          buildingTitle
          __typename
        }
      }
    }
  }
`;
export const installerProjects = /* GraphQL */ `
  query InstallerProjects {
    installerProjects {
      installerProjects {
        ... on TransferredProject {
          id
          installerId
          avatar {
            url {
              original
              small
              medium
              large
              __typename
            }
            uploadUrl
            __typename
          }
          buildingType
          buildingYear
          buildingSize
          locationInfo {
            country
            city
            postalCode
            street
            streetNumber
            unitNumber
            geoLocation {
              latitude
              longitude
              __typename
            }
            __typename
          }
          constructionType
          description {
            projectNumber
            orderReferenceNumber
            buildingTitle
            __typename
          }
          hubs {
            area {
              areaName
              areaType
              __typename
            }
            __typename
          }
          devices {
            area {
              areaName
              areaType
              __typename
            }
            deviceType
            __typename
          }
        }
        ... on OngoingProject {
          id
          installerId
          avatar {
            url {
              original
              small
              medium
              large
              __typename
            }
            uploadUrl
            __typename
          }
          home {
            id
            locationInfo {
              country
              city
              postalCode
              street
              streetNumber
              unitNumber
              geoLocation {
                latitude
                longitude
                __typename
              }
              __typename
            }
            homeInfo {
              nickname
              avatarUrl
              size
              ageOfBuilding
              numberOfFloors
              numberOfAdults
              numberOfChildren
              homeType
              dwellingUnit
              heatingType
              cooling
              ventilation
              propertyManagerName
              constructionType
              buildingYear
              __typename
            }
            avatar {
              url
              urlSmall
              urlMedium
              urlLarge
              uploadUrl
              __typename
            }
            areas {
              id
              areaName
              areaType
              size
              floor
              children
              devices {
                id
                deviceType
                name
                serialNumber
                firmwareVersion
                imei
                macAddress
                ipAddress
                roomPlacement
                favorite
                favoriteChangedAt
                registeredAt
                canBeUnregistered
                __typename
              }
              accessPolicies {
                actionType
                create
                update
                delete
                read
                __typename
              }
              __typename
            }
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              manufacturer {
                id
                name
                __typename
              }
              deviceTemplate {
                id
                deviceType
                name
                primaryPairingMode
                secondaryPairingModes
                __typename
              }
              image {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              area {
                id
                areaName
                areaType
                size
                floor
                children
                __typename
              }
              traits {
                traitType
                commands
                __typename
              }
              accessPolicy {
                read
                write
                delete
                expiresAt
                __typename
              }
              __typename
            }
            hubs {
              id
              name
              hubType
              brandName
              firmwareVersion
              serialNumber
              macAddress
              manufacturerInformation
              wasWaterSensorTapePreviouslyConnected
              waterGuard {
                wasWaterSensorTapePreviouslyConnected
                mode
                tamperModeWarningTriggeredAt
                tamperModeTriggersAt
                overrideModeTriggeredAt
                showLevel1Warning
                showLevel2Warning
                __typename
              }
              hubTemplate {
                id
                name
                hubAssetUrl
                hubWithBgAssetUrl
                hubQrCodeAssetUrl
                hubConnectionAssetUrl
                hubSearchAssetUrl
                waterLeakDetectorAssetUrl
                waterLeakDetectorInstructionsAssetUrl
                waterLeakDetectorHelpAssetUrl
                waterSensorTapeAssetUrl
                waterSensorTapeInstructionsAssetUrl
                waterLeakSystemPressHubButtonAssetUrl
                sintefLogoAssetUrl
                __typename
              }
              area {
                id
                areaName
                areaType
                size
                floor
                children
                __typename
              }
              devices {
                id
                deviceType
                name
                serialNumber
                firmwareVersion
                imei
                macAddress
                ipAddress
                roomPlacement
                favorite
                favoriteChangedAt
                registeredAt
                canBeUnregistered
                __typename
              }
              repeaters {
                id
                name
                hubType
                brandName
                firmwareVersion
                serialNumber
                macAddress
                manufacturerInformation
                wasWaterSensorTapePreviouslyConnected
                __typename
              }
              traits {
                traitType
                commands
                __typename
              }
              __typename
            }
            __typename
          }
          devices {
            id
            deviceType
            name
            serialNumber
            firmwareVersion
            imei
            macAddress
            ipAddress
            roomPlacement
            manufacturer {
              id
              name
              logo {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              __typename
            }
            deviceTemplate {
              id
              deviceType
              name
              primaryPairingMode
              secondaryPairingModes
              manufacturer {
                id
                name
                __typename
              }
              image {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              imageWithBg {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              installationInstructions {
                language
                header
                description
                __typename
              }
              instructions {
                connectionAssetUrl
                __typename
              }
              __typename
            }
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            favorite
            favoriteChangedAt
            registeredAt
            canBeUnregistered
            area {
              id
              areaName
              areaType
              size
              floor
              children
              devices {
                id
                deviceType
                name
                serialNumber
                firmwareVersion
                imei
                macAddress
                ipAddress
                roomPlacement
                favorite
                favoriteChangedAt
                registeredAt
                canBeUnregistered
                __typename
              }
              accessPolicies {
                actionType
                create
                update
                delete
                read
                __typename
              }
              __typename
            }
            traits {
              traitType
              commands
              attributes {
                ... on TraitAttributeAvailableArmLevels {
                  name
                  dataType
                  editable
                }
                ... on TraitAttributeIsArmed {
                  name
                  dataType
                  editable
                  isArmed
                }
                ... on TraitAttributeArmLevel {
                  name
                  dataType
                  editable
                  armLevel
                }
                ... on TraitAttributeDisarmDefaultTimeout {
                  name
                  dataType
                  editable
                  disarmDefaultTimeout
                }
                ... on TraitAttributeIsConnected {
                  name
                  dataType
                  editable
                  isConnected
                }
                ... on TraitAttributeHeartbeatInterval {
                  name
                  dataType
                  editable
                  heartbeatInterval
                }
                ... on TraitAttributeHeartbeatsThreshold {
                  name
                  dataType
                  editable
                  heartbeatsThreshold
                }
                ... on TraitAttributeGatewayConnected {
                  name
                  dataType
                  editable
                  ethernetConnected
                  wifiConnected
                  cellularConnected
                }
                ... on TraitAttributeWifiConfigured {
                  name
                  dataType
                  editable
                  wifiConfigured
                }
                ... on TraitAttributeNetworkSsid {
                  name
                  dataType
                  editable
                  networkSsid
                }
                ... on TraitAttributeNetworkCredentials {
                  name
                  dataType
                  editable
                  networkCredentials
                }
                ... on TraitAttributeCellularApn {
                  name
                  dataType
                  editable
                  cellularApn
                }
                ... on TraitAttributeCellularOperator {
                  name
                  dataType
                  editable
                  cellularOperator
                }
                ... on TraitAttributeBrightness {
                  name
                  dataType
                  editable
                  brightness
                }
                ... on TraitAttributeColor {
                  name
                  dataType
                  editable
                  hue
                  saturation
                }
                ... on TraitAttributeIsOn {
                  name
                  dataType
                  editable
                  isOn
                }
                ... on TraitAttributeOpenPercent {
                  name
                  dataType
                  editable
                  openPercent
                }
                ... on TraitAttributeCurrentPowerSourceLevel {
                  name
                  dataType
                  editable
                  currentPowerSourceLevel
                }
                ... on TraitAttributeLowBatteryWarning {
                  name
                  dataType
                  editable
                  lowBatteryWarning
                }
                ... on TraitAttributeCurrentPowerSource {
                  name
                  dataType
                  editable
                  currentPowerSource
                }
                ... on TraitAttributeStatusCharging {
                  name
                  dataType
                  editable
                  statusCharging
                }
                ... on TraitAttributeCurrentPowerMode {
                  name
                  dataType
                  editable
                  currentPowerMode
                }
                ... on TraitAttributeAvailablePowerSources {
                  name
                  dataType
                  editable
                  availablePowerSources
                }
                ... on TraitAttributeBatteryVoltage {
                  name
                  dataType
                  editable
                  batteryVoltage
                }
                ... on TraitAttributeMainsVoltage {
                  name
                  dataType
                  editable
                  mainsVoltage
                }
                ... on TraitAttributeRunningPowerConsumption {
                  name
                  dataType
                  editable
                  runningPowerConsumption
                }
                ... on TraitAttributePriority {
                  name
                  dataType
                  editable
                  priority
                }
                ... on TraitAttributeAirQuality {
                  name
                  dataType
                  editable
                  airQuality
                }
                ... on TraitAttributeCo2 {
                  name
                  dataType
                  editable
                  co2
                }
                ... on TraitAttributeHumidity {
                  name
                  dataType
                  editable
                  humidity
                  humidityIndicator
                }
                ... on TraitAttributeTemperature {
                  name
                  dataType
                  editable
                  temperature
                }
                ... on TraitAttributeVoc {
                  name
                  dataType
                  editable
                  voc
                }
                ... on TraitAttributeIsUnlocked {
                  name
                  dataType
                  editable
                  isUnlocked
                }
                ... on TraitAttributeChildLock {
                  name
                  dataType
                  editable
                  childLock
                }
                ... on TraitAttributeFault {
                  name
                  dataType
                  editable
                }
                ... on TraitAttributeTemperatureSetpoint {
                  name
                  dataType
                  editable
                  temperatureSetpoint
                  minSetpoint
                  maxSetpoint
                }
                ... on TraitAttributeTemperatureSetpointPercentage {
                  name
                  dataType
                  editable
                  temperatureSetpointPercentage
                  minSetpoint
                  maxSetpoint
                }
                ... on TraitAttributeAvailableClimateModes {
                  name
                  dataType
                  editable
                  availableClimateModes
                }
                ... on TraitAttributeClimateMode {
                  name
                  dataType
                  editable
                  climateMode
                }
                ... on TraitAttributeMaximumFloorTemperature {
                  name
                  dataType
                  editable
                  maximumFloorTemperature
                }
                ... on TraitAttributeFrostGuard {
                  name
                  dataType
                  editable
                  frostGuard
                }
                ... on TraitAttributeNightSwitch {
                  name
                  dataType
                  editable
                  nightSwitch
                }
                ... on TraitAttributeRegulatorMode {
                  name
                  dataType
                  editable
                  regulatorMode
                }
                ... on TraitAttributeHeatingElementActive {
                  name
                  dataType
                  editable
                  heatingElementActive
                }
                ... on TraitAttributeAlarmSound {
                  name
                  dataType
                  editable
                  alarmSound
                }
                ... on TraitAttributeAlarm {
                  name
                  dataType
                  editable
                  alarm
                  snoozed
                }
                ... on TraitAttributeWaterDetectorCableConnected {
                  name
                  dataType
                  editable
                  waterDetectorCableConnected
                }
                ... on TraitAttributeWaterValvesConnected {
                  name
                  dataType
                  editable
                  waterValvesConnected
                }
                ... on TraitAttributeBracket {
                  name
                  dataType
                  editable
                  bracket
                }
                ... on TraitAttributeDisabled {
                  name
                  dataType
                  editable
                  disabled
                }
                ... on TraitAttributeLocation {
                  name
                  dataType
                  editable
                  latitude
                  longitude
                }
                ... on TraitAttributeMotionDetected {
                  name
                  dataType
                  editable
                  lastMotionDetectedAt
                }
                ... on TraitAttributeValveInfo {
                  name
                  dataType
                  editable
                  valveType
                  typeOfValve
                  numValves
                }
                ... on TraitAttributeElectricVehicleCharger {
                  name
                  dataType
                  editable
                  status
                  chargingStartedAt
                  kwhCharged
                  chargingSessionCost
                }
                ... on TraitAttributeTestMode {
                  name
                  dataType
                  editable
                  isOn
                  lastTriggeredAt
                }
                ... on TraitAttributePing {
                  name
                  dataType
                  editable
                  ping
                }
              }
              __typename
            }
            accessPolicy {
              read
              write
              delete
              expiresAt
              __typename
            }
            __typename
          }
          hubs {
            id
            name
            hubType
            brandName
            firmwareVersion
            serialNumber
            macAddress
            manufacturerInformation
            wasWaterSensorTapePreviouslyConnected
            waterGuard {
              wasWaterSensorTapePreviouslyConnected
              mode
              tamperModeWarningTriggeredAt
              tamperModeTriggersAt
              overrideModeTriggeredAt
              showLevel1Warning
              showLevel2Warning
              __typename
            }
            hubTemplate {
              id
              name
              hubAssetUrl
              hubWithBgAssetUrl
              hubQrCodeAssetUrl
              hubConnectionAssetUrl
              hubSearchAssetUrl
              waterLeakDetectorAssetUrl
              waterLeakDetectorInstructionsAssetUrl
              waterLeakDetectorHelpAssetUrl
              waterSensorTapeAssetUrl
              waterSensorTapeInstructionsAssetUrl
              waterLeakSystemPressHubButtonAssetUrl
              sintefLogoAssetUrl
              __typename
            }
            area {
              id
              areaName
              areaType
              size
              floor
              children
              devices {
                id
                deviceType
                name
                serialNumber
                firmwareVersion
                imei
                macAddress
                ipAddress
                roomPlacement
                favorite
                favoriteChangedAt
                registeredAt
                canBeUnregistered
                __typename
              }
              accessPolicies {
                actionType
                create
                update
                delete
                read
                __typename
              }
              __typename
            }
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              manufacturer {
                id
                name
                __typename
              }
              deviceTemplate {
                id
                deviceType
                name
                primaryPairingMode
                secondaryPairingModes
                __typename
              }
              image {
                url
                urlSmall
                urlMedium
                urlLarge
                __typename
              }
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              area {
                id
                areaName
                areaType
                size
                floor
                children
                __typename
              }
              traits {
                traitType
                commands
                __typename
              }
              accessPolicy {
                read
                write
                delete
                expiresAt
                __typename
              }
              __typename
            }
            repeaters {
              id
              name
              hubType
              brandName
              firmwareVersion
              serialNumber
              macAddress
              manufacturerInformation
              wasWaterSensorTapePreviouslyConnected
              waterGuard {
                wasWaterSensorTapePreviouslyConnected
                mode
                tamperModeWarningTriggeredAt
                tamperModeTriggersAt
                overrideModeTriggeredAt
                showLevel1Warning
                showLevel2Warning
                __typename
              }
              hubTemplate {
                id
                name
                hubAssetUrl
                hubWithBgAssetUrl
                hubQrCodeAssetUrl
                hubConnectionAssetUrl
                hubSearchAssetUrl
                waterLeakDetectorAssetUrl
                waterLeakDetectorInstructionsAssetUrl
                waterLeakDetectorHelpAssetUrl
                waterSensorTapeAssetUrl
                waterSensorTapeInstructionsAssetUrl
                waterLeakSystemPressHubButtonAssetUrl
                sintefLogoAssetUrl
                __typename
              }
              area {
                id
                areaName
                areaType
                size
                floor
                children
                __typename
              }
              devices {
                id
                deviceType
                name
                serialNumber
                firmwareVersion
                imei
                macAddress
                ipAddress
                roomPlacement
                favorite
                favoriteChangedAt
                registeredAt
                canBeUnregistered
                __typename
              }
              repeaters {
                id
                name
                hubType
                brandName
                firmwareVersion
                serialNumber
                macAddress
                manufacturerInformation
                wasWaterSensorTapePreviouslyConnected
                __typename
              }
              traits {
                traitType
                commands
                __typename
              }
              __typename
            }
            traits {
              traitType
              commands
              attributes {
                ... on TraitAttributeAvailableArmLevels {
                  name
                  dataType
                  editable
                }
                ... on TraitAttributeIsArmed {
                  name
                  dataType
                  editable
                  isArmed
                }
                ... on TraitAttributeArmLevel {
                  name
                  dataType
                  editable
                  armLevel
                }
                ... on TraitAttributeDisarmDefaultTimeout {
                  name
                  dataType
                  editable
                  disarmDefaultTimeout
                }
                ... on TraitAttributeIsConnected {
                  name
                  dataType
                  editable
                  isConnected
                }
                ... on TraitAttributeHeartbeatInterval {
                  name
                  dataType
                  editable
                  heartbeatInterval
                }
                ... on TraitAttributeHeartbeatsThreshold {
                  name
                  dataType
                  editable
                  heartbeatsThreshold
                }
                ... on TraitAttributeGatewayConnected {
                  name
                  dataType
                  editable
                  ethernetConnected
                  wifiConnected
                  cellularConnected
                }
                ... on TraitAttributeWifiConfigured {
                  name
                  dataType
                  editable
                  wifiConfigured
                }
                ... on TraitAttributeNetworkSsid {
                  name
                  dataType
                  editable
                  networkSsid
                }
                ... on TraitAttributeNetworkCredentials {
                  name
                  dataType
                  editable
                  networkCredentials
                }
                ... on TraitAttributeCellularApn {
                  name
                  dataType
                  editable
                  cellularApn
                }
                ... on TraitAttributeCellularOperator {
                  name
                  dataType
                  editable
                  cellularOperator
                }
                ... on TraitAttributeBrightness {
                  name
                  dataType
                  editable
                  brightness
                }
                ... on TraitAttributeColor {
                  name
                  dataType
                  editable
                  hue
                  saturation
                }
                ... on TraitAttributeIsOn {
                  name
                  dataType
                  editable
                  isOn
                }
                ... on TraitAttributeOpenPercent {
                  name
                  dataType
                  editable
                  openPercent
                }
                ... on TraitAttributeCurrentPowerSourceLevel {
                  name
                  dataType
                  editable
                  currentPowerSourceLevel
                }
                ... on TraitAttributeLowBatteryWarning {
                  name
                  dataType
                  editable
                  lowBatteryWarning
                }
                ... on TraitAttributeCurrentPowerSource {
                  name
                  dataType
                  editable
                  currentPowerSource
                }
                ... on TraitAttributeStatusCharging {
                  name
                  dataType
                  editable
                  statusCharging
                }
                ... on TraitAttributeCurrentPowerMode {
                  name
                  dataType
                  editable
                  currentPowerMode
                }
                ... on TraitAttributeAvailablePowerSources {
                  name
                  dataType
                  editable
                  availablePowerSources
                }
                ... on TraitAttributeBatteryVoltage {
                  name
                  dataType
                  editable
                  batteryVoltage
                }
                ... on TraitAttributeMainsVoltage {
                  name
                  dataType
                  editable
                  mainsVoltage
                }
                ... on TraitAttributeRunningPowerConsumption {
                  name
                  dataType
                  editable
                  runningPowerConsumption
                }
                ... on TraitAttributePriority {
                  name
                  dataType
                  editable
                  priority
                }
                ... on TraitAttributeAirQuality {
                  name
                  dataType
                  editable
                  airQuality
                }
                ... on TraitAttributeCo2 {
                  name
                  dataType
                  editable
                  co2
                }
                ... on TraitAttributeHumidity {
                  name
                  dataType
                  editable
                  humidity
                  humidityIndicator
                }
                ... on TraitAttributeTemperature {
                  name
                  dataType
                  editable
                  temperature
                }
                ... on TraitAttributeVoc {
                  name
                  dataType
                  editable
                  voc
                }
                ... on TraitAttributeIsUnlocked {
                  name
                  dataType
                  editable
                  isUnlocked
                }
                ... on TraitAttributeChildLock {
                  name
                  dataType
                  editable
                  childLock
                }
                ... on TraitAttributeFault {
                  name
                  dataType
                  editable
                }
                ... on TraitAttributeTemperatureSetpoint {
                  name
                  dataType
                  editable
                  temperatureSetpoint
                  minSetpoint
                  maxSetpoint
                }
                ... on TraitAttributeTemperatureSetpointPercentage {
                  name
                  dataType
                  editable
                  temperatureSetpointPercentage
                  minSetpoint
                  maxSetpoint
                }
                ... on TraitAttributeAvailableClimateModes {
                  name
                  dataType
                  editable
                  availableClimateModes
                }
                ... on TraitAttributeClimateMode {
                  name
                  dataType
                  editable
                  climateMode
                }
                ... on TraitAttributeMaximumFloorTemperature {
                  name
                  dataType
                  editable
                  maximumFloorTemperature
                }
                ... on TraitAttributeFrostGuard {
                  name
                  dataType
                  editable
                  frostGuard
                }
                ... on TraitAttributeNightSwitch {
                  name
                  dataType
                  editable
                  nightSwitch
                }
                ... on TraitAttributeRegulatorMode {
                  name
                  dataType
                  editable
                  regulatorMode
                }
                ... on TraitAttributeHeatingElementActive {
                  name
                  dataType
                  editable
                  heatingElementActive
                }
                ... on TraitAttributeAlarmSound {
                  name
                  dataType
                  editable
                  alarmSound
                }
                ... on TraitAttributeAlarm {
                  name
                  dataType
                  editable
                  alarm
                  snoozed
                }
                ... on TraitAttributeWaterDetectorCableConnected {
                  name
                  dataType
                  editable
                  waterDetectorCableConnected
                }
                ... on TraitAttributeWaterValvesConnected {
                  name
                  dataType
                  editable
                  waterValvesConnected
                }
                ... on TraitAttributeBracket {
                  name
                  dataType
                  editable
                  bracket
                }
                ... on TraitAttributeDisabled {
                  name
                  dataType
                  editable
                  disabled
                }
                ... on TraitAttributeLocation {
                  name
                  dataType
                  editable
                  latitude
                  longitude
                }
                ... on TraitAttributeMotionDetected {
                  name
                  dataType
                  editable
                  lastMotionDetectedAt
                }
                ... on TraitAttributeValveInfo {
                  name
                  dataType
                  editable
                  valveType
                  typeOfValve
                  numValves
                }
                ... on TraitAttributeElectricVehicleCharger {
                  name
                  dataType
                  editable
                  status
                  chargingStartedAt
                  kwhCharged
                  chargingSessionCost
                }
                ... on TraitAttributeTestMode {
                  name
                  dataType
                  editable
                  isOn
                  lastTriggeredAt
                }
                ... on TraitAttributePing {
                  name
                  dataType
                  editable
                  ping
                }
              }
              __typename
            }
            __typename
          }
          description {
            projectNumber
            orderReferenceNumber
            buildingTitle
            __typename
          }
        }
      }
      __typename
    }
  }
`;
export const emergencyContact = /* GraphQL */ `
  query EmergencyContact($emergencyContactId: ID!) {
    emergencyContact(emergencyContactId: $emergencyContactId) {
      id
      firstName
      lastName
      phoneNumber {
        countryCode
        nationalNumber
        __typename
      }
      isAlarmStationContact
      __typename
    }
  }
`;
export const emergencyContacts = /* GraphQL */ `
  query EmergencyContacts($homeId: ID!) {
    emergencyContacts(homeId: $homeId) {
      emergencyContacts {
        id
        firstName
        lastName
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        isAlarmStationContact
        __typename
      }
      __typename
    }
  }
`;
export const alarmStationInfo = /* GraphQL */ `
  query AlarmStationInfo($homeId: ID!) {
    alarmStationInfo(homeId: $homeId) {
      homeId
      status
      contacts {
        id
        firstName
        lastName
        phoneNumber {
          countryCode
          nationalNumber
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const command = /* GraphQL */ `
  query Command($commandId: ID!) {
    command(commandId: $commandId) {
      id
      homeId
      commandType
      commandState
      triggeredAt
      __typename
    }
  }
`;
export const deviceGroups = /* GraphQL */ `
  query DeviceGroups($homeId: ID!) {
    deviceGroups(homeId: $homeId) {
      deviceGroups {
        id
        devices {
          id
          deviceType
          name
          serialNumber
          firmwareVersion
          imei
          macAddress
          ipAddress
          roomPlacement
          manufacturer {
            id
            name
            logo {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            __typename
          }
          deviceTemplate {
            id
            deviceType
            name
            primaryPairingMode
            secondaryPairingModes
            manufacturer {
              id
              name
              __typename
            }
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            imageWithBg {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            installationInstructions {
              language
              header
              description
              __typename
            }
            instructions {
              connectionAssetUrl
              __typename
            }
            __typename
          }
          image {
            url
            urlSmall
            urlMedium
            urlLarge
            __typename
          }
          favorite
          favoriteChangedAt
          registeredAt
          canBeUnregistered
          area {
            id
            areaName
            areaType
            size
            floor
            children
            devices {
              id
              deviceType
              name
              serialNumber
              firmwareVersion
              imei
              macAddress
              ipAddress
              roomPlacement
              favorite
              favoriteChangedAt
              registeredAt
              canBeUnregistered
              __typename
            }
            accessPolicies {
              actionType
              create
              update
              delete
              read
              __typename
            }
            __typename
          }
          traits {
            traitType
            commands
            attributes {
              ... on TraitAttributeAvailableArmLevels {
                name
                dataType
                editable
              }
              ... on TraitAttributeIsArmed {
                name
                dataType
                editable
                isArmed
              }
              ... on TraitAttributeArmLevel {
                name
                dataType
                editable
                armLevel
              }
              ... on TraitAttributeDisarmDefaultTimeout {
                name
                dataType
                editable
                disarmDefaultTimeout
              }
              ... on TraitAttributeIsConnected {
                name
                dataType
                editable
                isConnected
              }
              ... on TraitAttributeHeartbeatInterval {
                name
                dataType
                editable
                heartbeatInterval
              }
              ... on TraitAttributeHeartbeatsThreshold {
                name
                dataType
                editable
                heartbeatsThreshold
              }
              ... on TraitAttributeGatewayConnected {
                name
                dataType
                editable
                ethernetConnected
                wifiConnected
                cellularConnected
              }
              ... on TraitAttributeWifiConfigured {
                name
                dataType
                editable
                wifiConfigured
              }
              ... on TraitAttributeNetworkSsid {
                name
                dataType
                editable
                networkSsid
              }
              ... on TraitAttributeNetworkCredentials {
                name
                dataType
                editable
                networkCredentials
              }
              ... on TraitAttributeCellularApn {
                name
                dataType
                editable
                cellularApn
              }
              ... on TraitAttributeCellularOperator {
                name
                dataType
                editable
                cellularOperator
              }
              ... on TraitAttributeBrightness {
                name
                dataType
                editable
                brightness
              }
              ... on TraitAttributeColor {
                name
                dataType
                editable
                hue
                saturation
              }
              ... on TraitAttributeIsOn {
                name
                dataType
                editable
                isOn
              }
              ... on TraitAttributeOpenPercent {
                name
                dataType
                editable
                openPercent
              }
              ... on TraitAttributeCurrentPowerSourceLevel {
                name
                dataType
                editable
                currentPowerSourceLevel
              }
              ... on TraitAttributeLowBatteryWarning {
                name
                dataType
                editable
                lowBatteryWarning
              }
              ... on TraitAttributeCurrentPowerSource {
                name
                dataType
                editable
                currentPowerSource
              }
              ... on TraitAttributeStatusCharging {
                name
                dataType
                editable
                statusCharging
              }
              ... on TraitAttributeCurrentPowerMode {
                name
                dataType
                editable
                currentPowerMode
              }
              ... on TraitAttributeAvailablePowerSources {
                name
                dataType
                editable
                availablePowerSources
              }
              ... on TraitAttributeBatteryVoltage {
                name
                dataType
                editable
                batteryVoltage
              }
              ... on TraitAttributeMainsVoltage {
                name
                dataType
                editable
                mainsVoltage
              }
              ... on TraitAttributeRunningPowerConsumption {
                name
                dataType
                editable
                runningPowerConsumption
              }
              ... on TraitAttributePriority {
                name
                dataType
                editable
                priority
              }
              ... on TraitAttributeAirQuality {
                name
                dataType
                editable
                airQuality
              }
              ... on TraitAttributeCo2 {
                name
                dataType
                editable
                co2
              }
              ... on TraitAttributeHumidity {
                name
                dataType
                editable
                humidity
                humidityIndicator
              }
              ... on TraitAttributeTemperature {
                name
                dataType
                editable
                temperature
              }
              ... on TraitAttributeVoc {
                name
                dataType
                editable
                voc
              }
              ... on TraitAttributeIsUnlocked {
                name
                dataType
                editable
                isUnlocked
              }
              ... on TraitAttributeChildLock {
                name
                dataType
                editable
                childLock
              }
              ... on TraitAttributeFault {
                name
                dataType
                editable
              }
              ... on TraitAttributeTemperatureSetpoint {
                name
                dataType
                editable
                temperatureSetpoint
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeTemperatureSetpointPercentage {
                name
                dataType
                editable
                temperatureSetpointPercentage
                minSetpoint
                maxSetpoint
              }
              ... on TraitAttributeAvailableClimateModes {
                name
                dataType
                editable
                availableClimateModes
              }
              ... on TraitAttributeClimateMode {
                name
                dataType
                editable
                climateMode
              }
              ... on TraitAttributeMaximumFloorTemperature {
                name
                dataType
                editable
                maximumFloorTemperature
              }
              ... on TraitAttributeFrostGuard {
                name
                dataType
                editable
                frostGuard
              }
              ... on TraitAttributeNightSwitch {
                name
                dataType
                editable
                nightSwitch
              }
              ... on TraitAttributeRegulatorMode {
                name
                dataType
                editable
                regulatorMode
              }
              ... on TraitAttributeHeatingElementActive {
                name
                dataType
                editable
                heatingElementActive
              }
              ... on TraitAttributeAlarmSound {
                name
                dataType
                editable
                alarmSound
              }
              ... on TraitAttributeAlarm {
                name
                dataType
                editable
                alarm
                snoozed
              }
              ... on TraitAttributeWaterDetectorCableConnected {
                name
                dataType
                editable
                waterDetectorCableConnected
              }
              ... on TraitAttributeWaterValvesConnected {
                name
                dataType
                editable
                waterValvesConnected
              }
              ... on TraitAttributeBracket {
                name
                dataType
                editable
                bracket
              }
              ... on TraitAttributeDisabled {
                name
                dataType
                editable
                disabled
              }
              ... on TraitAttributeLocation {
                name
                dataType
                editable
                latitude
                longitude
              }
              ... on TraitAttributeMotionDetected {
                name
                dataType
                editable
                lastMotionDetectedAt
              }
              ... on TraitAttributeValveInfo {
                name
                dataType
                editable
                valveType
                typeOfValve
                numValves
              }
              ... on TraitAttributeElectricVehicleCharger {
                name
                dataType
                editable
                status
                chargingStartedAt
                kwhCharged
                chargingSessionCost
              }
              ... on TraitAttributeTestMode {
                name
                dataType
                editable
                isOn
                lastTriggeredAt
              }
              ... on TraitAttributePing {
                name
                dataType
                editable
                ping
              }
            }
            __typename
          }
          accessPolicy {
            read
            write
            delete
            expiresAt
            __typename
          }
          __typename
        }
        name
        favorite {
          isFavorite
          changedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const freezeProtection = /* GraphQL */ `
  query FreezeProtection($homeId: ID!) {
    freezeProtection(homeId: $homeId) {
      enabled
      thresholdTemperature
      temperatureSensors {
        id
        deviceType
        name
        serialNumber
        firmwareVersion
        imei
        macAddress
        ipAddress
        roomPlacement
        manufacturer {
          id
          name
          logo {
            url
            urlSmall
            urlMedium
            urlLarge
            __typename
          }
          __typename
        }
        deviceTemplate {
          id
          deviceType
          name
          primaryPairingMode
          secondaryPairingModes
          manufacturer {
            id
            name
            logo {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            __typename
          }
          image {
            url
            urlSmall
            urlMedium
            urlLarge
            __typename
          }
          imageWithBg {
            url
            urlSmall
            urlMedium
            urlLarge
            __typename
          }
          installationInstructions {
            language
            header
            description
            installationAsset {
              url
              assetType
              __typename
            }
            connectionAsset {
              url
              assetType
              __typename
            }
            __typename
          }
          instructions {
            installation {
              assetUrl
              __typename
            }
            configuration {
              assetUrl
              __typename
            }
            help {
              assetUrl
              __typename
            }
            connectionAssetUrl
            __typename
          }
          __typename
        }
        image {
          url
          urlSmall
          urlMedium
          urlLarge
          __typename
        }
        favorite
        favoriteChangedAt
        registeredAt
        canBeUnregistered
        area {
          id
          areaName
          areaType
          size
          floor
          children
          devices {
            id
            deviceType
            name
            serialNumber
            firmwareVersion
            imei
            macAddress
            ipAddress
            roomPlacement
            manufacturer {
              id
              name
              __typename
            }
            deviceTemplate {
              id
              deviceType
              name
              primaryPairingMode
              secondaryPairingModes
              __typename
            }
            image {
              url
              urlSmall
              urlMedium
              urlLarge
              __typename
            }
            favorite
            favoriteChangedAt
            registeredAt
            canBeUnregistered
            area {
              id
              areaName
              areaType
              size
              floor
              children
              __typename
            }
            traits {
              traitType
              commands
              __typename
            }
            accessPolicy {
              read
              write
              delete
              expiresAt
              __typename
            }
            __typename
          }
          accessPolicies {
            actionType
            create
            update
            delete
            read
            __typename
          }
          __typename
        }
        traits {
          traitType
          commands
          attributes {
            ... on TraitAttributeAvailableArmLevels {
              name
              dataType
              editable
              availableArmLevels {
                id
                description
                __typename
              }
            }
            ... on TraitAttributeIsArmed {
              name
              dataType
              editable
              isArmed
            }
            ... on TraitAttributeArmLevel {
              name
              dataType
              editable
              armLevel
            }
            ... on TraitAttributeDisarmDefaultTimeout {
              name
              dataType
              editable
              disarmDefaultTimeout
            }
            ... on TraitAttributeIsConnected {
              name
              dataType
              editable
              isConnected
            }
            ... on TraitAttributeHeartbeatInterval {
              name
              dataType
              editable
              heartbeatInterval
            }
            ... on TraitAttributeHeartbeatsThreshold {
              name
              dataType
              editable
              heartbeatsThreshold
            }
            ... on TraitAttributeGatewayConnected {
              name
              dataType
              editable
              ethernetConnected
              wifiConnected
              cellularConnected
            }
            ... on TraitAttributeWifiConfigured {
              name
              dataType
              editable
              wifiConfigured
            }
            ... on TraitAttributeNetworkSsid {
              name
              dataType
              editable
              networkSsid
            }
            ... on TraitAttributeNetworkCredentials {
              name
              dataType
              editable
              networkCredentials
            }
            ... on TraitAttributeCellularApn {
              name
              dataType
              editable
              cellularApn
            }
            ... on TraitAttributeCellularOperator {
              name
              dataType
              editable
              cellularOperator
            }
            ... on TraitAttributeBrightness {
              name
              dataType
              editable
              brightness
            }
            ... on TraitAttributeColor {
              name
              dataType
              editable
              hue
              saturation
            }
            ... on TraitAttributeIsOn {
              name
              dataType
              editable
              isOn
            }
            ... on TraitAttributeOpenPercent {
              name
              dataType
              editable
              openPercent
            }
            ... on TraitAttributeCurrentPowerSourceLevel {
              name
              dataType
              editable
              currentPowerSourceLevel
            }
            ... on TraitAttributeLowBatteryWarning {
              name
              dataType
              editable
              lowBatteryWarning
            }
            ... on TraitAttributeCurrentPowerSource {
              name
              dataType
              editable
              currentPowerSource
            }
            ... on TraitAttributeStatusCharging {
              name
              dataType
              editable
              statusCharging
            }
            ... on TraitAttributeCurrentPowerMode {
              name
              dataType
              editable
              currentPowerMode
            }
            ... on TraitAttributeAvailablePowerSources {
              name
              dataType
              editable
              availablePowerSources
            }
            ... on TraitAttributeBatteryVoltage {
              name
              dataType
              editable
              batteryVoltage
            }
            ... on TraitAttributeMainsVoltage {
              name
              dataType
              editable
              mainsVoltage
            }
            ... on TraitAttributeRunningPowerConsumption {
              name
              dataType
              editable
              runningPowerConsumption
            }
            ... on TraitAttributePriority {
              name
              dataType
              editable
              priority
            }
            ... on TraitAttributeAirQuality {
              name
              dataType
              editable
              airQuality
            }
            ... on TraitAttributeCo2 {
              name
              dataType
              editable
              co2
            }
            ... on TraitAttributeHumidity {
              name
              dataType
              editable
              humidity
              humidityIndicator
            }
            ... on TraitAttributeTemperature {
              name
              dataType
              editable
              temperature
            }
            ... on TraitAttributeVoc {
              name
              dataType
              editable
              voc
            }
            ... on TraitAttributeIsUnlocked {
              name
              dataType
              editable
              isUnlocked
            }
            ... on TraitAttributeChildLock {
              name
              dataType
              editable
              childLock
            }
            ... on TraitAttributeFault {
              name
              dataType
              editable
              fault {
                id
                name
                code
                description
                consumer
                guiPriority
                __typename
              }
            }
            ... on TraitAttributeTemperatureSetpoint {
              name
              dataType
              editable
              temperatureSetpoint
              minSetpoint
              maxSetpoint
            }
            ... on TraitAttributeTemperatureSetpointPercentage {
              name
              dataType
              editable
              temperatureSetpointPercentage
              minSetpoint
              maxSetpoint
            }
            ... on TraitAttributeAvailableClimateModes {
              name
              dataType
              editable
              availableClimateModes
            }
            ... on TraitAttributeClimateMode {
              name
              dataType
              editable
              climateMode
            }
            ... on TraitAttributeMaximumFloorTemperature {
              name
              dataType
              editable
              maximumFloorTemperature
            }
            ... on TraitAttributeFrostGuard {
              name
              dataType
              editable
              frostGuard
            }
            ... on TraitAttributeNightSwitch {
              name
              dataType
              editable
              nightSwitch
            }
            ... on TraitAttributeRegulatorMode {
              name
              dataType
              editable
              regulatorMode
            }
            ... on TraitAttributeHeatingElementActive {
              name
              dataType
              editable
              heatingElementActive
            }
            ... on TraitAttributeAlarmSound {
              name
              dataType
              editable
              alarmSound
            }
            ... on TraitAttributeAlarm {
              name
              dataType
              editable
              alarm
              snoozed
            }
            ... on TraitAttributeWaterDetectorCableConnected {
              name
              dataType
              editable
              waterDetectorCableConnected
            }
            ... on TraitAttributeWaterValvesConnected {
              name
              dataType
              editable
              waterValvesConnected
            }
            ... on TraitAttributeBracket {
              name
              dataType
              editable
              bracket
            }
            ... on TraitAttributeDisabled {
              name
              dataType
              editable
              disabled
            }
            ... on TraitAttributeLocation {
              name
              dataType
              editable
              latitude
              longitude
            }
            ... on TraitAttributeMotionDetected {
              name
              dataType
              editable
              lastMotionDetectedAt
            }
            ... on TraitAttributeValveInfo {
              name
              dataType
              editable
              valveType
              typeOfValve
              numValves
            }
            ... on TraitAttributeElectricVehicleCharger {
              name
              dataType
              editable
              status
              chargingStartedAt
              kwhCharged
              chargingSessionCost
            }
            ... on TraitAttributeTestMode {
              name
              dataType
              editable
              isOn
              lastTriggeredAt
            }
            ... on TraitAttributePing {
              name
              dataType
              editable
              ping
            }
          }
          __typename
        }
        accessPolicy {
          read
          write
          delete
          expiresAt
          __typename
        }
        __typename
      }
      triggeredAt
      __typename
    }
  }
`;
export const smartChargeUserSync = /* GraphQL */ `
  query SmartChargeUserSync {
    smartChargeUserSync {
      payload
      __typename
    }
  }
`;
export const getGeofenceStatus = /* GraphQL */ `
  query GetGeofenceStatus($homeId: ID!) {
    getGeofenceStatus(homeId: $homeId) {
      isActive
      radius
      __typename
    }
  }
`;
export const getMOBActivityStatus = /* GraphQL */ `
  query GetMOBActivityStatus($homeId: ID!) {
    getMOBActivityStatus(homeId: $homeId) {
      isActive
      countdownTime
      emergencyMessageSentAt
      __typename
    }
  }
`;
