import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CompanyInfoModule } from 'src/app/components/company/company-info/company-info.module';
import { AddCompanyComponent } from './add-company.component';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    CompanyInfoModule,
    MatIconModule,
    PipesModule
  ],
  declarations: [AddCompanyComponent]
})
export class AddCompanyModule {}
