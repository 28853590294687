import { Action, createAction, props } from '@ngrx/store';

import { BrainTreeDTO } from 'src/app/dtos';

import {
  ClientOperatorPackage,
  OperatorPackage
} from '../../operator-package/operator-package.model';

export const SelectOperatorPackageID = createAction(
  '[OperatorPackage] Select OperatorPackage ID',
  props<{ PK_OperatorPackageID: number }>()
);

export const OpenModal = createAction('[OperatorPackage] Open modal');
export const CloseModal = createAction('[OperatorPackage] Close modal');

export const LoadAllOperatorPackage = createAction(
  '[OperatorPackage] Load all OperatorPackage'
);

export const AllOperatorPackageLoaded = createAction(
  '[OperatorPackage] All OperatorPackage loaded',
  props<{ operatorPackages: OperatorPackage[] }>()
);

export const LoadOperatorPackage = createAction(
  '[OperatorPackage] Load an OperatorPackage',
  props<{ PK_OperatorPackageID: number }>()
);

export const CreateOperatorPackage = createAction(
  '[OperatorPackage] Create OperatorPackage',
  props<{ operatorPackage: OperatorPackage }>()
);

export const CreateOperatorPackageSuccess = createAction(
  '[OperatorPackage] Create OperatorPackage Success',
  props<{ operatorPackage: OperatorPackage }>()
);

export const UpdateOperatorPackageSuccess = createAction(
  '[OperatorPackage] Update OperatorPackage Success',
  props<{ operatorPackage: OperatorPackage }>()
);

export const SetSelectedCountryID = createAction(
  '[OperatorPackage] Set selected countryID',
  props<{ PK_CountryID: number }>()
);

// export const ClientOperatorPackageUpdated = createAction(
//   '[OperatorPackage] Client OperatorPackage Updated',
//   props<{ clientPackage: ClientOperatorPackage }>()
// );
